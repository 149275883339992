import {
  EnterpriseDesignSettingsDto,
  getEnterprisesPublicIDDesignSettings,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getClientZoneEnterpriseDesignSettingsAsync = createAsyncAction(
  "@clientZone/GET_ENTERPRISE_DESIGN_SETTINGS_REQUEST",
  "@clientZone/GET_ENTERPRISE_DESIGN_SETTINGS_RESPONSE",
  "@clientZone/GET_ENTERPRISE_DESIGN_SETTINGS_FAILURE",
)<{ publicID: string }, EnterpriseDesignSettingsDto, Error>();

function* getClientZoneEnterpriseDesignSettings(
  action: ReturnType<typeof getClientZoneEnterpriseDesignSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getEnterprisesPublicIDDesignSettings,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getClientZoneEnterpriseDesignSettingsAsync.success(data));
      return;
    }

    yield put(
      getClientZoneEnterpriseDesignSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(getClientZoneEnterpriseDesignSettingsAsync.failure(err as Error));
  }
}
export function* getClientZoneEnterpriseDesignSettingsSaga() {
  yield takeLatest(
    getType(getClientZoneEnterpriseDesignSettingsAsync.request),
    getClientZoneEnterpriseDesignSettings,
  );
}

export const getClientZoneEnterpriseDesignSettingsSlice = createSlice({
  initialState: getFetchStateDefaultValue<EnterpriseDesignSettingsDto>(false),
  name: "@clientZone/getClientZoneEnterpriseDesignSettings",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EnterpriseDesignSettingsDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getClientZoneEnterpriseDesignSettingsAsync, builder);
    handleSuccessCase(getClientZoneEnterpriseDesignSettingsAsync, builder);
    handleFailureCase(getClientZoneEnterpriseDesignSettingsAsync, builder);
  },
});

export const { resetSlice } =
  getClientZoneEnterpriseDesignSettingsSlice.actions;
