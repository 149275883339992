import { ClientInvoiceStatus, OrderDto, RelationType, StepType } from "Api/Api";
import { OrderDetailIconButton } from "Components/Orders/Detail/Styles";
import { BlConfirmDialog } from "Components/Shared/Dialogs/BlConfirmDialog";
import { ContractRemoveIcon } from "Components/Shared/Icons/ContractRemove";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { cancelInvoiceForClientByOrderAsync } from "State/Invoice/CancelInvoiceForClientByOrderSlice";
import { Resources, useResource } from "Translations/Resources";
import { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

const StyledTrashIcon = styled(ContractRemoveIcon)`
  color: ${props => props.theme.palette.error.main} !important;
`;

type Props = {
  order: OrderDto;
};

export const CancelInvoiceIcon: React.FunctionComponent<Props> = props => {
  const { order } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useResource();
  const invoice = order.invoices?.[0];
  const navigate = useNavigate();

  const isReadOnly = order.userRelationType === RelationType.Visibility;
  const isZeroPrice = order.isZeroPrice;
  const disabled =
    order.currentStepType !== StepType.ClientInvoiceIssuance ||
    invoice.status === ClientInvoiceStatus.Canceled ||
    isZeroPrice ||
    isReadOnly;

  const disabledText = isZeroPrice
    ? Resources.Orders.CancelInvoiceConfirmDialog.Tooltip.IsZeroPrice
    : isReadOnly
      ? Resources.Orders.CancelInvoiceConfirmDialog.Tooltip.IsReadOnly
      : Resources.Orders.CancelInvoiceConfirmDialog.Tooltip.Disabled;

  const handleCancel = (isAccepted: boolean) => {
    if (disabled) {
      return;
    }

    if (isAccepted) {
      dispatch(
        cancelInvoiceForClientByOrderAsync.request({
          invoicePublicID: invoice.invoicePublicID,
          navigate: navigate,
          orderPublicID: order.publicID,
        }),
      );
    }
    setIsOpen(false);
  };
  return (
    <>
      <OrderDetailIconButton
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        title={
          disabled
            ? t(disabledText)
            : t(Resources.Orders.CancelInvoiceConfirmDialog.Tooltip.Enabled)
        }
      >
        <StyledTrashIcon />
      </OrderDetailIconButton>

      <BlConfirmDialog
        isOpen={isOpen}
        handleResult={handleCancel}
        modalTitle={t(Resources.Orders.CancelInvoiceConfirmDialog.Title)}
        modalContent={t(Resources.Orders.CancelInvoiceConfirmDialog.Text)}
      />
    </>
  );
};
