import {
  GetPaidUserCommissionQueryResult,
  getCutoffsPaidUserCommission,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getPaidUserCommissionAsync = createAsyncAction(
  "@dashboard/GET_PAID_USER_COMMISSION_REQUEST",
  "@dashboard/GET_PAID_USER_COMMISSION_COUNTS_RESPONSE",
  "@dashboard/GET_PAID_USER_COMMISSION_COUNTS_FAILURE",
)<{}, GetPaidUserCommissionQueryResult, Error>();

function* getPaidUserCommission(
  action: ReturnType<typeof getPaidUserCommissionAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(getCutoffsPaidUserCommission);

    if (status === 200) {
      yield put(getPaidUserCommissionAsync.success(data));
      return;
    }

    yield put(
      getPaidUserCommissionAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getPaidUserCommissionAsync.failure(err as Error));
  }
}
export function* getPaidUserCommissionSaga() {
  yield takeLatest(
    getType(getPaidUserCommissionAsync.request),
    getPaidUserCommission,
  );
}

export const getPaidUserCommissionSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<GetPaidUserCommissionQueryResult>(false),
  name: "collection",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<GetPaidUserCommissionQueryResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getPaidUserCommissionAsync, builder);
    handleSuccessCase(getPaidUserCommissionAsync, builder);
    handleFailureCase(getPaidUserCommissionAsync, builder);
  },
});

export const { resetSlice } = getPaidUserCommissionSlice.actions;
