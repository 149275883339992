import {
  getOrdersPublicIDOrderServiceOrderServiceIDEucsOrderInfo,
  GetEucsOrderInfoQueryResult,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getEucsOrderInfoAsync = createAsyncAction(
  "@order/GET_EUCS_ORDER_INFO_REQUEST",
  "@order/GET_EUCS_ORDER_INFO_RESPONSE",
  "@order/GET_EUCS_ORDER_INFO_FAILURE",
)<
  {
    orderPublicID: string;
    orderServiceID: number;
  },
  GetEucsOrderInfoQueryResult,
  Error
>();

function* getEucsOrderInfo(
  action: ReturnType<typeof getEucsOrderInfoAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrdersPublicIDOrderServiceOrderServiceIDEucsOrderInfo,
      action.payload.orderPublicID,
      action.payload.orderServiceID,
    );

    if (status === 200) {
      yield put(getEucsOrderInfoAsync.success(data));
      return;
    }

    yield put(
      getEucsOrderInfoAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getEucsOrderInfoAsync.failure(err as Error));
  }
}
export function* getEucsOrderInfoSaga() {
  yield takeLatest(getType(getEucsOrderInfoAsync.request), getEucsOrderInfo);
}

export const getEucsOrderInfoSlice = createSlice({
  initialState: getFetchStateDefaultValue<GetEucsOrderInfoQueryResult>(false),
  name: "detail",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<GetEucsOrderInfoQueryResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getEucsOrderInfoAsync, builder);
    handleSuccessCase(getEucsOrderInfoAsync, builder);
    handleFailureCase(getEucsOrderInfoAsync, builder);
  },
});

export const { resetSlice: getEucsOrderInfoReset } =
  getEucsOrderInfoSlice.actions;
