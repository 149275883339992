import {
  ClientInvoiceRecurringPaymentCancelCommandResult,
  postOrdersPublicIDWorkflowClientInvoiceRecurringPaymentPaymentCalendarItemIDCancel,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getPaymentCalendarItemsAsync } from "State/OrderWorkflow/Slices/GetPaymentCalendarItemsSlice";

export const cancelPaymentCalendarItemAsync = createAsyncAction(
  "@orderWorkflow/CANCEL_PAYMENT_CALENDAR_ITEM_REQUEST",
  "@orderWorkflow/CANCEL_PAYMENT_CALENDAR_ITEM_RESPONSE",
  "@orderWorkflow/CANCEL_PAYMENT_CALENDAR_ITEM_FAILURE",
)<
  {
    orderPublicID: string;
    paymentCalendarItemID: number;
  },
  ClientInvoiceRecurringPaymentCancelCommandResult,
  Error
>();

function* cancelPaymentCalendarItem(
  action: ReturnType<typeof cancelPaymentCalendarItemAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowClientInvoiceRecurringPaymentPaymentCalendarItemIDCancel,
      action.payload.orderPublicID,
      action.payload.paymentCalendarItemID,
    );

    if (status === 200) {
      yield put(
        getPaymentCalendarItemsAsync.request({
          publicID: action.payload.orderPublicID,
        }),
      );
      yield put(cancelPaymentCalendarItemAsync.success(data));
      return;
    }

    yield put(
      cancelPaymentCalendarItemAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(cancelPaymentCalendarItemAsync.failure(err as Error));
  }
}
export function* cancelPaymentCalendarItemSaga() {
  yield takeLatest(
    getType(cancelPaymentCalendarItemAsync.request),
    cancelPaymentCalendarItem,
  );
}

export const cancelPaymentCalendarItemSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<ClientInvoiceRecurringPaymentCancelCommandResult>(
      false,
    ),
  name: "@orderWorkflow/cancelPaymentCalendarItem",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<ClientInvoiceRecurringPaymentCancelCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(cancelPaymentCalendarItemAsync, builder);
    handleSuccessCase(cancelPaymentCalendarItemAsync, builder);
    handleFailureCase(cancelPaymentCalendarItemAsync, builder);
  },
});

export const { resetSlice } = cancelPaymentCalendarItemSlice.actions;
