import { OrderPaymentFrequency } from "Api/Api";
import { addDays, addMonths, format, parse } from "date-fns";

export function formatDate(date: Date) {
  return format(date, "d.M.yyyy")!;
}

export function getDateToOptions(
  dateFrom: Date,
  paymentFrequency: OrderPaymentFrequency,
) {
  switch (paymentFrequency) {
    case OrderPaymentFrequency.Single:
      return [formatDate(dateFrom)].map(x => ({
        Name: x,
        Value: x,
      }));
    case OrderPaymentFrequency.Monthly:
      return Array.from({ length: 48 }, (_, i) => i + 1)
        .map(x => formatDate(addDays(addMonths(dateFrom, x), -1)))
        .map(x => ({
          Name: x,
          Value: x,
        }));
    case OrderPaymentFrequency.Quarterly:
      return Array.from({ length: 16 }, (_, i) => i + 1)
        .map(x =>
          formatDate(addDays(addMonths(dateFrom, Math.max(x * 3, 0)), -1)),
        )
        .map(x => ({
          Name: x,
          Value: x,
        }));
    case OrderPaymentFrequency.Semiannually:
      return Array.from({ length: 8 }, (_, i) => i + 1)
        .map(x =>
          formatDate(addDays(addMonths(dateFrom, Math.max(x * 6, 0)), -1)),
        )
        .map(x => ({
          Name: x,
          Value: x,
        }));
    case OrderPaymentFrequency.Yearly:
      return Array.from({ length: 4 }, (_, i) => i + 1)
        .map(x =>
          formatDate(addDays(addMonths(dateFrom, Math.max(x * 12, 0)), -1)),
        )
        .map(x => ({
          Name: x,
          Value: x,
        }));
    default:
      return [];
  }
}

export function getDefaultToDate(
  dateFrom: Date,
  paymentFrequency: OrderPaymentFrequency,
) {
  const options = getDateToOptions(dateFrom, paymentFrequency);

  switch (paymentFrequency) {
    case OrderPaymentFrequency.Monthly:
      return options[11].Value;
    case OrderPaymentFrequency.Quarterly:
      return options[3].Value;
    case OrderPaymentFrequency.Semiannually:
      return options[1].Value;
    case OrderPaymentFrequency.Single:
    case OrderPaymentFrequency.Yearly:
    default:
      return options[0].Value;
  }
}

export function orderPaymentFrequencyToMonths(
  paymentFrequency: OrderPaymentFrequency,
) {
  switch (paymentFrequency) {
    case OrderPaymentFrequency.Monthly:
      return 1;
    case OrderPaymentFrequency.Quarterly:
      return 3;
    case OrderPaymentFrequency.Semiannually:
      return 6;
    case OrderPaymentFrequency.Yearly:
      return 12;
    default:
      return 0;
  }
}

/**
 * Parses string at format MM.yyyy to Date
 * @param value string at format MM.yyyy
 */
export function parseDate(value: string) {
  return parse(value, "d.M.yyyy", new Date());
}

export function getDefaultDateFromDateToValues(
  orderPaymentFrequency: OrderPaymentFrequency,
) {
  const now = getInitDate();

  return {
    dateFrom: now,
    dateTo: parseDate(getDefaultToDate(now, orderPaymentFrequency)),
  };
}

export function getInitDate() {
  return new Date();
}
