import {
  EntityListOfOrderWorkflowStepDto,
  getOrdersPublicIDWorkflowSteps,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getOrderWorkflowStepsAsync = createAsyncAction(
  "@orderWorkflow/GET_WORKFLOW_STEPS_REQUEST",
  "@orderWorkflow/GET_WORKFLOW_STEPS_RESPONSE",
  "@orderWorkflow/GET_WORKFLOW_STEPS_FAILURE",
)<
  {
    publicID: string;
  },
  EntityListOfOrderWorkflowStepDto,
  Error
>();

function* getOrderWorkflowSteps(
  action: ReturnType<typeof getOrderWorkflowStepsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrdersPublicIDWorkflowSteps,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getOrderWorkflowStepsAsync.success(data));
      return;
    }

    yield put(
      getOrderWorkflowStepsAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getOrderWorkflowStepsAsync.failure(err as Error));
  }
}
export function* getOrderWorkflowStepsSaga() {
  yield takeLatest(
    getType(getOrderWorkflowStepsAsync.request),
    getOrderWorkflowSteps,
  );
}

export const getOrderWorkflowStepsSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfOrderWorkflowStepDto>(false),
  name: "@orderWorkflow/workflowSteps",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfOrderWorkflowStepDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getOrderWorkflowStepsAsync, builder);
    handleSuccessCase(getOrderWorkflowStepsAsync, builder);
    handleFailureCase(getOrderWorkflowStepsAsync, builder);
  },
});

export const { resetSlice: getOrderWorkflowStepsReset } =
  getOrderWorkflowStepsSlice.actions;
