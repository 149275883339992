import { Resources, useResource } from "Translations/Resources";
import { useEffect } from "react";
import { setLocale } from "yup";

const YupResource = Resources.Validation;

export function useYupLocale() {
  const { t } = useResource();

  useEffect(() => {
    // TODO: make full locale support

    setLocale({
      mixed: {
        required: t(YupResource.Required),
        notNull: t(YupResource.Required),
      },
      array: {
        min: ({ min: count }) => t(YupResource.Array.Min, { count }),
      },
      string: {
        email: t(YupResource.InvalidEmail),
        min: ({ min: count }) => t(YupResource.String.Min, { count }),
        matches: t(YupResource.String.InvalidFormat),
        max: ({ max: count }) => t(YupResource.String.Max, { count }),
        length: ({ length: count }) =>
          t(YupResource.String.StrictLength, { count }),
      },
      number: {
        integer: t(YupResource.Number.Integer),
        min: ({ min: count }) => t(YupResource.Number.Min, { count }),
        moreThan: ({ more: count }) =>
          t(YupResource.Number.MoreThen, { count }),
      },
    });
  }, [t]);
}
