import { Typography } from "@mui/material";
import { LoadingWrapper } from "Components/Orders/Draft/LoadingWrapper";
import { OrderFormModel } from "Components/Orders/Draft/StepFormValidationSchema";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { Control, FieldErrors } from "react-hook-form";

type Props = {
  control: Control<OrderFormModel, any>;
  errors: FieldErrors<OrderFormModel>;
  isLoading: boolean;
  isReadOnly: boolean;
};

export const NoteFields: React.FunctionComponent<Props> = props => {
  const { control, errors, isLoading, isReadOnly } = props;
  const { t } = useResource();

  return (
    <StyledContentWrapper>
      <Typography variant="h2" marginBottom={2}>
        {t(Resources.Orders.Detail.Note.Title)}
      </Typography>

      <LoadingWrapper isLoading={isLoading}>
        <BlFormInput
          control={control}
          errors={errors}
          name={"note"}
          label={t(Resources.Orders.Detail.Note.Label)}
          placeholder={t(Resources.Orders.Detail.Note.Placeholder)}
          maxLength={1024}
          disabled={isReadOnly}
        ></BlFormInput>
      </LoadingWrapper>
    </StyledContentWrapper>
  );
};
