import { Box, Typography } from "@mui/material";
import {
  OrderPeriodicity,
  OrderServiceRate,
  ServiceVariantFrequency,
} from "Api/Api";
import { OrderFormModel } from "Components/Orders/Draft/StepFormValidationSchema";
import { ServiceDescription } from "Components/Orders/Summary/ServiceDescription";
import { BlTooltip } from "Components/Shared/BlTooltip";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { CheckIcon, EditIcon } from "Components/Shared/Icons";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import {
  BlDropdown,
  DropdownValueType,
} from "Components/Shared/Inputs/Form/BlDropdown";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useTaxLabels } from "Hooks/useWithTax";
import { CodeListItem } from "Models/ICodeListDto";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import styled from "styled-components";

const StyledWrapper = styled.div<{
  $isSelected: boolean;
  $isHidden: boolean;
}>`
  display: ${props => (props.$isHidden ? "none" : "grid")};
  grid-template-columns: auto 1fr auto;
  gap: ${props => props.theme.spacing(1)};
  padding: ${props => props.theme.spacing(1.5)};
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props =>
    props.$isSelected
      ? props.theme.colors.primaryMainTransparent
      : props.theme.colors.fieldBackground};
  margin-bottom: ${props => props.theme.spacing(1)};

  .main-section {
    .bl-form-control {
      display: flex;
      align-items: center;
      margin-top: ${props => props.theme.spacing(1)};

      &__label {
        padding-right: ${props => props.theme.spacing(2)};
      }

      &__input {
        flex: 0 1 160px;
      }
    }
  }
`;

const StyledEditInput = styled.div`
  position: relative;
  width: 160px;

  .edit-input-adornment {
    position: absolute;
    right: 0;
    top: 0;
    height: 47px;
    width: 42px;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    pointer-events: none;

    &.is-btn {
      cursor: pointer;
      pointer-events: all;
    }
  }

  .MuiFormControl-root {
    padding: 0;
    margin: 0;
  }

  .MuiInputBase-root:not(.Mui-error) + .MuiFormHelperText-root {
    display: none;
  }
`;

const ServiceVariantDropdownWrapper = styled(Box)<{ $isWide: boolean }>`
  .bl-form-control__input {
    ${props => props.$isWide && "min-width: 220px;"}
  }
`;

const EditInput = (props: {
  form: UseFormReturn<OrderFormModel>;
  isVisible: boolean;
  isFixedServiceRate: boolean;
  index: number;
  onSetPrice: () => void;
  isReadOnly: boolean;
}) => {
  const { isFixedServiceRate, form, index, onSetPrice, isVisible, isReadOnly } =
    props;

  const validatePrice = () => {
    form
      .trigger(`services.${index}.priceWithoutTax`, { shouldFocus: true })
      .then(isValid => {
        if (isValid) {
          const priceWithoutTax = form.getValues(
            `services.${index}.priceWithoutTax`,
          );
          const taxRate = form.getValues(`services.${index}.taxRate`);
          const priceWithTax = priceWithoutTax * (1 + taxRate);
          form.setValue(`services.${index}.priceWithTax`, priceWithTax);

          onSetPrice();
        }
      });
  };

  const { t } = useResource();

  if (!isVisible) {
    return null;
  }

  const isDisabled = !form.watch(`services.${index}.isSelected`);

  return (
    <div>
      <StyledEditInput>
        <BlFormInput
          key={`${index}-${isFixedServiceRate}`}
          control={form.control}
          errors={form.formState.errors}
          name={
            isFixedServiceRate
              ? `services.${index}.priceWithoutTax`
              : `services.${index}.hoursSpent`
          }
          disabled={isDisabled || isReadOnly}
          formControlClass="no-padding"
          isNumber
          mask={Number}
          autoFocus={isFixedServiceRate}
          min={0}
          max={isFixedServiceRate ? 100_000 : 9_000}
          scale={2}
        />
        <BlTooltip
          title={isFixedServiceRate ? t(Resources.Common.Save) : null}
          placement="top"
        >
          <div
            className={`edit-input-adornment ${
              isFixedServiceRate ? "is-btn" : ""
            }`}
            onClick={() => isFixedServiceRate && validatePrice()}
          >
            {isFixedServiceRate ? <CheckIcon /> : <Typography>h</Typography>}
          </div>
        </BlTooltip>
      </StyledEditInput>
    </div>
  );
};

type Service = {
  name: string;
  description?: string | null;
  variants?:
    | {
        serviceVariantID: number;
        name: string;
        priceWithoutTax?: number | null;
        priceWithTax?: number | null;
        currencyCode: string;
        taxRate: number;
        frequency: ServiceVariantFrequency;
        isEnabled?: boolean;
      }[]
    | null;
};

type Props = {
  index: number;
  service: Service;
  formService: OrderFormModel["services"][0];
  orderServiceRate: OrderServiceRate;
  form: UseFormReturn<OrderFormModel>;
  canUserSetPrice: boolean;
  isVatUsed: boolean;
  isReadOnly: boolean;
  isServiceDisabled: boolean;
};

export const ServiceFields: React.FunctionComponent<Props> = props => {
  const {
    service,
    orderServiceRate,
    formService,
    form,
    index,
    canUserSetPrice,
    isVatUsed,
    isReadOnly,
    isServiceDisabled,
  } = props;
  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = form;
  const { t } = useResource();

  const isFixedServiceRate = orderServiceRate === OrderServiceRate.FixedPrice;
  const isEditInputVisible = formService.isEditing || !isFixedServiceRate;

  const onVariantSelected = (value: DropdownValueType | null) => {
    const serviceVariant = service?.variants?.find(
      x => x.serviceVariantID === value,
    );

    if (!!serviceVariant) {
      setValue(
        `services.${index}.priceWithoutTax`,
        serviceVariant.priceWithoutTax!,
      );
      setValue(`services.${index}.priceWithTax`, serviceVariant.priceWithTax!);
      setValue(`services.${index}.currencyCode`, serviceVariant.currencyCode);
      setValue(`services.${index}.taxRate`, serviceVariant.taxRate!);
      setValue(
        `services.${index}.originalPriceWithTax`,
        serviceVariant.priceWithTax!,
      );
      setValue(
        `services.${index}.originalPriceWithoutTax`,
        serviceVariant.priceWithoutTax!,
      );
      setValue(`services.${index}.serviceVariantName`, serviceVariant.name);
    }
  };

  const onIsSelectedChange = (value: boolean) => {
    form.setValue(`services.${index}.isEditing`, false);

    if (!value) {
      form.setValue(`services.${index}.hoursSpent`, 0);
    }
  };

  const { withTax, withoutTax } = useTaxLabels();

  const paymentPeriodicity = watch("order.paymentPeriodicity");

  useEffect(() => {
    if (isServiceDisabled) {
      if (form.watch(`services.${index}.isSelected`) === true) {
        form.setValue(`services.${index}.isSelected`, false);
      }
    }
  }, [isServiceDisabled, form, index]);

  return (
    <StyledWrapper
      $isSelected={formService.isSelected}
      $isHidden={isServiceDisabled}
    >
      <div>
        <BlCheckboxInput
          control={control}
          errors={errors}
          name={`services.${index}.isSelected`}
          onChange={onIsSelectedChange}
          disabled={isReadOnly}
        />
      </div>

      <div className="main-section">
        <Typography fontSize={16} fontWeight={700} marginBottom={0.5}>
          {service?.name ?? ""}
        </Typography>

        <ServiceDescription
          fontColor="primary"
          description={formService?.serviceDescription}
          withPackageServicesName={true}
        />

        {isFixedServiceRate && (
          <ServiceVariantDropdownWrapper
            $isWide={paymentPeriodicity !== OrderPeriodicity.Single}
          >
            <BlDropdown
              control={control}
              errors={errors}
              name={`services.${index}.serviceVariantID`}
              disabled={!formService.isSelected || isReadOnly}
              label={t(Resources.Orders.Detail.Services.Variant)}
              codeList={
                service?.variants
                  ?.filter(
                    x => x.isEnabled === true || x.isEnabled === undefined,
                  )
                  .filter(x =>
                    paymentPeriodicity === OrderPeriodicity.Single
                      ? x.frequency === ServiceVariantFrequency.Single
                      : x.frequency === ServiceVariantFrequency.Monthly,
                  )
                  .map(
                    x =>
                      ({
                        name: x.name,
                        id: x.serviceVariantID,
                      }) as CodeListItem,
                  ) ?? []
              }
              onChange={onVariantSelected}
            />
          </ServiceVariantDropdownWrapper>
        )}
      </div>

      <div>
        <StyledFlex $alignItems="center">
          <Box marginRight={1} textAlign={"right"}>
            {!isEditInputVisible && (
              <Typography fontWeight={600} fontSize={16}>
                {withoutTax(
                  formatCurrency(
                    formService.priceWithoutTax,
                    formService.currencyCode,
                  ),
                  isVatUsed,
                )}
              </Typography>
            )}

            <EditInput
              isFixedServiceRate={isFixedServiceRate}
              isVisible={isEditInputVisible}
              form={form}
              index={index}
              onSetPrice={() =>
                form.setValue(`services.${index}.isEditing`, false)
              }
              isReadOnly={isReadOnly}
            />

            {isFixedServiceRate && isVatUsed && (
              <Typography
                variant="subtitle2"
                fontSize={12}
                fontStyle={"normal"}
                textAlign={"right"}
              >
                {withTax(
                  formatCurrency(
                    formService.priceWithTax,
                    formService.currencyCode,
                  ),
                )}
              </Typography>
            )}
          </Box>
          {!isEditInputVisible && canUserSetPrice && (
            <BlTooltip title={t(Resources.Common.Edit)} placement="top">
              <Box>
                <BlDefaultButton
                  disabled={!formService.isSelected || isReadOnly}
                  onClick={() =>
                    form.setValue(`services.${index}.isEditing`, true)
                  }
                >
                  <EditIcon />
                </BlDefaultButton>
              </Box>
            </BlTooltip>
          )}
        </StyledFlex>
      </div>
    </StyledWrapper>
  );
};
