import { SignInCommand, SignInResult, postAuthSignIn } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { NavigateFunction } from "react-router";
import { afterSignInSuccess } from "Utils/AuthUtils";

export const signInAsync = createAsyncAction(
  "@auth/SIGN_IN_REQUEST",
  "@auth/SIGN_IN_RESPONSE",
  "@auth/SIGN_IN_FAILURE",
)<
  {
    model: SignInCommand;
    navigate: NavigateFunction;
  },
  SignInResult,
  Error
>();

function* signIn(action: ReturnType<typeof signInAsync.request>): Generator {
  try {
    const { data, error, status } = yield* call(
      postAuthSignIn,
      action.payload.model,
    );

    if (status === 200) {
      yield put(signInAsync.success(data));
      yield* call(afterSignInSuccess, data, action.payload.navigate);
      return;
    }

    yield put(signInAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(signInAsync.failure(err as Error));
  }
}
export function* signInSaga() {
  yield takeLatest(getType(signInAsync.request), signIn);
}

type SignInSliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: SignInSliceState = {
  isLoading: false,
  error: null,
};

export const signInSlice = createSlice({
  initialState,
  name: "signIn",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(signInAsync.request),
      (state, action: ReturnType<typeof signInAsync.request>) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(signInAsync.success),
      (state, action: ReturnType<typeof signInAsync.success>) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(signInAsync.failure),
      (state, action: ReturnType<typeof signInAsync.failure>) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
