import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { deleteOrdersServicesServiceID } from "Api/Api";
import { getOrderServicesAsync } from "State/Orders/Detail/OrderServicesSlice";
import { getOrderDetailAsync } from "State/Orders/Detail/OrderDetailSlice";

export const deleteOrderServiceAsync = createAsyncAction(
  "@order/DELETE_SERVICE_REQUEST",
  "@order/DELETE_SERVICE_RESPONSE",
  "@order/DELETE_SERVICE_FAILURE",
)<
  {
    orderServiceID: number;
    publicID: string;
  },
  { wasDeleted: boolean },
  Error
>();

function* deleteOrderService(
  action: ReturnType<typeof deleteOrderServiceAsync.request>,
): Generator {
  const { orderServiceID, publicID } = action.payload;
  try {
    const { data, error, status } = yield* call(
      deleteOrdersServicesServiceID,
      orderServiceID,
    );

    if (status === 200) {
      yield put(
        getOrderServicesAsync.request({
          publicID,
        }),
      );
      yield put(getOrderDetailAsync.request({ publicID }));
      yield put(deleteOrderServiceAsync.success({ wasDeleted: true }));
      return;
    }

    yield put(
      deleteOrderServiceAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(deleteOrderServiceAsync.failure(err as Error));
  }
}
export function* deleteOrderServiceSaga() {
  yield takeLatest(
    getType(deleteOrderServiceAsync.request),
    deleteOrderService,
  );
}

export const deleteOrderServiceSlice = createSlice({
  initialState: getFetchStateDefaultValue(false),
  name: "deleteOrderService",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue(false),
  },
  extraReducers: builder => {
    handleRequestCase(deleteOrderServiceAsync, builder);
    handleSuccessCase(deleteOrderServiceAsync, builder);
    handleFailureCase(deleteOrderServiceAsync, builder);
  },
});

export const { resetSlice } = deleteOrderServiceSlice.actions;
