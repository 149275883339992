import { getCompaniesPublicIDDesignSettings } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getClientZoneLogoAsync = createAsyncAction(
  "@clientZone/GET_LOGO_REQUEST",
  "@clientZone/GET_LOGO_RESPONSE",
  "@clientZone/GET_LOGO_FAILURE",
)<{ companyPublicID: string }, State, Error>();

function* getClientZoneLogo(
  action: ReturnType<typeof getClientZoneLogoAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCompaniesPublicIDDesignSettings,
      action.payload.companyPublicID,
    );

    if (status === 200) {
      yield put(getClientZoneLogoAsync.success(data));
      return;
    }

    yield put(
      getClientZoneLogoAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getClientZoneLogoAsync.failure(err as Error));
  }
}
export function* getClientZoneLogoSaga() {
  yield takeLatest(getType(getClientZoneLogoAsync.request), getClientZoneLogo);
}

export type State = {
  logoBase64?: string | null;
};

export const getClientZoneLogoSlice = createSlice({
  initialState: getFetchStateDefaultValue<State>(false),
  name: "@clientZone/getClientZoneLogo",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<State>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getClientZoneLogoAsync, builder);
    handleSuccessCase(getClientZoneLogoAsync, builder);
    handleFailureCase(getClientZoneLogoAsync, builder);
  },
});

export const { resetSlice } = getClientZoneLogoSlice.actions;
