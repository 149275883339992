import { Typography } from "@mui/material";
import { OrderServiceDto } from "Api/Api";
import { StyledOrderSummaryText } from "Components/Orders/Summary/OrderSummary";
import { ServiceDescription } from "Components/Orders/Summary/ServiceDescription";
import { useTaxLabels } from "Hooks/useWithTax";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { styled } from "styled-components";

const StyledService = styled.div`
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.colors.fieldBackground};
  padding: ${props => props.theme.spacing(1.5)};
  display: grid;
  grid-template-columns: 1fr minmax(150px, auto);
  gap: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(1)};

  & > div:last-child {
    justify-self: flex-end;
    text-align: right;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

type Props = {
  service: OrderServiceDto;
  isVatUsed: boolean;
};

const SecondaryText = styled(Typography)<{}>`
  font-size: 12px;
  color: ${props => props.theme.palette.text["secondary"]};
`;
const PageResources = Resources.Orders.Detail.Summary.Services;

export const Service: React.FunctionComponent<Props> = props => {
  const { service, isVatUsed } = props;
  const {
    currencyCode,
    priceWithTax,
    priceWithoutTax,
    serviceName,
    serviceDescription,
    relatedServices,
  } = service;
  const { withTax, withoutTax } = useTaxLabels();
  const { t } = useResource();

  return (
    <StyledService>
      <div>
        <Typography variant="h3" marginBottom={0.5}>
          {serviceName}
        </Typography>

        <ServiceDescription
          fontColor="secondary"
          description={serviceDescription}
          withPackageServicesName={false}
        />

        {relatedServices.length > 0 && (
          <>
            <SecondaryText mt={1} mb={1}>
              {t(PageResources.PackageIncludeText)}
            </SecondaryText>
            {relatedServices.map(x => (
              <div key={x.orderServiceRelatedServiceID}>
                <Typography variant="h3" marginBottom={1} marginTop={1}>
                  {x.serviceName}
                </Typography>

                <ServiceDescription
                  fontColor="secondary"
                  description={x.serviceDescription}
                  withPackageServicesName={false}
                />
              </div>
            ))}
          </>
        )}
      </div>

      <div>
        <Typography variant="h3" fontWeight={600}>
          {withoutTax(formatCurrency(priceWithoutTax, currencyCode), isVatUsed)}
        </Typography>

        {isVatUsed && (
          <StyledOrderSummaryText fontSize={12}>
            {withTax(formatCurrency(priceWithTax, currencyCode))}
          </StyledOrderSummaryText>
        )}
      </div>
    </StyledService>
  );
};
