import {
  SetPeriodicityRequest,
  SetPeriodicityCommandResult,
  putOrdersPublicIDPeriodicity,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderDetailAsync } from "State/Orders/Detail/OrderDetailSlice";

export const setPeriodicityAsync = createAsyncAction(
  "@order/SET_PERIODICITY_REQUEST",
  "@order/SET_PERIODICITY_RESPONSE",
  "@order/SET_PERIODICITY_FAILURE",
)<
  { request: SetPeriodicityRequest; publicID: string },
  SetPeriodicityCommandResult,
  Error
>();

function* setPeriodicity(
  action: ReturnType<typeof setPeriodicityAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      putOrdersPublicIDPeriodicity,
      action.payload.request,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(
        getOrderDetailAsync.request({ publicID: action.payload.publicID }),
      );
      yield put(setPeriodicityAsync.success(data));
      return;
    }

    yield put(setPeriodicityAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(setPeriodicityAsync.failure(err as Error));
  }
}
export function* setPeriodicitySaga() {
  yield takeLatest(getType(setPeriodicityAsync.request), setPeriodicity);
}

export const setPeriodicitySlice = createSlice({
  initialState: getFetchStateDefaultValue<SetPeriodicityCommandResult>(false),
  name: "@order/setPeriodicity",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<SetPeriodicityCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(setPeriodicityAsync, builder);
    handleSuccessCase(setPeriodicityAsync, builder);
    handleFailureCase(setPeriodicityAsync, builder);
  },
});

export const { resetSlice } = setPeriodicitySlice.actions;
