import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  ClientInvoiceStatus,
  OrderDto,
  OrderPaymentType,
  PaymentCalendarItemDto,
  PaymentCalendarItemStatus,
} from "Api/Api";
import { CreatedActions } from "Components/Orders/Detail/Steps/RecurringPayment/Actions/CreatedActions";
import { InvoiceForClientByOrderActions } from "Components/Orders/Detail/Steps/RecurringPayment/Actions/InvoiceForClientByOrderActions";
import { InvoiceIssuedActions } from "Components/Orders/Detail/Steps/RecurringPayment/Actions/InvoiceIssuedActions";
import { BlTooltip } from "Components/Shared/BlTooltip";
import { useParty } from "Hooks/Party/useParty";
import { useTaxLabels } from "Hooks/useWithTax";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { formatDate } from "Utils/DateUtils";
import { Gender } from "Utils/PersonalNumberUtils";
import { max } from "date-fns";
import styled, { useTheme } from "styled-components";

const PageResources = Resources.Orders.Workflow.Workflow.RecurringPaymentStep;

const StyledWrapper = styled(Box)<{
  $isCanceled: boolean;
}>`
  background-color: ${props => props.theme.colors.gray};
  padding: ${props => props.theme.spacing(1.5)};
  border-radius: 7px;

  opacity: ${props => (props.$isCanceled ? 0.5 : 1)};
`;

const StyledStatus = styled.div<{
  $isCanceled: boolean;
  $isPointer: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(0.6, 1.6)};
  border: 1px solid
    ${props =>
      props.$isCanceled
        ? props.theme.palette.error.main
        : props.theme.colors.border};
  border-radius: 36px;
  color: ${props => props.theme.palette.text.secondary};
  cursor: ${props => (props.$isPointer ? "pointer" : "default")};
`;

type Widths =
  | {
      invoiceDate: number;
      price: number;
      commission: number;
      status: number;
      actions: number;
    }
  | {
      invoiceDate: number;
      price: number;
      status: number;
      actions: number;
      commission?: undefined;
    };

type Props = {
  publicID: string;
  item: PaymentCalendarItemDto;
  isWorkflowWithCommission: boolean;
  widths: Widths;
  order: OrderDto;
};

export const CalendarItem: React.FunctionComponent<Props> = props => {
  const { publicID, item, isWorkflowWithCommission, widths, order } = props;
  const {
    invoiceDate,
    priceWithoutTax,
    priceWithTax,
    advisorCommissionWithoutTax,
    advisorCommissionWithTax,
    status,
    currencyCode,
    dateCanceled,
    paidByClientDate,
    invoicePublicID,
    invoiceForClientByOrderNumber,
    paymentCalendarItemID,
    dateOfSentInvoiceToClient,
    lastReminderSent,
    invoiceForClientByOrderStatus,
    invoiceForClientByOrderDateCanceled,
  } = item;

  const { t } = useResource();
  const isPrepaidOrder = order.paymentType === OrderPaymentType.Prepaid;
  const isZeroPrice = order.isZeroPrice;
  const formattedDate =
    status === PaymentCalendarItemStatus.PaidByClient
      ? formatDate(paidByClientDate, true)
      : status === PaymentCalendarItemStatus.Canceled
        ? formatDate(dateCanceled, true)
        : isPrepaidOrder &&
            !!lastReminderSent &&
            status !== PaymentCalendarItemStatus.CommissionPaid
          ? formatDate(lastReminderSent, true)
          : status === PaymentCalendarItemStatus.SentToClient
            ? formatDate(
                max(
                  [dateOfSentInvoiceToClient, lastReminderSent]
                    .filter(x => !!x)
                    .map(x => new Date(x!)),
                ),
                true,
              )
            : "";

  const reminderWasSent =
    (status === PaymentCalendarItemStatus.SentToClient ||
      (isPrepaidOrder && status === PaymentCalendarItemStatus.Created)) && //in prepaid only for created
    !!lastReminderSent;

  const formattedStatus = !!reminderWasSent
    ? `${t(PageResources.ReminderSentStatus)} ${formattedDate}`
    : isZeroPrice
      ? `${t(Resources.PaymentCalendarItemStatus.ZeroPrice)}`
      : isPrepaidOrder && item.status === PaymentCalendarItemStatus.Created
        ? `${t(Resources.PaymentCalendarItemStatus.PrepaidPaymentCreated)}`
        : `${t(Resources.PaymentCalendarItemStatus[status])} ${formattedDate}`;

  const isCanceledPaymentItem = status === PaymentCalendarItemStatus.Canceled;
  const isInvoiceCanceled =
    invoiceForClientByOrderStatus === ClientInvoiceStatus.Canceled;

  const { withTax, withoutTax } = useTaxLabels();
  const isVatUsed = priceWithTax !== priceWithoutTax;
  const isVatUsedAtCommission =
    advisorCommissionWithTax !== advisorCommissionWithoutTax;

  const wasProcessedByRepresentative = reminderWasSent
    ? order.userPartyPublicID !== item.lastReminderSentByUserPartyPublicID
    : order.userPartyPublicID !== item.processedByUserPartyPublicID;

  const { gender, formattedName } = useParty(
    reminderWasSent
      ? item.lastReminderSentByUserPartyPublicID
      : wasProcessedByRepresentative
        ? item.processedByUserPartyPublicID
        : null,
  );
  const theme = useTheme();

  return (
    <StyledWrapper $isCanceled={isCanceledPaymentItem || isInvoiceCanceled}>
      <Grid container>
        <Grid item xs={widths.invoiceDate} alignSelf={"center"}>
          <Typography fontWeight={500}>{formatDate(invoiceDate)}</Typography>
        </Grid>
        <Grid item xs={widths.price} alignSelf={"center"}>
          <Stack ml={0.1}>
            <Typography fontWeight={500} align="left">
              {withoutTax(
                formatCurrency(priceWithoutTax, currencyCode),
                isVatUsed,
              )}
            </Typography>

            {isVatUsed && (
              <Typography align="left" fontSize={12}>
                {withTax(formatCurrency(priceWithTax, currencyCode))}
              </Typography>
            )}
            {item.isInvoiceAfterDueDate && (
              <Typography
                fontWeight={500}
                align="left"
                color={theme.palette.error.main}
              >
                {t(Resources.Common.InvoceAfterDueDate)}
              </Typography>
            )}
            {item.isPrepaidInvoiceAfterDueDate && (
              <Typography
                fontWeight={500}
                align="left"
                color={theme.palette.error.main}
              >
                {t(Resources.Common.InvoceAfterDueDate)}
              </Typography>
            )}
          </Stack>
        </Grid>
        {isWorkflowWithCommission && (
          <Grid item xs={widths.commission} alignSelf={"center"}>
            <>
              {isZeroPrice && <>{t(Resources.Common.NoCommission)}</>}
              {!!advisorCommissionWithTax && (
                <Stack direction={"row"} pl={1} pr={1} justifyContent={"left"}>
                  <Stack alignSelf={"center"}>
                    <Typography align="left" fontWeight={500} mr={0.5}>
                      {withoutTax(
                        formatCurrency(
                          advisorCommissionWithoutTax,
                          currencyCode,
                        ),
                        isVatUsedAtCommission,
                      )}
                    </Typography>

                    {isVatUsedAtCommission && (
                      <Typography align="left" fontSize={12}>
                        {withTax(
                          formatCurrency(
                            advisorCommissionWithTax,
                            currencyCode,
                          ),
                        )}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              )}

              {status === PaymentCalendarItemStatus.PaidByClient && (
                <Typography align="center" fontWeight={500} mr={0.5}>
                  {t(
                    isZeroPrice
                      ? PageResources.ZeroPrice
                      : PageResources.WaitingForCommission,
                  )}
                </Typography>
              )}
            </>
          </Grid>
        )}

        <Grid item xs={widths.status}>
          <BlTooltip
            title={
              !!formattedName
                ? t(
                    reminderWasSent
                      ? Resources.Common.SendByRepresentative
                      : Resources.Common.ProcessedByRepresentative,
                    {
                      name: formattedName,
                      gender: gender === Gender.Female ? "a" : "",
                    },
                  )
                : null
            }
          >
            <StyledStatus
              $isCanceled={isCanceledPaymentItem || isInvoiceCanceled}
              $isPointer={!!formattedName}
            >
              {isInvoiceCanceled ? (
                <Typography align="center" fontWeight={700}>
                  {t(PageResources.CancelInvoice.Status, {
                    date: formatDate(invoiceForClientByOrderDateCanceled, true),
                  })}
                </Typography>
              ) : (
                <Typography align="center" fontWeight={700}>
                  {formattedStatus}
                </Typography>
              )}
            </StyledStatus>
          </BlTooltip>
        </Grid>

        <Grid
          item
          xs={widths.actions}
          alignSelf={"center"}
          display={"inline-flex"}
          justifyContent={"flex-end"}
        >
          <InvoiceIssuedActions
            status={status}
            item={item}
            paymentType={order.paymentType}
            paymentCalendarItemID={paymentCalendarItemID}
            orderPublicID={publicID}
            invoicePublicID={invoicePublicID}
            invoiceForClientByOrderStatus={invoiceForClientByOrderStatus}
          />

          <CreatedActions
            status={status}
            paymentCalendarItemID={paymentCalendarItemID}
            publicID={publicID}
          />

          <InvoiceForClientByOrderActions
            status={status}
            invoicePublicID={invoicePublicID}
            invoiceForClientByOrderNumber={invoiceForClientByOrderNumber}
          />
        </Grid>
      </Grid>
    </StyledWrapper>
  );
};
