import { Typography } from "@mui/material";
import { UpcomingPeriodicOrders } from "Components/Dashboard/UpcomingPeriodicOrders";
import { OrdersInProgress } from "Components/Dashboard/OrdersInProgress";
import { Statistics } from "Components/Dashboard/Statistics";
import { Resources, useResource } from "Translations/Resources";
import styled from "styled-components";

const StyledTablesWrapper = styled.div`
  display: grid;
  grid-template-columns: 695px 1fr;
  gap: ${props => props.theme.spacing(5)};
  margin-top: ${props => props.theme.spacing(8)};
`;

export const Dashboard: React.FunctionComponent = props => {
  const { t } = useResource();

  return (
    <>
      <Typography variant="h1" marginBottom={1}>
        {t(Resources.Dashboard.Title)}
      </Typography>
      <Typography variant="subtitle1">
        {t(Resources.Dashboard.Subtitle)}
      </Typography>

      <Statistics />

      <StyledTablesWrapper>
        <OrdersInProgress />
        <UpcomingPeriodicOrders />
      </StyledTablesWrapper>
    </>
  );
};
