import {
  ClientApprovalRejectRequest,
  ClientApprovalStepRejectCommandResult,
  getOrdersWorkflowClientReviewTokenSummary,
  postOrdersWorkflowClientApprovalTokenReject,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderDetailAsync } from "State/ClientZone/Orders/GetOrderDetailSlice";

export const clientApprovalRejectAsync = createAsyncAction(
  "@clientZone/CLIENT_APPROVAL_REJECT_REQUEST",
  "@clientZone/CLIENT_APPROVAL_REJECT_RESPONSE",
  "@clientZone/CLIENT_APPROVAL_REJECT_FAILURE",
)<
  {
    token: string;
  },
  ClientApprovalStepRejectCommandResult,
  Error
>();

function* clientApprovalReject(
  action: ReturnType<typeof clientApprovalRejectAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersWorkflowClientApprovalTokenReject,
      {
        userAgent: navigator.userAgent,
      } as ClientApprovalRejectRequest,
      action.payload.token,
    );

    if (status === 200) {
      const { data: orderDetail, status: orderDetailStatus } = yield* call(
        getOrdersWorkflowClientReviewTokenSummary,
        action.payload.token,
      );

      if (!!orderDetail && orderDetailStatus === 200) {
        yield put(getOrderDetailAsync.success(orderDetail));
      }

      yield put(clientApprovalRejectAsync.success(data));
      return;
    }

    yield put(
      clientApprovalRejectAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(clientApprovalRejectAsync.failure(err as Error));
  }
}
export function* clientApprovalRejectSaga() {
  yield takeLatest(
    getType(clientApprovalRejectAsync.request),
    clientApprovalReject,
  );
}

export const clientApprovalRejectSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<ClientApprovalStepRejectCommandResult>(false),
  name: "@clientZone/clientApprovalReject",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<ClientApprovalStepRejectCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(clientApprovalRejectAsync, builder);
    handleSuccessCase(clientApprovalRejectAsync, builder);
    handleFailureCase(clientApprovalRejectAsync, builder);
  },
});

export const { resetSlice } = clientApprovalRejectSlice.actions;
