import { AppUser } from "State/Auth/Models/AuthStateModels";
import { CompanyEntityType } from "State/Companies/Detail/SaveCompanyDetailSlice";
import { isNoU } from "Utils/ObjectUtils";

// type IdRouteType = string | number;

export const AppRouting = {
  Error403: {
    path: () => `/403`,
  },
  Error404: {
    path: () => `/404`,
  },
  SignOutPage: {
    path: () => `/`,
  },
  SignUp: {
    path: () => `/sign-up`,
  },
  SignIn: {
    path: () => `/sign-in`,
  },
  GoogleCallback: {
    path: () => `/auth/google`,
  },
  BrokerAuth: {
    path: () => `/auth/broker`,
  },
  BrokerAuthCallback: {
    path: () => `/auth/broker-callback`,
  },
  ForgottenPassword: {
    route: `/forgotten-password`,
    path: () => `/forgotten-password`,
  },
  AwaitingEmailVerification: {
    route: `/awaiting-email-verification`,
    path: () => `/awaiting-email-verification`,
  },
  EmailVerification: {
    route: `/email-verification/:token`,
    path: (token: string) => `/email-verification/${encodeURIComponent(token)}`,
  },
  Dashboard: {
    path: () => `/dashboard`,
  },
  Orders: {
    path: () => `/orders`,
  },
  OrderCreate: {
    path: () => `/order`,
  },
  OrderDetail: {
    route: `/order/:id`,
    path: (id: string, isCloning?: boolean) =>
      `/order/${encodeURIComponent(id)}${isCloning ? "?isCloning=true" : ""}`,
  },
  Settings: {
    route: `/settings/:tab?`,
    path: (tab?: string) =>
      isNoU(tab) ? "/settings" : `/settings/${encodeURIComponent(tab)}`,
  },
  CompanyCreate: {
    route: `/company/:type`,
    path: (type: CompanyEntityType) => `/company/${encodeURIComponent(type)}`,
  },
  CompanyDetail: {
    route: `/company/:type/:id`,
    path: (type: CompanyEntityType, id: string) =>
      `/company/${encodeURIComponent(type)}/${encodeURIComponent(id)}`,
  },
  ClientZoneOrder: {
    route: `/client/order/:id`,
    path: (id: string) => `/client/order/${encodeURIComponent(id)}`,
  },
  ClientZoneRevocation: {
    route: `/client/revocation/:id`,
    path: (id: string) => `/client/revocation/${encodeURIComponent(id)}`,
  },
  ClientZoneTermsAndConditions: {
    route: `/client/terms-conditions/:clientToken/:isAdvisorDetail`,
    path: (clientToken: string, isAdvisorDetail: boolean) =>
      `/client/terms-conditions/${encodeURIComponent(
        clientToken,
      )}/{${encodeURIComponent(isAdvisorDetail)}}`,
  },
  ClientZoneGDPR: {
    route: `/client/gdpr/:clientToken/:isAdvisorDetail`,
    path: (clientToken: string, isAdvisorDetail: boolean) =>
      `/client/gdpr/${encodeURIComponent(clientToken)}/{${encodeURIComponent(
        isAdvisorDetail,
      )}}`,
  },
  ServicePackagesCreate: {
    path: () => `/service-packages/create`,
  },
  ServicePackagesDetail: {
    route: `/service-packages/:name/:id`,
    path: (name: string, id: string) =>
      `/service-packages/${encodeURIComponent(name)}/${encodeURIComponent(id)}`,
  },
} as const;

type RouteObject<T extends any[] = any[]> = {
  route?: string;
  path: (...args: T) => string;
};

export function getRoute<T extends RouteObject>(routeObj: T): string {
  return routeObj.route ?? routeObj.path();
}

export function getPath<T extends RouteObject>(
  routeObj: T,
  ...args: Parameters<T["path"]>
): string {
  return routeObj.path(...args);
}

export function resolveStartPage(
  user: AppUser | null,
  unauthenticatedUrl: string | null,
) {
  if (user === null) {
    return getPath(AppRouting.SignIn);
  }

  if (unauthenticatedUrl) {
    return unauthenticatedUrl;
  }
  return getPath(AppRouting.Dashboard);
}

export function getPathnameAndSearch(location: {
  pathname: string;
  search: string;
}) {
  return `${location.pathname}${location.search}`;
}
