import {
  ClientPrepaidPaymentApprovalStepCompleteCommandResult,
  postOrdersPublicIDWorkflowClientPrepaidPaymentApprovalComplete,
  ClientPrepaidPaymentApprovalStepCompleteCommandRequest,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderWorkflowStepsAsync } from "State/OrderWorkflow/Slices/OrderWorkflowStepsSlice";
import { getOrderDetailAsync } from "State/Orders/Detail/OrderDetailSlice";

export const completeClientPrepaidPaymentApprovalAsync = createAsyncAction(
  "@orderWorkflow/COMPLETE_INVOICE_PREPAID_PAYMENT_REQUEST",
  "@orderWorkflow/COMPLETE_INVOICE_PREPAID_PAYMENT_RESPONSE",
  "@orderWorkflow/COMPLETE_INVOICE_PREPAID_PAYMENT_FAILURE",
)<
  {
    publicID: string;
    request: ClientPrepaidPaymentApprovalStepCompleteCommandRequest;
  },
  ClientPrepaidPaymentApprovalStepCompleteCommandResult,
  Error
>();

function* completeClientPrepaidPaymentApproval(
  action: ReturnType<typeof completeClientPrepaidPaymentApprovalAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowClientPrepaidPaymentApprovalComplete,
      action.payload.request,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(
        getOrderWorkflowStepsAsync.request({
          publicID: action.payload.publicID,
        }),
      );
      yield put(
        getOrderDetailAsync.request({
          publicID: action.payload.publicID,
        }),
      );
      yield put(completeClientPrepaidPaymentApprovalAsync.success(data));
      return;
    }

    yield put(
      completeClientPrepaidPaymentApprovalAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(completeClientPrepaidPaymentApprovalAsync.failure(err as Error));
  }
}
export function* completeClientPrepaidPaymentApprovalSaga() {
  yield takeLatest(
    getType(completeClientPrepaidPaymentApprovalAsync.request),
    completeClientPrepaidPaymentApproval,
  );
}

export const completeClientPrepaidPaymentApprovalSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<ClientPrepaidPaymentApprovalStepCompleteCommandResult>(
      false,
    ),
  name: "@orderWorkflow/completeClientPrepaidPaymentApprovalSlice",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<ClientPrepaidPaymentApprovalStepCompleteCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(completeClientPrepaidPaymentApprovalAsync, builder);
    handleSuccessCase(completeClientPrepaidPaymentApprovalAsync, builder);
    handleFailureCase(completeClientPrepaidPaymentApprovalAsync, builder);
  },
});

export const { resetSlice } = completeClientPrepaidPaymentApprovalSlice.actions;
