import { PartyDto, PartyType } from "Api/Api";

export const usePartyName = () => {
  return {
    formatPartyName: (party: PartyDto | null | undefined) => {
      if (!party) {
        return "";
      }

      return party?.type === PartyType.LegalEntity
        ? `${party.companyName ?? ""}`
        : `${party?.firstName ?? ""} ${party?.lastName ?? ""}`;
    },
  };
};
