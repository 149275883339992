import { EnterpriseLogoDto, getEnterprisesLogo } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const enterpriseLogoAsync = createAsyncAction(
  "@ENTERPRISE/LOGO_REQUEST",
  "@ENTERPRISE/LOGO_RESPONSE",
  "@ENTERPRISE/LOGO_FAILURE",
)<void, EnterpriseLogoDto, Error>();

function* enterpriseLogo(
  action: ReturnType<typeof enterpriseLogoAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(getEnterprisesLogo);

    if (status === 200) {
      yield put(enterpriseLogoAsync.success(data));
      return;
    }

    yield put(enterpriseLogoAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(enterpriseLogoAsync.failure(err as Error));
  }
}
export function* enterpriseLogoSaga() {
  yield takeLatest(getType(enterpriseLogoAsync.request), enterpriseLogo);
}

export const enterpriseLogoSlice = createSlice({
  initialState: getFetchStateDefaultValue<EnterpriseLogoDto>(false),
  name: "enterpriseLogo",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<EnterpriseLogoDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(enterpriseLogoAsync, builder);
    handleSuccessCase(enterpriseLogoAsync, builder);
    handleFailureCase(enterpriseLogoAsync, builder);
  },
});

export const { resetSlice } = enterpriseLogoSlice.actions;
