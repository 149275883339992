import {
  InvoicePaymentStepReminderCommand,
  postOrdersPublicIDWorkflowInvoicePaymentReminder,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderWorkflowStepsAsync } from "State/OrderWorkflow/Slices/OrderWorkflowStepsSlice";

export const sendInvoicePaymentReminderAsync = createAsyncAction(
  "@orderWorkflow/SEND_INVOICE_PAYMENT_REMINDER_REQUEST",
  "@orderWorkflow/SEND_INVOICE_PAYMENT_REMINDER_RESPONSE",
  "@orderWorkflow/SEND_INVOICE_PAYMENT_REMINDER_FAILURE",
)<{ publicID: string }, InvoicePaymentStepReminderCommand, Error>();

function* sendInvoicePaymentReminder(
  action: ReturnType<typeof sendInvoicePaymentReminderAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowInvoicePaymentReminder,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(
        getOrderWorkflowStepsAsync.request({
          publicID: action.payload.publicID,
        }),
      );
      yield put(sendInvoicePaymentReminderAsync.success(data));
      return;
    }

    yield put(
      sendInvoicePaymentReminderAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(sendInvoicePaymentReminderAsync.failure(err as Error));
  }
}
export function* sendInvoicePaymentReminderSaga() {
  yield takeLatest(
    getType(sendInvoicePaymentReminderAsync.request),
    sendInvoicePaymentReminder,
  );
}

export const sendInvoicePaymentReminderSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<InvoicePaymentStepReminderCommand>(false),
  name: "@orderWorkflow/sendInvoicePaymentReminder",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<InvoicePaymentStepReminderCommand>(false),
  },
  extraReducers: builder => {
    handleRequestCase(sendInvoicePaymentReminderAsync, builder);
    handleSuccessCase(sendInvoicePaymentReminderAsync, builder);
    handleFailureCase(sendInvoicePaymentReminderAsync, builder);
  },
});

export const { resetSlice } = sendInvoicePaymentReminderSlice.actions;
