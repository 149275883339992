import {
  ResendVerificationEmailCommand,
  ResendVerificationEmailCommandResult,
  postAuthEmailVerificationSend,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";

export const resendVerificationEmailAsync = createAsyncAction(
  "@auth/RESEND_VERIFICATION_EMAIL_REQUEST",
  "@auth/RESEND_VERIFICATION_EMAIL_RESPONSE",
  "@auth/RESEND_VERIFICATION_EMAIL_FAILURE",
)<
  ResendVerificationEmailCommand,
  ResendVerificationEmailCommandResult,
  Error
>();

function* resendVerificationEmail(
  action: ReturnType<typeof resendVerificationEmailAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postAuthEmailVerificationSend,
      action.payload,
    );

    if (status === 200) {
      yield put(resendVerificationEmailAsync.success(data));
      return;
    }

    yield put(
      resendVerificationEmailAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(resendVerificationEmailAsync.failure(err as Error));
  }
}
export function* resendVerificationEmailSaga() {
  yield takeLatest(
    getType(resendVerificationEmailAsync.request),
    resendVerificationEmail,
  );
}

type ResendVerificationEmailSliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: ResendVerificationEmailSliceState = {
  isLoading: false,
  error: null,
};

export const resendVerificationEmailSlice = createSlice({
  initialState,
  name: "resendVerificationEmail",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(resendVerificationEmailAsync.request),
      (
        state,
        action: ReturnType<typeof resendVerificationEmailAsync.request>,
      ) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(resendVerificationEmailAsync.success),
      (
        state,
        action: ReturnType<typeof resendVerificationEmailAsync.success>,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(resendVerificationEmailAsync.failure),
      (
        state,
        action: ReturnType<typeof resendVerificationEmailAsync.failure>,
      ) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
