import { Box } from "@mui/material";
import { VATPayerOrderTaxChangeType } from "Api/Api";
import { CompanyFormModel } from "Components/Companies/CompanyDetail";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlRadioButtons } from "Components/Shared/Inputs/Form/BlRadioButtons";
import { CodeListItem } from "Models/ICodeListDto";
import { Resources, useResource } from "Translations/Resources";
import { enumToArray } from "Utils/ObjectUtils";
import { UseFormReturn } from "react-hook-form";

type Props = {
  isVATPayerOrderTaxChangeTypeShown: boolean;
  form: UseFormReturn<CompanyFormModel>;
};

const PageResources = Resources.Companies.Detail.VatPayerOrderTaxChange;

export const VatPayerOrderTaxChange: React.FunctionComponent<Props> = props => {
  const { isVATPayerOrderTaxChangeTypeShown, form } = props;

  const { t } = useResource();

  if (!isVATPayerOrderTaxChangeTypeShown) {
    return null;
  }

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <>
      <BlRadioButtons
        control={control}
        row={true}
        codeList={enumToArray(VATPayerOrderTaxChangeType)
          .filter(x => x !== VATPayerOrderTaxChangeType.None)
          .map(
            x =>
              ({
                code: x,
                name: t(PageResources.RadioButtons[x].Label),
                endAdornment: (
                  <Box mt={0.25}>
                    <BlDefaultTooltip
                      title={t(PageResources.RadioButtons[x].Tooltip)}
                    />
                  </Box>
                ),
              }) as CodeListItem,
          )}
        errors={errors}
        name="vatPayerOrderTaxChangeType"
        useCodesAsLabels={false}
        slotProps={{
          typography: {
            width: "100%",
          },
        }}
      />
    </>
  );
};
