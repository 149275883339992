import * as React from "react";
export const CopyIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.6666 8H10.6666C9.56206 8 8.66663 8.89543 8.66663 10V18C8.66663 19.1046 9.56206 20 10.6666 20H18.6666C19.7712 20 20.6666 19.1046 20.6666 18V10C20.6666 8.89543 19.7712 8 18.6666 8Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6666 8V6C16.6666 5.46957 16.4559 4.96086 16.0808 4.58579C15.7058 4.21071 15.1971 4 14.6666 4H6.66663C6.13619 4 5.62749 4.21071 5.25241 4.58579C4.87734 4.96086 4.66663 5.46957 4.66663 6V14C4.66663 14.5304 4.87734 15.0391 5.25241 15.4142C5.62749 15.7893 6.13619 16 6.66663 16H8.66663"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
