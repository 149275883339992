import { Typography } from "@mui/material";
import {
  ClientInvoiceStatus,
  OrderDto,
  OrderPaymentFrequency,
  OrderPaymentType,
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  StepType,
} from "Api/Api";
import { AutomationDisclaimer } from "Components/Orders/Detail/Steps/Shared/AutomationDisclaimer";
import { StyledWorkflowButton } from "Components/Orders/Detail/Steps/WorkflowStep";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { completeInvoicePaymentAsync } from "State/OrderWorkflow/Slices/CompleteInvoicePaymentSlice";
import { sendInvoicePaymentReminderAsync } from "State/OrderWorkflow/Slices/SendInvoicePaymentReminder";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { useTheme } from "styled-components";

type Props = {
  publicID: string;
  order: OrderDto | null | undefined;
  step: OrderWorkflowStepDto;
  steps: OrderWorkflowStepDto[];
  isReadOnly: boolean;
};

const PageResources =
  Resources.Orders.Workflow.Workflow.ClientInvoicePaymentStep;

export const InvoicePaymentStep: React.FunctionComponent<Props> = props => {
  const { step, steps, publicID, order, isReadOnly } = props;

  const dispatch = useAppDispatch();
  const { t } = useResource();

  const { isLoading: isLoadingCompleteInvoicePayment } = useAppSelector(
    e => e.orderWorkflow.completeInvoicePayment,
  );

  const { isLoading: isLoadingSendInvoicePaymentReminder, data: reminderData } =
    useAppSelector(e => e.orderWorkflow.sendInvoicePaymentReminder);

  const theme = useTheme();
  if (
    step.status === OrderWorkflowStepStatus.Completed &&
    order?.isZeroPrice &&
    order.paymentFrequency === OrderPaymentFrequency.Single &&
    order.paymentType === OrderPaymentType.PaymentAfterProcessingOrder
  ) {
    return (
      <Typography
        fontWeight={500}
        align="left"
        color={theme.palette.error.main}
      >
        {t(
          Resources.Orders.Workflow.Workflow.ClientInvoicePaymentStep.ZeroPrice,
        )}
      </Typography>
    );
  }

  const isNotDisplayed =
    step.status === OrderWorkflowStepStatus.Completed ||
    steps.some(
      x =>
        x.type === StepType.ClientInvoiceIssuance &&
        x.status !== OrderWorkflowStepStatus.Completed,
    );

  if (isNotDisplayed || !order) {
    return null;
  }

  const complete = () => {
    dispatch(
      completeInvoicePaymentAsync.request({
        publicID: publicID,
      }),
    );
  };

  const sendReminder = () => {
    dispatch(
      sendInvoicePaymentReminderAsync.request({
        publicID: publicID,
      }),
    );
  };

  const lastReminderSent = step.invoicePaymentStep?.lastReminderSent;

  const isSinglePaymentOrderWithCanceledInvoice =
    order?.isRecurringPayment === false &&
    order.invoices?.[0]?.status === ClientInvoiceStatus.Canceled;

  const dateCanceledInvoice = isSinglePaymentOrderWithCanceledInvoice
    ? order.invoices?.[0]?.dateCanceled
    : null;
  return (
    <>
      {isSinglePaymentOrderWithCanceledInvoice ? (
        <Typography color={theme => theme.palette.error.main} fontSize={16}>
          {t(PageResources.CancelInvoice, {
            date: formatDate(dateCanceledInvoice, true),
          })}
        </Typography>
      ) : (
        <>
          <AutomationDisclaimer
            companyPublicID={order.supplierCompanyPublicID}
            isPrepaid={false}
          />

          {!!lastReminderSent && (
            <Typography marginBottom={2}>
              {t(PageResources.Reminder.LastSent, {
                date: formatDate(lastReminderSent, true),
              })}
            </Typography>
          )}
          <StyledFlex $gap={1.5} $alignItems="center">
            <StyledWorkflowButton
              onClick={complete}
              color="primary"
              isLoading={isLoadingCompleteInvoicePayment}
              disabled={isReadOnly}
            >
              {t(PageResources.Pay)}
            </StyledWorkflowButton>

            <StyledWorkflowButton
              onClick={sendReminder}
              disabled={!!reminderData || isReadOnly}
              isLoading={isLoadingSendInvoicePaymentReminder}
            >
              {!!reminderData
                ? t(PageResources.Reminder.WasSent)
                : t(PageResources.Reminder.Send)}
            </StyledWorkflowButton>
          </StyledFlex>
        </>
      )}
    </>
  );
};
