import { Typography } from "@mui/material";
import {
  EnterpriseEmailCommunicationMode,
  EnterprisePrepaidOrderInvoiceToClientCommunicationMode,
} from "Api/Api";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlRadioButtons } from "Components/Shared/Inputs/Form/BlRadioButtons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { putEnterpriseCommunicationModeAsync } from "State/Enterprises/BasicSettings/PutEnterpriseCommunicationModeSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { enumToCodeList } from "Utils/EnumUtils";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

type FormModel = {
  emailCommunicationMode: EnterpriseEmailCommunicationMode;
  prepaidOrderReminderForPaymentCalendarItemsStatus: EnterprisePrepaidOrderInvoiceToClientCommunicationMode;
};

export const CommunicationModeForm: React.FunctionComponent = _ => {
  const dispatch = useAppDispatch();
  const { t } = useResource();
  const basicSettings = useAppSelector(
    state => state.enterprise.basicSettings.settings,
  );
  const isLoading = basicSettings.isLoading && !basicSettings.data;
  const isSaving = useAppSelector(
    x => x.enterprise.postEnterpriseChangeMode.isLoading,
  );

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormModel>({});

  useEffect(() => {
    reset({
      emailCommunicationMode:
        basicSettings.data?.emailCommunicationMode ??
        EnterpriseEmailCommunicationMode.OnlySupplierCompany,
      prepaidOrderReminderForPaymentCalendarItemsStatus:
        basicSettings.data?.prepaidOrderReminderForPaymentCalendarItemsStatus ??
        EnterprisePrepaidOrderInvoiceToClientCommunicationMode.Active,
    });
  }, [reset, basicSettings.data]);

  const resetForm = () => {
    reset({
      emailCommunicationMode:
        basicSettings.data?.emailCommunicationMode ??
        EnterpriseEmailCommunicationMode.OnlySupplierCompany,
      prepaidOrderReminderForPaymentCalendarItemsStatus:
        basicSettings.data?.prepaidOrderReminderForPaymentCalendarItemsStatus ??
        EnterprisePrepaidOrderInvoiceToClientCommunicationMode.Active,
    });
  };

  const onSubmit = (data: FormModel) => {
    dispatch(
      putEnterpriseCommunicationModeAsync.request({
        emailCommunicationMode: data.emailCommunicationMode,
        prepaidOrderReminderForPaymentCalendarItemsStatus:
          data.prepaidOrderReminderForPaymentCalendarItemsStatus,
      }),
    );
  };

  const formModeValue = watch("emailCommunicationMode");
  const prepaidOrderReminderForPaymentCalendarItemsStatusValue = watch(
    "prepaidOrderReminderForPaymentCalendarItemsStatus",
  );
  const isSubmitDisabled =
    basicSettings.data?.emailCommunicationMode === formModeValue &&
    basicSettings.data?.prepaidOrderReminderForPaymentCalendarItemsStatus ===
      prepaidOrderReminderForPaymentCalendarItemsStatusValue;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFlex $alignItems="center" $gap={0.5}>
          <Typography variant="h2">
            {t(Resources.Settings.Enterprise.EmailMode.Title)}
          </Typography>
          <BlDefaultTooltip
            title={
              <>
                <Typography>
                  {t(Resources.Settings.Enterprise.EmailMode.Tooltip.Title)}
                </Typography>

                <br />
                <Typography fontWeight={700}>
                  {t(
                    Resources.Transactions.EnterpriseEmailCommunicationMode
                      .OnlySupplierCompany,
                  )}
                </Typography>
                <Typography>
                  {t(
                    Resources.Settings.Enterprise.EmailMode.Tooltip
                      .OnlySupplier,
                  )}
                </Typography>

                <br />
                <Typography fontWeight={700}>
                  {t(
                    Resources.Transactions.EnterpriseEmailCommunicationMode
                      .SupplierCompanyAndUser,
                  )}
                </Typography>
                <Typography>
                  {t(
                    Resources.Settings.Enterprise.EmailMode.Tooltip
                      .SupplierAndUser,
                  )}
                </Typography>
                <Typography>
                  {t(Resources.Settings.Enterprise.EmailMode.Tooltip.Example)}
                </Typography>
                <Typography marginLeft={4}>
                  {t(
                    Resources.Settings.Enterprise.EmailMode.Tooltip
                      .ExampleNameUser,
                  )}
                </Typography>
                <Typography marginLeft={4}>
                  {t(
                    Resources.Settings.Enterprise.EmailMode.Tooltip
                      .ExampleNameSupplier,
                  )}
                </Typography>
              </>
            }
          />
        </StyledFlex>
        <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={1}>
          {t(Resources.Settings.Enterprise.EmailMode.Description)}
        </Typography>

        {isLoading ? (
          <BlSkeleton height={60} />
        ) : (
          <BlRadioButtons
            control={control}
            errors={errors}
            name={"emailCommunicationMode"}
            codeList={enumToCodeList(
              EnterpriseEmailCommunicationMode,
              Resources.Transactions.EnterpriseEmailCommunicationMode,
            )}
          />
        )}

        <StyledFlex $alignItems="center" $gap={0.5}>
          <Typography variant="h2">
            {t(
              Resources.Settings.Enterprise
                .PrepaidOrderReminderForPaymentCalendarItemsStatus.Title,
            )}
          </Typography>
          <BlDefaultTooltip
            title={
              <Typography>
                {t(
                  Resources.Settings.Enterprise
                    .PrepaidOrderReminderForPaymentCalendarItemsStatus.Tooltip
                    .Text,
                )}
              </Typography>
            }
          />
        </StyledFlex>
        <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={1}>
          {t(
            Resources.Settings.Enterprise
              .PrepaidOrderReminderForPaymentCalendarItemsStatus.Description,
          )}
        </Typography>

        {isLoading ? (
          <BlSkeleton height={60} />
        ) : (
          <BlRadioButtons
            control={control}
            errors={errors}
            name={"prepaidOrderReminderForPaymentCalendarItemsStatus"}
            codeList={enumToCodeList(
              EnterprisePrepaidOrderInvoiceToClientCommunicationMode,
              Resources.Transactions
                .PrepaidOrderReminderForPaymentCalendarItemsStatus,
            )}
          />
        )}

        <StyledFlex $alignItems="center" $gap={2}>
          <BlButton
            color="primary"
            type="submit"
            disabled={isSubmitDisabled}
            isLoading={isSaving}
          >
            {t(Resources.Common.Save_FirstUppercase)}
          </BlButton>
          <BlButton type="button" onClick={() => resetForm()}>
            {t(Resources.Common.Reset)}
          </BlButton>
        </StyledFlex>
      </form>
    </>
  );
};
