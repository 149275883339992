import { Typography } from "@mui/material";
import { Logo } from "Components/Layout/Logo";
import { useResource, Resources } from "Translations/Resources";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  padding-bottom: ${props => props.theme.spacing(4)};
  padding-top: ${props => props.theme.spacing(6)};
`;

export const UnauthenticatedHeader: React.FunctionComponent = _ => {
  const { t } = useResource();

  return (
    <StyledWrapper>
      <Logo isUserLogoVisible={false}></Logo>
      <Typography component="h1" variant="h1" mt={3}>
        {t(Resources.Common.AppName)}
      </Typography>
    </StyledWrapper>
  );
};
