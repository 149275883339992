import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { OrderDraft } from "Components/Orders/OrderDraft";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";

export const CreatePage: React.FunctionComponent = () => {
  const { t } = useResource();

  return (
    <AuthenticatedLayout title={t(Resources.Orders.Detail.CreateTitle)}>
      <StyledPageWrapper $isNarrow>
        <OrderDraft
          isLoading={false}
          order={null}
          publicID={null}
          clonedOrderPublicID={null}
        />
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};
