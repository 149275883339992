import { combineReducers } from "@reduxjs/toolkit";
import {
  getStepListSaga,
  getStepListSlice,
} from "State/Workflows/Steps/GetStepsSlice";
import { all } from "typed-redux-saga";

export const workflowReducer = combineReducers({
  list: getStepListSlice.reducer,
});

export function* watchWorkflowSagas() {
  yield all([getStepListSaga()]);
}
