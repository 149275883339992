import { Typography } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { BlDataTable } from "Components/Shared/DataTable/BlDataTable";
import { ExpandArrowIcon } from "Components/Shared/Icons";
import { BlDropdown } from "Components/Shared/Inputs/Form/BlDropdown";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { CodeListItem } from "Models/ICodeListDto";
import { getUpcomingPeriodicOrdersAsync } from "State/Dashboard/UpcomingPeriodicOrders/UpcomingPeriodicOrdersSlice";
import { RootStateType } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { toLocalTimeZoneString } from "Utils/DateUtils";
import { enumToArray } from "Utils/ObjectUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { addMonths, addWeeks } from "date-fns";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";

const StyledBlDefaultButton = styled(BlDefaultButton)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;

  svg {
    color: ${props => props.theme.palette.text.secondary};
    width: 12px;
    height: auto;
    transform: rotate(-90deg);
  }
`;

type Props = {};

export enum UpcomingOrdersDateRange {
  Week = "Week",
  Month = "Month",
  Quarter = "Quarter",
  Year = "Year",
}

export const UpcomingPeriodicOrders: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const navigate = useNavigate();

  const {
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      filter: UpcomingOrdersDateRange.Week,
    },
  });
  const filter = watch("filter");

  const dispatch = useDispatch();

  useEffect(() => {
    const dateFrom =
      filter === UpcomingOrdersDateRange.Week
        ? addWeeks(new Date(), 1)
        : filter === UpcomingOrdersDateRange.Month
          ? addMonths(new Date(), 1)
          : filter === UpcomingOrdersDateRange.Quarter
            ? addMonths(new Date(), 3)
            : addMonths(new Date(), 12);

    dispatch(
      getUpcomingPeriodicOrdersAsync.request({
        offset: 0,
        limit: 25,
        dateFrom: toLocalTimeZoneString(dateFrom),
      }),
    );
  }, [filter, dispatch]);

  const { data } = useSelector(
    (e: RootStateType) => e.dashboard.upcomingPeriodicOrders,
  );

  return (
    <div>
      <StyledFlex $justifyContent="space-between" $alignItems="center">
        <StyledFlex $alignItems="center">
          <Typography variant="h2" marginRight={1}>
            {t(Resources.Dashboard.Anniversary.Title)}
          </Typography>
          <BlDefaultTooltip
            title={t(Resources.Dashboard.Anniversary.Tooltip)}
          />
        </StyledFlex>

        <BlDropdown
          control={control}
          name={"filter"}
          codeList={enumToArray(UpcomingOrdersDateRange).map(
            x =>
              ({
                code: x,
                name: t(Resources.UpcomingOrdersDateRange[x]),
              }) as CodeListItem,
          )}
          errors={errors}
          hasFilterStyle
        ></BlDropdown>
      </StyledFlex>

      <BlDataTable
        rows={data?.items.map(x => ({ data: x })) ?? []}
        rowEntityID="externalID"
        isPaginationDisabled
        skeletonRowsCount={5}
        onRowClick={row => {
          navigate(getPath(AppRouting.OrderDetail, row.externalID));
        }}
        getHeaderCell={column => {
          return <div>{column.label ?? column.id}</div>;
        }}
        columns={[
          {
            id: "clientFirstName",
            label: "Klient",
          },
          {
            id: "number",
            label: "Číslo objednávky",
          },
          { id: "action", label: "", maxWidth: 0, justifySelf: "flex-end" },
        ]}
        getCell={(column, row) => {
          switch (column) {
            case "clientFirstName":
              const formattedName = `${row.clientFirstName ?? ""} ${
                row.clientLastName ?? ""
              }`;
              return <span>{formattedName}</span>;

            case "number":
              return <span>{row.number}</span>;

            case "action":
              return (
                <UnstyledLink
                  to={getPath(AppRouting.OrderDetail, row.externalID)}
                >
                  <StyledBlDefaultButton>
                    <ExpandArrowIcon></ExpandArrowIcon>
                  </StyledBlDefaultButton>
                </UnstyledLink>
              );

            default:
              const value = (row as any)[column as any] ?? "";

              return <div>{value}</div>;
          }
        }}
      ></BlDataTable>
    </div>
  );
};
