import { OrderWorkflowStepStatus, StepType } from "Api/Api";
import { RoundedIcon } from "Components/Orders/Detail/RoundedIcon";
import {
  CrossIcon,
  RepeatIcon,
  SendIcon,
  CheckIcon,
} from "Components/Shared/Icons";
import styled from "styled-components";

const StyledStepLine = styled.div<{ $isCompleted: boolean }>`
  width: 1px;
  position: absolute;
  left: 50%;
  top: 12px;
  bottom: -12px;
  z-index: -1;
  transform: translateX(-50%);
  background-color: ${props =>
    props.$isCompleted
      ? props.theme.palette.primary.main
      : props.theme.colors.workflowIcon};
`;

export const StepIcon = ({
  nextStepStatus,
  status,
  type,
  isLastStep,
}: {
  status: OrderWorkflowStepStatus;
  type: StepType;
  nextStepStatus: OrderWorkflowStepStatus | null;
  isLastStep: boolean;
}) => {
  const icon = () => {
    if (status === OrderWorkflowStepStatus.Rejected) {
      return <CrossIcon />;
    }
    switch (type) {
      case StepType.ProcessingServices:
        return <RepeatIcon />;
      case StepType.ClientInvoiceIssuance:
        return <SendIcon />;

      default:
        return <CheckIcon />;
    }
  };

  return (
    <>
      <RoundedIcon
        isFinished={status === OrderWorkflowStepStatus.Completed}
        isRejected={status === OrderWorkflowStepStatus.Rejected}
      >
        {icon()}
      </RoundedIcon>

      {!isLastStep && (
        <StyledStepLine
          $isCompleted={
            nextStepStatus === OrderWorkflowStepStatus.Completed ||
            nextStepStatus === OrderWorkflowStepStatus.Rejected
          }
        ></StyledStepLine>
      )}
    </>
  );
};
