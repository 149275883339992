import {
  ClientInvoiceStatus,
  OrderDto,
  OrderPaymentFrequency,
  OrderWorkflowStepStatus,
  PaymentCalendarItemStatus,
  RelationType,
  StepType,
} from "Api/Api";
import { OrderDetailIconButton } from "Components/Orders/Detail/Styles";
import { BlConfirmDialog } from "Components/Shared/Dialogs/BlConfirmDialog";
import { TrashIcon } from "Components/Shared/Icons";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { deleteOrderAsync } from "State/Orders/Detail/DeleteOrderSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

const StyledTrashIcon = styled(TrashIcon)`
  color: ${props => props.theme.palette.error.main} !important;
`;

type Props = {
  order: OrderDto;
};

export const DeleteOrderIcon: React.FunctionComponent<Props> = props => {
  const { order } = props;
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useResource();
  const navigate = useNavigate();

  const { data: workflowSteps } = useAppSelector(
    e => e.orderWorkflow.workflowSteps,
  );

  const clientInvoicePayment = workflowSteps?.items.find(
    e => e.type === StepType.ClientInvoicePayment,
  );

  const isReadOnly = order.userRelationType === RelationType.Visibility;

  const { isLoading: isLoadingData, data: paymentCalendarItems } =
    useAppSelector(e => e.orderWorkflow.paymentCalendarItems);

  const canCancelPeriodicOrder =
    order.paymentFrequency !== OrderPaymentFrequency.Single &&
    !isLoadingData &&
    paymentCalendarItems?.items.some(x =>
      [
        PaymentCalendarItemStatus.SentToClient,
        PaymentCalendarItemStatus.PaidByClient,
        PaymentCalendarItemStatus.CommissionPaid,
      ].includes(x.status),
    );

  const invoice = order.invoices?.[0];

  const isCancelInvoiceOnSinleOrder =
    order.paymentFrequency === OrderPaymentFrequency.Single &&
    order.invoices?.length > 0 &&
    invoice?.status === ClientInvoiceStatus.Canceled;

  const isAnyPaidInvoice =
    order.invoices?.filter(x => x.status === ClientInvoiceStatus.Paid)?.length >
    0;

  const disabled =
    clientInvoicePayment?.status === OrderWorkflowStepStatus.Completed ||
    isReadOnly ||
    isAnyPaidInvoice ||
    isCancelInvoiceOnSinleOrder ||
    canCancelPeriodicOrder;

  const handleDelete = (isAccepted: boolean) => {
    if (disabled) {
      return;
    }

    if (isAccepted) {
      dispatch(
        deleteOrderAsync.request({ publicID: order.publicID, navigate }),
      );
    }
    setIsOpen(false);
  };
  return (
    <>
      <OrderDetailIconButton
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        title={
          disabled
            ? t(
                isReadOnly
                  ? Resources.Orders.DeleteConfirmDialog.Tooltip.IsReadOnly
                  : Resources.Orders.DeleteConfirmDialog.Tooltip.Disabled,
              )
            : t(Resources.Orders.DeleteConfirmDialog.Tooltip.Enabled)
        }
      >
        <StyledTrashIcon />
      </OrderDetailIconButton>

      <BlConfirmDialog
        isOpen={isOpen}
        handleResult={handleDelete}
        modalTitle={t(Resources.Orders.DeleteConfirmDialog.Title)}
        modalContent={t(Resources.Orders.DeleteConfirmDialog.Text)}
      />
    </>
  );
};
