export const formatPhoneNumber = (phoneNumber: string | undefined | null) => {
  if (!phoneNumber) {
    return "";
  }

  return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(
    4,
    7,
  )} ${phoneNumber.slice(7, 10)} ${phoneNumber.slice(10)}`;
};
