import { combineReducers } from "@reduxjs/toolkit";
import {
  clientApprovalRejectSaga,
  clientApprovalRejectSlice,
} from "State/ClientZone/Orders/ClientApprovalRejectSlice";
import {
  clientPrepaidPaymentApprovalInProgressSaga,
  clientPrepaidPaymentApprovalInProgressSlice,
} from "State/ClientZone/Orders/ClientPrepaidPaymentApprovalInprogressSlice";
import {
  clientPrepaidPaymentApprovalRejectSaga,
  clientPrepaidPaymentApprovalRejectSlice,
} from "State/ClientZone/Orders/ClientPrepaidPaymentApprovalRejectSlice";
import {
  completeClientApprovalSaga,
  completeClientApprovalSlice,
} from "State/ClientZone/Orders/CompleteClientApprovalSlice";
import {
  downloadOrderRevocationSaga,
  downloadOrderRevocationSlice,
} from "State/ClientZone/Orders/DownloadOrderRevocationExample";
import {
  getOrderDetailSaga,
  getOrderDetailSlice,
} from "State/ClientZone/Orders/GetOrderDetailSlice";
import {
  getPaymentCalendarItemsClientZoneSaga,
  getPaymentCalendarItemsClientZoneSlice,
} from "State/ClientZone/Orders/GetPaymentCalendarItemsSlice";
import {
  sendSignatureCodeSaga,
  sendSignatureCodeSlice,
} from "State/ClientZone/Orders/SendSignatureCodeSlice";
import {
  getClientZoneEnterpriseDesignSettingsSaga,
  getClientZoneEnterpriseDesignSettingsSlice,
} from "State/ClientZone/Settings/EnterpriseDesignSettingsSlice";
import {
  getClientZoneLogoSaga,
  getClientZoneLogoSlice,
} from "State/ClientZone/Settings/EnterpriseLogoSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { all } from "typed-redux-saga";

export const clientZoneReducer = combineReducers({
  orderDetail: getOrderDetailSlice.reducer,
  logo: getClientZoneLogoSlice.reducer,
  designSettings: getClientZoneEnterpriseDesignSettingsSlice.reducer,
  clientApprovalReject: clientApprovalRejectSlice.reducer,
  clientPrepaidPaymentApprovalReject:
    clientPrepaidPaymentApprovalRejectSlice.reducer,
  clientPrepaidPaymentApprovalInProgress:
    clientPrepaidPaymentApprovalInProgressSlice.reducer,
  getPaymentCalendarItemsClientZone:
    getPaymentCalendarItemsClientZoneSlice.reducer,
  sendSignatureCode: sendSignatureCodeSlice.reducer,
  completeClientApproval: completeClientApprovalSlice.reducer,
  downloadOrderRevocation: downloadOrderRevocationSlice.reducer,
});

export function persistedClientZoneReducer() {
  return persistReducer(
    {
      key: "clientZone",
      storage,
      whitelist: [],
    },
    clientZoneReducer,
  );
}

export function* watchClientZoneSagas() {
  yield all([
    getOrderDetailSaga(),
    getClientZoneLogoSaga(),
    getClientZoneEnterpriseDesignSettingsSaga(),
    getPaymentCalendarItemsClientZoneSaga(),
    clientApprovalRejectSaga(),
    clientPrepaidPaymentApprovalRejectSaga(),
    clientPrepaidPaymentApprovalInProgressSaga(),
    sendSignatureCodeSaga(),
    completeClientApprovalSaga(),
    downloadOrderRevocationSaga(),
  ]);
}
