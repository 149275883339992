import {
  EnterpriseServiceListItemDto,
  getEnterprisesServices as request,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getEnterpriseServicesAsync = createAsyncAction(
  "@ENTERPRISE/GET_SERVICES_REQUEST",
  "@ENTERPRISE/GET_SERVICES_RESPONSE",
  "@ENTERPRISE/GET_SERVICES_FAILURE",
)<void, EnterpriseServiceListItemDto[], Error>();

function* getEnterpriseServices(
  action: ReturnType<typeof getEnterpriseServicesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(request);

    if (status === 200) {
      yield put(getEnterpriseServicesAsync.success(data));
      return;
    }

    yield put(
      getEnterpriseServicesAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getEnterpriseServicesAsync.failure(err as Error));
  }
}
export function* getEnterpriseServicesSaga() {
  yield takeLatest(
    getType(getEnterpriseServicesAsync.request),
    getEnterpriseServices,
  );
}

export const getEnterpriseServicesSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EnterpriseServiceListItemDto[]>(false),
  name: "getEnterpriseServices",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EnterpriseServiceListItemDto[]>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getEnterpriseServicesAsync, builder);
    handleSuccessCase(getEnterpriseServicesAsync, builder);
    handleFailureCase(getEnterpriseServicesAsync, builder);
  },
});

export const { resetSlice: getEnterpriseServicesReset } =
  getEnterpriseServicesSlice.actions;
