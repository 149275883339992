import {
  ClientPrepaidInvoiceRecurringPaymentReminderCommandResult,
  postOrdersPublicIDWorkflowClientInvoiceRecurringPaymentPaymentCalendarItemIDPrepaidReminder,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { toast } from "sonner";
import { Resources, getTranslation } from "Translations/Resources";
import { getPaymentCalendarItemsAsync } from "State/OrderWorkflow/Slices/GetPaymentCalendarItemsSlice";

export const paymentCalendarItemPrepaidOrderInvoiceReminderAsync =
  createAsyncAction(
    "@orderWorkflow/PAYMENT_CALENDAR_ITEM_PREPAID_ORDER_REMINDER_REQUEST",
    "@orderWorkflow/PAYMENT_CALENDAR_ITEM_PREPAID_ORDER_REMINDER_RESPONSE",
    "@orderWorkflow/PAYMENT_CALENDAR_ITEM_PREPAID_ORDER_REMINDER_FAILURE",
  )<
    {
      orderPublicID: string;
      paymentCalendarItemID: number;
    },
    ClientPrepaidInvoiceRecurringPaymentReminderCommandResult,
    Error
  >();

function* paymentCalendarItemPrepaidOrderInvoiceReminder(
  action: ReturnType<
    typeof paymentCalendarItemPrepaidOrderInvoiceReminderAsync.request
  >,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowClientInvoiceRecurringPaymentPaymentCalendarItemIDPrepaidReminder,
      action.payload.orderPublicID,
      action.payload.paymentCalendarItemID,
    );

    if (status === 200) {
      yield put(
        paymentCalendarItemPrepaidOrderInvoiceReminderAsync.success(data),
      );
      yield put(
        getPaymentCalendarItemsAsync.request({
          publicID: action.payload.orderPublicID,
        }),
      );
      toast.success(
        getTranslation(
          Resources.Orders.Workflow.Workflow.RecurringPaymentStep.Remind
            .Success,
        ),
      );
      return;
    }

    yield put(
      paymentCalendarItemPrepaidOrderInvoiceReminderAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(
      paymentCalendarItemPrepaidOrderInvoiceReminderAsync.failure(err as Error),
    );
  }
}
export function* paymentCalendarItemPrepaidOrderInvoiceReminderSaga() {
  yield takeLatest(
    getType(paymentCalendarItemPrepaidOrderInvoiceReminderAsync.request),
    paymentCalendarItemPrepaidOrderInvoiceReminder,
  );
}

export const paymentCalendarItemPrepaidOrderInvoiceReminderSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<ClientPrepaidInvoiceRecurringPaymentReminderCommandResult>(
      false,
    ),
  name: "@orderWorkflow/paymentCalendarItemPrepaidOrderInvoiceReminder",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<ClientPrepaidInvoiceRecurringPaymentReminderCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(
      paymentCalendarItemPrepaidOrderInvoiceReminderAsync,
      builder,
    );
    handleSuccessCase(
      paymentCalendarItemPrepaidOrderInvoiceReminderAsync,
      builder,
    );
    handleFailureCase(
      paymentCalendarItemPrepaidOrderInvoiceReminderAsync,
      builder,
    );
  },
});

export const { resetSlice } =
  paymentCalendarItemPrepaidOrderInvoiceReminderSlice.actions;
