import { createSlice } from "@reduxjs/toolkit";
import {
  ChangeEnterpriseModeCommandResult,
  EnterpriseModeChangeRequest,
  postEnterprisesChangeMode,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { getEnterpriseBasicSettingsAsync } from "State/Enterprises/BasicSettings/EnterpriseBasicSettingsSlice";
import { RootStateType } from "State/Store";
import { toast } from "sonner";
import { getTranslation, Resources } from "Translations/Resources";
import { call, put, select, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const postEnterpriseChangeModeAsync = createAsyncAction(
  "@ENTERPRISE/CHANGE_MODE_REQUEST",
  "@ENTERPRISE/CHANGE_MODE_RESPONSE",
  "@ENTERPRISE/CHANGE_MODE_FAILURE",
)<EnterpriseModeChangeRequest, ChangeEnterpriseModeCommandResult, Error>();

function* postEnterpriseChangeMode(
  action: ReturnType<typeof postEnterpriseChangeModeAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postEnterprisesChangeMode,
      action.payload,
    );
    const settingsState = (yield select(
      (state: RootStateType) => state.enterprise.basicSettings.settings,
    )) as unknown as RootStateType["enterprise"]["basicSettings"]["settings"];

    if (status === 200) {
      toast.success(getTranslation(Resources.Settings.Enterprise.Save.Success));
      yield put(postEnterpriseChangeModeAsync.success(data));
      yield put(
        getEnterpriseBasicSettingsAsync.success({
          ...settingsState.data!,
          mode: action.payload.mode,
        }),
      );
      return;
    }

    yield put(
      postEnterpriseChangeModeAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(postEnterpriseChangeModeAsync.failure(err as Error));
  }
}
export function* postEnterpriseChangeModeSaga() {
  yield takeLatest(
    getType(postEnterpriseChangeModeAsync.request),
    postEnterpriseChangeMode,
  );
}

type EnterpriseChangeModeSliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: EnterpriseChangeModeSliceState = {
  isLoading: false,
  error: null,
};

export const postEnterpriseChangeModeSlice = createSlice({
  initialState,
  name: "postEnterpriseChangeMode",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(postEnterpriseChangeModeAsync.request),
      (
        state,
        action: ReturnType<typeof postEnterpriseChangeModeAsync.request>,
      ) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(postEnterpriseChangeModeAsync.success),
      (
        state,
        action: ReturnType<typeof postEnterpriseChangeModeAsync.success>,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(postEnterpriseChangeModeAsync.failure),
      (
        state,
        action: ReturnType<typeof postEnterpriseChangeModeAsync.failure>,
      ) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
