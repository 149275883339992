import { DEFAULT_APP_COLOR } from "Components/Layout/Layout";
import { StyledFlex } from "Components/Shared/StyledComponents";
import styled from "styled-components";

const StyledColor = styled.div<{ $color: string }>`
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  background-color: ${props => props.$color};
  cursor: pointer;
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: scale(1.2);
  }
`;

type Props = { onChange: (color: string) => void };

export const Colors: React.FunctionComponent<Props> = props => {
  const colors = [
    DEFAULT_APP_COLOR,
    "#19C3FF",
    "#006DFF",
    "#5147D9",
    "#FF0046",
    "#FF1018",
    "#FF8500",
    "#FFC400",
    "#00D947",
  ];

  return (
    <StyledFlex $alignItems="center" $gap={1}>
      {colors.map(color => (
        <StyledColor
          key={color}
          $color={color}
          onClick={() => props.onChange(color)}
        ></StyledColor>
      ))}
    </StyledFlex>
  );
};
