import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Typography } from "@mui/material";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { StyledDialog } from "Components/Shared/Dialogs/DialogStyles";
import { StyledValidationText } from "Components/Shared/FormStyles";
import { CrossIcon } from "Components/Shared/Icons";
import { BlDateTimePicker } from "Components/Shared/Inputs/DatePickers/BlDateTimePicker";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import {
  BlDropdown,
  DropdownValueType,
} from "Components/Shared/Inputs/Form/BlDropdown";
import { StyledFlex } from "Components/Shared/StyledComponents";
import useHandleServerError from "Hooks/Form/useHandleServerError";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getNextCutoffDateFromAsync } from "State/Cutoff/GetNextCutoffDateFrom";
import { getCutoffUserSupplierCompaniesAsync } from "State/Cutoff/List/GetCutoffUserSupplierCompaniesSlice";
import { postCutoffAsync } from "State/Cutoff/PostCutoffSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { toLocalTimeZoneString } from "Utils/DateUtils";
import { formatCompanyNameAndNumbers } from "Utils/PartyUtils";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { styled } from "styled-components";
import { ObjectSchema, boolean, date, number, object } from "yup";

const StyledInputsWrapper = styled(StyledFlex)`
  & > div {
    flex: 1;
  }

  label {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .Mui-disabled.MuiInputBase-formControl {
    background-color: transparent;

    .MuiInputBase-input {
      color: ${({ theme }) => theme.palette.text.primary};
      -webkit-text-fill-color: ${({ theme }) => theme.palette.text.primary};
      opacity: 0.5;
    }
  }
`;

type FormModel = {
  dateFrom: Date;
  dateTo: Date;
  isConfirmed: boolean;
  orderSupplierCompanyID: number;
};

type Props = {
  onClosed: () => void;
};

const getDefaultValues = (nextDateFrom: string | null): FormModel => {
  return {
    isConfirmed: false,
    dateFrom: !!nextDateFrom ? new Date(nextDateFrom) : ("" as unknown as Date),
    dateTo: new Date(),
    orderSupplierCompanyID: 0,
  };
};

export const CutoffDialog: React.FunctionComponent<Props> = props => {
  const { onClosed } = props;
  const { t } = useResource();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCutoffUserSupplierCompaniesAsync.request());
  }, [dispatch]);

  const { isLoading: isRunningCutoff, isDraft: isRunningCutoffDraft } =
    useAppSelector(state => state.cutoff.post);

  const validationSchema: ObjectSchema<FormModel> = object().shape({
    dateFrom: date().required(),
    dateTo: date().required(),
    isConfirmed: boolean()
      .required()
      .test("isConfirmed", t(Resources.Validation.IsTrue), value => value),
    orderSupplierCompanyID: number().required(),
  });

  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: getDefaultValues(null),
  });

  const {
    control,
    trigger,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = form;

  const orderSupplierCompanyID = watch("orderSupplierCompanyID");
  const { data: companies } = useAppSelector(e => e.cutoff.companies);

  const firstCompanyID = companies?.[0]?.companyID;

  useEffect(() => {
    if (orderSupplierCompanyID === 0 && !!firstCompanyID) {
      setValue("orderSupplierCompanyID", firstCompanyID);
      dispatch(
        getNextCutoffDateFromAsync.request({
          companyID: firstCompanyID,
        }),
      );
    }
  }, [orderSupplierCompanyID, firstCompanyID, setValue, dispatch]);

  const { data: nextDateFrom } = useAppSelector(
    state => state.cutoff.nextDateFrom,
  );

  useEffect(() => {
    if (!!nextDateFrom?.nextCutoffDateFrom) {
      setValue("dateFrom", new Date(nextDateFrom.nextCutoffDateFrom));
    }
  }, [nextDateFrom, setValue]);

  const runCutoff = (isDraft: boolean) => {
    const data = getValues();
    dispatch(
      postCutoffAsync.request({
        dateFrom: toLocalTimeZoneString(data.dateFrom ?? ""),
        dateTo: toLocalTimeZoneString(data.dateTo),
        isDraft: isDraft,
        orderSupplierCompanyID: data.orderSupplierCompanyID,
      }),
    );
  };

  const generateCutoff = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    runCutoff(false);
  };

  const handleOrderSupplierCompanyIDChange = (
    companyID: DropdownValueType | null,
  ) => {
    if (!companyID || typeof companyID !== "number") {
      return;
    }
    setValue("dateFrom", null as unknown as Date);
    setValue("isConfirmed", false);

    dispatch(
      getNextCutoffDateFromAsync.request({
        companyID,
      }),
    );
  };

  const dateFrom = watch("dateFrom");
  const { error } = useAppSelector(state => state.cutoff.post);

  useHandleServerError({
    form,
    formKey: "orderSupplierCompanyID",
    error,
    resource: Resources.Settings.Cutoff.Create.Validation,
  });

  return (
    <>
      <StyledDialog open={true} onClose={onClosed}>
        <StyledFlex $alignItems="center" $justifyContent="space-between">
          <Typography variant="h2">
            {t(Resources.Settings.Cutoff.Dialog.Title)}
          </Typography>

          <BlDefaultButton
            title={t(Resources.Common.Close)}
            onClick={() => onClosed()}
          >
            <CrossIcon />
          </BlDefaultButton>
        </StyledFlex>

        <Divider sx={{ marginTop: 1, marginBottom: 2 }} />

        <div>
          <form>
            <StyledFlex $gap={0.5} $alignItems="center" $marginBottom={2}>
              <Typography variant="subtitle2" fontStyle={"normal"}>
                {t(Resources.Settings.Cutoff.Dialog.Label)}
              </Typography>

              <BlDefaultTooltip
                title={t(Resources.Settings.Cutoff.Dialog.Tooltip)}
              />
            </StyledFlex>

            <BlDropdown
              control={control}
              errors={errors}
              name={"orderSupplierCompanyID"}
              label={t(Resources.Orders.Detail.Provider.Title)}
              codeList={(companies ?? []).map(e => ({
                code: e.companyID,
                name: formatCompanyNameAndNumbers(
                  e.companyName,
                  e.companyNumber,
                  e.taxNumber,
                ),
              }))}
              onChange={handleOrderSupplierCompanyIDChange}
            />

            <StyledInputsWrapper $gap={1} $marginBottom={0.5}>
              <BlDateTimePicker
                label={t(Resources.Settings.Cutoff.Dialog.DateFrom)}
                name="dateFrom"
                value={dateFrom}
                onChange={v => {
                  setValue("dateFrom", v ?? ("" as unknown as Date));
                }}
                hasError={!!errors.dateFrom}
                errorMessage={errors.dateFrom?.message}
                disabled
              />

              <BlDateTimePicker
                label={t(Resources.Settings.Cutoff.Dialog.DateTo)}
                name="dateTo"
                value={watch("dateTo")}
                onChange={v => {
                  setValue("dateTo", v ?? ("" as unknown as Date));
                }}
                hasError={!!errors.dateTo}
                errorMessage={errors.dateTo?.message}
                disableFuture
                minDateTime={watch("dateFrom") ?? undefined}
                readOnlyField
              />
            </StyledInputsWrapper>

            <BlCheckboxInput
              label={t(Resources.Settings.Cutoff.Dialog.IsConfirmed)}
              control={control}
              errors={errors}
              name={"isConfirmed"}
              //validation is not triggered on change, so we need to trigger it manually
              onChange={v => {
                if (errors.isConfirmed) {
                  trigger("isConfirmed");
                }
              }}
              hasErrorMessage
            />
          </form>

          <StyledFlex
            $alignItems="center"
            $gap={1.5}
            $marginBottom={2.5}
            $marginTop={1}
          >
            <BlButton
              color="primary"
              fullWidth
              isLoading={isRunningCutoff && !isRunningCutoffDraft}
              onClick={() => generateCutoff()}
              disabled={!dateFrom}
            >
              {t(Resources.Settings.Cutoff.Dialog.Title)}
            </BlButton>

            <BlButton
              color="secondary"
              fullWidth
              isLoading={isRunningCutoff && isRunningCutoffDraft}
              onClick={() => runCutoff(true)}
              disabled={!dateFrom}
            >
              {t(Resources.Settings.Cutoff.Dialog.Draft)}
            </BlButton>
          </StyledFlex>

          <StyledValidationText $fontSize="14px">
            {t(Resources.Settings.Cutoff.Dialog.Warning.T1)}
            <b>{t(Resources.Settings.Cutoff.Dialog.Warning.T2)}</b>
            {t(Resources.Settings.Cutoff.Dialog.Warning.T3)}
          </StyledValidationText>
        </div>
      </StyledDialog>
    </>
  );
};
