import {
  ClientInvoiceRecurringPaymentReminderCommandResult,
  postOrdersPublicIDWorkflowClientInvoiceRecurringPaymentPaymentCalendarItemIDReminder,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { toast } from "sonner";
import { Resources, getTranslation } from "Translations/Resources";
import { getPaymentCalendarItemsAsync } from "State/OrderWorkflow/Slices/GetPaymentCalendarItemsSlice";

export const sendPaymentCalendarItemReminderAsync = createAsyncAction(
  "@orderWorkflow/SEND_PAYMENT_CALENDAR_ITEM_REMINDER_REQUEST",
  "@orderWorkflow/SEND_PAYMENT_CALENDAR_ITEM_REMINDER_RESPONSE",
  "@orderWorkflow/SEND_PAYMENT_CALENDAR_ITEM_REMINDER_FAILURE",
)<
  {
    orderPublicID: string;
    paymentCalendarItemID: number;
  },
  ClientInvoiceRecurringPaymentReminderCommandResult,
  Error
>();

function* sendPaymentCalendarItemReminder(
  action: ReturnType<typeof sendPaymentCalendarItemReminderAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowClientInvoiceRecurringPaymentPaymentCalendarItemIDReminder,
      action.payload.orderPublicID,
      action.payload.paymentCalendarItemID,
    );

    if (status === 200) {
      yield put(sendPaymentCalendarItemReminderAsync.success(data));
      yield put(
        getPaymentCalendarItemsAsync.request({
          publicID: action.payload.orderPublicID,
        }),
      );
      toast.success(
        getTranslation(
          Resources.Orders.Workflow.Workflow.RecurringPaymentStep.Remind
            .Success,
        ),
      );
      return;
    }

    yield put(
      sendPaymentCalendarItemReminderAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(sendPaymentCalendarItemReminderAsync.failure(err as Error));
  }
}
export function* sendPaymentCalendarItemReminderSaga() {
  yield takeLatest(
    getType(sendPaymentCalendarItemReminderAsync.request),
    sendPaymentCalendarItemReminder,
  );
}

export const sendPaymentCalendarItemReminderSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<ClientInvoiceRecurringPaymentReminderCommandResult>(
      false,
    ),
  name: "@orderWorkflow/sendPaymentCalendarItemReminder",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<ClientInvoiceRecurringPaymentReminderCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(sendPaymentCalendarItemReminderAsync, builder);
    handleSuccessCase(sendPaymentCalendarItemReminderAsync, builder);
    handleFailureCase(sendPaymentCalendarItemReminderAsync, builder);
  },
});

export const { resetSlice } = sendPaymentCalendarItemReminderSlice.actions;
