import {
  InvoicePaymentStepCompleteCommandResult,
  postOrdersPublicIDWorkflowInvoicePaymentComplete,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderWorkflowStepsAsync } from "State/OrderWorkflow/Slices/OrderWorkflowStepsSlice";
import { getOrderDetailAsync } from "State/Orders/Detail/OrderDetailSlice";

export const completeInvoicePaymentAsync = createAsyncAction(
  "@orderWorkflow/COMPLETE_INVOICE_PAYMENT_REQUEST",
  "@orderWorkflow/COMPLETE_INVOICE_PAYMENT_RESPONSE",
  "@orderWorkflow/COMPLETE_INVOICE_PAYMENT_FAILURE",
)<
  {
    publicID: string;
  },
  InvoicePaymentStepCompleteCommandResult,
  Error
>();

function* completeInvoicePayment(
  action: ReturnType<typeof completeInvoicePaymentAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowInvoicePaymentComplete,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(
        getOrderWorkflowStepsAsync.request({
          publicID: action.payload.publicID,
        }),
      );
      yield put(
        getOrderDetailAsync.request({
          publicID: action.payload.publicID,
        }),
      );
      yield put(completeInvoicePaymentAsync.success(data));
      return;
    }

    yield put(
      completeInvoicePaymentAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(completeInvoicePaymentAsync.failure(err as Error));
  }
}
export function* completeInvoicePaymentSaga() {
  yield takeLatest(
    getType(completeInvoicePaymentAsync.request),
    completeInvoicePayment,
  );
}

export const completeInvoicePaymentSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<InvoicePaymentStepCompleteCommandResult>(false),
  name: "@orderWorkflow/completeInvoicePaymentSlice",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<InvoicePaymentStepCompleteCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(completeInvoicePaymentAsync, builder);
    handleSuccessCase(completeInvoicePaymentAsync, builder);
    handleFailureCase(completeInvoicePaymentAsync, builder);
  },
});

export const { resetSlice } = completeInvoicePaymentSlice.actions;
