import { PriceTaxType } from "Constants/PriceTaxType";
import { JWT_KEY } from "Utils/AuthUtils";

export function setToken(result: { token?: string | null | undefined }) {
  if (!!result.token) {
    localStorage.setItem(JWT_KEY, `Bearer ${result.token}`);
    return;
  }

  localStorage.removeItem(JWT_KEY);
}

export const PRICE_TAX_TYPE_STORAGE_KEY = "priceTaxType";

export function getPriceTaxTypeFromLocalStorage() {
  return localStorage.getItem(PRICE_TAX_TYPE_STORAGE_KEY)
    ? PriceTaxType[
        localStorage.getItem(
          PRICE_TAX_TYPE_STORAGE_KEY,
        ) as keyof typeof PriceTaxType
      ]
    : PriceTaxType.WithoutTax;
}
