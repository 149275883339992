import { Box, Stack, styled } from "@mui/material";
import { ServiceVariantProduct } from "Api/Api";
import { APP_CURRENCY_CODE } from "App";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { getEnterpriseCutoffProductPriceSettingsAsync } from "State/Cutoff/GetEnterpriseCutoffProductPriceSettings";
import { putEnterpriseCutoffProductPriceSettingsAsync } from "State/Cutoff/PutEnterpriseCutoffProductPriceSettings";
import { getHasAccessToProductAsync } from "State/Enterprises/Products/HasAccessToProductSlice";
import { RootStateType, useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { getCurrencySymbol } from "Utils/CurrencyUtils";
import { useEffect, useImperativeHandle, forwardRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

type Props = {};

type FormModel = {
  product: ServiceVariantProduct;
  priceWithTax?: number | null;
};
const StyledInputWrapper = styled(Box)`
  width: 275px;
`;

export type EucsCutoffSettingsRef = {
  submitForm: () => void;
};
export const EucsCutoffSettings = forwardRef<EucsCutoffSettingsRef, Props>(
  (props, ref) => {
    const dispatch = useDispatch();
    const { t } = useResource();

    useEffect(() => {
      dispatch(
        getHasAccessToProductAsync.request({
          product: ServiceVariantProduct.EucsGarance,
        }),
      );
      dispatch(
        getEnterpriseCutoffProductPriceSettingsAsync.request({
          product: ServiceVariantProduct.EucsGarance,
        }),
      );
    }, [dispatch]);

    const {
      data: enterpriseCutoffProductPriceSettings,
      isLoading: isLoadingEnterpriseCutoffProductPriceSettings,
    } = useAppSelector(x => x.cutoff.getEnterpriseCutoffProductPriceSettings);

    const form = useForm<FormModel>({
      mode: "onChange",
      defaultValues: {
        product: ServiceVariantProduct.EucsGarance,
        priceWithTax: enterpriseCutoffProductPriceSettings?.priceWithTax,
      },
    });

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
    } = form;

    const { data: hasAccessToEucsProduct } = useSelector(
      (e: RootStateType) => e.enterprise.getHasAccessToProduct,
    );

    useEffect(() => {
      setValue(
        "priceWithTax",
        enterpriseCutoffProductPriceSettings?.priceWithTax,
      );
    }, [enterpriseCutoffProductPriceSettings, setValue]);

    const onSubmit = (data: FormModel) => {
      if (!!hasAccessToEucsProduct && hasAccessToEucsProduct.hasAcces) {
        dispatch(
          putEnterpriseCutoffProductPriceSettingsAsync.request({
            product: data.product,
            priceWithTax: data?.priceWithTax ?? 0,
          }),
        );
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    return (
      <>
        {!!hasAccessToEucsProduct &&
          hasAccessToEucsProduct.hasAcces &&
          !isLoadingEnterpriseCutoffProductPriceSettings && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction={"row"} gap={4} width={"100%"}>
                <StyledInputWrapper>
                  <BlFormInput
                    name={"priceWithTax"}
                    control={control}
                    errors={errors}
                    label={t(Resources.Settings.Cutoff.New.Eucs.Price.Label)}
                    labelStartAdornment={
                      <BlDefaultTooltip
                        title={t(
                          Resources.Settings.Cutoff.New.Eucs.Price.Tooltip,
                        )}
                      />
                    }
                    isNumber
                    mask={Number}
                    min={0}
                    max={999999}
                    unit={getCurrencySymbol(APP_CURRENCY_CODE)}
                  />
                </StyledInputWrapper>
              </Stack>
            </form>
          )}
      </>
    );
  },
);
