import { Stack, Typography } from "@mui/material";
import { OrderDto, OrderServiceDto } from "Api/Api";
import { useParty } from "Hooks/Party/useParty";
import { useResource, Resources } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { Gender } from "Utils/PersonalNumberUtils";
import styled from "styled-components";

const StyledDateProcessed = styled.div<{
  $wasProcessedByRepresentative: boolean;
}>`
  width: 250px;
  height: ${({ $wasProcessedByRepresentative }) =>
    $wasProcessedByRepresentative ? "60px" : "35px"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: 1px solid ${({ theme }) => theme.colors.border};

  p {
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: 12px;
    font-weight: 700;
  }
`;

type Props = {
  orderService: OrderServiceDto;
  order: OrderDto | null | undefined;
};

export const CompletedChip: React.FunctionComponent<Props> = props => {
  const { orderService, order } = props;
  const { t } = useResource();

  const wasProcessedByRepresentative =
    !!orderService.dateProcessed &&
    !!order &&
    order.userPartyPublicID !== orderService.processedByUserPartyPublicID;

  const { gender, formattedName } = useParty(
    wasProcessedByRepresentative
      ? orderService.processedByUserPartyPublicID
      : null,
  );

  return (
    <>
      <StyledDateProcessed
        $wasProcessedByRepresentative={wasProcessedByRepresentative}
      >
        <Stack>
          <Typography>
            {t(
              Resources.Orders.Workflow.Workflow.ProcessingServicesStep
                .DateProcessed,
              { date: formatDate(orderService.dateProcessed, true) },
            )}
          </Typography>
          {wasProcessedByRepresentative && (
            <Typography>
              {t(Resources.Common.ProcessedByRepresentative, {
                name: formattedName,
                gender: gender === Gender.Female ? "a" : "",
              })}
            </Typography>
          )}
        </Stack>
      </StyledDateProcessed>
    </>
  );
};
