import { EntityListOfStepDto, getWorkflowsSteps } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getStepListAsync = createAsyncAction(
  "@workflow/GET_STEP_LIST_REQUEST",
  "@workflow/GET_STEP_LIST_RESPONSE",
  "@workflow/GET_STEP_LIST_FAILURE",
)<void, EntityListOfStepDto, Error>();

function* getStepList(
  action: ReturnType<typeof getStepListAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(getWorkflowsSteps);

    if (status === 200) {
      yield put(getStepListAsync.success(data));
      return;
    }

    yield put(getStepListAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getStepListAsync.failure(err as Error));
  }
}
export function* getStepListSaga() {
  yield takeLatest(getType(getStepListAsync.request), getStepList);
}

export const getStepListSlice = createSlice({
  initialState: getFetchStateDefaultValue<EntityListOfStepDto>(false),
  name: "list",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<EntityListOfStepDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getStepListAsync, builder);
    handleSuccessCase(getStepListAsync, builder);
    handleFailureCase(getStepListAsync, builder);
  },
});

export const { resetSlice } = getStepListSlice.actions;
