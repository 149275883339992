import { useResource, Resources } from "Translations/Resources";

export function useMonthTranslations() {
  const { t } = useResource();

  return (monthCount: number) => {
    switch (monthCount) {
      case 1:
        return t(Resources.Common.Months, { count: monthCount });
      case 2:
      case 3:
      case 4:
        return t(Resources.Common.Months_few, { count: monthCount });
      default:
        return t(Resources.Common.Months, { count: monthCount });
    }
  };
}
