import { combineReducers } from "@reduxjs/toolkit";
import {
  deleteServicePackageSlice,
  deleteServicePackageSaga,
} from "State/ServicePackages/Slices/DeleteServicePackageSlice";
import {
  getAvailableServicesSaga,
  getAvailableServicesSlice,
} from "State/ServicePackages/Slices/GetAvailableServicesSlice";
import {
  getPackageServiceSettingsSaga,
  getPackageServiceSettingsSlice,
} from "State/ServicePackages/Slices/GetPackagesServiceSettingsSlice";
import {
  getServicePackageDetailSaga,
  getServicePackageDetailSlice,
} from "State/ServicePackages/Slices/GetServicePackageDetailSlice";
import {
  getServicePackagesSaga,
  getServicePackagesSlice,
} from "State/ServicePackages/Slices/GetServicePackagesSlice";
import {
  savePackageServiceSettingsSaga,
  savePackageServiceSettingsSlice,
} from "State/ServicePackages/Slices/SavePackagesServiceSettingsSlice";
import {
  savePackageServiceSaga,
  savePackageServiceSlice,
} from "State/ServicePackages/Slices/SaveServicePackage";
import { all } from "typed-redux-saga";

export const servicePackageReducer = combineReducers({
  list: getServicePackagesSlice.reducer,
  availableServices: getAvailableServicesSlice.reducer,
  save: savePackageServiceSlice.reducer,
  detail: getServicePackageDetailSlice.reducer,
  delete: deleteServicePackageSlice.reducer,
  serviceSettings: getPackageServiceSettingsSlice.reducer,
  saveServiceSettings: savePackageServiceSettingsSlice.reducer,
});

export function* watchServicePackageSagas() {
  yield all([
    getServicePackagesSaga(),
    getAvailableServicesSaga(),
    savePackageServiceSaga(),
    getServicePackageDetailSaga(),
    deleteServicePackageSaga(),
    getPackageServiceSettingsSaga(),
    savePackageServiceSettingsSaga(),
  ]);
}
