import { Divider, Grid, Typography } from "@mui/material";
import {
  OrderServiceProductDto,
  OrderServiceProductInstitutionStatus,
  ServiceVariantProduct,
} from "Api/Api";
import { baseLinkStyles } from "Components/Routes/UnstyledLink";
import { FileTextIcon } from "Components/Shared/Icons/FileText";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { getEucsOrderInfoAsync } from "State/Orders/Detail/EucsOrderInfoSlice";
import { RootStateType } from "State/Store";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import eucLogo from "./../../../../Assets/Images/Images/eucs_logo.png";
import { BlConfirmButton } from "Components/Shared/Buttons/BlConfirmButton";
import { Resources, useResource } from "Translations/Resources";
import {
  StyledRowWrapper,
  StyledTrashIcon,
} from "Components/Orders/Detail/Service";
import { postCancelOrderServiceProductAsync } from "State/Orders/Detail/CancelOrderServiceProductSlice";
import { postCreateOrderServiceProductAsync } from "State/Orders/Detail/CreateOrderServiceProductSlice";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { DefaultTheme } from "styled-components/dist/types";

type Props = {
  orderServiceID: number;
  publicID: string;
  serviceName: string;
  orderServiceProduct: OrderServiceProductDto;
  isReadOnly: boolean;
};

const DocumentationWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(1)};
`;

const StyledEUCSLogo = styled.img<{ $height?: number }>`
  width: auto;
  height: ${props => props.$height}px;
  position: absolute;
`;
const StyledEUCSLogoWrapper = styled.div`
  position: relative;
  padding-bottom: ${props => props.theme.spacing(1.5)};
`;
const PageResources = Resources.Orders.Workflow.Services.EucsService;

const StyledDocumentLink = styled.a`
  ${baseLinkStyles}
  font-size: 12px;
`;

export const eucsFormatDocumentName = function (documentName: string) {
  documentName = documentName.replaceAll("garance-eucs-", "");
  documentName = documentName.replaceAll("-", " ");
  documentName = documentName[0].toUpperCase() + documentName.slice(1);
  documentName = documentName.replaceAll(".pdf", "");
  return documentName;
};

export const eucsStateFontColor = function (
  statusName: string,
  theme: DefaultTheme,
) {
  if (statusName === "Zrušeno") {
    return theme.palette.error.main;
  }

  if (statusName === "Aktivní") {
    return theme.palette.success.dark;
  }

  return "default";
};

export const eucsFormatStateName = function (state: string | null | undefined) {
  if (!state) {
    return "";
  }
  if (state === "[AUTO-5]") {
    return "Zrušeno";
  }
  if (state === "[AUTO-0]" || state === "[AUTO-1]" || state === "[AUTO-2]") {
    return "Zaslána smlouva a pokyny k platbě";
  }
  return state;
};

export const EucsService: React.FunctionComponent<Props> = props => {
  const {
    orderServiceID,
    publicID,
    serviceName,
    orderServiceProduct,
    isReadOnly,
  } = props;

  const theme = useTheme();
  const { t } = useResource();
  const wasProductCreate =
    orderServiceProduct.institutionStatus ===
      OrderServiceProductInstitutionStatus.Created ||
    orderServiceProduct.institutionStatus ===
      OrderServiceProductInstitutionStatus.Canceled;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!!publicID && !!orderServiceID && wasProductCreate) {
      dispatch(
        getEucsOrderInfoAsync.request({
          orderPublicID: publicID,
          orderServiceID: orderServiceID,
        }),
      );
    }
  }, [dispatch, publicID, orderServiceID, wasProductCreate]);

  const {
    data: eucsInfo,
    isLoading: isLoadingEucsInfo,
    error: errorLoadingEucsInfo,
  } = useSelector((e: RootStateType) => e.order.eucsOrderInfo);

  const cancelOrderServiceProduct = (
    orderServiceID: number,
    orderPublicID: string,
  ) => {
    if (isLoadingEucsInfo) {
      return;
    }
    dispatch(
      postCancelOrderServiceProductAsync.request({
        request: { product: ServiceVariantProduct.EucsGarance },
        orderPublicID: orderPublicID,
        orderServiceID: orderServiceID,
      }),
    );
  };

  const createOrderServiceProduct = (
    orderServiceID: number,
    orderPublicID: string,
  ) => {
    if (isLoadingEucsInfo) {
      return;
    }
    dispatch(
      postCreateOrderServiceProductAsync.request({
        request: {
          product: ServiceVariantProduct.EucsGarance,
          sendEmailWhenCreationError: false,
        },
        orderPublicID: orderPublicID,
        orderServiceID: orderServiceID,
      }),
    );
  };

  const { isLoading: isLoadingCancelOrderServiceProduct } = useSelector(
    (e: RootStateType) => e.order.postCancelOrderServiceProduct,
  );

  const { isLoading: isLoadingCreateOrderServiceProduct } = useSelector(
    (e: RootStateType) => e.order.postCreateOrderServiceProduct,
  );

  const canCancel =
    orderServiceProduct.institutionStatus ===
      OrderServiceProductInstitutionStatus.Created &&
    !isLoadingCancelOrderServiceProduct &&
    !isReadOnly;

  const showCreateProductBtn =
    orderServiceProduct.institutionStatus ===
      OrderServiceProductInstitutionStatus.CreationError ||
    orderServiceProduct.institutionStatus ===
      OrderServiceProductInstitutionStatus.NotCreatedBecauseTest;

  const showCancelBtn = !showCreateProductBtn;

  const canCreate =
    showCreateProductBtn && !isLoadingCreateOrderServiceProduct && !isReadOnly;

  const isEucsInfoLoaded = !!eucsInfo && !!eucsInfo.orderInfoResponseDto;

  return (
    <>
      <StyledRowWrapper
        $alignItems="center"
        $justifyContent="space-between"
        $marginBottom={0.5}
      >
        <div>
          <StyledEUCSLogoWrapper>
            <StyledEUCSLogo src={eucLogo} alt="Logo" $height={12} />
          </StyledEUCSLogoWrapper>

          <Typography fontWeight={500}>{serviceName}</Typography>
        </div>

        {showCancelBtn && (
          <BlConfirmButton
            modalContent={t(PageResources.Cancel.Text)}
            modalTitle={t(PageResources.Cancel.Title)}
            tooltip={
              canCancel
                ? t(PageResources.Cancel.Tooltip)
                : isReadOnly
                  ? t(PageResources.Cancel.Disabled.IsReadOnly)
                  : t(
                      PageResources.Cancel.Disabled.Status[
                        orderServiceProduct.institutionStatus
                      ],
                    )
            }
            disabled={!canCancel}
            onResult={r => {
              if (r) {
                cancelOrderServiceProduct(orderServiceID, publicID);
              }
            }}
          >
            <StyledTrashIcon />
          </BlConfirmButton>
        )}
      </StyledRowWrapper>

      <Divider />

      {!wasProductCreate && (
        <Typography mt={1} mb={2}>
          {t(PageResources.Status[orderServiceProduct.institutionStatus])}
        </Typography>
      )}

      {wasProductCreate &&
      (!isEucsInfoLoaded || isLoadingEucsInfo) &&
      !errorLoadingEucsInfo ? (
        <Typography mt={1} mb={2}>
          {t(PageResources.Loading)}
        </Typography>
      ) : (
        <></>
      )}

      {wasProductCreate && errorLoadingEucsInfo ? (
        <Typography mt={1} mb={2}>
          {t(PageResources.ErrorLoading)}
        </Typography>
      ) : (
        <></>
      )}

      {showCreateProductBtn && (
        <Grid marginTop={1} marginBottom={1} textAlign={"center"}>
          <BlButton
            color="primary"
            onClick={() => createOrderServiceProduct(orderServiceID, publicID)}
            disabled={!canCreate}
          >
            {t(PageResources.Create.Text)}
          </BlButton>
        </Grid>
      )}

      {!!isEucsInfoLoaded && !isLoadingEucsInfo && (
        <>
          <StyledFlex $marginTop={1}>
            <Typography fontSize={12}>{t(PageResources.State)}</Typography>

            <Typography
              fontSize={12}
              marginLeft={"auto"}
              fontWeight={500}
              color={eucsStateFontColor(
                eucsFormatStateName(eucsInfo.orderInfoResponseDto.order_state),
                theme,
              )}
            >
              {eucsFormatStateName(eucsInfo.orderInfoResponseDto.order_state)}
            </Typography>
          </StyledFlex>
          {eucsInfo.orderInfoResponseDto.documents?.length > 0 && (
            <DocumentationWrapper>
              <Typography fontSize={12} fontWeight={500}>
                {t(PageResources.Documents)}
              </Typography>
              {eucsInfo.orderInfoResponseDto.documents.map(x => (
                <StyledFlex key={x.filename}>
                  <FileTextIcon
                    color={theme.palette.primary.main}
                  ></FileTextIcon>
                  <StyledDocumentLink href={x.url} target="_blank">
                    {eucsFormatDocumentName(x.filename)}
                  </StyledDocumentLink>
                </StyledFlex>
              ))}
            </DocumentationWrapper>
          )}
        </>
      )}

      <Divider />
    </>
  );
};
