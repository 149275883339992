import {
  ActionReducerMapBuilder,
  Draft,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  ActionCreator,
  AsyncActionCreatorBuilder,
  getType,
} from "typesafe-actions";

export type FetchStateType<T, TError extends Error = Error> = {
  isLoading: boolean;
  data?: T | null;
  error?: TError | null;
};

export function getFetchStateDefaultValue<T>(
  isLoading = false,
): FetchStateType<T> {
  return {
    isLoading,
    data: null,
    error: null,
  };
}

export function handleRequestCase(
  action: AsyncActionCreatorBuilder<any, any, any>,
  builder: ActionReducerMapBuilder<FetchStateType<unknown, Error>>,
) {
  return builder.addCase(getType(action.request), state => {
    state.error = null;
    state.isLoading = true;
  });
}

export function handleSuccessCase<T>(
  action: AsyncActionCreatorBuilder<any, any, any>,
  builder: ActionReducerMapBuilder<FetchStateType<unknown, Error>>,
) {
  return builder.addCase(
    getType(action.success as ActionCreator<string>),
    (state, action: PayloadAction<T>) => {
      state.isLoading = false;
      state.data = action.payload as Draft<T>;
      state.error = null;
    },
  );
}

export function handleFailureCase(
  action: AsyncActionCreatorBuilder<any, any, any>,
  builder: ActionReducerMapBuilder<FetchStateType<unknown, Error>>,
  clearDataOnFailure = false,
) {
  return builder.addCase(
    getType(action.failure),
    (state, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.error = action.payload;
      if (clearDataOnFailure) {
        state.data = null;
      }
    },
  );
}
