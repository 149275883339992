import {
  OrderServiceCompleteCommandResult,
  postOrdersPublicIDWorkflowProcessingServicesOrderServiceIDComplete,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, delay, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderWorkflowStepsAsync } from "State/OrderWorkflow/Slices/OrderWorkflowStepsSlice";
import { getOrderServicesAsync } from "State/Orders/Detail/OrderServicesSlice";
import { getOrderDetailAsync } from "State/Orders/Detail/OrderDetailSlice";

export const completeOrderServiceAsync = createAsyncAction(
  "@order/COMPLETE_ORDER_SERVICE_REQUEST",
  "@order/COMPLETE_ORDER_SERVICE_RESPONSE",
  "@order/COMPLETE_ORDER_SERVICE_FAILURE",
)<
  {
    publicID: string;
    orderServiceID: number;
  },
  OrderServiceCompleteCommandResult,
  Error
>();

function* completeOrderService(
  action: ReturnType<typeof completeOrderServiceAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowProcessingServicesOrderServiceIDComplete,
      action.payload.publicID,
      action.payload.orderServiceID,
    );

    if (status === 200) {
      if (data.areAllServicesCompleted) {
        yield put(
          getOrderWorkflowStepsAsync.request({
            publicID: action.payload.publicID,
          }),
        );
        yield put(
          getOrderServicesAsync.request({
            publicID: action.payload.publicID,
          }),
        );
        yield put(
          getOrderDetailAsync.request({ publicID: action.payload.publicID }),
        );
      }

      //wait one second to reload services
      yield delay(1000);

      yield put(completeOrderServiceAsync.success(data));
      return;
    }

    yield put(
      completeOrderServiceAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(completeOrderServiceAsync.failure(err as Error));
  }
}
export function* completeOrderServiceSaga() {
  yield takeLatest(
    getType(completeOrderServiceAsync.request),
    completeOrderService,
  );
}

export const completeOrderServiceSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<OrderServiceCompleteCommandResult>(false),
  name: "@order/completeOrderService",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<OrderServiceCompleteCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(completeOrderServiceAsync, builder);
    handleSuccessCase(completeOrderServiceAsync, builder);
    handleFailureCase(completeOrderServiceAsync, builder);
  },
});

export const { resetSlice } = completeOrderServiceSlice.actions;
