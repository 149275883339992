import { Box, Stack, Typography } from "@mui/material";
import { ServicePackageListItemDto } from "Api/Api";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlConfirmButton } from "Components/Shared/Buttons/BlConfirmButton";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { TrashIcon, EditIcon } from "Components/Shared/Icons";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { useTaxLabels } from "Hooks/useWithTax";
import { deleteServicePackageAsync } from "State/ServicePackages/Slices/DeleteServicePackageSlice";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import styled from "styled-components";

const Subheader = styled(Typography)`
  font-weight: 700;
  color: ${props => props.theme.palette.text.secondary};
`;

const ListItem = styled.li`
  color: ${props => props.theme.palette.text.secondary};
`;

const UnorderedList = styled.ul`
  padding-inline-start: ${props => props.theme.spacing(3)};
`;

const StyledService = styled(Box)`
  padding: ${props => props.theme.spacing(1.5)};
  background-color: ${props => props.theme.colors.fieldBackground};
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: ${props => props.theme.spacing(2)};

  .checkbox-wrapper {
    margin-right: ${props => props.theme.spacing(1)};
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  color: ${props => props.theme.palette.error.main} !important;
`;

const PageResources = Resources.Settings.ServicePackages;

type Props = { servicePackage: ServicePackageListItemDto };

export const ServicePackageItem: React.FunctionComponent<Props> = props => {
  const { servicePackage } = props;
  const { withTax, withoutTax } = useTaxLabels();
  const { t } = useResource();
  const dispatch = useAppDispatch();

  return (
    <>
      <StyledService key={servicePackage.servicePublicID}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Typography variant="h3" mb={1}>
            {servicePackage.name}
          </Typography>

          <Stack flexDirection={"row"} alignItems={"flex-end"}>
            {servicePackage.canEdit ? (
              <UnstyledLink
                to={getPath(
                  AppRouting.ServicePackagesDetail,
                  servicePackage.name,
                  servicePackage.servicePublicID,
                )}
              >
                <BlDefaultButton title={t(Resources.Common.Edit)}>
                  <EditIcon />
                </BlDefaultButton>
              </UnstyledLink>
            ) : (
              <BlDefaultButton title={t(Resources.Common.Edit)} disabled={true}>
                <EditIcon />
              </BlDefaultButton>
            )}

            <BlConfirmButton
              tooltip={t(Resources.Common.Delete)}
              modalTitle={t(PageResources.List.Delete.ModalTitle)}
              modalContent={t(PageResources.List.Delete.ModalContent)}
              onResult={result => {
                if (result && servicePackage.canEdit) {
                  dispatch(
                    deleteServicePackageAsync.request({
                      servicePublicID: servicePackage.servicePublicID,
                    }),
                  );
                }
              }}
              disabled={!servicePackage.canEdit}
            >
              <StyledTrashIcon />
            </BlConfirmButton>
          </Stack>
        </Stack>
        <Typography variant="subtitle2" fontStyle={"normal"}>
          {servicePackage.description}
        </Typography>

        <Subheader mt={3}>{t(PageResources.List.Services)}</Subheader>

        <UnorderedList>
          {servicePackage.relatedServices?.map(service => (
            <ListItem key={service.name}>{service.name}</ListItem>
          ))}
        </UnorderedList>

        <Subheader mt={3}>{t(PageResources.List.Variants)}</Subheader>

        <UnorderedList>
          {servicePackage.variants?.map(variant => (
            <ListItem key={variant.name}>
              <Stack direction="row" gap={0.5}>
                <Typography>{variant.name}:</Typography>
                <Typography fontWeight={700}>
                  {withoutTax(
                    formatCurrency(
                      variant.priceWithoutTax,
                      variant.currencyCode,
                    ),
                  )}
                </Typography>

                <Typography>
                  {`(${withTax(
                    formatCurrency(variant.priceWithTax, variant.currencyCode),
                  )})`}
                </Typography>
              </Stack>
            </ListItem>
          ))}
        </UnorderedList>
      </StyledService>
    </>
  );
};
