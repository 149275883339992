import { Typography } from "@mui/material";
import {
  OrderDto,
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  StepType,
} from "Api/Api";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getEnterpriseInvoiceIssuanceAndPaymentAsync } from "State/OrderWorkflow/Slices/GetEnterpriseInvoiceIssuanceAndPayment";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { useEffect } from "react";

type Props = {
  publicID: string;
  step: OrderWorkflowStepDto;
  steps: OrderWorkflowStepDto[];
  order: OrderDto | null | undefined;
};

export const EnterpriseInvoiceIssuanceAndPaymentStep: React.FunctionComponent<
  Props
> = props => {
  const { step, steps, publicID } = props;
  const { t } = useResource();

  const clientInvoicePaymentStep = steps.find(
    x => x.type === StepType.ClientInvoicePayment,
  );

  const dispatch = useAppDispatch();
  const isCompleted = step.status === OrderWorkflowStepStatus.Completed;
  const isZeroPrice = props.order?.isZeroPrice;

  const isNotShown =
    clientInvoicePaymentStep?.status !== OrderWorkflowStepStatus.Completed;

  useEffect(() => {
    if (isNotShown) {
      return;
    }
    dispatch(
      getEnterpriseInvoiceIssuanceAndPaymentAsync.request({
        publicID: publicID,
      }),
    );
  }, [dispatch, publicID, isNotShown]);

  const { data } = useAppSelector(
    e => e.orderWorkflow.enterpriseInvoiceIssuanceAndPayment,
  );

  if (isCompleted && isZeroPrice) {
    return (
      <Typography marginBottom={2}>
        {t(
          Resources.Orders.Workflow.Workflow
            .EnterpriseInvoiceIssuanceAndPaymentStep.CompletedZeroPrice,
        )}
      </Typography>
    );
  }

  if (isNotShown) {
    return null;
  }

  return (
    <>
      {isCompleted ? (
        <Typography marginBottom={2}>
          {t(
            Resources.Orders.Workflow.Workflow
              .EnterpriseInvoiceIssuanceAndPaymentStep.Completed,
            { date: formatDate(data?.dueDate) },
          )}
        </Typography>
      ) : (
        <Typography marginBottom={2}>
          {t(
            Resources.Orders.Workflow.Workflow
              .EnterpriseInvoiceIssuanceAndPaymentStep.Description,
          )}
        </Typography>
      )}
    </>
  );
};
