import { convertIbanToBankAccount, convertToIban } from "Utils/IbanUtils";
import { isNoU } from "Utils/ObjectUtils";

export const isCompanyNumberValid = (value: string): boolean => {
  if (isNoU(value) || !new RegExp(/^\d{8}$/g).test(value)) {
    return false;
  }

  const numbers = [...value].map(v => Number(v));
  const checkSum = numbers.slice(0, 7).reduce((sum, v, index) => {
    return (sum += v * (8 - index));
  }, 0);

  const remainder = checkSum % 11;
  let lastDigit: number;
  if (remainder === 0) {
    lastDigit = 1;
  } else if (remainder === 1) {
    lastDigit = 0;
  } else {
    lastDigit = 11 - remainder;
  }

  return numbers[7] === lastDigit;
};

export const isTaxNumberValid = (value: string): boolean => {
  if (isNoU(value)) {
    return false;
  }

  return new RegExp(/CZ+[0-9]{8,10}/, "g").test(value);
};

export const isBankAccountNumberValid = (
  bankAccountNumber: string,
  bankCode: string,
) => {
  const isNumberPartValid = (part: string) => {
    let sum = 0;
    for (let i = 0; i < part.length; i++) {
      const num = +part[i];
      const weight = Math.pow(2, part.length - i - 1) % 11;
      sum += num * weight;
    }

    const isValidFormat = sum % 11 === 0;

    if (!isValidFormat) {
      return false;
    }

    var iban = convertToIban(bankAccountNumber, bankCode);
    if (!iban) {
      return false;
    }
    var bankAccount = convertIbanToBankAccount(iban);

    if (!bankAccount) {
      return false;
    }

    return true;
  };

  return bankAccountNumber
    .split("-")
    .every(x => x.length && isNumberPartValid(x));
};
