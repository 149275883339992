import {
  HasEnterpriseAccessToServiceProductQueryResult,
  ServiceVariantProduct,
  getEnterprisesHasAccessToProductProduct,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getHasAccessToProductAsync = createAsyncAction(
  "@ENTERPRISE/GET_HAS_ACCESS_TO_PRODUCT_REQUEST",
  "@ENTERPRISE/GET_HAS_ACCESS_TO_PRODUCT_RESPONSE",
  "@ENTERPRISE/GET_HAS_ACCESS_TO_PRODUCT_FAILURE",
)<
  { product: ServiceVariantProduct },
  HasEnterpriseAccessToServiceProductQueryResult,
  Error
>();

function* getHasAccessToProduct(
  action: ReturnType<typeof getHasAccessToProductAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getEnterprisesHasAccessToProductProduct,
      action.payload.product,
    );

    if (status === 200) {
      yield put(getHasAccessToProductAsync.success(data));
      return;
    }

    yield put(
      getHasAccessToProductAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(getHasAccessToProductAsync.failure(err as Error));
  }
}
export function* getHasAccessToProductSaga() {
  yield takeLatest(
    getType(getHasAccessToProductAsync.request),
    getHasAccessToProduct,
  );
}

export const getHasAccessToProductSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<HasEnterpriseAccessToServiceProductQueryResult>(
      false,
    ),
  name: "collection",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<HasEnterpriseAccessToServiceProductQueryResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(getHasAccessToProductAsync, builder);
    handleSuccessCase(getHasAccessToProductAsync, builder);
    handleFailureCase(getHasAccessToProductAsync, builder);
  },
});

export const { resetSlice } = getHasAccessToProductSlice.actions;
