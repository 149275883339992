import { Divider } from "@mui/material";
import styled from "styled-components";

export const StyledWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: auto auto 1fr;
  min-height: ${props => props.theme.vh(100)};
`;

export const StyledDivider = styled(Divider)`
  margin: ${props => props.theme.spacing(3, 3)};
`;

export const StyledContainer = styled.div<{ $isGray?: boolean }>`
  display: flex;
  justify-content: center;
  ${props => props.$isGray && `background-color: ${props.theme.colors.gray};`}

  & > div {
    width: 335px;
  }
`;

export const StyledOrWrapper = styled.div`
  margin: ${props => props.theme.spacing(3, 0)};
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.palette.text.secondary};
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100px;
    background-color: ${props => props.theme.colors.border};
    top: calc(50% - 1px);
  }
  &::before {
    left: ${props => props.theme.spacing(3)};
  }
  &::after {
    right: ${props => props.theme.spacing(3)};
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: grid;
  gap: ${props => props.theme.spacing(2)};
`;
