import { CompanyDto, getUserCompany, getCompaniesPublicID } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getUserCompanyDetailAsync = createAsyncAction(
  "@COMPANIES/GET_USER_COMPANY_DETAIL_REQUEST",
  "@COMPANIES/GET_USER_COMPANY_DETAIL_RESPONSE",
  "@COMPANIES/GET_USER_COMPANY_DETAIL_FAILURE",
)<void, CompanyDto | null, Error>();

function* getUserCompanyDetail(
  action: ReturnType<typeof getUserCompanyDetailAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(getUserCompany);

    if (status === 200 && data.publicID) {
      const companyDetail = yield* call(getCompaniesPublicID, data.publicID);

      if (companyDetail.status === 200) {
        yield put(getUserCompanyDetailAsync.success(companyDetail.data));
        return;
      }

      yield put(
        getUserCompanyDetailAsync.failure(
          mapAPIErrorResponse(companyDetail.error ?? companyDetail.data),
        ),
      );
      return;
    } else if (status !== 200) {
      yield put(
        getUserCompanyDetailAsync.failure(mapAPIErrorResponse(error ?? data)),
      );
      return;
    }

    yield put(getUserCompanyDetailAsync.success(null));
  } catch (err) {
    yield put(getUserCompanyDetailAsync.failure(err as Error));
  }
}
export function* getUserCompanyDetailSaga() {
  yield takeLatest(
    getType(getUserCompanyDetailAsync.request),
    getUserCompanyDetail,
  );
}

export const getUserCompanyDetailSlice = createSlice({
  initialState: getFetchStateDefaultValue<CompanyDto>(false),
  name: "userCompanyDetail",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<CompanyDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getUserCompanyDetailAsync, builder);
    handleSuccessCase(getUserCompanyDetailAsync, builder);
    handleFailureCase(getUserCompanyDetailAsync, builder);
  },
});

export const { resetSlice } = getUserCompanyDetailSlice.actions;
