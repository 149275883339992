import * as React from "react";
export const StarIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 17.75L5.82802 20.995L7.00702 14.122L2.00702 9.25501L8.90702 8.25501L11.993 2.00201L15.079 8.25501L21.979 9.25501L16.979 14.122L18.158 20.995L12 17.75Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
