import { Box } from "@mui/material";
import { PaymentCalendarItemStatus } from "Api/Api";
import { BlConfirmButton } from "Components/Shared/Buttons/BlConfirmButton";
import { CrossIcon } from "Components/Shared/Icons";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { cancelPaymentCalendarItemAsync } from "State/OrderWorkflow/Slices/CancelPaymentCalendarItemSlice";
import { Resources, useResource } from "Translations/Resources";

const PageResources = Resources.Orders.Workflow.Workflow.RecurringPaymentStep;

type Props = {
  status: PaymentCalendarItemStatus;
  paymentCalendarItemID: number;
  publicID: string;
};

export const CreatedActions: React.FunctionComponent<Props> = props => {
  const { status, paymentCalendarItemID, publicID } = props;
  const { t } = useResource();
  const dispatch = useAppDispatch();

  if (status !== PaymentCalendarItemStatus.Created) {
    return null;
  }

  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
      <BlConfirmButton
        variant="default"
        modalTitle={t(PageResources.Cancel.Title)}
        modalContent={t(PageResources.Cancel.Text)}
        tooltip={`${t(PageResources.Cancel.Title)} ${t(
          PageResources.Cancel.Text,
        )}`}
        onResult={r => {
          if (r) {
            dispatch(
              cancelPaymentCalendarItemAsync.request({
                paymentCalendarItemID,
                orderPublicID: publicID,
              }),
            );
          }
        }}
      >
        <CrossIcon color="red" />
      </BlConfirmButton>
    </Box>
  );
};
