import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum Step {
  Form,
  Summary,
}

type SliceState = {
  step: Step;
};

const initialState: SliceState = {
  step: Step.Form,
};

export const draftSlice = createSlice({
  initialState,
  name: "draft",
  reducers: {
    setStep: (state, action: PayloadAction<Step>) => {
      state.step = action.payload;
    },
  },
});

export const { setStep } = draftSlice.actions;
