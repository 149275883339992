import { IdentificationType, OrderClientStatus, PartyType } from "Api/Api";
import { PartyFormType } from "Components/Shared/Party/PartyForm";
import { Resources } from "Translations/Resources";
import { nameof } from "Utils/ObjectUtils";
import { PersonalNumber } from "Utils/PersonalNumberUtils";
import {
  isBankAccountNumberValid,
  isCompanyNumberValid,
  isTaxNumberValid,
} from "Utils/ValidationUtils";
import { ObjectSchema, boolean, date, object, string } from "yup";

export type PartyFormModel = {
  party: {
    status: OrderClientStatus;
    type: PartyType;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    taxNumber?: string;
    isVATPayer?: boolean;
    companyNumber?: string;
    personalNumber?: string;
    isForeigner?: boolean;
    birthDate?: Date | null;
    phone?: string;
    email?: string;
    street?: string | null;
    streetNumber?: string | null;
    orientationNumber?: string | null;
    postalCode?: string;
    municipality?: string;
    bankAccountNumber?: string;
    bankCode?: string;
    companyRepresentativeFirstName?: string | null;
    companyRepresentativeLastName?: string | null;
    companyRepresentativeBirthDate?: Date | null;
  };
  clientID?: number;
  clientIdentification?: IdentificationType | null;
  clientIdentificationNumber?: string | null;
};

type PartyFormPartyType = PartyFormModel["party"];

export const getPartyFormValidationsSchema = (
  t: (resourcePath: string, options?: any) => string,
  areFieldsRequired: boolean,
  isBankAccountVisible: boolean,
  partyFormType: PartyFormType,
) => {
  const validationSchema: ObjectSchema<PartyFormPartyType> = object({
    status: string().oneOf(Object.values(OrderClientStatus)).required(),
    type: string().oneOf(Object.values(PartyType)).required(),
    firstName: string().when(nameof<PartyFormPartyType>("type"), {
      is: (type: PartyFormPartyType["type"]) =>
        areFieldsRequired &&
        (type === PartyType.NaturalPerson || type === PartyType.SelfEmployed),
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    lastName: string().when(nameof<PartyFormPartyType>("type"), {
      is: (type: PartyFormPartyType["type"]) =>
        areFieldsRequired &&
        (type === PartyType.NaturalPerson || type === PartyType.SelfEmployed),
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    companyName: string().when(nameof<PartyFormPartyType>("type"), {
      is: (type: PartyFormPartyType["type"]) =>
        areFieldsRequired && type === PartyType.LegalEntity,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    companyNumber: string().when(nameof<PartyFormPartyType>("type"), {
      is: (type: PartyFormPartyType["type"]) =>
        areFieldsRequired &&
        (type === PartyType.LegalEntity || type === PartyType.SelfEmployed),
      then: schema =>
        schema
          .required()
          .test(
            "companyNumber",
            t(Resources.Validation.InvalidCompanyNumber),
            (value, ctx) => {
              if (!value) {
                return false;
              }
              const isExisting =
                ctx.parent.status === OrderClientStatus.Existing;

              if (isExisting) {
                return true;
              }

              return isCompanyNumberValid(value);
            },
          ),
      otherwise: schema => schema.optional(),
    }),
    personalNumber: string().when([nameof<PartyFormPartyType>("type")], {
      is: (type: PartyFormPartyType["type"]) =>
        areFieldsRequired && type === PartyType.NaturalPerson,
      then: schema =>
        schema
          .required()
          .matches(/^(|\d+| )$/, t(Resources.Validation.DigitsOnly))
          .test(
            "personalIdentificationNumber",
            t(Resources.Validation.InvalidPersonalIdentificationNumber),
            (value, ctx) => {
              if (!value) {
                return false;
              }
              const isExisting =
                ctx.parent.status === OrderClientStatus.Existing;

              if (isExisting) {
                return true;
              }

              const isForeigner = ctx.parent.isForeigner;

              if (isForeigner) {
                return value.length === 10
                  ? true
                  : ctx.createError({
                      message: `Vyplňte RČ cizince ve formátu YYMMDD/0000`,
                    });
              }

              const personIdentification = PersonalNumber.getInfo(value);

              return personIdentification.IsValid;
            },
          ),
      otherwise: schema => schema.optional(),
    }),
    isForeigner: boolean().optional(),
    birthDate: date().when(nameof<PartyFormPartyType>("type"), {
      is: (type: PartyFormPartyType["type"]) =>
        areFieldsRequired &&
        (type === PartyType.NaturalPerson ||
          (type === PartyType.SelfEmployed &&
            partyFormType === PartyFormType.OrderClient)),
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    isVATPayer: boolean().optional(),
    taxNumber: string().when(
      [
        nameof<PartyFormPartyType>("type"),
        nameof<PartyFormPartyType>("isVATPayer"),
      ],
      {
        is: (
          type: PartyFormPartyType["type"],
          isVatPayer: PartyFormPartyType["isVATPayer"],
        ) =>
          areFieldsRequired && isVatPayer && type !== PartyType.NaturalPerson,
        then: schema =>
          schema.required().test({
            test: val => isTaxNumberValid(val),
            message: t(Resources.Validation.InvalidTaxNumber),
          }),
        otherwise: schema => schema.optional(),
      },
    ),
    street: string().when({
      is: () => areFieldsRequired,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    streetNumber: string().when({
      is: () => areFieldsRequired,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    orientationNumber: string().optional(),
    postalCode: string().when({
      is: () => areFieldsRequired,
      then: schema => schema.required().length(5),
      otherwise: schema => schema.optional(),
    }),
    municipality: string().when({
      is: () => areFieldsRequired,
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
    phone: string().when({
      is: () => areFieldsRequired,
      then: schema =>
        schema.required().test({
          test: val => {
            return val.startsWith("+420") || val.startsWith("+421")
              ? val.length === 13
              : !val;
          },
          message: t(Resources.Validation.InvalidPhone),
        }),
      otherwise: schema => schema.optional(),
    }),
    email: string()
      .email()
      .when({
        is: () => areFieldsRequired,
        then: schema => schema.required(),
        otherwise: schema => schema.optional(),
      }),
    bankAccountNumber: string().when({
      is: () => areFieldsRequired && isBankAccountVisible,
      then: schema =>
        schema.required().test({
          test: (value, ctx) => {
            return isBankAccountNumberValid(value, ctx.parent.bankCode);
          },
          message: t(Resources.Validation.InvalidBankAccount),
        }),
      otherwise: schema => schema.optional(),
    }),
    bankCode: string().when({
      is: () => areFieldsRequired && isBankAccountVisible,
      then: schema => schema.required().matches(/^\d{4}$/),
      otherwise: schema => schema.optional(),
    }),
    companyRepresentativeFirstName: string().when(
      nameof<PartyFormPartyType>("type"),
      {
        is: (type: PartyFormPartyType["type"]) =>
          areFieldsRequired &&
          type === PartyType.LegalEntity &&
          partyFormType === PartyFormType.OrderClient,
        then: schema => schema.required(),
        otherwise: schema => schema.optional(),
      },
    ),
    companyRepresentativeLastName: string().when(
      nameof<PartyFormPartyType>("type"),
      {
        is: (type: PartyFormPartyType["type"]) =>
          areFieldsRequired &&
          type === PartyType.LegalEntity &&
          partyFormType === PartyFormType.OrderClient,
        then: schema => schema.required(),
        otherwise: schema => schema.optional(),
      },
    ),
    companyRepresentativeBirthDate: date().when(
      nameof<PartyFormPartyType>("type"),
      {
        is: (type: PartyFormPartyType["type"]) =>
          areFieldsRequired &&
          type === PartyType.LegalEntity &&
          partyFormType === PartyFormType.OrderClient,
        then: schema => schema.required(),
        otherwise: schema => schema.optional(),
      },
    ),
  }).defined();

  return validationSchema;
};
