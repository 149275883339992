import { Box, Grid, Typography } from "@mui/material";
import {
  OrderDto,
  OrderPaymentType,
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  PaymentCalendarItemDto,
  PaymentCalendarItemStatus,
  StepType,
  WorkflowType,
} from "Api/Api";
import { CalendarItem } from "Components/Orders/Detail/Steps/RecurringPayment/CalendarItem";
import { AutomationDisclaimer } from "Components/Orders/Detail/Steps/Shared/AutomationDisclaimer";
import { RevocationDisclaimer } from "Components/Orders/Detail/Steps/Shared/RevocationDisclaimer";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { ExpandArrowIcon } from "Components/Shared/Icons";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getPaymentCalendarItemsAsync } from "State/OrderWorkflow/Slices/GetPaymentCalendarItemsSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { max, orderBy } from "lodash-es";
import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledDescription = styled(Box)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const StyledListButton = styled(Grid)`
  background-color: ${props => props.theme.colors.gray};
  border-radius: 7px;
  margin: ${props => props.theme.spacing(0.5, 0)};
  cursor: pointer;
  padding: ${props => props.theme.spacing(0.4)};
`;

const StyledExpandArrowIcon = styled(ExpandArrowIcon)<{ $arrowUp: boolean }>`
  transform: ${({ $arrowUp }) => ($arrowUp ? "" : "rotate(180deg)")};
  transition: transform 0.3s;
`;

const getRenderListIndexes = (
  items: PaymentCalendarItemDto[],
): { startIndex: number; endIndex: number } => {
  let startIndex = 0;
  let endIndex = 0;

  const createdIndexFirst = items.findIndex(
    x => x.status === PaymentCalendarItemStatus.Created,
  );
  const sendToClientIndexFirst = items.findIndex(
    x => x.status === PaymentCalendarItemStatus.SentToClient,
  );

  const hasUnpaidItem =
    sendToClientIndexFirst !== -1 &&
    sendToClientIndexFirst + 1 !== createdIndexFirst;

  startIndex = createdIndexFirst !== -1 ? createdIndexFirst : items.length - 1;
  endIndex = startIndex + 2;

  return {
    startIndex: hasUnpaidItem
      ? sendToClientIndexFirst
      : max([startIndex - 2, 0]) ?? 0,
    endIndex: max([endIndex, 4]) ?? 4,
  };
};

const PageResources = Resources.Orders.Workflow.Workflow.RecurringPaymentStep;

type Props = {
  step: OrderWorkflowStepDto;
  steps: OrderWorkflowStepDto[];
  publicID: string;
  order: OrderDto;
};

export const ClientInvoiceRecurringPaymentStep: React.FunctionComponent<
  Props
> = props => {
  const { steps, publicID, order } = props;
  const { t } = useResource();

  const clientApprovalStep = steps.find(
    x => x.type === StepType.ClientApproval,
  );

  const clientPrepaidPaymentApprovalStep = steps.find(
    x => x.type === StepType.ClientPrepaidPaymentApproval,
  );

  const canLoadData =
    clientApprovalStep?.status === OrderWorkflowStepStatus.Completed ||
    (order.paymentType === OrderPaymentType.Prepaid &&
      (clientPrepaidPaymentApprovalStep?.status ===
        OrderWorkflowStepStatus.InProgress ||
        clientPrepaidPaymentApprovalStep?.status ===
          OrderWorkflowStepStatus.Completed));

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (canLoadData) {
      dispatch(getPaymentCalendarItemsAsync.request({ publicID }));
    }
  }, [dispatch, canLoadData, publicID]);

  const { isLoading: isLoadingData, data: paymentCalendarItems } =
    useAppSelector(e => e.orderWorkflow.paymentCalendarItems);

  const isLoading = isLoadingData && !paymentCalendarItems;

  const isWorkflowWithCommission =
    order.workflowType === WorkflowType.PaidAdvisoryStrict ||
    order.workflowType === WorkflowType.PaidAdvisoryRecurringPaymentStrict ||
    order.workflowType === WorkflowType.PaidAdvisoryPrepaidPaymentStrict ||
    order.workflowType ===
      WorkflowType.PaidAdvisoryPrepaidRecurringPaymentStrict;

  const widths = isWorkflowWithCommission
    ? {
        invoiceDate: 2,
        price: 2.5,
        commission: 2.5,
        status: 3,
        actions: 2,
      }
    : {
        invoiceDate: 3,
        price: 3,
        status: 3,
        actions: 3,
      };

  const [paging, setPaging] = useState({
    previous: {
      isVisible: true,
      isExpanded: false,
    },
    next: {
      isVisible: true,
      isExpanded: false,
    },
  });
  const [renderedItems, setRenderedItems] = useState<PaymentCalendarItemDto[]>(
    [],
  );

  useEffect(() => {
    if (
      !paymentCalendarItems?.items?.length ||
      paymentCalendarItems?.items?.length <= 5
    ) {
      setRenderedItems(
        orderBy(paymentCalendarItems?.items ?? [], x => x.invoiceDate) ?? [],
      );
      setPaging(prev => {
        prev.previous.isVisible = false;
        prev.next.isVisible = false;
        return prev;
      });
      return;
    }

    const orderedItems = orderBy(
      paymentCalendarItems.items,
      x => x.invoiceDate,
    );

    const { endIndex, startIndex } = getRenderListIndexes(orderedItems);

    const items = orderedItems.slice(
      paging.previous.isExpanded ? 0 : startIndex,
      paging.next.isExpanded ? orderedItems.length : endIndex + 1,
    );
    setRenderedItems(items);
    setPaging(prev => ({
      previous: {
        ...prev.previous,
        isVisible: startIndex > 0,
      },
      next: {
        ...prev.next,
        isVisible: endIndex < orderedItems.length - 1,
      },
    }));
  }, [
    paymentCalendarItems,
    paging.next.isExpanded,
    paging.previous.isExpanded,
  ]);

  const handleExpand = (type: "previous" | "next") => {
    setPaging(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        isExpanded: !prev[type].isExpanded,
      },
    }));
  };

  return (
    <>
      <RevocationDisclaimer
        steps={steps}
        paymentFrequency={order?.paymentType}
      />

      {order.paymentType !== OrderPaymentType.Prepaid && !order.isZeroPrice && (
        <StyledDescription>
          <Typography>{t(PageResources.Description)}</Typography>
        </StyledDescription>
      )}

      <StyledDescription>
        <AutomationDisclaimer
          companyPublicID={order.supplierCompanyPublicID}
          isPrepaid={false}
        />
      </StyledDescription>
      {canLoadData && (
        <>
          {isLoading && (
            <>
              <BlSkeleton height={60} />
              <BlSkeleton height={60} />
            </>
          )}

          {!!paymentCalendarItems && !isLoading && (
            <Grid container direction={"row"} mt={2}>
              <Grid container item>
                <Grid item xs={widths.invoiceDate} alignSelf={"center"}>
                  {t(PageResources.Header.InvoiceDate)}
                </Grid>
                <Grid item xs={widths.price}>
                  <Typography align="left" ml={1}>
                    {t(PageResources.Header.Price)}
                  </Typography>
                </Grid>
                {isWorkflowWithCommission && (
                  <Grid item xs={widths.commission}>
                    <Typography align="left" ml={1.5}>
                      {t(PageResources.Header.Commission)}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={widths.status}>
                  <Typography align="left">
                    {t(PageResources.Header.Status)}
                  </Typography>
                </Grid>
                <Grid item xs={widths.actions}>
                  <Typography
                    align="left"
                    ml={isWorkflowWithCommission ? 1 : 7}
                  >
                    {t(PageResources.Header.Actions)}
                  </Typography>
                </Grid>
              </Grid>

              {paging.previous.isVisible && (
                <StyledListButton
                  container
                  item
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={0.5}
                  onClick={() => handleExpand("previous")}
                >
                  <Typography align="center" fontWeight={600}>
                    {t(
                      paging.previous.isExpanded
                        ? PageResources.Previous.Hide
                        : PageResources.Previous.Show,
                    )}
                  </Typography>
                  <StyledExpandArrowIcon
                    $arrowUp={paging.previous.isExpanded}
                  />
                </StyledListButton>
              )}

              {renderedItems.map((item, index) => (
                <Grid item key={index} xs={12} mt={0.5} mb={0.5}>
                  <CalendarItem
                    order={order}
                    publicID={publicID}
                    item={item}
                    isWorkflowWithCommission={isWorkflowWithCommission}
                    widths={widths}
                  />
                </Grid>
              ))}

              {paging.next.isVisible && (
                <StyledListButton
                  container
                  item
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={0.5}
                  onClick={() => handleExpand("next")}
                >
                  <Typography align="center" fontWeight={600}>
                    {t(
                      paging.next.isExpanded
                        ? PageResources.Next.Hide
                        : PageResources.Next.Show,
                    )}
                  </Typography>
                  <StyledExpandArrowIcon $arrowUp={!paging.next.isExpanded} />
                </StyledListButton>
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
};
