import * as React from "react";
export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 12 12"
      {...props}
    >
      <path
        d="M-984-451a6.007,6.007,0,0,1-6-6,6.151,6.151,0,0,1,2.344-4.759A5.785,5.785,0,0,1-984-463a6.006,6.006,0,0,1,6,6A6.007,6.007,0,0,1-984-451Zm-1.434-3.78a.567.567,0,0,0-.567.566.568.568,0,0,0,.567.567h3.007a.568.568,0,0,0,.567-.567.567.567,0,0,0-.567-.566l.007-.007h-.941v-3.193a.567.567,0,0,0-.567-.566h-.753a.567.567,0,0,0-.567.566.568.568,0,0,0,.567.567h.186v2.633Zm1.507-6.22a.721.721,0,0,0-.533.226.749.749,0,0,0-.221.534.73.73,0,0,0,.221.534.767.767,0,0,0,.533.22.693.693,0,0,0,.527-.22.727.727,0,0,0,.227-.534.714.714,0,0,0-.227-.534A.7.7,0,0,0-983.927-461Z"
        transform="translate(990 463)"
        fill="currentColor"
      />
    </svg>
  );
};
