import { Typography } from "@mui/material";
import { UserSupplierCompanyListItemDto } from "Api/Api";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { EditIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { CompanyEntityType } from "State/Companies/Detail/SaveCompanyDetailSlice";
import { useResource, Resources } from "Translations/Resources";
import {
  formatAddressStreet,
  formatAddressMunicipality,
} from "Utils/AddressUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import styled from "styled-components";

type Props = {
  company: UserSupplierCompanyListItemDto;
  type: CompanyEntityType;
};

const StyledCompany = styled.div`
  padding: ${props => props.theme.spacing(1.5)};
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.colors.fieldBackground};
  margin-bottom: ${props => props.theme.spacing(2)};
  width: 100%;
  max-width: 700px;
`;

const StyledDefaultCompany = styled.div`
  background-color: ${props => props.theme.palette.primary.main};
  color: ${props => props.theme.palette.primary.contrastText};
  padding: ${props => props.theme.spacing(0.2, 0.8)};
  border-radius: ${props => props.theme.borderRadius};
  margin-left: ${props => props.theme.spacing(0.5)};
`;

const StyledUnstyledLink = styled(UnstyledLink)`
  margin-left: ${props => props.theme.spacing(1)};

  svg {
    width: 24px;
    height: auto;
  }
`;

export const Company: React.FunctionComponent<Props> = props => {
  const { company, type } = props;
  const { t } = useResource();

  const getNumbersText = (company: UserSupplierCompanyListItemDto) => {
    return [
      company.companyNumber
        ? `${t(Resources.Companies.List.CompanyNumber)}: ${
            company.companyNumber
          }`
        : null,
      company.taxNumber
        ? `${t(Resources.Companies.List.TaxNumber)}: ${company.taxNumber}`
        : null,
    ]
      .filter(x => !!x)
      .join(", ");
  };

  return (
    <>
      <StyledCompany>
        <StyledFlex $alignItems="center">
          <StyledFlex $alignItems="center" $flexWrap="wrap">
            <Typography variant="h3" marginRight={0.5}>
              {company.companyName}
            </Typography>

            {<Typography>({getNumbersText(company)}) </Typography>}
          </StyledFlex>

          <StyledFlex
            $alignItems="center"
            $justifyContent="space-between"
            $flexGrow={1}
          >
            {company.isDefault ? (
              <StyledDefaultCompany>
                {t(Resources.Companies.List.IsDefault)}
              </StyledDefaultCompany>
            ) : (
              <div></div>
            )}
            <StyledUnstyledLink
              to={getPath(AppRouting.CompanyDetail, type, company.publicID)}
            >
              <BlDefaultButton title={t(Resources.Common.Edit)}>
                <EditIcon />
              </BlDefaultButton>
            </StyledUnstyledLink>
          </StyledFlex>
        </StyledFlex>

        <div>
          <Typography variant="subtitle2" fontStyle={"normal"}>
            {formatAddressStreet(company.permanentAddress)}
          </Typography>
          <Typography variant="subtitle2" fontStyle={"normal"}>
            {formatAddressMunicipality(company.permanentAddress)}
          </Typography>
        </div>
      </StyledCompany>
    </>
  );
};
