import styled from "styled-components";
import { AddressType, PartyType, OrderClientStatus } from "Api/Api";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { BlDatePicker } from "Components/Shared/Inputs/DatePickers/BlDatePicker";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { BlRadioButtons } from "Components/Shared/Inputs/Form/BlRadioButtons";
import { IMask } from "react-imask";
import { LoadingWrapper } from "Components/Orders/Draft/LoadingWrapper";
import { PartyBankAccount } from "Components/Shared/Party/PartyBankAccount";
import { PartyFormModel } from "Components/Shared/Party/PartyFormValidationSchema";
import { PersonalNumber } from "Utils/PersonalNumberUtils";
import { Resources, useResource } from "Translations/Resources";
import { searchByPersonalNumberAsync } from "State/Clients/Slices/SearchByPersonalNumberSlice";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Grid, Typography } from "@mui/material";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { useAppSelector } from "State/Store";
import { useCallback, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import {
  BlAutocomplete,
  LookupItem,
} from "Components/Shared/Inputs/Autocomplete/BlAutocomplete";
import {
  aresSearchAsync,
  resetSlice,
} from "State/Parties/Slices/AresSearchSlice";
import { addYears } from "date-fns";
import {
  resetSearchPartiesSlice,
  searchPartiesAsync,
} from "State/Clients/Slices/SearchSlice";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { usePartyName } from "Hooks/Party/usePartyName";
import {
  formatCompanyNameAndNumbers,
  formatPersonalNameAndNumbers,
} from "Utils/PartyUtils";

export const StyledPartyFormRow = styled.div<{
  $maxWidth?: number;
  $marginBottom?: number;
}>`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
  gap: ${props => props.theme.spacing(2, 2.5)};
  ${props => props.$maxWidth && `max-width: ${props.$maxWidth}px;`}
  margin-bottom: ${props =>
    props.$marginBottom ? props.theme.spacing(props.$marginBottom) : ""};
`;

export const StyledPartyFormItem = styled.div<{ $span: number }>`
  grid-column: span ${props => props.$span};
`;

type Props = {
  form: UseFormReturn<PartyFormModel>;
  isLoading: boolean;
  isPartyTypeEditable: boolean;
  isPartyStatusEditable: boolean;
  areContactInformationSeparate?: boolean;
  isBankAccountVisible?: boolean;
  isCompanyNumberEditable?: boolean;
  isReadOnly?: boolean;
  taxRowChildren?: JSX.Element;
  clientConsentsChildren?: JSX.Element;
  partyFormType: PartyFormType;
};
export enum PartyFormType {
  OrderClient = "OrderClient",
  Company = "Company",
}
export const PartyForm: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const {
    form,
    isLoading,
    isPartyTypeEditable,
    isPartyStatusEditable,
    areContactInformationSeparate,
    isBankAccountVisible,
    isCompanyNumberEditable,
    isReadOnly,
    taxRowChildren,
    clientConsentsChildren,
    partyFormType,
  } = props;
  const {
    control,
    watch,
    getValues,
    trigger,
    setValue,
    resetField,
    formState: { errors },
  } = form;

  const isExisting = watch("party.status") === OrderClientStatus.Existing;
  const partyType = watch("party.type");

  useEffect(() => {
    return () => {
      dispatch(resetSlice());
    };
  }, [dispatch]);

  const searchByCompanyNumber = async () => {
    const isValid = await trigger("party.companyNumber");
    if (isValid) {
      dispatch(
        aresSearchAsync.request({
          query: getValues("party.companyNumber") ?? "",
          partyType: getValues("party.type"),
        }),
      );
    }
  };

  const ares = useAppSelector(state => state.party.aresSearch);

  useEffect(() => {
    if (ares.isLoading || ares.error || !ares.data) {
      return;
    }

    const address = ares.data.addresses.find(
      x => x.type === AddressType.Permanent,
    );
    setValue("party", {
      status: getValues("party.status"),
      type: ares.data.type,
      firstName: ares.data.firstName ?? "",
      lastName: ares.data.lastName ?? "",
      companyRepresentativeFirstName:
        ares.data.companyRepresentativeFirstName ?? "",
      companyRepresentativeLastName:
        ares.data.companyRepresentativeLastName ?? "",
      taxNumber: ares.data.taxNumber ?? "",
      phone: ares.data.phone ?? "+420",
      email: ares.data.email ?? "",
      companyName: ares.data.companyName ?? "",
      companyNumber: ares.data.companyNumber ?? "",
      isVATPayer: ares.data.isVATPayer ?? false,
      municipality: address?.municipality,
      orientationNumber: address?.orientationNumber ?? "",
      postalCode: address?.postalCode,
      street: address?.street,
      streetNumber: address?.streetNumber,
      isForeigner: false,
      bankAccountNumber: getValues("party.bankAccountNumber"),
      bankCode: getValues("party.bankCode"),
    });
  }, [ares, setValue, getValues]);

  const handlePersonalNumberOnBlur = async (e: any) => {
    //check if e is SyntheticBaseEvent
    if (!e.target) {
      return;
    }

    const personalNumber = getValues("party.personalNumber");
    var personalInfo = PersonalNumber.getInfo(personalNumber);

    const isValid = await trigger("party.personalNumber");

    if (isValid && personalInfo.IsValid && !!personalNumber) {
      dispatch(
        searchByPersonalNumberAsync.request({
          personalNumber: personalNumber,
        }),
      );
      if (!!personalInfo.BirthDate) {
        setValue("party.birthDate", new Date(personalInfo.BirthDate));
      }
    }
  };

  const { data: personalNumberSearch } = useAppSelector(
    e => e.client.personalNumberSearch,
  );

  useEffect(() => {
    const party = personalNumberSearch?.party;
    if (!party || party.personalNumber !== getValues("party.personalNumber")) {
      return;
    }

    const address = party.addresses.find(x => x.type === AddressType.Permanent);
    const personalInfo = PersonalNumber.getInfo(party.personalNumber);

    setValue("party", {
      status: getValues("party.status"),
      type: PartyType.NaturalPerson,
      firstName: party.firstName ?? "",
      lastName: party.lastName ?? "",
      personalNumber: party.personalNumber ?? "",
      birthDate: !!party.birthDate
        ? new Date(party.birthDate)
        : personalInfo.BirthDate ?? undefined,
      phone: party.phone ?? "+420",
      email: party.email ?? "",
      municipality: address?.municipality,
      orientationNumber: address?.orientationNumber ?? "",
      postalCode: address?.postalCode,
      street: address?.street,
      streetNumber: address?.streetNumber,
      companyName: "",
      companyNumber: "",
      isVATPayer: false,
      taxNumber: "",
      isForeigner: false,
      companyRepresentativeFirstName:
        party.companyRepresentativeFirstName ?? "",
      companyRepresentativeLastName: party.companyRepresentativeLastName ?? "",
      companyRepresentativeBirthDate: !!party.companyRepresentativeBirthDate
        ? new Date(party.companyRepresentativeBirthDate)
        : undefined,
    });
    setValue("clientID", personalNumberSearch.clientID);
  }, [personalNumberSearch, setValue, getValues]);

  const search = useCallback(
    (query: string) => {
      if (query?.length >= 3) {
        dispatch(
          searchPartiesAsync.request({
            query,
          }),
        );
      }
    },
    [dispatch],
  );

  const { data: searchedClients, isLoading: isSearchedPartiesLoading } =
    useAppSelector(e => e.client.search);

  const { formatPartyName } = usePartyName();

  const autocompleteOptions =
    searchedClients?.map(x => {
      const formattedName = formatPartyName(x.party);

      const name =
        x.party.type === PartyType.NaturalPerson
          ? formatPersonalNameAndNumbers(formattedName, x.party.personalNumber)
          : formatCompanyNameAndNumbers(
              formattedName,
              x.party.companyNumber,
              x.party.taxNumber,
            );

      return {
        id:
          x.partyID ||
          x.externalClientID ||
          x.party.personalNumber ||
          x.party.companyNumber ||
          0,
        name,
        data: JSON.stringify(x),
      } satisfies LookupItem;
    }) ?? [];

  const onExistingClientChange = (val: LookupItem | null) => {
    const client =
      (val &&
        searchedClients?.find(
          x =>
            (x.partyID !== 0 && x.partyID === val.id) ||
            x.externalClientID === val.id ||
            x.party?.personalNumber === val.id ||
            x.party?.companyNumber === val.id,
        )) ||
      undefined;

    const party = client?.party;

    if (!!party) {
      const address = party.addresses.find(
        x => x.type === AddressType.Permanent,
      );

      const personalInfo = PersonalNumber.getInfo(party.personalNumber);

      setValue("party", {
        status: getValues("party.status"),
        type: party.type,
        personalNumber: party.personalNumber ?? "",
        birthDate: !!party.birthDate
          ? new Date(party.birthDate)
          : personalInfo.BirthDate ?? undefined,
        phone: party.phone ?? "+420",
        email: party.email ?? "",
        municipality: address?.municipality,
        orientationNumber: address?.orientationNumber ?? "",
        postalCode: address?.postalCode,
        street: address?.street,
        streetNumber: address?.streetNumber,
        companyNumber: party.companyNumber ?? "",
        isVATPayer: party.isVATPayer ?? false,
        taxNumber: party.taxNumber ?? "",
        companyRepresentativeFirstName:
          party.companyRepresentativeFirstName ?? "",
        companyRepresentativeLastName:
          party.companyRepresentativeLastName ?? "",
        companyRepresentativeBirthDate: !!party.companyRepresentativeBirthDate
          ? new Date(party.companyRepresentativeBirthDate)
          : undefined,
        isForeigner: party.isForeigner ?? false,
      });

      setValue("clientID", client.clientID);

      //trigger this to show change
      trigger("party.birthDate");
      trigger("party.companyRepresentativeBirthDate");

      //this does not work without timeout
      setTimeout(() => {
        if (party.type !== PartyType.NaturalPerson) {
          setValue("party.companyName", party.companyName ?? "");
        }

        if (
          party.type === PartyType.NaturalPerson ||
          party.type === PartyType.SelfEmployed
        ) {
          setValue("party.firstName", party.firstName ?? "");
          setValue("party.lastName", party.lastName ?? "");
        }
      });
    }
  };

  const handleBlAutocompleteOnBlur = (e: any) => {
    dispatch(resetSearchPartiesSlice());
  };

  const onStatusChange = (v: string) => {
    setTimeout(() => {
      setValue("party", {
        status: v as OrderClientStatus,
        firstName: "",
        lastName: "",
        companyRepresentativeFirstName: "",
        companyRepresentativeLastName: "",
        personalNumber: "",
        phone: "+420",
        email: "",
        municipality: "",
        orientationNumber: "",
        postalCode: "",
        street: "",
        streetNumber: "",
        companyName: "",
        companyNumber: "",
        isVATPayer: false,
        taxNumber: "",
        type: PartyType.NaturalPerson,
        isForeigner: false,
        bankAccountNumber: "",
        bankCode: "",
      });
      setValue("clientID", 0);
      resetField("party.birthDate");
      trigger("party.birthDate");
      resetField("party.companyRepresentativeBirthDate");
      trigger("party.companyRepresentativeBirthDate");
    });
  };

  const isForeigner = watch("party.isForeigner");
  const type = watch("party.type");

  const onBirthDateChange = (v: Date) => {
    if (isForeigner && type === PartyType.NaturalPerson) {
      const year = v.getFullYear().toString().slice(-2);
      const month = v.getMonth() + 1;
      const day = v.getDate();
      const formattedMonth = month < 10 ? `0${month}` : `${month}`;
      const formattedDay = day < 10 ? `0${day}` : `${day}`;
      setValue(
        "party.personalNumber",
        `${year}${formattedMonth}${formattedDay}0000`,
      );
    }
  };

  return (
    <>
      {isPartyStatusEditable && (
        <LoadingWrapper isLoading={isLoading}>
          <BlRadioButtons
            control={control}
            errors={errors}
            name={"party.status"}
            disabled={isReadOnly}
            codeList={[
              {
                code: OrderClientStatus.Existing,
                name: t(Resources.Orders.Detail.Client.Status.Existing),
              },
              {
                code: OrderClientStatus.New,
                name: t(Resources.Orders.Detail.Client.Status.New),
              },
            ]}
            onChange={onStatusChange}
          />
        </LoadingWrapper>
      )}

      {isExisting && (
        <LoadingWrapper isLoading={isLoading}>
          <BlAutocomplete
            label={t(Resources.Orders.Detail.Client.Search)}
            options={autocompleteOptions}
            onOptionSelected={onExistingClientChange}
            debounceTime={100}
            onSearch={search}
            isLoading={isSearchedPartiesLoading}
            onBlur={handleBlAutocompleteOnBlur}
            disabled={isReadOnly}
          />
        </LoadingWrapper>
      )}

      {isPartyTypeEditable && (
        <LoadingWrapper isLoading={isLoading}>
          <BlRadioButtons
            control={control}
            errors={errors}
            name={"party.type"}
            label={t(Resources.Party.Type)}
            disabled={isExisting || isReadOnly}
            codeList={[
              {
                code: PartyType.NaturalPerson,
                name: t(Resources.Transactions.PartyType.NaturalPerson),
              },
              {
                code: PartyType.SelfEmployed,
                name: t(Resources.Transactions.PartyType.SelfEmployed),
              },
              {
                code: PartyType.LegalEntity,
                name: t(Resources.Transactions.PartyType.LegalEntity),
              },
            ]}
          />
        </LoadingWrapper>
      )}

      {partyType !== PartyType.NaturalPerson && (
        <>
          <StyledPartyFormRow>
            <StyledPartyFormItem $span={3}>
              <LoadingWrapper isLoading={isLoading}>
                <BlFormInput
                  control={control}
                  errors={errors}
                  name={"party.companyNumber"}
                  label={t(Resources.Party.CompanyNumber)}
                  disabled={
                    isExisting ||
                    isCompanyNumberEditable === false ||
                    isReadOnly
                  }
                  mask={"00000000"}
                ></BlFormInput>
              </LoadingWrapper>
            </StyledPartyFormItem>

            {!isExisting && (
              <StyledPartyFormItem $span={3}>
                <LoadingWrapper isLoading={isLoading}>
                  <BlButton
                    color="primary"
                    onClick={() => searchByCompanyNumber()}
                    isLoading={ares.isLoading}
                    disabled={isReadOnly}
                  >
                    {t(Resources.Party.Load)}
                  </BlButton>
                </LoadingWrapper>
              </StyledPartyFormItem>
            )}
          </StyledPartyFormRow>

          <StyledPartyFormRow>
            <StyledPartyFormItem $span={3}>
              <LoadingWrapper isLoading={isLoading}>
                <BlFormInput
                  control={control}
                  errors={errors}
                  name={"party.taxNumber"}
                  label={t(Resources.Party.TaxNumber)}
                  mask={"aa0000000000"}
                  maxLength={32}
                  disabled={isReadOnly}
                ></BlFormInput>
              </LoadingWrapper>
            </StyledPartyFormItem>

            <StyledPartyFormItem $span={3}>
              <LoadingWrapper isLoading={isLoading}>
                <BlCheckboxInput
                  control={control}
                  errors={errors}
                  name={"party.isVATPayer"}
                  label={t(Resources.Party.IsVatPayer)}
                  disabled={isReadOnly}
                ></BlCheckboxInput>
              </LoadingWrapper>
            </StyledPartyFormItem>
          </StyledPartyFormRow>

          {taxRowChildren}
        </>
      )}

      {partyType === PartyType.LegalEntity ? (
        <StyledPartyFormRow>
          <StyledPartyFormItem $span={6}>
            <LoadingWrapper isLoading={isLoading}>
              <BlFormInput
                control={control}
                errors={errors}
                name={"party.companyName"}
                label={t(Resources.Party.CompanyName)}
                maxLength={256}
                disabled={isReadOnly}
              ></BlFormInput>
            </LoadingWrapper>
          </StyledPartyFormItem>
        </StyledPartyFormRow>
      ) : (
        <StyledPartyFormRow>
          <StyledPartyFormItem $span={3}>
            <LoadingWrapper isLoading={isLoading}>
              <BlFormInput
                control={control}
                errors={errors}
                name={"party.firstName"}
                label={t(Resources.Party.FirstName)}
                disabled={isReadOnly}
              ></BlFormInput>
            </LoadingWrapper>
          </StyledPartyFormItem>

          <StyledPartyFormItem $span={3}>
            <LoadingWrapper isLoading={isLoading}>
              <BlFormInput
                control={control}
                errors={errors}
                name={"party.lastName"}
                label={t(Resources.Party.LastName)}
                disabled={isReadOnly}
              />
            </LoadingWrapper>
          </StyledPartyFormItem>
        </StyledPartyFormRow>
      )}

      {(partyType === PartyType.NaturalPerson ||
        (partyType === PartyType.SelfEmployed &&
          partyFormType === PartyFormType.OrderClient)) && (
        <StyledPartyFormRow>
          {partyType === PartyType.NaturalPerson && (
            <StyledPartyFormItem $span={3}>
              <LoadingWrapper isLoading={isLoading}>
                <Grid container gap={1} display="flex">
                  <Grid item xs={6}>
                    <BlFormInput
                      control={control}
                      errors={errors}
                      name={"party.personalNumber"}
                      label={t(Resources.Party.PersonalNumber.Label)}
                      type="text"
                      mask={"000000/0000"}
                      placeholder="______/____"
                      onBlur={e => handlePersonalNumberOnBlur(e)}
                      labelStartAdornment={
                        <BlDefaultTooltip
                          title={t(Resources.Party.PersonalNumber.Tooltip)}
                        />
                      }
                      disabled={isReadOnly || isExisting}
                    />
                  </Grid>
                  <Grid
                    item
                    ml={2}
                    xs={2}
                    display={"flex"}
                    alignSelf={"center"}
                  >
                    <BlCheckboxInput
                      control={control}
                      errors={errors}
                      name={"party.isForeigner"}
                      label={t(Resources.Party.IsForeigner.Label)}
                      disabled={isReadOnly || isExisting}
                    />
                  </Grid>
                </Grid>
              </LoadingWrapper>
            </StyledPartyFormItem>
          )}
          <StyledPartyFormItem $span={3} key={watch("party.status")}>
            <LoadingWrapper isLoading={isLoading}>
              <BlDatePicker
                label={t(Resources.Party.BirthDate)}
                name="party.birthDate"
                value={new Date(watch("party.birthDate")!)}
                onChange={v => {
                  setValue("party.birthDate", v as any);
                  onBirthDateChange(v as Date);
                }}
                hasError={!!errors.party?.birthDate?.message}
                errorMessage={errors.party?.birthDate?.message}
                disableFuture={true}
                maxDate={addYears(new Date(), -18)}
                disabled={
                  isReadOnly ||
                  (isExisting && partyType === PartyType.NaturalPerson)
                }
              />
            </LoadingWrapper>
          </StyledPartyFormItem>
        </StyledPartyFormRow>
      )}

      {partyType === PartyType.LegalEntity &&
        partyFormType === PartyFormType.OrderClient && (
          <>
            <StyledPartyFormRow>
              <StyledPartyFormItem $span={3}>
                <LoadingWrapper isLoading={isLoading}>
                  <BlFormInput
                    control={control}
                    errors={errors}
                    name={"party.companyRepresentativeFirstName"}
                    label={t(Resources.Party.CompanyRepresentativeFirstName)}
                    disabled={isReadOnly}
                  ></BlFormInput>
                </LoadingWrapper>
              </StyledPartyFormItem>

              <StyledPartyFormItem $span={3}>
                <LoadingWrapper isLoading={isLoading}>
                  <BlFormInput
                    control={control}
                    errors={errors}
                    name={"party.companyRepresentativeLastName"}
                    label={t(Resources.Party.CompanyRepresentativeLastName)}
                    disabled={isReadOnly}
                  />
                </LoadingWrapper>
              </StyledPartyFormItem>
            </StyledPartyFormRow>
            <StyledPartyFormRow>
              <StyledPartyFormItem $span={3}>
                <LoadingWrapper isLoading={isLoading}>
                  <BlDatePicker
                    label={t(Resources.Party.CompanyRepresentativeBirthDate)}
                    name="party.companyRepresentativeBirthDate"
                    value={
                      new Date(watch("party.companyRepresentativeBirthDate")!)
                    }
                    onChange={v => {
                      setValue(
                        "party.companyRepresentativeBirthDate",
                        v as any,
                      );
                    }}
                    hasError={
                      !!errors.party?.companyRepresentativeBirthDate?.message
                    }
                    errorMessage={
                      errors.party?.companyRepresentativeBirthDate?.message
                    }
                    disableFuture={true}
                    disabled={isReadOnly}
                    // readOnlyField={true}
                    maxDate={addYears(new Date(), -18)}
                  />
                </LoadingWrapper>
              </StyledPartyFormItem>
            </StyledPartyFormRow>
          </>
        )}

      <StyledPartyFormRow>
        <StyledPartyFormItem $span={4}>
          <LoadingWrapper isLoading={isLoading}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"party.street"}
              label={t(Resources.Party.Street)}
              maxLength={128}
              disabled={isReadOnly}
            ></BlFormInput>
          </LoadingWrapper>
        </StyledPartyFormItem>

        <StyledPartyFormItem $span={2}>
          <LoadingWrapper isLoading={isLoading}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"party.streetNumber"}
              label={t(Resources.Party.StreetNumber)}
              maxLength={32}
              disabled={isReadOnly}
            ></BlFormInput>
          </LoadingWrapper>
        </StyledPartyFormItem>

        <StyledPartyFormItem $span={1} hidden={true}>
          <LoadingWrapper isLoading={isLoading}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"party.orientationNumber"}
              label={t(Resources.Party.OrientationNumber)}
              maxLength={32}
              disabled={isReadOnly}
            ></BlFormInput>
          </LoadingWrapper>
        </StyledPartyFormItem>
      </StyledPartyFormRow>

      <StyledPartyFormRow>
        <StyledPartyFormItem $span={2}>
          <LoadingWrapper isLoading={isLoading}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"party.postalCode"}
              label={t(Resources.Party.PostalCode)}
              mask={"000 00"}
              placeholder="___ __"
              disabled={isReadOnly}
            ></BlFormInput>
          </LoadingWrapper>
        </StyledPartyFormItem>

        <StyledPartyFormItem $span={4}>
          <LoadingWrapper isLoading={isLoading}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"party.municipality"}
              label={t(Resources.Party.City)}
              maxLength={32}
              disabled={isReadOnly}
            ></BlFormInput>
          </LoadingWrapper>
        </StyledPartyFormItem>
      </StyledPartyFormRow>

      {areContactInformationSeparate && (
        <>
          <StyledFlex $alignItems="center" $gap={0.5} $marginTop={3}>
            <Typography variant="h2">
              {t(Resources.Party.ContactInformation.Title)}
            </Typography>
            {/* <BlDefaultTooltip
              title={t(Resources.Companies.Detail.BasicInformation.Tooltip)}
            /> */}
          </StyledFlex>
          <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={1}>
            {t(Resources.Party.ContactInformation.Description)}
          </Typography>
        </>
      )}

      <StyledPartyFormRow>
        <StyledPartyFormItem $span={2}>
          <LoadingWrapper isLoading={isLoading}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"party.phone"}
              label={t(Resources.Party.ContactInformation.PhoneNumber)}
              mask={"{+42}P NUM"}
              maskBlocks={{
                P: {
                  mask: IMask.MaskedRange,
                  from: 0,
                  to: 1,
                  lazy: true,
                },
                NUM: {
                  mask: "000 000 000",
                  lazy: true,
                },
              }}
              disabled={isReadOnly}
            ></BlFormInput>
          </LoadingWrapper>
        </StyledPartyFormItem>

        <StyledPartyFormItem $span={4}>
          <LoadingWrapper isLoading={isLoading}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"party.email"}
              label={t(Resources.Party.ContactInformation.Email)}
              placeholder="@"
              maxLength={128}
              disabled={isReadOnly}
            ></BlFormInput>
          </LoadingWrapper>
        </StyledPartyFormItem>
      </StyledPartyFormRow>

      {clientConsentsChildren}

      {isBankAccountVisible && (
        <PartyBankAccount
          control={control}
          errors={errors}
          isLoading={isLoading}
          isDisabled={isReadOnly}
          setValue={setValue}
        />
      )}
    </>
  );
};
