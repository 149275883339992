import { createSlice } from "@reduxjs/toolkit";
import {
  SendCutoffPaymentsCommandResult,
  postCutoffsPublicIDSendPayments,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { Resources, getTranslation } from "Translations/Resources";
import { toast } from "sonner";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const cutoffSendPaymentsAsync = createAsyncAction(
  "@cutoff/SEND_PAYMENTS_REQUEST",
  "@cutoff/SEND_PAYMENTS_RESPONSE",
  "@cutoff/SEND_PAYMENTS_FAILURE",
)<{ cutoffPublicID: string }, SendCutoffPaymentsCommandResult, Error>();

function* cutoffSendPayments(
  action: ReturnType<typeof cutoffSendPaymentsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postCutoffsPublicIDSendPayments,
      action.payload.cutoffPublicID,
    );

    if (status === 200) {
      yield put(cutoffSendPaymentsAsync.success(data));
      toast.success(
        getTranslation(Resources.Settings.Cutoff.List.GeneratePaymentsSuccess),
      );
      return;
    }

    yield put(
      cutoffSendPaymentsAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(cutoffSendPaymentsAsync.failure(err as Error));
  }
}
export function* cutoffSendPaymentsSaga() {
  yield takeLatest(
    getType(cutoffSendPaymentsAsync.request),
    cutoffSendPayments,
  );
}

type CutoffSendPaymentsSliceState = {
  cutoffPublicID: string | null;
  isLoading: boolean;
  error: Error | null;
};

const initialState: CutoffSendPaymentsSliceState = {
  cutoffPublicID: null,
  isLoading: false,
  error: null,
};

export const cutoffSendPaymentsSlice = createSlice({
  initialState,
  name: "@cutoff/cutoffSendPayments",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(cutoffSendPaymentsAsync.request),
      (state, action: ReturnType<typeof cutoffSendPaymentsAsync.request>) => {
        state.cutoffPublicID = action.payload.cutoffPublicID;
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(cutoffSendPaymentsAsync.success),
      (state, action: ReturnType<typeof cutoffSendPaymentsAsync.success>) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(cutoffSendPaymentsAsync.failure),
      (state, action: ReturnType<typeof cutoffSendPaymentsAsync.failure>) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
