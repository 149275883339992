import { Typography } from "@mui/material";
import { LoadingWrapper } from "Components/Orders/Draft/LoadingWrapper";
import { ServiceFields } from "Components/Orders/Draft/ServiceFields";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { BlRadioButtons } from "Components/Shared/Inputs/Form/BlRadioButtons";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { UseFormReturn } from "react-hook-form";
import {
  EnterpriseServiceListItemDto,
  EnterpriseServiceSettingsDto,
  OrderPaymentFrequency,
  OrderPaymentType,
  OrderPeriodicity,
  OrderServiceRate,
  ServicePackageListItemDto,
  ServiceType,
  ServiceVariantFrequency,
  ServiceVariantProduct,
  UserSupplierCompanyListItemDto,
} from "Api/Api";
import { CodeListItem } from "Models/ICodeListDto";
import { enumToArray } from "Utils/ObjectUtils";
import {
  OrderFormModel,
  ServicePackageType,
} from "Components/Orders/Draft/StepFormValidationSchema";
import { formatCurrency } from "Utils/CurrencyUtils";
import { StyledValidationText } from "Components/Shared/FormStyles";
import { useTaxLabels } from "Hooks/useWithTax";
import styled from "styled-components";
import { usePriceData } from "Hooks/Orders/usePriceData";
import {
  fillServices,
  fillServicesFromServicePackages,
} from "Components/Orders/Draft/Utils";
import { getDefaultDateFromDateToValues } from "Utils/Order/PaymentFrequencyUtils";

const Description = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-weight: 400;
`;

type Props = {
  form: UseFormReturn<OrderFormModel>;
  isLoading: boolean;
  isReadOnly: boolean;
  enterpriseServices: EnterpriseServiceListItemDto[];
  serviceSettings: EnterpriseServiceSettingsDto;
  companies: UserSupplierCompanyListItemDto[];
  servicePackages: ServicePackageListItemDto[];
};

export const ServicesFields: React.FunctionComponent<Props> = props => {
  const {
    isLoading,
    form,
    enterpriseServices,
    serviceSettings,
    isReadOnly,
    companies,
    servicePackages,
  } = props;
  const { t } = useResource();

  const {
    currencyCode,
    isFixedServiceRate,
    isHourRateServiceRate,
    isVatUsed,
    labelWithTax,
    labelWithoutTax,
    totalHours,
    hourRateVariants,
    hourVariant,
  } = usePriceData(form, enterpriseServices, companies);

  const { withTax } = useTaxLabels();

  const { watch, setValue } = form;

  const services = watch("services");
  const serviceRate = watch("serviceRate");
  const paymentFrequency = watch("order.paymentFrequency");

  const onServiceRateChange = () => {
    services.forEach((x, index) => {
      x.isEditing = false;

      const enterpriseService =
        enterpriseServices.find(y => y.serviceID === x.serviceID) ??
        servicePackages.find(y => y.serviceID === x.serviceID)!;

      const serviceVariant = enterpriseService.variants?.find(
        y => y.serviceVariantID === x.serviceVariantID,
      )!;
      x.priceWithoutTax = serviceVariant.priceWithoutTax ?? 0;
      x.priceWithTax = serviceVariant.priceWithTax ?? 0;
      x.currencyCode = serviceVariant.currencyCode;
      x.taxRate = serviceVariant.taxRate;
      x.originalPriceWithTax = serviceVariant.priceWithTax ?? 0;
      x.originalPriceWithoutTax = serviceVariant.priceWithoutTax ?? 0;
      x.serviceVariantName = serviceVariant.name;

      setValue(`services.${index}`, x);
    });
  };

  const onPaymentPeriodicityChange = (
    paymentPeriodicity: OrderPeriodicity,
    servicePackageType: ServicePackageType,
  ) => {
    if (serviceRate === OrderServiceRate.HourRate) {
      return;
    }

    setPaymentFrequencies(paymentPeriodicity);

    if (servicePackageType === ServicePackageType.Package) {
      onServicePackageTypeChange(servicePackageType, paymentPeriodicity);
      return;
    }

    setServicesFromEnterpriseServices(paymentPeriodicity);
  };

  const setServicesFromEnterpriseServices = (
    paymentPeriodicity: OrderPeriodicity,
  ) => {
    const frequency =
      paymentPeriodicity === OrderPeriodicity.Single
        ? ServiceVariantFrequency.Single
        : ServiceVariantFrequency.Monthly;

    const selectedServices = services
      .filter(x => x.isSelected)
      .map(x => x.serviceID);

    const newServices = fillServices(enterpriseServices, frequency, []).map(
      x => {
        x.isSelected = selectedServices.includes(x.serviceID);
        return x;
      },
    );

    setValue("services", newServices);
  };

  const setPaymentFrequencies = (paymentPeriodicity: OrderPeriodicity) => {
    const orderPaymentFrequency =
      paymentPeriodicity === OrderPeriodicity.Single
        ? OrderPaymentFrequency.Single
        : OrderPaymentFrequency.Monthly;

    setValue("order.paymentFrequency", orderPaymentFrequency);

    const { dateFrom, dateTo } = getDefaultDateFromDateToValues(
      orderPaymentFrequency,
    );

    setValue("order.paymentFrequencyDateFrom", dateFrom);
    setValue("order.paymentFrequencyDateTo", dateTo);
  };

  const isPeriodicPayment =
    watch("order.paymentPeriodicity") === OrderPeriodicity.Periodic;

  const onServicePackageTypeChange = (
    value: ServicePackageType,
    paymentPeriodicity: OrderPeriodicity,
  ) => {
    setValue("serviceRate", OrderServiceRate.FixedPrice);

    if (value === ServicePackageType.ProvidedService) {
      setServicesFromEnterpriseServices(paymentPeriodicity);
      return;
    }

    const frequency =
      paymentPeriodicity === OrderPeriodicity.Single
        ? ServiceVariantFrequency.Single
        : ServiceVariantFrequency.Monthly;

    const selectedServices = services
      .filter(x => x.isSelected)
      .map(x => x.serviceID);

    const newServices = fillServicesFromServicePackages(
      servicePackages,
      frequency,
      [],
    ).map(x => {
      x.isSelected = selectedServices.includes(x.serviceID);
      return x;
    });

    setValue("services", newServices);
  };

  const servicePackageTypesCodeList = enumToArray(ServicePackageType)
    .filter(x =>
      x === ServicePackageType.Package ? servicePackages.length > 0 : true,
    )
    .map(
      x =>
        ({
          code: x,
          name: t(Resources.ServicePackageType[x]),
        }) as CodeListItem,
    );

  const hasSomeHourRateVariant =
    enterpriseServices?.filter(
      x =>
        x.type === ServiceType.HourRate &&
        x.variants.filter(y => y.isEnabled === true)?.length > 0,
    )?.length > 0;

  const hasAnyPackageServices = servicePackages.length > 0;
  const hasAnyServices = services.length > 0;

  return (
    <StyledContentWrapper $fullPadding>
      <Typography variant="h2" marginBottom={2}>
        {t(Resources.Orders.Detail.Services.Title)}
      </Typography>

      <LoadingWrapper isLoading={isLoading}>
        <BlRadioButtons
          control={form.control}
          errors={form.formState.errors}
          name={"order.paymentType"}
          label={t(Resources.Orders.Detail.Payment.PaymentType.Label)}
          codeList={enumToArray(OrderPaymentType).map(
            x =>
              ({
                code: x,
                name: t(Resources.OrderPaymentType[x]),
              }) as CodeListItem,
          )}
          disabled={isReadOnly}
        />
      </LoadingWrapper>

      <LoadingWrapper isLoading={isLoading}>
        <BlRadioButtons
          control={form.control}
          errors={form.formState.errors}
          name={"order.paymentPeriodicity"}
          label={t(Resources.Orders.Detail.Payment.Periodicity.Label)}
          codeList={enumToArray(OrderPeriodicity).map(
            x =>
              ({
                code: x,
                name: t(Resources.OrderPeriodicity[x]),
              }) as CodeListItem,
          )}
          onChange={v =>
            onPaymentPeriodicityChange(
              v as OrderPeriodicity,
              watch("order.servicePackageType"),
            )
          }
          disabled={isReadOnly}
        />
      </LoadingWrapper>

      {servicePackageTypesCodeList.length > 1 && (
        <BlRadioButtons
          control={form.control}
          errors={form.formState.errors}
          name={"order.servicePackageType"}
          label={t(Resources.Orders.Detail.Payment.ServicePackage.Type)}
          codeList={servicePackageTypesCodeList}
          onChange={v =>
            onServicePackageTypeChange(
              v as ServicePackageType,
              watch("order.paymentPeriodicity"),
            )
          }
          disabled={isReadOnly}
        />
      )}
      {hasSomeHourRateVariant && (
        <LoadingWrapper isLoading={isLoading}>
          <BlRadioButtons
            control={form.control}
            errors={form.formState.errors}
            name={"serviceRate"}
            label={t(Resources.Orders.Detail.Services.ServiceRate)}
            codeList={enumToArray(OrderServiceRate)
              .filter(
                x =>
                  x === OrderServiceRate.FixedPrice ||
                  watch("order.servicePackageType") ===
                    ServicePackageType.ProvidedService,
              )
              .map(
                x =>
                  ({
                    code: x,
                    name: t(Resources.OrderServiceRate[x]),
                  }) as CodeListItem,
              )}
            onChange={onServiceRateChange}
            disabled={isReadOnly}
          />
        </LoadingWrapper>
      )}

      {isHourRateServiceRate && hourRateVariants && (
        <LoadingWrapper isLoading={isLoading}>
          <BlRadioButtons
            control={form.control}
            errors={form.formState.errors}
            name={"hourRateServiceVariantID"}
            label={t(Resources.Orders.Detail.Services.HourlyCharge)}
            disabled={isReadOnly}
            codeList={hourRateVariants
              .filter(x => x.isEnabled)
              .map(
                x =>
                  ({
                    name: x.name,
                    id: x.serviceVariantID,
                    code: x.serviceVariantID,
                    description: (
                      <>
                        <Description>
                          {formatCurrency(x.priceWithoutTax, x.currencyCode)}
                        </Description>

                        {isVatUsed && (
                          <Description fontSize={12}>
                            {withTax(
                              formatCurrency(x.priceWithTax, x.currencyCode),
                            )}
                          </Description>
                        )}
                      </>
                    ),
                  }) as CodeListItem,
              )}
          />
        </LoadingWrapper>
      )}

      <Typography>{t(Resources.Orders.Detail.Services.Offers)}</Typography>

      <StyledValidationText data-name={"services.root"}>
        {form.formState.errors.services?.root?.message}
      </StyledValidationText>

      {isLoading ? (
        <>
          <BlSkeleton height={90} />
          <BlSkeleton height={90} />
          <BlSkeleton height={90} />
          <BlSkeleton height={90} />
          <BlSkeleton height={90} />
        </>
      ) : services.length > 0 ? (
        services.map((service, i) => {
          const servicePackageService = servicePackages.find(
            x => x.serviceID === service.serviceID,
          );

          const isServicePackage = !!servicePackageService;

          const enterpriseService =
            enterpriseServices.find(x => x.serviceID === service.serviceID) ??
            servicePackageService!;

          const hasEucs = isServicePackage
            ? (servicePackageService.relatedServices?.filter(y =>
                y.products.some(x => x === ServiceVariantProduct.EucsGarance),
              )?.length ?? 0) > 0
            : enterpriseService.variants?.some(
                y => y.product === ServiceVariantProduct.EucsGarance,
              ) === true;

          //eucs is disabled for single order
          const isServiceDisabled =
            paymentFrequency === OrderPaymentFrequency.Single && hasEucs;

          return (
            <ServiceFields
              key={service.serviceID}
              index={i}
              form={form}
              service={enterpriseService}
              formService={service}
              orderServiceRate={serviceRate}
              canUserSetPrice={
                serviceSettings.canUserSetPrice && !isServicePackage
              }
              isVatUsed={isVatUsed}
              isReadOnly={isReadOnly}
              isServiceDisabled={isServiceDisabled}
            />
          );
        })
      ) : (
        <Typography fontWeight={800}>
          {!hasAnyPackageServices && !hasAnyServices
            ? t(Resources.Orders.Detail.Services.NoServiceVariantsAndPackages)
            : t(Resources.Orders.Detail.Services.NoServiceVariants)}
        </Typography>
      )}

      <Typography marginBottom={0.5} marginTop={2}>
        {isPeriodicPayment
          ? isFixedServiceRate
            ? t(Resources.Orders.Detail.Services.TotalPrice.Periodic.FixedRate)
            : t(Resources.Orders.Detail.Services.TotalPrice.Periodic.HourRate)
          : isFixedServiceRate
            ? t(Resources.Orders.Detail.Services.TotalPrice.Single.FixedRate)
            : t(Resources.Orders.Detail.Services.TotalPrice.Single.HourRate)}
      </Typography>
      <Typography marginBottom={0.5} fontSize={16} fontWeight={600}>
        {isFixedServiceRate ? (
          <>{labelWithoutTax}</>
        ) : (
          <>
            {`${totalHours.toString().replace(".", ",")}h x ${formatCurrency(
              hourVariant?.priceWithoutTax ?? 0,
              currencyCode,
            )} = ${labelWithoutTax}`}
          </>
        )}
      </Typography>

      {isVatUsed && <Typography>{labelWithTax}</Typography>}
    </StyledContentWrapper>
  );
};
