import { createSlice } from "@reduxjs/toolkit";
import {
  EnterpriseCutoffProductPriceSettingDto,
  ServiceVariantProduct,
  getCutoffsEnterpriseCutoffProductPriceSettingsProduct,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getEnterpriseCutoffProductPriceSettingsAsync = createAsyncAction(
  "@cutoff/GET_ENTERPRISE_CUTOFF_PRODUCT_PRICE_SETTINGS_REQUEST",
  "@cutoff/GET_ENTERPRISE_CUTOFF_PRODUCT_PRICE_SETTINGS_RESPONSE",
  "@cutoff/GET_ENTERPRISE_CUTOFF_PRODUCT_PRICE_SETTINGS_FAILURE",
)<
  { product: ServiceVariantProduct },
  EnterpriseCutoffProductPriceSettingDto,
  Error
>();

function* getEnterpriseCutoffProductPriceSettings(
  action: ReturnType<
    typeof getEnterpriseCutoffProductPriceSettingsAsync.request
  >,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCutoffsEnterpriseCutoffProductPriceSettingsProduct,
      action.payload.product,
    );

    if (status === 200) {
      yield put(getEnterpriseCutoffProductPriceSettingsAsync.success(data));

      return;
    }

    yield put(
      getEnterpriseCutoffProductPriceSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(
      getEnterpriseCutoffProductPriceSettingsAsync.failure(err as Error),
    );
  }
}
export function* getEnterpriseCutoffProductPriceSettingsSaga() {
  yield takeLatest(
    getType(getEnterpriseCutoffProductPriceSettingsAsync.request),
    getEnterpriseCutoffProductPriceSettings,
  );
}

export const getEnterpriseCutoffProductPriceSettingsSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EnterpriseCutoffProductPriceSettingDto>(false),
  name: "@cutoff/getCutoffProductPriceSetting",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EnterpriseCutoffProductPriceSettingDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getEnterpriseCutoffProductPriceSettingsAsync, builder);
    handleSuccessCase(getEnterpriseCutoffProductPriceSettingsAsync, builder);
    handleFailureCase(getEnterpriseCutoffProductPriceSettingsAsync, builder);
  },
});
