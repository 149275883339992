import {
  EntityListOfOrderServiceDto,
  getOrdersPublicIDServices,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { completeOrderServiceAsync } from "State/Orders/Detail/CompleteOrderServiceSlice";

export const getOrderServicesAsync = createAsyncAction(
  "@order/GET_SERVICES_REQUEST",
  "@order/GET_SERVICES_RESPONSE",
  "@order/GET_SERVICES_FAILURE",
)<
  {
    publicID: string;
  },
  EntityListOfOrderServiceDto,
  Error
>();

function* getOrderServices(
  action: ReturnType<typeof getOrderServicesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrdersPublicIDServices,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getOrderServicesAsync.success(data));
      return;
    }

    yield put(
      getOrderServicesAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getOrderServicesAsync.failure(err as Error));
  }
}
export function* getOrderServicesSaga() {
  yield takeLatest(getType(getOrderServicesAsync.request), getOrderServices);
}

export const getOrderServicesSlice = createSlice({
  initialState: getFetchStateDefaultValue<EntityListOfOrderServiceDto>(false),
  name: "orderServices",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfOrderServiceDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getOrderServicesAsync, builder);
    handleSuccessCase(getOrderServicesAsync, builder);
    handleFailureCase(getOrderServicesAsync, builder);
    builder.addCase(
      getType(completeOrderServiceAsync.success),
      (state, action: ReturnType<typeof completeOrderServiceAsync.success>) => {
        if (!state.data?.items) {
          return;
        }

        state.data.items = [
          ...state.data?.items.filter(
            x =>
              x.orderServiceID !== action.payload.orderService.orderServiceID,
          ),
          ...[action.payload.orderService],
        ];
      },
    );
  },
});

export const { resetSlice: getOrderServicesReset } =
  getOrderServicesSlice.actions;
