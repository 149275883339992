import { ClientDto, getClientsSearchPersonalNumber } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const searchByPersonalNumberAsync = createAsyncAction(
  "@client/SEARCH_BY_PERSONAL_NUMBER_REQUEST",
  "@client/SEARCH_BY_PERSONAL_NUMBER_RESPONSE",
  "@client/SEARCH_BY_PERSONAL_NUMBER_FAILURE",
)<{ personalNumber: string }, ClientDto, Error>();

function* searchByPersonalNumber(
  action: ReturnType<typeof searchByPersonalNumberAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getClientsSearchPersonalNumber,
      action.payload.personalNumber,
    );

    if (status === 200) {
      yield put(searchByPersonalNumberAsync.success(data));
      return;
    }

    yield put(
      searchByPersonalNumberAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(searchByPersonalNumberAsync.failure(err as Error));
  }
}
export function* searchByPersonalNumberSaga() {
  yield takeLatest(
    getType(searchByPersonalNumberAsync.request),
    searchByPersonalNumber,
  );
}

export const searchByPersonalNumberSlice = createSlice({
  initialState: getFetchStateDefaultValue<ClientDto>(false),
  name: "@client/searchByPersonalNumber",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<ClientDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(searchByPersonalNumberAsync, builder);
    handleSuccessCase(searchByPersonalNumberAsync, builder);
    handleFailureCase(searchByPersonalNumberAsync, builder);
  },
});

export const { resetSlice } = searchByPersonalNumberSlice.actions;
