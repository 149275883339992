import { Typography } from "@mui/material";
import { OrderPaymentType, OrderWorkflowStepDto, StepType } from "Api/Api";
import { useResource, Resources } from "Translations/Resources";
import { addDays, isAfter } from "date-fns";
import styled from "styled-components";

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

type Props = {
  steps: OrderWorkflowStepDto[];
  paymentFrequency: OrderPaymentType | undefined | null;
};

export const RevocationDisclaimer: React.FunctionComponent<Props> = props => {
  const { steps, paymentFrequency } = props;
  const { t } = useResource();

  const isRevocationDisabled =
    paymentFrequency === OrderPaymentType.Prepaid
      ? steps.find(x => x.type === StepType.ClientPrepaidPaymentApproval)
          ?.clientPrepaidPaymentApprovalStep?.isRevocationDisabled
      : steps.find(x => x.type === StepType.ClientApproval)?.clientApprovalStep
          ?.isRevocationDisabled;

  const findStepType =
    paymentFrequency === OrderPaymentType.Prepaid
      ? StepType.ClientPrepaidPaymentApproval
      : StepType.ClientApproval;
  const approvalStep = steps.find(x => x.type === findStepType);
  return (
    <>
      <>
        {isRevocationDisabled === true && (
          <>
            <StyledDescription fontWeight={700} marginBottom={2} marginTop={1}>
              {t(
                Resources.Orders.Workflow.Workflow.ProcessingServicesStep
                  .Revocation.True,
              )}
            </StyledDescription>
          </>
        )}

        {isRevocationDisabled === false &&
          !!approvalStep?.dateProcessed &&
          isAfter(
            new Date(approvalStep.dateProcessed),
            addDays(new Date(), -14),
          ) && (
            <StyledDescription fontWeight={700} marginBottom={2} marginTop={1}>
              {t(
                Resources.Orders.Workflow.Workflow.ProcessingServicesStep
                  .Revocation.False,
              )}
            </StyledDescription>
          )}
      </>
    </>
  );
};
