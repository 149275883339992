import {
  EmailVerificationCommandResult,
  getApiUrl,
  getOrdersRevocationExampleFilePath,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { downloadFile } from "Utils/FileUtils";
import FileSaver from "file-saver";
import { getTranslation, Resources } from "Translations/Resources";

export const downloadOrderRevocationAsync = createAsyncAction(
  "@clientZone/DOWNLOAD_ORDER_REVOCATION_REQUEST",
  "@clientZone/DOWNLOAD_ORDER_REVOCATION_RESPONSE",
  "@clientZone/DOWNLOAD_ORDER_REVOCATION_FAILURE",
)<void, void, Error>();

function* downloadOrderRevocation(
  action: ReturnType<typeof downloadOrderRevocationAsync.request>,
): Generator {
  try {
    const response = yield* downloadFile(
      getApiUrl() + getOrdersRevocationExampleFilePath(),
      "GET",
    );
    if (response.clone.status === 200) {
      response.clone.blob().then(blob => {
        FileSaver.saveAs(
          blob,
          getTranslation(
            Resources.ClientZone.Order.Revocation.Page.ExampleFile,
          ),
        );
      });
      yield put(downloadOrderRevocationAsync.success());
    }

    yield put(
      downloadOrderRevocationAsync.failure(
        new Error(response.clone.statusText),
      ),
    );
  } catch (err) {
    yield put(downloadOrderRevocationAsync.failure(err as Error));
  }
}
export function* downloadOrderRevocationSaga() {
  yield takeLatest(
    getType(downloadOrderRevocationAsync.request),
    downloadOrderRevocation,
  );
}

export const downloadOrderRevocationSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EmailVerificationCommandResult>(false),
  name: "@clientZone/downloadOrderRevocation",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EmailVerificationCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(downloadOrderRevocationAsync, builder);
    handleSuccessCase(downloadOrderRevocationAsync, builder);
    handleFailureCase(downloadOrderRevocationAsync, builder);
  },
});

export const { resetSlice } = downloadOrderRevocationSlice.actions;
