import { PartyDto, getPartiesPublicID } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, select, takeEvery } from "typed-redux-saga";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { RootStateType } from "State/Store";

export const getPartyDetailAsync = createAsyncAction(
  "@party/GET_DETAIL_REQUEST",
  "@party/GET_DETAIL_RESPONSE",
  "@party/GET_DETAIL_FAILURE",
)<
  {
    publicID: string;
  },
  PartyDto,
  {
    publicID: string;
    error: Error;
  }
>();

function* getPartyDetail(
  action: ReturnType<typeof getPartyDetailAsync.request>,
): Generator {
  try {
    const state = yield* select((s: RootStateType) => s.party.details);
    const partyDetail = state[action.payload.publicID];

    if (!!partyDetail?.party) {
      yield put(getPartyDetailAsync.success(partyDetail.party));
      return;
    }

    if (partyDetail?.isLoading === true) {
      return;
    }

    yield put(
      setIsLoading({ publicID: action.payload.publicID, isLoading: true }),
    );

    const { data, error, status } = yield* call(
      getPartiesPublicID,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getPartyDetailAsync.success(data));

      return;
    }

    yield put(
      getPartyDetailAsync.failure({
        publicID: action.payload.publicID,
        error: mapAPIErrorResponse(error ?? data, status),
      }),
    );
  } catch (err) {
    yield put(
      getPartyDetailAsync.failure({
        publicID: action.payload.publicID,
        error: err as Error,
      }),
    );
  }
}
export function* getPartyDetailSaga() {
  yield takeEvery(getType(getPartyDetailAsync.request), getPartyDetail);
}

type State = {
  [key: string]: {
    party?: PartyDto;
    isLoading: boolean;
    error?: Error | null;
  };
};

function getInitialState(): State {
  return {};
}

export const getPartyDetailSlice = createSlice({
  initialState: getInitialState(),
  name: "@party/getPartyDetail",
  reducers: {
    resetSlice: () => getInitialState(),
    setIsLoading: (
      state,
      action: PayloadAction<{ publicID: string; isLoading: boolean }>,
    ) => {
      state[action.payload.publicID].isLoading = action.payload.isLoading;
    },
    resetPartyDetail: (
      state,
      action: PayloadAction<{ publicID: string | null | undefined }>,
    ) => {
      delete state[action.payload.publicID ?? ""];
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getType(getPartyDetailAsync.request),
      (state, action: ReturnType<typeof getPartyDetailAsync.request>) => {
        if (!state[action.payload.publicID]) {
          state[action.payload.publicID] = {
            party: undefined,
            isLoading: false,
            error: null,
          };
        }
      },
    );
    builder.addCase(
      getType(getPartyDetailAsync.success),
      (state, action: ReturnType<typeof getPartyDetailAsync.success>) => {
        state[action.payload.publicID] = {
          party: action.payload,
          isLoading: false,
          error: null,
        };
      },
    );
    builder.addCase(
      getType(getPartyDetailAsync.failure),
      (state, action: ReturnType<typeof getPartyDetailAsync.failure>) => {
        state[action.payload.publicID] = {
          party: undefined,
          isLoading: false,
          error: action.payload.error,
        };
      },
    );
  },
});

export const {
  resetSlice: resetParties,
  resetPartyDetail,
  setIsLoading,
} = getPartyDetailSlice.actions;
