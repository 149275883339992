import * as React from "react";
export const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18.001}
      viewBox="0 0 18 18.001"
      {...props}
    >
      <path
        d="M-799.172-486A2.854,2.854,0,0,1-802-488.872v-6.3a.771.771,0,0,1,.771-.772.772.772,0,0,1,.772.772v6.3a1.309,1.309,0,0,0,1.285,1.329h12.344a1.309,1.309,0,0,0,1.286-1.329v-6.3a.771.771,0,0,1,.771-.772.771.771,0,0,1,.771.772v6.3A2.854,2.854,0,0,1-786.828-486Zm6.186-2.8h-.032a.751.751,0,0,1-.259-.051.6.6,0,0,1-.075-.033h0a.8.8,0,0,1-.183-.131L-797.651-493a.77.77,0,0,1-.235-.554.769.769,0,0,1,.218-.537.771.771,0,0,1,1.091-.018l2.806,2.716v-11.836A.771.771,0,0,1-793-504a.771.771,0,0,1,.771.771v11.836l2.807-2.717a.771.771,0,0,1,1.091.018.769.769,0,0,1,.218.537.766.766,0,0,1-.235.554l-4.112,3.981a.761.761,0,0,1-.2.141l-.05.022h0a.741.741,0,0,1-.272.056h0Z"
        transform="translate(802 504)"
        fill="currentColor"
      />
    </svg>
  );
};
