import { Typography } from "@mui/material";
import { OrderWorkflowStepStatus, StepType, WorkflowType } from "Api/Api";
import { useStepTranslation } from "Hooks/Orders/useStepTranslation";
import styled from "styled-components";

type Props = {
  currentStepType: StepType;
  currentStepStatus: OrderWorkflowStepStatus;
  lastCompleteStepType: StepType;
  lastCompleteStepStatus: OrderWorkflowStepStatus;
  areAllStepsCompleted: boolean;
  workflowType: WorkflowType;
  dateOfInvoiceAfterDueDate?: string | null; //only set in HP in orders in progress
};

const StyledStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(0.6, 1.6)};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 36px;
`;

export const OrderStatus: React.FunctionComponent<Props> = props => {
  const {
    currentStepType,
    currentStepStatus,
    lastCompleteStepType,
    lastCompleteStepStatus,
    areAllStepsCompleted,
    workflowType,
    dateOfInvoiceAfterDueDate,
  } = props;
  const translation = useStepTranslation({
    currentStepType: currentStepType,
    currentStepStatus: currentStepStatus,
    lastCompleteStepType: lastCompleteStepType,
    lastCompleteStepStatus: lastCompleteStepStatus,
    areAllStepsCompleted: areAllStepsCompleted,
    workflowType: workflowType,
    dateOfInvoiceAfterDueDate: dateOfInvoiceAfterDueDate,
  });

  return (
    <StyledStatus>
      <Typography fontWeight={500} align="center">
        {translation}
      </Typography>
    </StyledStatus>
  );
};
