import { DeleteOrderCommandResult, deleteOrdersPublicID } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { toast } from "sonner";
import { NavigateFunction } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";

export const deleteOrderAsync = createAsyncAction(
  "@order/DELETE_REQUEST",
  "@order/DELETE_RESPONSE",
  "@order/DELETE_FAILURE",
)<
  {
    publicID: string;
    navigate: NavigateFunction;
  },
  DeleteOrderCommandResult,
  Error
>();

function* deleteOrder(
  action: ReturnType<typeof deleteOrderAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      deleteOrdersPublicID,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(deleteOrderAsync.success(data));

      action.payload.navigate(getPath(AppRouting.Dashboard));
      toast.success("Objednávka byla úspěšně smazána");

      return;
    }

    yield put(deleteOrderAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(deleteOrderAsync.failure(err as Error));
  }
}
export function* deleteOrderSaga() {
  yield takeLatest(getType(deleteOrderAsync.request), deleteOrder);
}

export const deleteOrderSlice = createSlice({
  initialState: getFetchStateDefaultValue<DeleteOrderCommandResult>(false),
  name: "@order/deleteOrder",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<DeleteOrderCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(deleteOrderAsync, builder);
    handleSuccessCase(deleteOrderAsync, builder);
    handleFailureCase(deleteOrderAsync, builder);
  },
});

export const { resetSlice } = deleteOrderSlice.actions;
