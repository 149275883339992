import { PartyDto } from "Api/Api";
import { Resources, getTranslation } from "Translations/Resources";
import { formatAddressStreet } from "Utils/AddressUtils";
import { formatPersonalNumber } from "Utils/PersonalNumberUtils";

export function formatCompanyNameAndNumbers(
  companyName: string | null | undefined,
  companyNumber: string | null | undefined,
  taxNumber: string | null | undefined,
): string {
  if (companyNumber && taxNumber) {
    const companyNumberTranslation = getTranslation(
      Resources.Common.CompanyNumber,
    );

    const taxNumberTranslation = getTranslation(Resources.Common.TaxNumber);

    return `${companyName} (${companyNumberTranslation}: ${companyNumber}, ${taxNumberTranslation}: ${taxNumber})`;
  } else if (companyNumber) {
    return `${companyName} (${getTranslation(
      Resources.Common.CompanyNumber,
    )}: ${companyNumber})`;
  } else if (taxNumber) {
    return `${companyName} (${getTranslation(
      Resources.Common.TaxNumber,
    )}: ${taxNumber})`;
  } else {
    return companyName ?? "";
  }
}

export function formatCompanyNumbers(
  companyNumber: string | null | undefined,
  taxNumber: string | null | undefined,
) {
  if (companyNumber && taxNumber) {
    const companyNumberTranslation = getTranslation(
      Resources.Common.CompanyNumber,
    );

    const taxNumberTranslation = getTranslation(Resources.Common.TaxNumber);

    return `(${companyNumberTranslation}: ${companyNumber}, ${taxNumberTranslation}: ${taxNumber})`;
  }

  if (companyNumber) {
    return `(${getTranslation(
      Resources.Common.CompanyNumber,
    )}: ${companyNumber})`;
  }

  if (taxNumber) {
    return `(${getTranslation(Resources.Common.TaxNumber)}: ${taxNumber})`;
  }

  return "";
}

export function formatPersonalNameAndNumbers(
  name: string | null | undefined,
  personalNumber: string | null | undefined,
): string {
  if (personalNumber) {
    return `${name} (${getTranslation(
      Resources.Common.PersonalNumber,
    )}: ${formatPersonalNumber(personalNumber)})`;
  } else {
    return `${name}`;
  }
}

export function formatCompanyWithAddress(party?: PartyDto): string {
  if (!party) {
    return "";
  }
  const companyNumberTranslation = getTranslation(
    Resources.Common.CompanyNumber,
  );

  const taxNumberTranslation = getTranslation(Resources.Common.TaxNumber);

  let formatCompanyName = `${
    party.companyName
  } se sídlem/místem podnikání ${formatAddressStreet(
    party.addresses[0],
  )} ${companyNumberTranslation}: ${party.companyNumber}`;
  if (party.taxNumber) {
    formatCompanyName = `${formatCompanyName}, ${taxNumberTranslation}: ${party.taxNumber} `;
  }
  formatCompanyName = `${formatCompanyName}, email: ${party.email} `;

  return formatCompanyName;
}
