import { createSlice } from "@reduxjs/toolkit";
import { getInvoicesPublicIDISDOC } from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { Resources, getTranslation } from "Translations/Resources";
import { downloadBase64File } from "Utils/FileUtils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getInvoiceXmlAsync = createAsyncAction(
  "@invoice/GET_XML_REQUEST",
  "@invoice/GET_XML_RESPONSE",
  "@invoice/GET_XML_FAILURE",
)<{ invoicePublicID: string; invoiceNumber: number }, void, Error>();

function* getInvoiceXml(
  action: ReturnType<typeof getInvoiceXmlAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getInvoicesPublicIDISDOC,
      action.payload.invoicePublicID,
    );

    if (status === 200) {
      downloadBase64File(
        data.isdocXmlBase64,
        "application/xml",
        `${getTranslation(Resources.Settings.Invoices.Invoice)}-${
          action.payload.invoiceNumber
        }.isdoc`,
      );
      yield put(getInvoiceXmlAsync.success());
      return;
    }

    yield put(getInvoiceXmlAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getInvoiceXmlAsync.failure(err as Error));
  }
}
export function* getInvoiceXmlSaga() {
  yield takeLatest(getType(getInvoiceXmlAsync.request), getInvoiceXml);
}

type GetInvoiceXmlSliceState = {
  invoicePublicID: string | null;
  isLoading: boolean;
  error: Error | null;
};

const initialState: GetInvoiceXmlSliceState = {
  invoicePublicID: null,
  isLoading: false,
  error: null,
};

export const getInvoiceXmlSlice = createSlice({
  initialState,
  name: "@invoice/getInvoiceXml",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(getInvoiceXmlAsync.request),
      (state, action: ReturnType<typeof getInvoiceXmlAsync.request>) => {
        state.invoicePublicID = action.payload.invoicePublicID;
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(getInvoiceXmlAsync.success),
      (state, action: ReturnType<typeof getInvoiceXmlAsync.success>) => {
        state.invoicePublicID = null;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(getInvoiceXmlAsync.failure),
      (state, action: ReturnType<typeof getInvoiceXmlAsync.failure>) => {
        state.invoicePublicID = null;
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
