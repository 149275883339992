import { Typography } from "@mui/material";
import { PartyDto } from "Api/Api";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import { formatCompanyWithAddress } from "Utils/PartyUtils";
import { styled } from "styled-components";

const T = styled(Typography)``;

type Props = {
  supplierParty: PartyDto | undefined;
};

export const GDPR: React.FunctionComponent<Props> = props => {
  const { supplierParty } = props;

  return (
    <StyledContentWrapper $fullPadding>
      <style>{` 
      table {
        border-collapse: collapse;
        width: 100%;
        }
        th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
             width: calc(100% / 3); 
        }`}</style>

      <T fontWeight={800}>1. KOMU JE TENTO DOKUMENT URČEN A KDE JEJ NAJDETE</T>
      <T marginBottom={2}>
        Tento dokument je určen klientům správce, jak je definován níže pod
        bodem 2. Klientem správce je osoba, se kterou správce uzavřel smlouvu o
        poskytování poradenství, jejímž předmětem je poskytnutí služeb placeného
        finančního poradenství (dále jen „Smlouva“)
      </T>
      <T fontWeight={800}>2. TOTOŽNOST A KONTAKTNÍ ÚDAJE SPRÁVCE: </T>
      <T marginBottom={2}>
        {formatCompanyWithAddress(supplierParty)} (dále jen „správce“)
      </T>

      <T fontWeight={800}>
        3. TOTOŽNOST A KONTAKTNÍ ÚDAJE POVĚŘENCE PRO OCHRANU OSOBNÍCH ÚDAJŮ
      </T>
      <T marginBottom={2}>Správce nemá pověřence pro ochranu osobních údajů.</T>

      <T marginBottom={2} fontWeight={800}>
        4. JAKÉ VAŠE OSOBNÍ ÚDAJE ZPRACOVÁVÁME, PRO JAKÉ ÚČELY ZPRACOVÁNÍ A NA
        ZÁKLADĚ JAKÝCH PRÁVNÍCH ZÁKLADŮ PRO JEJICH ZPRACOVÁNÍ
      </T>

      <table>
        <thead>
          <tr>
            <th>Osobní údaje klientů</th>
            <th>Právní základ zpracování</th>
            <th>Účely zpracování</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Identifikační:</b>
              <br></br> jméno a příjmení či pseudonym, datum narození, rodné
              číslo, identifikační číslo údaj o zápisu v Registru živnostenského
              podnikání, obchodním rejstříku nebo v jiné evidenci,
            </td>
            <td>
              <b>podle čl. 6 odst. 1 písm. b) GDPR (plnění smlouvy)</b> uzavření
              a plnění Smlouvy, jejíž smluvní stranou je subjekt údajů – klient,
              je-li fyzickou osobou, je-li smluvní stranou Smlouvy jako klient
              právnická osoba (korporace), zpracovává právce osobní údaje
              subjektu údajů, který je jeho statutárním orgánem (či jeho
              členem), zmocněncem, vedoucí osobou
            </td>
            <td>
              • uzavření, evidence a zajištění plnění Smlouvy o placeném
              poradenství,
              <br></br>• komunikace se správcem, včetně komunikace
              prostřednictvím informačních systémů správce a informačních
              systémů používaných správcem
              <br></br>• reakce na stížnosti a reklamace klientů
            </td>
          </tr>
          <tr>
            <td>
              <b>Kontaktní:</b>
              <br></br> adresa trvalého pobytu, doručovací adresa, kontaktní
              údaje, telefon, e-mail či jiný el. kontakt),
              <br></br>
              <b>Popisné a ostatní:</b>
              <br></br> bankovní spojení, v případě, že klient je právnickou
              osobou údaj o jeho/jejím členství ve statutárním orgánu či jiné
              funkci či pozici <br></br>
              <b>Další údaje:</b>
              <br></br> Údaje z insolvenčního rejstříku a CEE (Centrální
              evidence exekucí), údaje z rozhodnutí ve správních a soudních
              řízeních, které se klienta týkají Údaje sdělené klientem anebo
              obsažené v dokumentech, předložených klientem, tj. zejména údaje o
              majetkových poměrech klienta, jeho závazcích a jeho rodinných
              poměrech, vč. osobních údajů (zpravidla zejména identifikačních)
              nezletilých dětí klienta <br></br>
              <b>Zvláštní kategorie údajů:</b>
              <br></br> Údaje o zdravotním stavu v případě klientů, kteří mají
              zájem o poradenství týkající se životního pojištění, hlášení
              pojistných událostí z oblasti životního pojištění nebo finančního
              či krizového plánu. Je-li klientem nezletilé dítě a jedná za něj
              jako zákonný zástupce (nejčastěji rodič), zpracovává správce tyto
              údaje nezletilých dětí a v rozsahu nezbytném pro uzavření Smlouvy
              a plnění smluvních povinností také údaje zákonného zástupce.
            </td>
            <td>
              <b>
                Čl. 6 odst. 1 písm. c) GDPR (plnění právních povinností správce,
                které se na správce vztahují)
              </b>
              <br></br>zejména plnění právních povinností dle těchto práv.
              předpisů: <br></br>• zák. č. 455/1991 Sb. (živnostenský zákon){" "}
              <br></br>• zák. č. 89/2012 Sb. (občanský zákoník) <br></br>•
              daňové zákony <br></br>a ze smluv, uzavřených s osobami, jejichž
              prostřednictvím poskytuje správce služby placeného poradentství
              klientům
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <b>Údaje uvedené výše </b>
            </td>
            <td>
              <b>
                Čl. 6 odst. 1 písm. c) GDPR (plnění právních povinností správce,
                které se na správce vztahují)
              </b>
              zejména plnění právních povinností dle těchto práv. předpisů:{" "}
              <br></br>• zák. č. 455/1991 Sb. (živnostenský zákon) <br></br>•
              zák. č. 89/2012 Sb. (občanský zákoník) <br></br>• daňové zákony a
              ze smluv, uzavřených s osobami, jejichž prostřednictvím poskytuje
              správce služby placeného poradentství klientům
            </td>
            <td>
              • plnění právních povinností správce <br></br>• plnění povinností
              uložených správci ve správním či soudním řízení, jehož je
              účastníkem
              <br></br>• plnění povinností vůči orgánům činným v trestním řízení
              <br></br>• plnění povinností vůči soudu v občanském soudním
              řízení, včetně řízení exekučního a insolvenčního a vůči
              insolvenčnímu správci
              <br></br>• plnění povinností ze smluv s osobami, jejichž
              prostřednictvím poskytuje správce služby placeného poradentství
              klientům, včetně jejich kontroly
            </td>
          </tr>
          <tr>
            <td>
              <b>
                Výše uvedené údaje identifikační, kontaktní, popisné a ostatní,
                další údaje kromě zvláštní kategorie údajů
              </b>
            </td>
            <td>
              <b>čl. 6 odst. 1 písm. f) GDPR (oprávněné zájmy správce)</b>
              <br></br>Zpracování je nezbytné pro účely oprávněných zájmů
              správce
            </td>
            <td>
              • vymáhání pohledávek a vedení případných sporů ze Smlouvy{" "}
              <br></br>• obrana práv správce v souvislosti se Smlouvou a jejím
              plněním <br></br>• uplatňování případných nároků správce vůči
              osobám, jejichž prostřednictvím poskytuje správce služby placeného
              poradentství klientům z titulu porušení smluvních a právních
              povinností <br></br>• zpracování klientských informací
              poskytnutých subjektem údajů a informací o jím využívaných
              finančních a jiných službách pro účely segmentace klientů a
              zacílení obchodních nabídek, pro které není nezbytný souhlas
              subjektu údajů <br></br>• využívání prostředků pro elektronickou
              identifikaci subjektu údajů na dálku a elektronické podepisování
              dokumentů <br></br>• zasílání občasných obchodních sdělení
              prostředky elektronické komunikace, vč. push-up notifikací, pokud
              se týkají služeb správce a pokud subjekt údajů zasílání obchodních
              sdělení neodmítl
            </td>
          </tr>
        </tbody>
      </table>

      <T marginTop={2} fontWeight={800}>
        5. PŘÍJEMCI NEBO KATEGORIE PŘÍJEMCŮ OSOBNÍCH ÚDAJŮ{" "}
      </T>
      <T>
        Osoby, jejichž prostřednictvím poskytuje správce služby placeného
        poradentství klientům, osoby poskytující IT služby, zejména
        provozovatelé systému, jehož prostřednictvím probíhá objednávka služeb
        placeného poradenství, uzavření Smlouvy a fakturace, provozovatel
        systému myPLANN,{" "}
      </T>
      <T>
        Dodavatelé správce, kteří pro správce vykonávají činnosti, při nichž je
        nezbytné, aby měli přístup k osobním údajům klientů (např. účetní a
        konzultační služby).
      </T>
      <T>
        Osoby, se kterými má klient uzavřené smlouvy na finanční a jiné
        produkty, které jsou předmětem služeb podle Smlouvy.
      </T>
      <T marginBottom={2}>
        Úřady a správní orgány, orgány činné v trestním řízení, soudy a osoby,
        jejichž činnost se považuje za činnost soudu nebo je vykonávána z
        pověření soudu.
      </T>
      <T fontWeight={800}>
        6. PŘEDÁVÁNÍ OSOBNÍCH ÚDAJŮ PŘÍJEMCI VE TŘETÍ ZEMI NEBO MEZINÁRODNÍ
        ORGANIZACI
      </T>
      <T marginBottom={2}>
        Osobní údaje nebudou předávány do států mimo EU, ani mezinárodní
        organizaci.
      </T>
      <T fontWeight={800}>7. DOBA UCHOVÁVÁNÍ OSOBNÍCH ÚDAJŮ</T>
      <T marginBottom={2}>
        Osobní údaje uchovává správce po dobu jedenácti let ode dne ukončení
        Smlouvy nebo po dobu jedenácti let od poslední komunikace s klientem
        zákazníkem. Tato doba je stanovena s ohledem na promlčecí dobu některých
        nároků, která může dosáhnout až deseti let. Pokud je vedeno soudní či
        správní řízení, související s činností správce podle Smlouvy, uchovává
        správce osobní údaje po dobu vedení takového řízení plus šest měsíců.
      </T>
      <T fontWeight={800}>
        8. PRÁVA, KTERÁ SE TÝKAJÍ ZPRACOVÁNÍ VAŠICH OSOBNÍCH ÚDAJŮ
      </T>
      <T>
        Plnění Vašich práv musí být vždy v souladu s plněním právních
        povinností, které se na správce vztahují. Máte nárok na uplatnění
        následujících práv přiznaných Vám GDPR a dalšími právními předpisy,
        zejména zákonem č. 110/2019 Sb., o zpracování osobních údajů (dále jen
        <b>„ZOÚ“</b>). Vaše práva mohou být omezena v případě, že se jedná o
        zpracování osobních údajů, které nevyžaduje Vaši identifikaci, v tomto
        případě může být postupováno dle čl. 11 GDPR.{" "}
      </T>
      <T>
        Máte <b>právo</b> požadovat od nás <b>přístup k osobním údajům</b>,
        které se Vás týkají jako subjektu údajů, na jejich <b>opravu/úpravu</b>{" "}
        anebo
        <b>omezení zpracování</b>. Tato práva mohou být v případě použití
        osobních údajů pro statistické účely uplatněna přiměřeně nebo může být
        jejich plnění odloženo (§ 16 odst. 3 ZOÚ).{" "}
      </T>
      <T>
        Máte právo na získání svých osobních údajů a máte právo na jejich
        předání jinému správci dle požadavků určených článkem 20 GDPR. Právo na
        získání a na předání osobních údajů dle č. 20 GDPR je omezeno pouze na
        údaje zpracovávané na základě souhlasu, smlouvy s Vámi a automatizovaně.
        Při výkonu tohoto svého <b>práva na přenositelnost</b> údajů máte právo
        na to, aby osobní údaje byly předány přímo jinému správci, je-li to
        technicky proveditelné. Tím není dotčena naše právní povinnost uchovávat
        i nadále dokumenty zejména v rozsahu a ve lhůtách podle čl. 7 tohoto
        dokumentu, a to bez ohledu na to, zda obsahují osobní údaje.
      </T>
      <T>
        <b>Právo na výmaz</b> můžete uplatnit dle podmínek stanovených v článku
        17 GDPR. Tím není dotčena naše právní povinnost uchovávat i nadále
        dokumenty zejména v rozsahu a ve lhůtách podle čl. 7 tohoto dokumentu, a
        to bez ohledu na to, zda obsahují osobní údaje.
      </T>
      <T>
        Máte <b>právo kdykoli vznést námitku</b> proti zpracování osobních
        údajů, které se Vás týkají a zpracování je prováděno v oprávněném zájmu
        správce nebo třetí strany. Důsledek pro zpracování při vznesení námitky:
      </T>
      <T>
        a) pokud má správce jiný právní základ, než je oprávněný zájem,
        pokračuje ve zpracování, ovšem pouze v rozsahu a k účelům dle
        příslušného právního základu zpracování,
      </T>
      <T>
        b) pokud má správce jen oprávněný zájem, provede podle čl. 21 odst. 1
        GDPR balanční test a ve zpracování pokračuje pouze tehdy, pokud se ukáží
        závažné oprávněné důvody pro zpracování, které převažují nad zájmy nebo
        právy a svobodami subjektu údajů, nebo pro určení, výkon nebo obhajobu
        právních nároků.
      </T>
      <T>
        Stejné důsledky, jak je uvedeno v těchto bodech a), b), na zpracování
        osobních údajů, má uplatnění práva na přenositelnost osobních údajů a
        práva na výmaz.
      </T>
      <T>
        <b>Právo na odvolání souhlasu</b> můžete využít pouze pro údaje
        zpracovávané na základě Vámi poskytnutého souhlasu se zpracováním
        osobních údajů. Důsledky odvolání jsou totožné jako u práva na výmaz
        (viz výše).
      </T>
      <T marginBottom={2}>
        <b>Máte právo podat stížnost</b> u dozorového úřadu, kterým je{" "}
        <b>Úřad pro ochranu osobních údajů</b> (www.uoou.cz).
      </T>

      <T fontWeight={800}>9. ZÁSADY POUŽÍVÁNÍ COOKIES</T>
      <T marginBottom={2}>Správce nepoužívá jiná než nezbytná cookies. </T>
      <T fontWeight={800}>
        10. PRAVIDLA PODÁNÍ ŽÁDOSTI O UPLATNĚNÍ VAŠICH PRÁV
      </T>
      <T>
        Žádost o uplatnění svých práv můžete podat přes datovou schránku, pokud
        ji správce má, na kontaktní e-mail správce anebo poštou na adresu
        sídla/místa podnikání správce
      </T>
      <T>
        Pokud bude žadatelem zákonný zástupce/opatrovník, je nutné vztah k
        subjektu údajů doložit kopií rodného listu či dokladem o ustanovení
        opatrovníka. Pokud za Vás žádost bude podávat někdo jiný, potřebujeme
        mít také úředně ověřenou plnou moc.
      </T>
    </StyledContentWrapper>
  );
};
