import { CompanyDetail } from "Components/Companies/CompanyDetail";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getCompanyDesignSettingsAsync } from "State/Companies/Detail/GetCompanyDesignSettings";
import { getCompanyDetailAsync } from "State/Companies/Detail/GetCompanyDetailSlice";
import { getCompanyFioSettingsAsync } from "State/Companies/Detail/GetCompanyFioSettingsSlice";
import { CompanyEntityType } from "State/Companies/Detail/SaveCompanyDetailSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useParams } from "react-router";

export const getCompanyDetailReturnUrl = (
  type: CompanyEntityType | undefined,
  t: ReturnType<typeof useResource>["t"],
) => {
  switch (type) {
    case "user":
      return getPath(AppRouting.Settings, t(Resources.Settings.Invoices.Url));

    case "enterprise":
    default:
      return getPath(AppRouting.Settings, t(Resources.Settings.Companies.Url));
  }
};

export const CompanyDetailPage: React.FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();
  const { id, type } = useParams<{ id: string; type: CompanyEntityType }>();

  const { data: company } = useAppSelector(x => x.company.detail);
  const { data: fioSettings } = useAppSelector(x => x.company.fioSettings);
  const { data: designSettings } = useAppSelector(
    x => x.company.designSettings,
  );

  const companyPublicID = id;
  useEffect(() => {
    if (!!companyPublicID) {
      dispatch(getCompanyDetailAsync.request({ publicID: companyPublicID }));
      dispatch(
        getCompanyFioSettingsAsync.request({ publicID: companyPublicID }),
      );
      dispatch(
        getCompanyDesignSettingsAsync.request({ publicID: companyPublicID }),
      );
    }
  }, [dispatch, companyPublicID]);

  return (
    <>
      <AuthenticatedLayout title={t(Resources.Companies.Detail.Title.Edit)}>
        <StyledPageWrapper $isNarrow>
          <PageTitle
            title={t(Resources.Companies.Detail.Title.Edit)}
            goBackUrl={getCompanyDetailReturnUrl(type, t)}
          ></PageTitle>

          <CompanyDetail
            type={type!}
            company={company ?? null}
            fioSettings={fioSettings ?? null}
            designSettings={designSettings ?? null}
          />
        </StyledPageWrapper>
      </AuthenticatedLayout>
    </>
  );
};
