import {
  EntityListOfPaymentCalendarItemDto,
  getOrdersPublicIDPaymentCalendarItems,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getPaymentCalendarItemsAsync = createAsyncAction(
  "@orderWorkflow/GET_PAYMENT_CALENDAR_REQUEST",
  "@orderWorkflow/GET_PAYMENT_CALENDAR_RESPONSE",
  "@orderWorkflow/GET_PAYMENT_CALENDAR_FAILURE",
)<
  {
    publicID: string;
  },
  EntityListOfPaymentCalendarItemDto,
  Error
>();

function* getPaymentCalendarItems(
  action: ReturnType<typeof getPaymentCalendarItemsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrdersPublicIDPaymentCalendarItems,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getPaymentCalendarItemsAsync.success(data));
      return;
    }

    yield put(
      getPaymentCalendarItemsAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getPaymentCalendarItemsAsync.failure(err as Error));
  }
}
export function* getPaymentCalendarItemsSaga() {
  yield takeLatest(
    getType(getPaymentCalendarItemsAsync.request),
    getPaymentCalendarItems,
  );
}

export const getPaymentCalendarItemsSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfPaymentCalendarItemDto>(false),
  name: "@orderWorkflow/getPaymentCalendarItems",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfPaymentCalendarItemDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getPaymentCalendarItemsAsync, builder);
    handleSuccessCase(getPaymentCalendarItemsAsync, builder);
    handleFailureCase(getPaymentCalendarItemsAsync, builder);
  },
});

export const { resetSlice: getPaymentCalendarItemsReset } =
  getPaymentCalendarItemsSlice.actions;
