import { Typography } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { BlDataTable } from "Components/Shared/DataTable/BlDataTable";
import { ExpandArrowIcon } from "Components/Shared/Icons";
import { OrderStatus } from "Components/Shared/OrderStatus";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { getOrdersInProgressAsync } from "State/Dashboard/OrdersInProgress/OrdersInProgressSlice";
import { RootStateType } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";

type Props = {};

const StyledBlDefaultButton = styled(BlDefaultButton)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;

  svg {
    color: ${props => props.theme.palette.text.secondary};
    width: 12px;
    height: auto;
    transform: rotate(-90deg);
  }
`;

const StyledUlTooltip = styled.ul`
  padding-inline-start: ${props => props.theme.spacing(1.5)};
`;

export const OrdersInProgress: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrdersInProgressAsync.request());
  }, [dispatch]);

  const { data, isLoading } = useSelector(
    (e: RootStateType) => e.dashboard.ordersInProgress,
  );

  return (
    <div>
      <StyledFlex $alignItems="center">
        <Typography variant="h2" marginRight={1}>
          {t(Resources.Dashboard.OrdersInProgress.Title)}
        </Typography>
        <BlDefaultTooltip
          title={
            <>
              Smlouvy, u kterých je potřeba Vaše pozornost. Jedná se především
              o:
              <br></br>
              <StyledUlTooltip>
                <li>
                  <b>Návrh</b> - K dokončení nebo smazání návrhu objednávky
                </li>
                <li>
                  <b>Viděno klientem</b> - Pro zaslání připomínky, kdy klient za
                  více než 14 dní objednávku neschválil
                </li>
                <li>
                  <b>Schváleno klientem, Zaplaceno klientem</b> - Pro poskytnutí
                  služby a jeho potvrzení
                </li>
                <li>
                  <b>Zpracování služeb</b> - Pro zaslání faktury klientovi
                </li>
                <li>
                  <b>Vystavení faktury</b> - Pro označení faktury za zaplacenou
                  / zaslání upomínky
                </li>
                <li>
                  <b>Faktura po splatnosti</b> - Pro urgenci klienta o ohrazení
                  faktury
                </li>
                <li>
                  <b>Zamítnuto klientem, Platba klientem zamítnuta</b> -
                  Informace o zamítnutí, pro možnost smazání
                </li>
              </StyledUlTooltip>
            </>
          }
        />
      </StyledFlex>

      <BlDataTable
        isLoading={isLoading}
        rows={data?.items.map(x => ({ data: x })) ?? []}
        rowEntityID="externalID"
        isPaginationDisabled
        skeletonRowsCount={5}
        onRowClick={row => {
          navigate(getPath(AppRouting.OrderDetail, row.externalID));
        }}
        getHeaderCell={column => {
          return <div>{column.label ?? column.id}</div>;
        }}
        columns={[
          {
            id: "clientFormattedName",
            label: "Klient",
          },
          {
            id: "number",
            label: "Číslo objednávky",
          },
          {
            id: "currentStepType",
            label: "Stav",
          },
          { id: "action", label: "", maxWidth: 0, justifySelf: "flex-end" },
        ]}
        getCell={(column, row) => {
          switch (column) {
            case "clientFormattedName":
              return <span>{row.clientFormattedName}</span>;

            case "number":
              return <span>{row.number}</span>;

            case "currentStepType":
              return (
                <OrderStatus
                  currentStepType={row.currentStepType}
                  currentStepStatus={row.currentStepStatus}
                  lastCompleteStepStatus={row.lastCompleteStepStatus}
                  lastCompleteStepType={row.lastCompleteStepType}
                  areAllStepsCompleted={row.areAllStepsCompleted}
                  workflowType={row.workflowType}
                  dateOfInvoiceAfterDueDate={row.dateOfInvoiceAfterDueDate}
                />
              );

            case "action":
              return (
                <UnstyledLink
                  to={getPath(AppRouting.OrderDetail, row.externalID)}
                >
                  <StyledBlDefaultButton>
                    <ExpandArrowIcon></ExpandArrowIcon>
                  </StyledBlDefaultButton>
                </UnstyledLink>
              );

            default:
              const value = (row as any)[column as any] ?? "";

              return <div>{value}</div>;
          }
        }}
      />
    </div>
  );
};
