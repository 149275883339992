import {
  EnterpriseCommunicationSettingsUpdateRequest,
  SaveEnterpriseCommunicationSettingsCommandResult,
  putEnterprisesCommunicationSettings,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, select, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { getEnterpriseBasicSettingsAsync } from "State/Enterprises/BasicSettings/EnterpriseBasicSettingsSlice";
import { RootStateType } from "State/Store";
import { toast } from "sonner";
import { getTranslation, Resources } from "Translations/Resources";

export const putEnterpriseCommunicationModeAsync = createAsyncAction(
  "@ENTERPRISE/PUT_ENTERPRISE_COMMUNICATION_MODE_REQUEST",
  "@ENTERPRISE/PUT_ENTERPRISE_COMMUNICATION_MODE_RESPONSE",
  "@ENTERPRISE/PUT_ENTERPRISE_COMMUNICATION_MODE_FAILURE",
)<
  EnterpriseCommunicationSettingsUpdateRequest,
  SaveEnterpriseCommunicationSettingsCommandResult,
  Error
>();

function* putEnterpriseCommunicationMode(
  action: ReturnType<typeof putEnterpriseCommunicationModeAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      putEnterprisesCommunicationSettings,
      action.payload,
    );
    const settingsState = (yield select(
      (state: RootStateType) => state.enterprise.basicSettings.settings,
    )) as unknown as RootStateType["enterprise"]["basicSettings"]["settings"];

    if (status === 200) {
      toast.success(getTranslation(Resources.Settings.Enterprise.Save.Success));
      yield put(putEnterpriseCommunicationModeAsync.success(data));
      yield put(
        getEnterpriseBasicSettingsAsync.success({
          ...settingsState.data!,
          emailCommunicationMode: action.payload.emailCommunicationMode,
          prepaidOrderReminderForPaymentCalendarItemsStatus:
            action.payload.prepaidOrderReminderForPaymentCalendarItemsStatus,
        }),
      );

      return;
    }

    yield put(
      putEnterpriseCommunicationModeAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(putEnterpriseCommunicationModeAsync.failure(err as Error));
  }
}
export function* putEnterpriseCommunicationModeSaga() {
  yield takeLatest(
    getType(putEnterpriseCommunicationModeAsync.request),
    putEnterpriseCommunicationMode,
  );
}

type EnterpriseCommunicationModeSliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: EnterpriseCommunicationModeSliceState = {
  isLoading: false,
  error: null,
};

export const putEnterpriseCommunicationModeSlice = createSlice({
  initialState,
  name: "putEnterpriseCommunicationMode",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(putEnterpriseCommunicationModeAsync.request),
      (
        state,
        action: ReturnType<typeof putEnterpriseCommunicationModeAsync.request>,
      ) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(putEnterpriseCommunicationModeAsync.success),
      (
        state,
        action: ReturnType<typeof putEnterpriseCommunicationModeAsync.success>,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(putEnterpriseCommunicationModeAsync.failure),
      (
        state,
        action: ReturnType<typeof putEnterpriseCommunicationModeAsync.failure>,
      ) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
