import {
  EmailVerificationCommandResult,
  getInvoicesInvoiceForSupplierCompanyByUserISDOCPdf,
  getInvoicesUserInvoices,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { downloadBase64File } from "Utils/FileUtils";
import { Resources, getTranslation } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";

export const getAllInvoicePdfsAsync = createAsyncAction(
  "@invoice/GET_ALL_REQUEST",
  "@invoice/GET_ALL_RESPONSE",
  "@invoice/GET_ALL_FAILURE",
)<void, void, Error>();

function* getAllInvoicePdfs(
  action: ReturnType<typeof getAllInvoicePdfsAsync.request>,
): Generator {
  try {
    const {
      data: allInvoices,
      error: allInvoicesError,
      status: allInvoicesStatus,
    } = yield* call(getInvoicesUserInvoices, 0, 10_000);

    if (allInvoicesError || allInvoicesStatus !== 200) {
      yield put(
        getAllInvoicePdfsAsync.failure(
          mapAPIErrorResponse(allInvoicesError ?? allInvoices),
        ),
      );
      return;
    }

    const invoicePublicIDs = allInvoices.items.map(invoice => invoice.publicID);

    const { data, error, status } = yield* call(
      getInvoicesInvoiceForSupplierCompanyByUserISDOCPdf,
      invoicePublicIDs,
    );

    if (status === 200) {
      downloadBase64File(
        data.isdocPdfsZipBase64,
        "application/zip",
        `${getTranslation(
          Resources.Settings.Invoices.InvoicesList.DownloadAll.File,
        )}_${formatDate(new Date(), true, true)}.zip`,
      );
      yield put(getAllInvoicePdfsAsync.success());
      return;
    }

    yield put(
      getAllInvoicePdfsAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getAllInvoicePdfsAsync.failure(err as Error));
  }
}
export function* getAllInvoicePdfsSaga() {
  yield takeLatest(getType(getAllInvoicePdfsAsync.request), getAllInvoicePdfs);
}

export const getAllInvoicePdfsSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EmailVerificationCommandResult>(false),
  name: "@invoice/getAllInvoicePdfs",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EmailVerificationCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getAllInvoicePdfsAsync, builder);
    handleSuccessCase(getAllInvoicePdfsAsync, builder);
    handleFailureCase(getAllInvoicePdfsAsync, builder);
  },
});

export const { resetSlice } = getAllInvoicePdfsSlice.actions;
