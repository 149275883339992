import {
  GetExpectedUserCommissionQueryResult,
  getCutoffsExpectedUserCommission,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getExpectedUserCommissionAsync = createAsyncAction(
  "@dashboard/GET_EXPECTED_USER_COMMISSION_REQUEST",
  "@dashboard/GET_EXPECTED_USER_COMMISSION_COUNTS_RESPONSE",
  "@dashboard/GET_EXPECTED_USER_COMMISSION_COUNTS_FAILURE",
)<{}, GetExpectedUserCommissionQueryResult, Error>();

function* getExpectedUserCommission(
  action: ReturnType<typeof getExpectedUserCommissionAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCutoffsExpectedUserCommission,
    );

    if (status === 200) {
      yield put(getExpectedUserCommissionAsync.success(data));
      return;
    }

    yield put(
      getExpectedUserCommissionAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(getExpectedUserCommissionAsync.failure(err as Error));
  }
}
export function* getExpectedUserCommissionSaga() {
  yield takeLatest(
    getType(getExpectedUserCommissionAsync.request),
    getExpectedUserCommission,
  );
}

export const getExpectedUserCommissionSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<GetExpectedUserCommissionQueryResult>(false),
  name: "collection",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<GetExpectedUserCommissionQueryResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getExpectedUserCommissionAsync, builder);
    handleSuccessCase(getExpectedUserCommissionAsync, builder);
    handleFailureCase(getExpectedUserCommissionAsync, builder);
  },
});

export const { resetSlice } = getExpectedUserCommissionSlice.actions;
