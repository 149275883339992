import { Stack, Typography } from "@mui/material";
import { AddressType } from "Api/Api";
import { ClientZoneLayout } from "Components/ClientZone/ClientZoneLayout";
import { DownloadIcon } from "Components/Shared/Icons";
import { PageTitle } from "Components/Shared/PageTitle";
import {
  StyledContentWrapper,
  StyledPageWrapper,
} from "Components/Shared/StyledComponents";
import { usePartyName } from "Hooks/Party/usePartyName";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { downloadOrderRevocationAsync } from "State/ClientZone/Orders/DownloadOrderRevocationExample";
import { getOrderDetailAsync } from "State/ClientZone/Orders/GetOrderDetailSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import {
  formatAddressMunicipality,
  formatAddressStreet,
} from "Utils/AddressUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useParams } from "react-router";
import { styled } from "styled-components";

const StyledExample = styled.div`
  background-color: ${props => props.theme.colors.fieldBackground};
  border-radius: "4px";
  padding: ${props => props.theme.spacing(1.5)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 290px;
  margin-top: ${props => props.theme.spacing(3)};
  margin-bottom: ${props => props.theme.spacing(2)};

  p {
    color: ${props => props.theme.palette.text.primary};
  }
  cursor: pointer;
`;

export const RevocationPage: React.FunctionComponent = _ => {
  const { t } = useResource();
  const params = useParams();
  const { id: token } = params;

  const { formatPartyName } = usePartyName();
  const { data: orderDetail } = useAppSelector(e => e.clientZone.orderDetail);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!!token && !orderDetail) {
      dispatch(
        getOrderDetailAsync.request({
          token: token,
        }),
      );
    }
  }, [dispatch, token, orderDetail]);

  const supplierParty = orderDetail?.supplierParty;

  const supplierAddress = supplierParty?.addresses?.find(
    x => x.type === AddressType.Permanent,
  );

  const downloadExample = () => {
    dispatch(downloadOrderRevocationAsync.request());
  };

  return (
    <ClientZoneLayout
      title={t(Resources.ClientZone.Order.Revocation.Page.Title)}
      companyPublicID={orderDetail?.supplierCompanyPublicID}
      enterprisePublicID={orderDetail?.enterprisePublicID}
    >
      <StyledPageWrapper>
        <PageTitle
          title={t(Resources.ClientZone.Order.Revocation.Page.Title)}
          goBackUrl={getPath(AppRouting.ClientZoneOrder, token!)}
        />
        <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={2}>
          {t(Resources.ClientZone.Order.Revocation.Page.Description)}
        </Typography>

        <StyledContentWrapper $fullPadding>
          <Typography>
            Pro účely uplatnění práva na odstoupení od smlouvy musíte o svém
            odstoupení od této smlouvy informovat poskytovatele služeb placeného
            poradenství:
          </Typography>

          <Typography mt={2} fontWeight={600}>
            {formatPartyName(supplierParty)}
          </Typography>

          <Stack direction={"row"} gap={0.5}>
            <Typography>se sídlem/místem podnikání: </Typography>

            <Typography fontWeight={600}>
              {supplierAddress
                ? `${formatAddressStreet(
                    supplierAddress,
                    true,
                  )} ${formatAddressMunicipality(supplierAddress)}`
                : "__________________"}
            </Typography>
          </Stack>
          <Typography fontWeight={600}>
            IČO: {supplierParty?.companyNumber ?? "______________"}
          </Typography>

          <Typography mb={2} fontWeight={600}>
            e-mail: {supplierParty?.email ?? "_______________"}
          </Typography>

          <Typography>
            formou jednostranného právního jednání (například dopisem zaslaným
            prostřednictvím provozovatele poštovních služeb nebo e-mailem).
          </Typography>
          <Typography>
            Můžete použít přiložený vzorový formulář pro odstoupení od smlouvy,
            není to však Vaší povinností.
          </Typography>
          <Typography>
            Na naší webové stránce můžete rovněž elektronicky vyplnit a odeslat
            níže uvedený formulář pro odstoupení od smlouvy. Využijete-li této
            možnosti, obratem Vám zašleme potvrzení o přijetí tohoto oznámení o
            odstoupení od smlouvy.
          </Typography>
          <Typography>
            Aby byla dodržena lhůta pro odstoupení od této smlouvy, postačuje
            odeslat odstoupení od smlouvy před uplynutím příslušné lhůty 14 dnů.
          </Typography>
          <Typography>Doručením odstoupení smlouva zaniká.</Typography>
          <Typography>
            Ve lhůtě pro odstoupení od smlouvy začneme s poskytováním služeb
            pouze na Vaši výslovnou žádost. Poskytnutím služby v plném rozsahu
            zaniká Vaše právo coby spotřebitele od smlouvy bez udání důvodu
            odstoupit v zákonné lhůtě 14 dnů (viz výše). Pokud je naše služba
            poskytnuta do okamžiku odstoupení od smlouvy částečně, uhradíte
            poměrnou část sjednané ceny za plnění poskytnuté do okamžiku
            odstoupení od smlouvy.
          </Typography>

          <StyledExample onClick={downloadExample}>
            <Typography fontWeight={600}>
              {t(Resources.ClientZone.Order.Revocation.Page.Example)}
            </Typography>

            <DownloadIcon />
          </StyledExample>

          <Typography fontWeight={700}>
            Poučení o mimosoudním řešení spotřebitelských sporů
          </Typography>
          <Typography>
            Vyřizování stížností spotřebitelů zajišťujeme prostřednictvím
            e-mailové adresy {supplierParty?.email ?? "______________"}.
            Informaci o vyřízení stížnosti zašleme na e-mailovou adresu
            spotřebitele. K mimosoudnímu řešení spotřebitelských sporů ze
            smlouvy o poskytování placeného poradenství se spotřebitelem je
            příslušná Česká obchodní inspekce, se sídlem Štěpánská 567/15, 120
            00 Praha 2, IČO: 000 20 869, internetová adresa:{" "}
            <a href={"https://www.coi.cz/"} target="_blank" rel="noreferrer">
              www.coi.cz
            </a>
          </Typography>
        </StyledContentWrapper>
      </StyledPageWrapper>
    </ClientZoneLayout>
  );
};
