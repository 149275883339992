import {
  CompleteClientApprovalStepCommandResult,
  postOrdersWorkflowClientApprovalTokenComplete,
  ClientApprovalCompleteRequest,
  getOrdersWorkflowClientReviewTokenSummary,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderDetailAsync } from "State/ClientZone/Orders/GetOrderDetailSlice";
import { Resources, getTranslation } from "Translations/Resources";
import { toast } from "sonner";

export const completeClientApprovalAsync = createAsyncAction(
  "@clientZone/COMPLETE_CLIENT_APPROVAL_REQUEST",
  "@clientZone/COMPLETE_CLIENT_APPROVAL_RESPONSE",
  "@clientZone/COMPLETE_CLIENT_APPROVAL_FAILURE",
)<
  {
    token: string;
    request: ClientApprovalCompleteRequest;
  },
  CompleteClientApprovalStepCommandResult,
  Error
>();

function* completeClientApproval(
  action: ReturnType<typeof completeClientApprovalAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersWorkflowClientApprovalTokenComplete,
      action.payload.request,
      action.payload.token,
    );

    if (status === 200) {
      const { data: orderDetail, status: orderDetailStatus } = yield* call(
        getOrdersWorkflowClientReviewTokenSummary,
        action.payload.token,
      );

      if (!!orderDetail && orderDetailStatus === 200) {
        yield put(getOrderDetailAsync.success(orderDetail));
      }

      yield put(completeClientApprovalAsync.success(data));

      toast.success(
        getTranslation(
          Resources.ClientZone.Order.Approval.Signature.CompletedToast,
        ),
      );
      return;
    }

    yield put(
      completeClientApprovalAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(completeClientApprovalAsync.failure(err as Error));
  }
}
export function* completeClientApprovalSaga() {
  yield takeLatest(
    getType(completeClientApprovalAsync.request),
    completeClientApproval,
  );
}

export const completeClientApprovalSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<CompleteClientApprovalStepCommandResult>(false),
  name: "@clientZone/completeClientApproval",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<CompleteClientApprovalStepCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(completeClientApprovalAsync, builder);
    handleSuccessCase(completeClientApprovalAsync, builder);
    handleFailureCase(completeClientApprovalAsync, builder);
  },
});

export const { resetSlice } = completeClientApprovalSlice.actions;
