import { createSlice } from "@reduxjs/toolkit";
import { getInvoicesPublicIDISDOCPdf } from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { Resources, getTranslation } from "Translations/Resources";
import { downloadBase64File } from "Utils/FileUtils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getInvoicePdfAsync = createAsyncAction(
  "@invoice/GET_PDF_REQUEST",
  "@invoice/GET_PDF_RESPONSE",
  "@invoice/GET_PDF_FAILURE",
)<{ invoicePublicID: string; invoiceNumber: number }, void, Error>();

function* getInvoicePdf(
  action: ReturnType<typeof getInvoicePdfAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getInvoicesPublicIDISDOCPdf,
      action.payload.invoicePublicID,
    );

    if (status === 200) {
      downloadBase64File(
        data.isdocPdfBase64,
        "application/pdf",
        `${getTranslation(Resources.Settings.Invoices.Invoice)}-${
          action.payload.invoiceNumber
        }.pdf`,
      );
      yield put(getInvoicePdfAsync.success());
      return;
    }

    yield put(getInvoicePdfAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getInvoicePdfAsync.failure(err as Error));
  }
}
export function* getInvoicePdfSaga() {
  yield takeLatest(getType(getInvoicePdfAsync.request), getInvoicePdf);
}

type GetInvoicePdfSliceState = {
  invoicePublicID: string | null;
  isLoading: boolean;
  error: Error | null;
};

const initialState: GetInvoicePdfSliceState = {
  invoicePublicID: null,
  isLoading: false,
  error: null,
};

export const getInvoicePdfSlice = createSlice({
  initialState,
  name: "@invoice/getInvoicePdf",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(getInvoicePdfAsync.request),
      (state, action: ReturnType<typeof getInvoicePdfAsync.request>) => {
        state.invoicePublicID = action.payload.invoicePublicID;
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(getInvoicePdfAsync.success),
      (state, action: ReturnType<typeof getInvoicePdfAsync.success>) => {
        state.invoicePublicID = null;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(getInvoicePdfAsync.failure),
      (state, action: ReturnType<typeof getInvoicePdfAsync.failure>) => {
        state.invoicePublicID = null;
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
