import { GetClientsCountQueryResult, getClientsCount } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getClientCountsAsync = createAsyncAction(
  "@dashboard/GET_CLIENT_COUNTS_REQUEST",
  "@dashboard/GET_CLIENT_COUNTS_RESPONSE",
  "@dashboard/GET_CLIENT_COUNTS_FAILURE",
)<void, GetClientsCountQueryResult, Error>();

function* getClientCounts(
  action: ReturnType<typeof getClientCountsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(getClientsCount);

    if (status === 200) {
      yield put(getClientCountsAsync.success(data));
      return;
    }

    yield put(getClientCountsAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getClientCountsAsync.failure(err as Error));
  }
}
export function* getClientCountSaga() {
  yield takeLatest(getType(getClientCountsAsync.request), getClientCounts);
}

export const getClientCountsSlice = createSlice({
  initialState: getFetchStateDefaultValue<GetClientsCountQueryResult>(false),
  name: "collection",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<GetClientsCountQueryResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getClientCountsAsync, builder);
    handleSuccessCase(getClientCountsAsync, builder);
    handleFailureCase(getClientCountsAsync, builder);
  },
});

export const { resetSlice } = getClientCountsSlice.actions;
