import { IBAN } from "ibankit";

const calculateIban = (buf: string) => {
  let index = 0;
  let dividend = "";
  let pz = -1;
  while (index <= buf.length) {
    if (pz < 0) {
      dividend = buf.substring(index, index + 9);
      index += 9;
    } else if (pz >= 0 && pz <= 9) {
      dividend = pz + buf.substring(index, index + 8);
      index += 8;
    } else {
      dividend = pz + buf.substring(index, index + 7);
      index += 7;
    }
    pz = +dividend % 97;
  }
  return pz;
};

export const convertToIban = (
  accountNumber: string | null | undefined,
  bankCode: string | null | undefined,
) => {
  if (!accountNumber || !bankCode) {
    return null;
  }

  const accountSplit = accountNumber.split("-").reverse();
  const prefix = (accountSplit[1] ?? "").padStart(6, "0");
  const number = (accountSplit[0] ?? "").padStart(10, "0");
  const checkSum = 98 - calculateIban(bankCode + prefix + number + "123500");

  return `CZ${checkSum
    .toString()
    .padStart(2, "0")}${bankCode}${prefix}${number}`;
};

export const convertIbanToBankAccount = (iban: string | null | undefined) => {
  if (!iban) {
    return null;
  }

  try {
    const ibanKit = new IBAN(iban);
    const accountNumber = ibanKit.getAccountNumber() ?? "";
    const branchCode = ibanKit.getBranchCode() ?? "";

    const hasBranchCode = branchCode.split("").some(char => char !== "0");

    return {
      iban: ibanKit,
      bankCode: ibanKit.getBankCode(),
      accountNumber: `${hasBranchCode ? branchCode : ""}${
        hasBranchCode ? "-" : ""
      }${accountNumber}`,
    };
  } catch (e) {
    return null;
  }
};
