import { yupResolver } from "@hookform/resolvers/yup";
import {
  EnterpriseMode,
  EnterpriseServiceListItemDto,
  EnterpriseServiceSettingsDto,
  EnterpriseServiceUpdateListItemDto,
  ServiceType,
} from "Api/Api";
import { HourRateService } from "Components/Settings/Services/HourRateService";
import { ProvidedServices } from "Components/Settings/Services/ProvidedServices";
import { ServiceSettings } from "Components/Settings/Services/ServiceSettings";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { putEnterpriseServiceSettingsAsync } from "State/Enterprises/Services/PutServiceSettingsSlice";
import { putEnterpriseServicesAsync } from "State/Enterprises/Services/PutServicesSlice";
import { getEnterpriseServiceSettingsAsync } from "State/Enterprises/Services/ServiceSettingsSlice";
import { getEnterpriseServicesAsync } from "State/Enterprises/Services/ServicesSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ObjectSchema, array, boolean, number, object, string } from "yup";
import { random } from "lodash-es";
import { useUser } from "Hooks/useUser";
import { AccessRightCodes } from "Models/AccessRightCodes";
import BigNumber from "bignumber.js";
import { PriceTaxType } from "Constants/PriceTaxType";
import { getPriceTaxTypeFromLocalStorage } from "Utils/LocalStorageUtils";

export type ServicesFormModel = {
  services: (EnterpriseServiceUpdateListItemDto & { type: ServiceType })[];
  serviceSettings: EnterpriseServiceSettingsDto;
  priceTaxType: PriceTaxType;
};

const getDefaultValues = ({
  services,
  serviceSettings,
}: {
  services: EnterpriseServiceListItemDto[];
  serviceSettings: EnterpriseServiceSettingsDto | null;
}): ServicesFormModel => {
  return {
    services: services.map(s => ({
      serviceSettingID: s.serviceSettingID,
      isEnabled: s.isEnabled,
      type: s.type,
      variants: s.variants.map(v => ({
        serviceSettingServiceVariantID: v.serviceSettingServiceVariantID,
        isEnabled: v.isEnabled,
        priceWithoutTax: v.priceWithoutTax ?? 0,
        priceWithTax: v.priceWithTax ?? 0,
      })),
    })),
    priceTaxType: getPriceTaxTypeFromLocalStorage(),
    serviceSettings: {
      canUserSetPrice: serviceSettings?.canUserSetPrice ?? false,
      maximalPriceRangeRate: serviceSettings?.maximalPriceRangeRate ?? 0,
    },
  };
};

export const ServicesForm: React.FunctionComponent = _ => {
  const dispatch = useAppDispatch();
  const { t } = useResource();

  useEffect(() => {
    dispatch(getEnterpriseServicesAsync.request());
    dispatch(getEnterpriseServiceSettingsAsync.request());
  }, [dispatch]);

  const enterpriseSettings = useAppSelector(
    s => s.enterprise.basicSettings.settings.data,
  );

  const user = useUser();

  const canUpdateServiceSettings =
    (user?.accessRightCodes ?? []).some(
      x => x === AccessRightCodes.SettingsEnterprise,
    ) && enterpriseSettings?.mode === EnterpriseMode.Strict;

  const services = useAppSelector(state => state.enterprise.services);
  const { isLoading: isSaving } = useAppSelector(
    state => state.enterprise.putServices,
  );
  const isLoadingServices = services.isLoading && !services.data;
  const serviceSettings = useAppSelector(
    state => state.enterprise.serviceSettings,
  );
  const isLoadingServiceSettings =
    serviceSettings.isLoading &&
    canUpdateServiceSettings &&
    !serviceSettings.data;

  const validationSchema: ObjectSchema<ServicesFormModel> = object({
    priceTaxType: string<PriceTaxType>().required(),
    services: array()
      .of(
        object({
          serviceSettingID: number().required(),
          isEnabled: boolean().required(),
          type: string().oneOf(Object.values(ServiceType)).required(),
          variants: array()
            .of(
              object({
                serviceSettingServiceVariantID: number().required(),
                isEnabled: boolean().required(),
                priceWithoutTax: number().when("isEnabled", {
                  is: true,
                  then: schema =>
                    getPriceTaxTypeFromLocalStorage() ===
                    PriceTaxType.WithoutTax
                      ? schema.min(0).required()
                      : schema.optional(),
                  otherwise: schema => schema.optional(),
                }),
                priceWithTax: number().when("isEnabled", {
                  is: true,
                  then: schema =>
                    getPriceTaxTypeFromLocalStorage() === PriceTaxType.WithTax
                      ? schema.min(0).required()
                      : schema.optional(),
                  otherwise: schema => schema.optional(),
                }),
              }).required(),
            )
            .required()
            .test({
              test: (arr, c) => {
                const parent = c.parent as ServicesFormModel["services"][0];

                if (parent.type === ServiceType.HourRate) {
                  return true;
                }

                return !parent.isEnabled || arr.some(x => x.isEnabled);
              },
              message: t(Resources.Settings.Services.VariantIsRequired),
            }),
        }).required(),
      )
      .defined(),
    serviceSettings: object({
      canUserSetPrice: boolean().required(),
      maximalPriceRangeRate: number().required(),
    }).defined(),
  }).defined();

  const form = useForm<ServicesFormModel>({
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = form;

  useEffect(() => {
    reset(
      getDefaultValues({
        services: services.data ?? [],
        serviceSettings: {
          canUserSetPrice: serviceSettings.data?.canUserSetPrice ?? false,
          maximalPriceRangeRate: BigNumber(
            serviceSettings.data?.maximalPriceRangeRate ?? 10,
          )
            .times(100)
            .toNumber(),
        },
      }),
    );
  }, [reset, services.data, serviceSettings.data]);

  const onResetForm = () => {
    reset(
      getDefaultValues({
        services: services.data ?? [],
        serviceSettings: serviceSettings.data ?? null,
      }),
    );
  };

  const onSubmit = (data: ServicesFormModel) => {
    const toastID = random(10000).toString();
    dispatch(
      putEnterpriseServicesAsync.request({
        request: { services: data.services },
        toastID: toastID,
      }),
    );

    if (!canUpdateServiceSettings) {
      return;
    }

    dispatch(
      putEnterpriseServiceSettingsAsync.request({
        request: {
          canUserSetPrice: data.serviceSettings.canUserSetPrice,
          maximalPriceRangeRate:
            (data.serviceSettings.maximalPriceRangeRate ?? 0) / 100,
        },
        toastID: toastID,
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isLoadingServices ? (
        <>
          <BlSkeleton height={+50} />
          <BlSkeleton height={+50} />
          <BlSkeleton height={+50} />
          <BlSkeleton height={+50} />
        </>
      ) : (
        <>
          <ProvidedServices form={form} services={services.data ?? []} />

          <HourRateService form={form} services={services.data ?? []} />
        </>
      )}

      {isLoadingServiceSettings ? (
        <BlSkeleton height={100} />
      ) : canUpdateServiceSettings ? (
        <ServiceSettings form={form} />
      ) : null}

      <StyledFlex $alignItems="center" $gap={2} $marginTop={2}>
        <BlButton color="primary" type="submit" isLoading={isSaving}>
          {t(Resources.Common.Save_FirstUppercase)}
        </BlButton>
        <BlButton type="button" onClick={() => onResetForm()}>
          {t(Resources.Common.Reset)}
        </BlButton>
      </StyledFlex>
    </form>
  );
};
