import { Typography } from "@mui/material";
import { LoadingWrapper } from "Components/Orders/Draft/LoadingWrapper";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import {
  StyledPartyFormItem,
  StyledPartyFormRow,
} from "Components/Shared/Party/PartyForm";
import { PartyFormModel } from "Components/Shared/Party/PartyFormValidationSchema";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { Control, FieldErrors, UseFormSetValue } from "react-hook-form";

type Props = {
  control: Control<PartyFormModel>;
  errors: FieldErrors<PartyFormModel>;
  isLoading: boolean;
  isDisabled?: boolean;
  setValue: UseFormSetValue<PartyFormModel>;
};

export const PartyBankAccount: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const { control, errors, isLoading, isDisabled, setValue } = props;

  return (
    <>
      <StyledFlex $alignItems="center" $gap={0.5} $marginTop={3}>
        <Typography variant="h2">
          {t(Resources.Party.BankAccount.Title)}
        </Typography>
        <BlDefaultTooltip title={t(Resources.Party.BankAccount.Tooltip)} />
      </StyledFlex>
      <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={1}>
        {t(Resources.Party.BankAccount.Description)}
      </Typography>

      <StyledPartyFormRow $maxWidth={460}>
        <StyledPartyFormItem $span={4}>
          <LoadingWrapper isLoading={isLoading}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"party.bankAccountNumber"}
              label={t(Resources.Party.BankAccount.BankAccountNumber)}
              placeholder={t(
                Resources.Party.BankAccount.BankAccountNumberPlaceholder,
              )}
              maxLength={17}
              disabled={isDisabled}
              onChange={val => {
                setValue(
                  "party.bankAccountNumber",
                  (val as string).replace(/\s/g, ""),
                ); //remove spaces
              }}
            ></BlFormInput>
          </LoadingWrapper>
        </StyledPartyFormItem>

        <StyledPartyFormItem $span={2}>
          <LoadingWrapper isLoading={isLoading}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"party.bankCode"}
              label={t(Resources.Party.BankAccount.BankCode)}
              placeholder={t(Resources.Party.BankAccount.BankCodePlaceholder)}
              mask={"0000"}
              disabled={isDisabled}
            ></BlFormInput>
          </LoadingWrapper>
        </StyledPartyFormItem>
      </StyledPartyFormRow>
    </>
  );
};
