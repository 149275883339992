import { Typography } from "@mui/material";
import { Logo } from "Components/Layout/Logo";
import { Colors } from "Components/Settings/Enterprise/Colors";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlTextInput } from "Components/Shared/Inputs/BlTextInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { enterpriseBasicSettingsActions } from "State/Enterprises/BasicSettings/EnterpriseBasicSettingsSlice";
import { putEnterpriseDesignSettingsAsync } from "State/Enterprises/BasicSettings/PutEnterpriseDesignSettings";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { getBase64Images } from "Utils/FileUtils";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

const StyledLogoWrapper = styled.div`
  display: grid;
  grid-template-columns: 340px auto;
  justify-content: flex-start;
  align-items: center;
  gap: ${props => props.theme.spacing(4)};
`;

type FormModel = {
  color: string;
  logoBase64: string;
};

export const DesignForm: React.FunctionComponent = _ => {
  const dispatch = useAppDispatch();
  const { t } = useResource();
  const basicSettings = useAppSelector(
    state => state.enterprise.basicSettings.settings,
  );
  const stateLogoBase64 = useAppSelector(
    state => state.enterprise.logo.data?.logoBase64,
  );

  const isLoading = basicSettings.isLoading && !basicSettings.data;

  useEffect(() => {
    return () => {
      dispatch(enterpriseBasicSettingsActions.modifyColor({ color: null }));
    };
  }, [dispatch]);

  const { handleSubmit, reset, setValue, watch } = useForm<FormModel>({});
  const logoBase64 = watch("logoBase64");

  useEffect(() => {
    reset({
      color: basicSettings.data?.color ?? "",
    });
  }, [reset, basicSettings.data]);

  const resetForm = () => {
    reset({
      color: basicSettings.data?.color ?? "",
    });
    dispatch(enterpriseBasicSettingsActions.modifyColor({ color: null }));
  };

  const onSubmit = (data: FormModel) => {
    dispatch(
      putEnterpriseDesignSettingsAsync.request({
        color: data.color,
        logoBase64: data.logoBase64 || stateLogoBase64,
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} onReset={() => resetForm()}>
      <StyledFlex $alignItems="center" $gap={0.5}>
        <Typography variant="h2">
          {t(Resources.Settings.Enterprise.LogoBase64.Title)}
        </Typography>
        <BlDefaultTooltip
          title={t(Resources.Settings.Enterprise.LogoBase64.Tooltip)}
        />
      </StyledFlex>
      <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={2}>
        {t(Resources.Settings.Enterprise.LogoBase64.Description)}
      </Typography>

      <StyledLogoWrapper>
        <BlTextInput
          label={t(Resources.Settings.Enterprise.LogoBase64.Label)}
          type="file"
          accept="image/png, image/jpeg, image/svg+xml"
          placeholder={t(Resources.Settings.Enterprise.LogoBase64.Placeholder)}
          onInputChange={async f => {
            setValue(
              "logoBase64",
              (
                await getBase64Images((f.target as HTMLInputElement).files)
              )?.[0] ?? "",
            );
          }}
        />

        <Logo enterpriseLogo={logoBase64} isUserLogoVisible={false} />
      </StyledLogoWrapper>

      <StyledFlex $alignItems="center" $gap={0.5} $marginTop={1}>
        <Typography variant="h2">
          {t(Resources.Settings.Enterprise.Color.Title)}
        </Typography>
        <BlDefaultTooltip
          title={t(Resources.Settings.Enterprise.Color.Tooltip)}
        />
      </StyledFlex>
      <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={2}>
        {t(Resources.Settings.Enterprise.Color.Description)}
      </Typography>

      {isLoading ? (
        <BlSkeleton height={40} />
      ) : (
        <Colors
          onChange={c => {
            setValue("color", c);
            dispatch(enterpriseBasicSettingsActions.modifyColor({ color: c }));
          }}
        />
      )}

      <StyledFlex $alignItems="center" $gap={2} $marginTop={3}>
        <BlButton color="primary" type="submit">
          {t(Resources.Common.Save_FirstUppercase)}
        </BlButton>
        <BlButton type="reset">{t(Resources.Common.Reset)}</BlButton>
      </StyledFlex>
    </form>
  );
};
