import {
  EnterpriseServiceSettingsDto,
  getEnterprisesServiceSettings,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getEnterpriseServiceSettingsAsync = createAsyncAction(
  "@ENTERPRISE/GET_SERVICE_SETTINGS_REQUEST",
  "@ENTERPRISE/GET_SERVICE_SETTINGS_RESPONSE",
  "@ENTERPRISE/GET_SERVICE_SETTINGS_FAILURE",
)<void, EnterpriseServiceSettingsDto, Error>();

function* getEnterpriseServiceSettings(
  action: ReturnType<typeof getEnterpriseServiceSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(getEnterprisesServiceSettings);

    if (status === 200) {
      yield put(getEnterpriseServiceSettingsAsync.success(data));
      return;
    }

    yield put(
      getEnterpriseServiceSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(getEnterpriseServiceSettingsAsync.failure(err as Error));
  }
}
export function* getEnterpriseServiceSettingsSaga() {
  yield takeLatest(
    getType(getEnterpriseServiceSettingsAsync.request),
    getEnterpriseServiceSettings,
  );
}

export const getEnterpriseServiceSettingsSlice = createSlice({
  initialState: getFetchStateDefaultValue<EnterpriseServiceSettingsDto>(false),
  name: "collection",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EnterpriseServiceSettingsDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getEnterpriseServiceSettingsAsync, builder);
    handleSuccessCase(getEnterpriseServiceSettingsAsync, builder);
    handleFailureCase(getEnterpriseServiceSettingsAsync, builder);
  },
});

export const { resetSlice } = getEnterpriseServiceSettingsSlice.actions;
