// import { GetCodeListCollectionQueryResult, getCodeLists } from "Api/Api";
// import { createAsyncAction, getType } from "typesafe-actions";
// import { call, put, takeLatest } from "typed-redux-saga";
// import { createSlice } from "@reduxjs/toolkit";
// import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
// import {
//   getFetchStateDefaultValue,
//   handleFailureCase,
//   handleRequestCase,
//   handleSuccessCase,
// } from "State/Utils";

// export const getCodeListCollectionAsync = createAsyncAction(
//   "@codeList/GET_COLLECTION_REQUEST",
//   "@codeList/GET_COLLECTION_RESPONSE",
//   "@codeList/GET_COLLECTION_FAILURE",
// )<void, GetCodeListCollectionQueryResult, Error>();

// function* getCodeListCollection(
//   action: ReturnType<typeof getCodeListCollectionAsync.request>,
// ): Generator {
//   try {
//     const { data, error, status } = yield* call(getCodeLists);

//     if (status === 200) {
//       yield put(getCodeListCollectionAsync.success(data));
//       return;
//     }

//     yield put(
//       getCodeListCollectionAsync.failure(mapAPIErrorResponse(error ?? data)),
//     );
//   } catch (err) {
//     yield put(getCodeListCollectionAsync.failure(err as Error));
//   }
// }
// export function* getCodeListCollectionSaga() {
//   yield takeLatest(
//     getType(getCodeListCollectionAsync.request),
//     getCodeListCollection,
//   );
// }

// export const getCodeListCollectionSlice = createSlice({
//   initialState: getFetchStateDefaultValue(false),
//   name: "collection",
//   reducers: {
//     resetSlice: () => getFetchStateDefaultValue(false),
//   },
//   extraReducers: builder => {
//     handleRequestCase(getCodeListCollectionAsync, builder);
//     handleSuccessCase(getCodeListCollectionAsync, builder);
//     handleFailureCase(getCodeListCollectionAsync, builder);
//   },
// });

// export const { resetSlice } = getCodeListCollectionSlice.actions;

import { GetCodeListCollectionQueryResult, getCodeLists } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getCodeListCollectionAsync = createAsyncAction(
  "@codeList/GET_COLLECTION_REQUEST",
  "@codeList/GET_COLLECTION_RESPONSE",
  "@codeList/GET_COLLECTION_FAILURE",
)<void, GetCodeListCollectionQueryResult, Error>();

function* getCodeListCollection(
  action: ReturnType<typeof getCodeListCollectionAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(getCodeLists);

    if (status === 200) {
      yield put(getCodeListCollectionAsync.success(data));
      return;
    }

    yield put(
      getCodeListCollectionAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getCodeListCollectionAsync.failure(err as Error));
  }
}
export function* getCodeListCollectionSaga() {
  yield takeLatest(
    getType(getCodeListCollectionAsync.request),
    getCodeListCollection,
  );
}

export const getCodeListCollectionSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<GetCodeListCollectionQueryResult>(false),
  name: "codeListCollection",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<GetCodeListCollectionQueryResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getCodeListCollectionAsync, builder);
    handleSuccessCase(getCodeListCollectionAsync, builder);
    handleFailureCase(getCodeListCollectionAsync, builder);
  },
});

export const { resetSlice } = getCodeListCollectionSlice.actions;
