import {
  ApiProblemDetails,
  ErrorCode,
  ErrorDetail,
  ProblemDetails,
} from "Api/Api";

type MaybeErrorMaybeProblemDetails = Error | ProblemDetails | ApiProblemDetails;

export type ErrorDetails = { [key: string | number]: ErrorDetail[] };

export class ApiCallError extends Error {
  public status: number;
  public errors?: { [key: string | number]: ErrorDetail[] };
  public title?: string;

  constructor(
    httpStatus: number,
    title: string,
    errors?: { [key: string | number]: ErrorDetail[] },
  ) {
    super(`API call failed with status ${httpStatus}`);
    this.name = "APICallError";
    this.cause = httpStatus;
    this.status = httpStatus;
    this.errors = errors;
    this.title = title;
  }
}

export const mapAPIErrorResponse = (
  error: MaybeErrorMaybeProblemDetails,
  status?: number,
): Error | ApiCallError => {
  if (error instanceof Error) {
    return new ApiCallError(status ?? 0, error.message, {
      error: [{ message: error.message, code: ErrorCode.Unspecified }],
    });
  }

  const hasStatus = "status" in error && !isNaN(Number(error.status));

  if (hasStatus || !!status) {
    const httpStatus = !!status ? status : Number(error.status);
    if ("errors" in error) {
      return new ApiCallError(
        httpStatus,
        error.title ?? "API call failed",
        error.errors,
      );
    }

    return new ApiCallError(httpStatus, error.title ?? "API call failed");
  }

  return new ApiCallError(0, "API call failed");
};
