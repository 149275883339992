import { Typography } from "@mui/material";
import { StyledContainer, StyledWrapper } from "Components/Auth/Styles";
import { Menu } from "Components/Layout/Menu";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resendVerificationEmailAsync } from "State/Auth/Verifications/ResendVerificationEmailSlice";
import { useAppSelector } from "State/Store";
import { AppRouting, getPath } from "Utils/UrlUtils";

export const AwaitingEmailVerificationPage: React.FunctionComponent = _ => {
  const { user } = useAppSelector(e => e.auth.session);
  const dispatch = useDispatch();
  const { verificationEmail } = useAppSelector(e => e.auth.emailVerification);
  const [wasResend, setWasResend] = React.useState(false);

  const resend = () => {
    if (!!verificationEmail && !wasResend) {
      dispatch(
        resendVerificationEmailAsync.request({
          email: verificationEmail,
        }),
      );
      setWasResend(true);
    }
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!!user) {
      navigate(getPath(AppRouting.Dashboard), { replace: true });
    }
  }, [user, navigate]);

  return (
    <UnauthenticatedLayout title="Ověření e-mailu">
      <StyledWrapper>
        <StyledContainer>
          <div>
            <br />
            <Menu></Menu>
            <br />
            <Typography variant="h2">Ověření e-mailu</Typography>
            <br />
            <Typography>Na Vaši e-mailovou adresu:</Typography>
            <br />
            {!!verificationEmail && (
              <Typography fontWeight={600}>{verificationEmail}</Typography>
            )}
            <br />
            <Typography>
              byl odeslán e-mail s aktivačním odkazem. Před pokračováním je
              nutné účet aktivovat kliknutím na odkaz v tomto e-mailu.
            </Typography>
            <br />
            <br />
            <br />
            <Typography>
              PS: Žádný e-mail nedorazil? Zkuste zkontrolovat složku SPAM nebo
              Hromadné. Případně si nechte poslat e-mail znovu.
            </Typography>
            <br />
          </div>
        </StyledContainer>
        <div></div>
        <StyledContainer $isGray={true}>
          <div>
            <br />
            <StyledFlex
              $alignItems={"center"}
              $gap={1}
              $justifyContent={"center"}
            ></StyledFlex>
            <BlButton
              onClick={() => resend()}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={wasResend}
            >
              {wasResend ? "E-mail byl odeslán" : "Odeslat e-mail znovu"}
            </BlButton>
          </div>
        </StyledContainer>
      </StyledWrapper>
    </UnauthenticatedLayout>
  );
};

export default AwaitingEmailVerificationPage;
