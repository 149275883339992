import * as React from "react";
import { Navigate, RouteProps, useRoutes } from "react-router-dom";
import { AuthenticatedRoute } from "Routes/AuthenticatedRoute";
import { useAppSelector } from "State/Store";
import { AppRouting, getPath, getRoute } from "Utils/UrlUtils";
import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import { Error404Page } from "Pages/Errors/Error404Page";
import { Error403Page } from "Pages/Errors/Error403Page";
import { DashboardPage } from "Pages/Dashboard/DashboardPage";
import { GoogleCallbackPage } from "Pages/Auth/GoogleCallbackPage";
import { EmailVerificationPage } from "Pages/Verifications/EmailVerificationPage";
import { AwaitingEmailVerificationPage } from "Pages/Verifications/AwaitingEmailVerificationPage";
import { ForgottenPasswordPage } from "Pages/Auth/ForgottenPasswordPage";
import { ListPage as OrdersListPage } from "Pages/Orders/ListPage";
import { CreatePage as OrderCreatePage } from "Pages/Orders/CreatePage";
import { DetailPage as OrderDetailPage } from "Pages/Orders/DetailPage";
import { SettingsPage } from "Pages/Settings/SettingsPage";
import { CompanyCreatePage } from "Pages/Companies/CompanyCreatePage";
import { CompanyDetailPage } from "Pages/Companies/CompanyDetailPage";
import { BrokerAuthCallbackPage } from "../Pages/Auth/BrokerAuthCallbackPage";
import { BrokerAuthPage } from "../Pages/Auth/BrokerAuthPage";
import { RevocationPage } from "../Pages/ClientZone/RevocationPage";
import { TermsAndConditionsPage } from "Pages/ClientZone/TermsAndConditionsPage";
import { GDPRPage } from "Pages/ClientZone/GDPRPage";
import { SignedOutPage } from "Pages/Auth/SignedOutPage";
import { ServicePackageDetailPage } from "Pages/Settings/ServicePackageDetailPage";

const SignInPage = reactLazyWithRetry(() => import("Pages/Auth/SignInPage"));

const SignUpPage = reactLazyWithRetry(() => import("Pages/Auth/SignUpPage"));

const ClientZoneOrderPage = reactLazyWithRetry(
  () => import("Pages/ClientZone/OrderPage"),
);

const Routes: React.FunctionComponent<RouteProps> = _ => {
  const isAuthenticated = useAppSelector(e => e.auth.session.isAuthenticated);

  const appRoutes = [
    {
      path: getRoute(AppRouting.SignOutPage),
      element: isAuthenticated ? (
        <Navigate to={getPath(AppRouting.Dashboard)} replace={true} />
      ) : (
        <SignedOutPage />
      ),
    },
    {
      path: getRoute(AppRouting.SignUp),
      element: <SignUpPage />,
    },
    {
      path: getRoute(AppRouting.SignIn),
      element: <SignInPage />,
    },
    {
      path: getRoute(AppRouting.GoogleCallback),
      element: <GoogleCallbackPage />,
    },
    {
      path: getRoute(AppRouting.BrokerAuth),
      element: <BrokerAuthPage />,
    },
    {
      path: getRoute(AppRouting.BrokerAuthCallback),
      element: <BrokerAuthCallbackPage />,
    },
    {
      path: getRoute(AppRouting.ForgottenPassword),
      element: <ForgottenPasswordPage />,
    },
    {
      path: getRoute(AppRouting.Dashboard),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isAuthenticated}>
          <DashboardPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.Orders),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isAuthenticated}>
          <OrdersListPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.OrderCreate),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isAuthenticated}>
          <OrderCreatePage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.OrderDetail),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isAuthenticated}>
          <OrderDetailPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.Settings),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isAuthenticated}>
          <SettingsPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.CompanyCreate),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isAuthenticated}>
          <CompanyCreatePage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.CompanyDetail),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isAuthenticated}>
          <CompanyDetailPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ServicePackagesCreate),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isAuthenticated}>
          <ServicePackageDetailPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.ServicePackagesDetail),
      element: (
        <AuthenticatedRoute isUserAuthenticated={isAuthenticated}>
          <ServicePackageDetailPage />
        </AuthenticatedRoute>
      ),
    },
    {
      path: getRoute(AppRouting.EmailVerification),
      element: <EmailVerificationPage />,
    },
    {
      path: getRoute(AppRouting.AwaitingEmailVerification),
      element: <AwaitingEmailVerificationPage />,
    },
    {
      path: getRoute(AppRouting.ClientZoneOrder),
      element: <ClientZoneOrderPage />,
    },
    {
      path: getRoute(AppRouting.ClientZoneRevocation),
      element: <RevocationPage />,
    },
    {
      path: getRoute(AppRouting.ClientZoneTermsAndConditions),
      element: <TermsAndConditionsPage />,
    },
    {
      path: getRoute(AppRouting.ClientZoneGDPR),
      element: <GDPRPage />,
    },
    {
      path: getRoute(AppRouting.Error403),
      element: <Error403Page />,
    },
    { path: "*", element: <Error404Page /> },
  ];

  return useRoutes(appRoutes);
};

export { Routes };
