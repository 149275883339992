import {
  SaveDraftRequest,
  SaveDraftCommandResult,
  postOrdersWorkflowDraft,
  OrderPeriodicity,
  putOrdersPublicIDPeriodicity,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, delay, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { FormMode } from "Components/Orders/Draft/StepFormValidationSchema";
import { NavigateFunction } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { toast } from "sonner";
import { Step, setStep } from "State/Orders/Draft/DraftSlice";
import { getOrderDetailAsync } from "State/Orders/Detail/OrderDetailSlice";
import { Resources, getTranslation } from "Translations/Resources";
import { resetParties } from "State/Parties/Slices/PartyDetailsSlice";

export const saveDraftAsync = createAsyncAction(
  "@order/SAVE_DRAFT_REQUEST",
  "@order/SAVE_DRAFT_RESPONSE",
  "@order/SAVE_DRAFT_FAILURE",
)<
  {
    mode: FormMode;
    request: SaveDraftRequest;
    navigate: NavigateFunction;
    clonedOrderPublicID?: string | null;
  },
  SaveDraftCommandResult,
  Error
>();

function* saveDraft(
  action: ReturnType<typeof saveDraftAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersWorkflowDraft,
      action.payload.request,
    );

    if (status === 200) {
      yield put(resetParties());
      yield put(saveDraftAsync.success(data));

      if (!!action.payload.clonedOrderPublicID) {
        yield* call(
          putOrdersPublicIDPeriodicity,
          {
            periodicity: OrderPeriodicity.Single,
            frequency: null,
          },
          action.payload.clonedOrderPublicID,
        );
      }

      if (action.payload.mode === FormMode.Draft) {
        toast.success(getTranslation(Resources.Orders.Detail.SavedSuccess));
        action.payload.navigate(getPath(AppRouting.Dashboard));
        return;
      }

      action.payload.navigate(
        getPath(AppRouting.OrderDetail, data.order.publicID),
      );
      yield delay(100);
      yield put(getOrderDetailAsync.success(data.order));
      yield put(setStep(Step.Summary));
      return;
    }

    yield put(saveDraftAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(saveDraftAsync.failure(err as Error));
  }
}
export function* saveDraftSaga() {
  yield takeLatest(getType(saveDraftAsync.request), saveDraft);
}

export const saveDraftSlice = createSlice({
  initialState: getFetchStateDefaultValue<SaveDraftCommandResult>(false),
  name: "saveDraft",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<SaveDraftCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(saveDraftAsync, builder);
    handleSuccessCase(saveDraftAsync, builder);
    handleFailureCase(saveDraftAsync, builder);
  },
});

export const { resetSlice } = saveDraftSlice.actions;
