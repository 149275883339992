import { Typography } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { FlagIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import styled from "styled-components";

const Wrapper = styled.div`
  border-radius: 12px;
  border: 1px solid;
  border-color: ${props => props.theme.palette.warning.light};
  padding: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const WarningButton = styled(BlButton)`
  color: ${props => props.theme.palette.common.white};
  border-color: ${props => props.theme.palette.warning.light};
  background-color: ${props => props.theme.palette.warning.light};
  &:hover {
    background-color: ${props => props.theme.palette.warning.light};
    color: ${props => props.theme.palette.common.white};
  }

  margin-top: ${props => props.theme.spacing(1)};
`;

export const MissingUserCompanyWarning: React.FunctionComponent = _ => {
  const { t } = useResource();

  return (
    <Wrapper>
      <StyledFlex $gap={1}>
        <FlagIcon />

        <Typography
          color={theme => theme.palette.warning.light}
          fontWeight={700}
          fontSize={18}
          marginBottom={1}
        >
          {t(Resources.MissingUserCompanyWarning.Title)}
        </Typography>
      </StyledFlex>
      <Typography>
        {t(Resources.MissingUserCompanyWarning.Description)}
      </Typography>

      <UnstyledLink to={getPath(AppRouting.CompanyCreate, "user")}>
        <WarningButton>
          {t(Resources.MissingUserCompanyWarning.Button)}
        </WarningButton>
      </UnstyledLink>
    </Wrapper>
  );
};
