import { StyledFlex } from "Components/Shared/StyledComponents";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { SearchIcon } from "Components/Shared/Icons";

type StyledAutocompleteType = {
  $allowCustomValue: boolean;
  $open: boolean;
  $hasError: boolean;
};

const StyledPopupIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  svg {
    width: 12px;
    height: auto;
    opacity: 0.5;
  }
`;

const StyledInput = styled(StyledFlex)`
  position: relative;
  & > div {
    width: 100%;
  }
`;

const StyledAddIcon = styled(BlDefaultButton)`
  position: absolute;
  right: 10px;
  top: 8px;
  padding: 2px;
  border-radius: 50%;

  svg {
    width: 25px;
    height: 25px;
  }
`;

const StyledValidation = styled.div`
  position: absolute;
  top: calc(100% - 12px);
  left: 15px;
  color: ${props => props.theme.palette.error.main};
  font-size: 12px;
`;

const StyledAutocomplete = styled.div<StyledAutocompleteType>`
  position: relative;

  .MuiAutocomplete-inputRoot {
    padding: 0 !important;
    background-color: ${props => props.theme.colors.fieldBackground};

    .MuiAutocomplete-input {
      padding: ${props => props.theme.spacing(1.2, 0)};
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${props =>
        props.$hasError ? props.theme.palette.error.main : ""};
    }
  }
  .MuiAutocomplete-endAdornment {
    right: ${props => (props.$allowCustomValue ? 50 : 12)}px !important;
    top: 50%;
    transform: translateY(-50%);
  }

  .MuiAutocomplete-popupIndicatorOpen {
    transform: ${props => (props.$allowCustomValue ? "rotate(0)" : "")};
  }

  .MuiAutocomplete-paper {
    margin: 0;
  }

  .MuiAutocomplete-clearIndicator {
    margin-right: ${props => props.theme.spacing(1)};
    height: 33px;
  }

  .MuiAutocomplete-clearIndicator {
    padding: ${props => (props.$allowCustomValue ? "0" : "")};
  }
`;

const StyledLoadingIcon = styled(CircularProgress)`
  position: absolute;
  right: 50px;
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: ${props => props.theme.palette.text.secondary};
  margin-left: ${props => props.theme.spacing(1.6)};
  margin-right: ${props => props.theme.spacing(1.6)};
`;

const useStyles = (hasError: boolean) =>
  makeStyles(theme =>
    createStyles({
      popper: {
        "& .MuiAutocomplete-paper": {
          boxShadow: "none",
          borderRadius: "12px",
          backgroundColor: theme.colors.fieldBackground,
          border: `2px solid ${
            hasError ? theme.palette.error.main : theme.palette.primary.main
          }`,
          position: "relative",
          width: "calc(100% - 0.5px)",

          "&:before": {
            content: "''",
            position: "absolute",
            height: 1,
            left: 0,
            right: 0,
            backgroundColor: theme.colors.border,
          },
        },
        "&[data-popper-placement='bottom']": {
          "& .MuiAutocomplete-paper": {
            borderTop: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            transform: "translateY(-12px)",
            "&:before": {
              top: 10,
            },
          },
          "& .MuiAutocomplete-listbox": {
            paddingTop: theme.spacing(2.6),
          },
        },
        "&[data-popper-placement='top']": {
          "& .MuiAutocomplete-paper": {
            borderBottom: "none",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transform: "translateY(12px)",
            "&:before": {
              bottom: 10,
            },
          },
          "& .MuiAutocomplete-listbox": {
            paddingBottom: theme.spacing(2.6),
          },
        },
        "& .MuiAutocomplete-listbox": {
          padding: theme.spacing(1.6, 0),
        },
        "& .MuiAutocomplete-option": {
          fontSize: "16px",
        },
      },
    }),
  );

export {
  StyledAddIcon,
  StyledAutocomplete,
  StyledInput,
  StyledPopupIcon,
  StyledValidation,
  StyledLoadingIcon,
  StyledSearchIcon,
  useStyles,
};
