import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { ServicePackageForm } from "Components/Settings/Services/ServicePackages/Details/ServicePackageForm";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import {
  getAvailableServicesAsync,
  getAvailableServicesSliceReset,
} from "State/ServicePackages/Slices/GetAvailableServicesSlice";
import { getPackageServiceSettingsAsync } from "State/ServicePackages/Slices/GetPackagesServiceSettingsSlice";
import {
  getServicePackageDetailAsync,
  resetServicePackageDetail,
} from "State/ServicePackages/Slices/GetServicePackageDetailSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useParams } from "react-router";

const PageResources = Resources.Settings.ServicePackages.Detail;

export const ServicePackageDetailPage: React.FunctionComponent = _ => {
  const { t } = useResource();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAvailableServicesAsync.request({ offset: 0, limit: 100 }));

    return () => {
      dispatch(getAvailableServicesSliceReset());
    };
  }, [dispatch]);

  const { data: availableServices } = useAppSelector(
    x => x.servicePackage.availableServices,
  );

  const canUserCreatePackage = useAppSelector(
    x => x.servicePackage.serviceSettings.data?.canUserCreatePackage,
  );

  const params = useParams();
  const { id: servicePublicID } = params;

  useEffect(() => {
    if (!!servicePublicID) {
      dispatch(
        getServicePackageDetailAsync.request({
          servicePublicID: servicePublicID,
        }),
      );
    }

    return () => {
      dispatch(resetServicePackageDetail());
    };
  }, [dispatch, servicePublicID]);

  const { data: servicePackage, isLoading } = useAppSelector(
    x => x.servicePackage.detail,
  );

  useEffect(() => {
    dispatch(getPackageServiceSettingsAsync.request());
  }, [dispatch]);

  const { data: serviceSettings } = useAppSelector(
    x => x.servicePackage.serviceSettings,
  );

  const title = isLoading
    ? ""
    : !!servicePackage
      ? t(PageResources.Title.Existing, { name: servicePackage.name })
      : t(PageResources.Title.New);

  if (!!serviceSettings && !servicePublicID && !canUserCreatePackage) {
    return null;
  }

  return (
    <>
      <AuthenticatedLayout title={title}>
        <StyledPageWrapper $isNarrow={false}>
          <PageTitle
            title={title}
            goBackUrl={getPath(
              AppRouting.Settings,
              t(Resources.Settings.ServicePackages.Url),
            )}
          />
          {!!availableServices && !!serviceSettings && (
            <ServicePackageForm
              key={servicePackage?.servicePublicID ?? null}
              servicePackage={servicePackage ?? null}
              availableServices={availableServices.items}
              serviceSettings={serviceSettings}
            />
          )}
        </StyledPageWrapper>
      </AuthenticatedLayout>
    </>
  );
};
