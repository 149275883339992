import { ClientDto, getClientsSearch } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const searchPartiesAsync = createAsyncAction(
  "@client/SEARCH_REQUEST",
  "@client/SEARCH_RESPONSE",
  "@client/SEARCH_FAILURE",
)<
  {
    query: string;
  },
  ClientDto[],
  Error
>();

function* searchParties(
  action: ReturnType<typeof searchPartiesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getClientsSearch,
      action.payload.query,
    );

    if (status === 200) {
      yield put(searchPartiesAsync.success(data));
      return;
    }

    yield put(searchPartiesAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(searchPartiesAsync.failure(err as Error));
  }
}
export function* searchPartiesSaga() {
  yield takeLatest(getType(searchPartiesAsync.request), searchParties);
}

export const searchPartiesSlice = createSlice({
  initialState: getFetchStateDefaultValue<ClientDto[]>(false),
  name: "@client/searchParties",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<ClientDto[]>(false),
  },
  extraReducers: builder => {
    handleRequestCase(searchPartiesAsync, builder);
    handleSuccessCase(searchPartiesAsync, builder);
    handleFailureCase(searchPartiesAsync, builder);
  },
});

export const { resetSlice: resetSearchPartiesSlice } =
  searchPartiesSlice.actions;
