import {
  postOrdersPublicIDOrderServiceOrderServiceIDProductCancel,
  CancelProductInInstitutionCommandResult,
  CancelProductInInstitutionCommandRequest,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getEucsOrderInfoAsync } from "State/Orders/Detail/EucsOrderInfoSlice";
import { getOrderServicesAsync } from "State/Orders/Detail/OrderServicesSlice";
import { toast } from "sonner";

export const postCancelOrderServiceProductAsync = createAsyncAction(
  "@order/POST_CANCEL_ORDER_PRODUCT_SERVICE_REQUEST",
  "@order/POST_CANCEL_ORDER_PRODUCT_SERVICE_RESPONSE",
  "@order/POST_CANCEL_ORDER_PRODUCT_SERVICE_FAILURE",
)<
  {
    orderPublicID: string;
    orderServiceID: number;
    request: CancelProductInInstitutionCommandRequest;
  },
  CancelProductInInstitutionCommandResult,
  Error
>();

function* postCancelOrderServiceProduct(
  action: ReturnType<typeof postCancelOrderServiceProductAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDOrderServiceOrderServiceIDProductCancel,
      action.payload.request,
      action.payload.orderPublicID,
      action.payload.orderServiceID,
    );

    if (status === 200) {
      yield put(postCancelOrderServiceProductAsync.success(data));

      yield put(
        getOrderServicesAsync.request({
          publicID: action.payload.orderPublicID,
        }),
      );

      yield put(
        getEucsOrderInfoAsync.request({
          orderPublicID: action.payload.orderPublicID,
          orderServiceID: action.payload.orderServiceID,
        }),
      );

      return;
    }

    toast.error("Problém se zrušením smlouvy u EUCS, zkuste zachvíli.");

    yield put(
      postCancelOrderServiceProductAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(postCancelOrderServiceProductAsync.failure(err as Error));
  }
}
export function* postCancelOrderServiceProductSaga() {
  yield takeLatest(
    getType(postCancelOrderServiceProductAsync.request),
    postCancelOrderServiceProduct,
  );
}

export const postCancelOrderServiceProductSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<CancelProductInInstitutionCommandResult>(false),
  name: "detail",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<CancelProductInInstitutionCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(postCancelOrderServiceProductAsync, builder);
    handleSuccessCase(postCancelOrderServiceProductAsync, builder);
    handleFailureCase(postCancelOrderServiceProductAsync, builder);
  },
});

export const { resetSlice: postCancelOrderServiceProductReset } =
  postCancelOrderServiceProductSlice.actions;
