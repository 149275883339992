import { Box, Typography } from "@mui/material";
import {
  EntityListOfOrderWorkflowStepDto,
  OrderDto,
  OrderPeriodicity,
  OrderWorkflowStepStatus,
} from "Api/Api";
import { RoundedIcon } from "Components/Orders/Detail/RoundedIcon";
import { StyledWorkflowButton } from "Components/Orders/Detail/Steps/WorkflowStep";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlConfirmButton } from "Components/Shared/Buttons/BlConfirmButton";
import { CheckIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { setPeriodicityAsync } from "State/Orders/Detail/SetPeriodicitySlice";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { isAfter, parseISO } from "date-fns";
import styled from "styled-components";

type Props = {
  order: OrderDto | null | undefined;
  workflowSteps: EntityListOfOrderWorkflowStepDto | null | undefined;
};

const Wrapper = styled.div`
  background: ${props => props.theme.colors.primaryMainTransparent};
  border-radius: 18px;
  padding: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

const ConfirmButtonWrapper = styled(Box)`
  button {
    background-color: ${props => props.theme.palette.common.white};

    &:hover {
      background-color: ${props => props.theme.palette.common.white};
    }
    font-size: 12px;
    padding: ${props => props.theme.spacing(0.6, 2.5)};
  }
`;

export const PeriodicityBox: React.FunctionComponent<Props> = props => {
  const { order, workflowSteps } = props;
  const { t } = useResource();

  const dispatch = useAppDispatch();

  const isShown =
    !!order?.nextOrderDate &&
    !!workflowSteps?.items &&
    (workflowSteps.items.every(
      x => x.status === OrderWorkflowStepStatus.Completed,
    ) ||
      isAfter(new Date(), parseISO(order.nextOrderDate)));

  if (!isShown) {
    return null;
  }

  const cancelRepetition = () => {
    dispatch(
      setPeriodicityAsync.request({
        publicID: order.publicID,
        request: {
          periodicity: OrderPeriodicity.Single,
          frequency: null,
        },
      }),
    );
  };

  return (
    <Wrapper>
      <StyledFlex>
        <RoundedIcon isFinished={true} isRejected={false}>
          <CheckIcon />
        </RoundedIcon>
        <Typography
          fontWeight={700}
          marginTop={0.5}
          marginLeft={1}
          marginBottom={2}
        >
          {t(Resources.Orders.Completed.Title)}
        </Typography>
      </StyledFlex>

      <Typography fontStyle={"italic"}>
        {t(Resources.Orders.Completed.Description, {
          date: formatDate(order.nextOrderDate),
        })}
      </Typography>

      <StyledFlex $gap={2} $marginTop={2}>
        <UnstyledLink
          to={getPath(AppRouting.OrderDetail, order.publicID, true)}
        >
          <StyledWorkflowButton color="primary">
            {t(Resources.Orders.Completed.RepeatOrder)}
          </StyledWorkflowButton>
        </UnstyledLink>
        <ConfirmButtonWrapper>
          <BlConfirmButton
            variant="secondary"
            modalTitle={t(
              Resources.Orders.Completed.CancelRepetitionDialog.Title,
            )}
            modalContent={t(
              Resources.Orders.Completed.CancelRepetitionDialog.Text,
            )}
            tooltip={t(Resources.Orders.Completed.CancelRepetitionDialog.Title)}
            onResult={r => {
              if (r) {
                cancelRepetition();
              }
            }}
          >
            {t(
              Resources.Orders.Completed.CancelRepetitionDialog
                .OpenDialogButton,
            )}
          </BlConfirmButton>
        </ConfirmButtonWrapper>
      </StyledFlex>
    </Wrapper>
  );
};
