import {
  SaveServicePackageRequest,
  postServicesPackage,
  putServicesServicePublicIDPackage,
  UpdateServicePackageCommandResult,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { Resources, getTranslation } from "Translations/Resources";
import { toast } from "sonner";
import { NavigateFunction } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { getServicePackagesSliceReset } from "State/ServicePackages/Slices/GetServicePackagesSlice";

export const saveServicePackageAsync = createAsyncAction(
  "@servicePackage/SAVE_REQUEST",
  "@servicePackage/SAVE_RESPONSE",
  "@servicePackage/SAVE_FAILURE",
)<
  {
    servicePublicID: string | null;
    request: SaveServicePackageRequest;
    navigate: NavigateFunction;
  },
  UpdateServicePackageCommandResult,
  Error
>();

function* savePackageService(
  action: ReturnType<typeof saveServicePackageAsync.request>,
): Generator {
  try {
    const { data, error, status } =
      action.payload.servicePublicID === null
        ? yield* call(postServicesPackage, action.payload.request)
        : yield* call(
            putServicesServicePublicIDPackage,
            action.payload.request,
            action.payload.servicePublicID,
          );

    if (status === 200) {
      yield put(saveServicePackageAsync.success(data));
      toast.success(getTranslation(Resources.Common.SaveSuccess));
      yield put(getServicePackagesSliceReset());

      action.payload.navigate(
        getPath(
          AppRouting.Settings,
          getTranslation(Resources.Settings.ServicePackages.Url),
        ),
      );

      return;
    }

    yield put(
      saveServicePackageAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(saveServicePackageAsync.failure(err as Error));
  }
}
export function* savePackageServiceSaga() {
  yield takeLatest(
    getType(saveServicePackageAsync.request),
    savePackageService,
  );
}

export const savePackageServiceSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<UpdateServicePackageCommandResult>(false),
  name: "@packageService/savePackageService",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<UpdateServicePackageCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(saveServicePackageAsync, builder);
    handleSuccessCase(saveServicePackageAsync, builder);
    handleFailureCase(saveServicePackageAsync, builder);
  },
});

export const { resetSlice } = savePackageServiceSlice.actions;
