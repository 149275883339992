import { EntityListOfOrderListItemDto, StepType, getOrders } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getOrdersInProgressAsync = createAsyncAction(
  "@dashboard/GET_ORDERS_IN_PROGRESS_REQUEST",
  "@dashboard/GET_ORDERS_IN_PROGRESS_RESPONSE",
  "@dashboard/GET_ORDERS_IN_PROGRESS_FAILURE",
)<void, EntityListOfOrderListItemDto, Error>();

function* getOrdersInProgress(
  action: ReturnType<typeof getOrdersInProgressAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrders,
      0,
      5,
      [
        StepType.Draft,
        StepType.ClientApproval,
        StepType.ClientPrepaidPaymentApproval,
        StepType.ProcessingServices,
        StepType.ClientInvoiceIssuance,
      ],
      null,
      null,
      null,
      false,
      false,
      true,
    );

    if (status === 200) {
      yield put(getOrdersInProgressAsync.success(data));
      return;
    }

    yield put(
      getOrdersInProgressAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getOrdersInProgressAsync.failure(err as Error));
  }
}
export function* getOrdersInProgressSaga() {
  yield takeLatest(
    getType(getOrdersInProgressAsync.request),
    getOrdersInProgress,
  );
}

export const getOrdersInProgressSlice = createSlice({
  initialState: getFetchStateDefaultValue<EntityListOfOrderListItemDto>(false),
  name: "ordersInProgress",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfOrderListItemDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getOrdersInProgressAsync, builder);
    handleSuccessCase(getOrdersInProgressAsync, builder);
    handleFailureCase(getOrdersInProgressAsync, builder);
  },
});

export const { resetSlice } = getOrdersInProgressSlice.actions;
