import { Typography } from "@mui/material";
import { OrderClientStatus, OrderDto, StepType } from "Api/Api";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { OrderDetail } from "Components/Orders/OrderDetail";
import { OrderDraft } from "Components/Orders/OrderDraft";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { getEnterpriseServicesReset } from "State/Enterprises/Services/ServicesSlice";
import { getPaymentCalendarItemsReset } from "State/OrderWorkflow/Slices/GetPaymentCalendarItemsSlice";
import {
  getOrderDetailAsync,
  getOrderDetailReset,
} from "State/Orders/Detail/OrderDetailSlice";
import { getOrderServicesReset } from "State/Orders/Detail/OrderServicesSlice";
import { RootStateType } from "State/Store";
import { useResource, Resources } from "Translations/Resources";
import { cloneDeep } from "lodash-es";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router";

export const DetailPage: React.FunctionComponent = () => {
  const { t } = useResource();
  const params = useParams();
  const { id: publicID } = params;

  const location = useLocation();
  const isCloning =
    new URLSearchParams(location.search).get("isCloning") === "true";

  const dispatch = useDispatch();
  useEffect(() => {
    if (!!publicID) {
      dispatch(
        getOrderDetailAsync.request({
          publicID,
        }),
      );
    }

    return () => {
      dispatch(getOrderDetailReset());
      dispatch(getOrderServicesReset());
      dispatch(getEnterpriseServicesReset());
      dispatch(getPaymentCalendarItemsReset());
    };
  }, [dispatch, publicID]);

  const { data, error } = useSelector((e: RootStateType) => e.order.detail);

  const currentStepType = data?.currentStepType;

  const order = isCloning && !!data ? clone(data) : data;

  return (
    <AuthenticatedLayout
      title={
        isCloning
          ? t(Resources.Orders.Detail.CreateTitle)
          : t(Resources.Orders.Detail.DraftTitle, { number: order?.number })
      }
    >
      <StyledPageWrapper
        $isNarrow={currentStepType === StepType.Draft || isCloning}
      >
        {error?.cause === 404 && (
          <Typography variant="h2" textAlign={"center"}>
            {t(Resources.Orders.Detail.NotFound)}
          </Typography>
        )}
        {!!currentStepType && !!publicID && (
          <>
            {currentStepType === StepType.Draft || isCloning ? (
              <OrderDraft
                publicID={publicID}
                isLoading={false}
                order={order ?? null}
                clonedOrderPublicID={isCloning ? publicID : null}
              />
            ) : (
              <OrderDetail publicID={publicID} />
            )}
          </>
        )}
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

function clone(order: OrderDto): OrderDto {
  const cloned = cloneDeep(order);

  cloned.orderID = 0;
  cloned.publicID = "";
  cloned.clientStatus = OrderClientStatus.Existing;

  return cloned;
}
