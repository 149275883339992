import { createSlice } from "@reduxjs/toolkit";
import {
  SaveEnterpriseProductAdditionalInfoCommandResult,
  SaveEnterpriseProductAdditionalInfoRequest,
  putEnterprisesEnterpriseProductAdditionalInfo,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { getEnterpriseProductAdditionalInfoAsync } from "State/Enterprises/Products/GetEnterpriseProductAdditionalInfo";
import { Resources, getTranslation } from "Translations/Resources";
import { toast } from "sonner";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const putEnterpriseProductAdditionalInfoAsync = createAsyncAction(
  "@cutoff/PUT_ENTERPRISE_PRODUCT_ADDITIONAL_INFO_REQUEST",
  "@cutoff/PUT_ENTERPRISE_PRODUCT_ADDITIONAL_INFO_RESPONSE",
  "@cutoff/PUT_ENTERPRISE_PRODUCT_ADDITIONAL_INFO_FAILURE",
)<
  SaveEnterpriseProductAdditionalInfoRequest,
  SaveEnterpriseProductAdditionalInfoCommandResult,
  Error
>();

function* putEnterpriseProductAdditionalInfo(
  action: ReturnType<typeof putEnterpriseProductAdditionalInfoAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      putEnterprisesEnterpriseProductAdditionalInfo,
      action.payload,
    );

    if (status === 200) {
      toast.success(getTranslation(Resources.Settings.Enterprise.Save.Success));

      yield put(putEnterpriseProductAdditionalInfoAsync.success(data));

      yield put(
        getEnterpriseProductAdditionalInfoAsync.success({
          invoiceForType: action.payload.invoiceForType,
          companyNumber: action.payload.companyNumber,
        }),
      );
      return;
    }

    yield put(
      putEnterpriseProductAdditionalInfoAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(putEnterpriseProductAdditionalInfoAsync.failure(err as Error));
  }
}
export function* putEnterpriseProductAdditionalInfoSaga() {
  yield takeLatest(
    getType(putEnterpriseProductAdditionalInfoAsync.request),
    putEnterpriseProductAdditionalInfo,
  );
}

type putEnterpriseProductAdditionalInfoSliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: putEnterpriseProductAdditionalInfoSliceState = {
  isLoading: false,
  error: null,
};

export const putEnterpriseProductAdditionalInfoSlice = createSlice({
  initialState,
  name: "@cutoff/putEnterpriseProductAdditionalInfo",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(putEnterpriseProductAdditionalInfoAsync.request),
      (
        state,
        action: ReturnType<
          typeof putEnterpriseProductAdditionalInfoAsync.request
        >,
      ) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(putEnterpriseProductAdditionalInfoAsync.success),
      (
        state,
        action: ReturnType<
          typeof putEnterpriseProductAdditionalInfoAsync.success
        >,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(putEnterpriseProductAdditionalInfoAsync.failure),
      (
        state,
        action: ReturnType<
          typeof putEnterpriseProductAdditionalInfoAsync.failure
        >,
      ) => {
        state.isLoading = false;
      },
    );
  },
});
