import {
  ClientPrepaidPaymentApprovalStepInProgressRequest,
  ClientPrepaidPaymentApprovalStepInProgressCommandResult,
  getOrdersWorkflowClientReviewTokenSummary,
  postOrdersWorkflowClientPrepaidPaymentApprovalTokenInProgress,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderDetailAsync } from "State/ClientZone/Orders/GetOrderDetailSlice";

export const clientPrepaidPaymentApprovalInProgressAsync = createAsyncAction(
  "@clientZone/CLIENT_PREPAID_PAYMENT_APPROVAL_INPROGRESS_REQUEST",
  "@clientZone/CLIENT_PREPAID_PAYMENT_APPROVAL_INPROGRESS_RESPONSE",
  "@clientZone/CLIENT_PREPAID_PAYMENT_APPROVAL_INPROGRESS_FAILURE",
)<
  {
    isRevocationDisabled: boolean;
    token: string;
  },
  ClientPrepaidPaymentApprovalStepInProgressCommandResult,
  Error
>();

function* clientPrepaidPaymentApprovalInProgress(
  action: ReturnType<
    typeof clientPrepaidPaymentApprovalInProgressAsync.request
  >,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersWorkflowClientPrepaidPaymentApprovalTokenInProgress,
      {
        userAgent: navigator.userAgent,
        isRevocationDisabled: action.payload.isRevocationDisabled,
      } as ClientPrepaidPaymentApprovalStepInProgressRequest,
      action.payload.token,
    );

    if (status === 200) {
      const { data: orderDetail, status: orderDetailStatus } = yield* call(
        getOrdersWorkflowClientReviewTokenSummary,
        action.payload.token,
      );

      if (!!orderDetail && orderDetailStatus === 200) {
        yield put(getOrderDetailAsync.success(orderDetail));
      }

      yield put(clientPrepaidPaymentApprovalInProgressAsync.success(data));
      return;
    }

    yield put(
      clientPrepaidPaymentApprovalInProgressAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(
      clientPrepaidPaymentApprovalInProgressAsync.failure(err as Error),
    );
  }
}
export function* clientPrepaidPaymentApprovalInProgressSaga() {
  yield takeLatest(
    getType(clientPrepaidPaymentApprovalInProgressAsync.request),
    clientPrepaidPaymentApprovalInProgress,
  );
}

export const clientPrepaidPaymentApprovalInProgressSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<ClientPrepaidPaymentApprovalStepInProgressCommandResult>(
      false,
    ),
  name: "@clientZone/clientPrepaidPaymentApprovalInProgress",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<ClientPrepaidPaymentApprovalStepInProgressCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(clientPrepaidPaymentApprovalInProgressAsync, builder);
    handleSuccessCase(clientPrepaidPaymentApprovalInProgressAsync, builder);
    handleFailureCase(clientPrepaidPaymentApprovalInProgressAsync, builder);
  },
});

export const { resetSlice } =
  clientPrepaidPaymentApprovalInProgressSlice.actions;
