import { Typography } from "@mui/material";
import { useResource, Resources } from "Translations/Resources";
import React from "react";
import styled from "styled-components";

const StyledText = styled.div<{ maxWidth?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(1, 0)};
  opacity: 0.32;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : "unset")};

  ${props => props.theme.breakpoints.down("md")} {
    max-width: unset;
  }
`;

export const Copyright: React.FunctionComponent<{
  maxWidth?: number;
}> = props => {
  const { t } = useResource();

  return (
    <StyledText
      maxWidth={props.maxWidth}
      title={import.meta.env.VITE_VERSION?.replace("PaidAdvisory-", "")}
    >
      <Typography>© {t(Resources.Common.AppName)}</Typography>
    </StyledText>
  );
};
