import { Box, Divider, Typography } from "@mui/material";
import { EnterpriseServiceListItemDto, ServiceType } from "Api/Api";
import { ServiceVariant } from "Components/Settings/Services/ServiceVariant";
import { ServicesFormModel } from "Components/Settings/Services/ServicesForms";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { StyledValidationText } from "Components/Shared/FormStyles";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { get } from "lodash-es";
import { FieldError, UseFormReturn } from "react-hook-form";

type Props = {
  form: UseFormReturn<ServicesFormModel>;
  services: EnterpriseServiceListItemDto[];
};

export const HourRateService: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const { form, services } = props;

  const { setValue, getValues } = form;
  const formServices = form.watch("services");

  const onVariantIsEnabledChange = (value: boolean, serviceIndex: number) => {
    setValue(
      `services.${serviceIndex}.isEnabled`,
      getValues(`services.${serviceIndex}.variants`).some(x => x.isEnabled),
    );
  };

  return (
    <>
      {formServices?.map((formService, serviceIndex) => {
        const service = services.find(
          y => y.serviceSettingID === formService.serviceSettingID,
        );

        if (!service || service.type !== ServiceType.HourRate) {
          return null;
        }

        const fieldError = get(
          form.formState.errors,
          `services.${serviceIndex}.variants`,
        ) as unknown as FieldError;

        return (
          <div key={formService.serviceSettingID}>
            <StyledFlex $alignItems="center" $gap={0.5}>
              <Typography variant="h2">{service.name}</Typography>
              <BlDefaultTooltip
                title={
                  <Box>
                    <Typography mb={1}>
                      {t(
                        Resources.Settings.Services.HourRateService.Tooltip
                          .Description,
                      )}
                    </Typography>
                    <Typography fontWeight={600}>
                      {t(
                        Resources.Settings.Services.HourRateService.Tooltip
                          .StrictModeHeader,
                      )}
                    </Typography>
                    <Typography>
                      {t(
                        Resources.Settings.Services.HourRateService.Tooltip
                          .StrictModeDescription,
                      )}
                    </Typography>
                    <Typography fontWeight={600} mt={1}>
                      {t(
                        Resources.Settings.Services.HourRateService.Tooltip
                          .FreeModeHeader,
                      )}
                    </Typography>
                    <Typography>
                      {t(
                        Resources.Settings.Services.HourRateService.Tooltip
                          .FreeModeDescription,
                      )}
                    </Typography>
                  </Box>
                }
              />
            </StyledFlex>
            {!!service?.description && (
              <Typography
                variant="subtitle2"
                fontStyle={"normal"}
                marginBottom={1}
              >
                {service.description}
              </Typography>
            )}

            <Box paddingLeft={4}>
              {formService.variants.map(formVariant => {
                return (
                  <ServiceVariant
                    key={formVariant.serviceSettingServiceVariantID}
                    serviceSettingServiceVariantID={
                      formVariant.serviceSettingServiceVariantID
                    }
                    form={form}
                    formVariant={formVariant}
                    service={service}
                    onIsEnabledChange={v =>
                      onVariantIsEnabledChange(v, serviceIndex)
                    }
                  />
                );
              })}

              {!!fieldError?.message && (
                <StyledValidationText>
                  {fieldError?.message}
                </StyledValidationText>
              )}
            </Box>
          </div>
        );
      })}

      <Box marginTop={3.5} />
      <Divider />
      <Box marginBottom={3.5} />
    </>
  );
};
