import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { OrdersList } from "Components/Orders/OrdersList";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";

type Props = {};

export const ListPage: React.FunctionComponent<Props> = props => {
  const { t } = useResource();

  return (
    <AuthenticatedLayout title={t(Resources.Orders.List.Title)}>
      <StyledPageWrapper>
        <OrdersList />
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};
