import { Stack, useTheme } from "@mui/material";
import {
  ClientInvoiceStatus,
  OrderPaymentType,
  PaymentCalendarItemDto,
  PaymentCalendarItemStatus,
} from "Api/Api";
import { BlConfirmButton } from "Components/Shared/Buttons/BlConfirmButton";
import { CheckIcon, CrossIcon, FlagIcon } from "Components/Shared/Icons";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { cancelInvoiceForClientByOrderAsync } from "State/Invoice/CancelInvoiceForClientByOrderSlice";
import { payPaymentCalendarItemAsync } from "State/OrderWorkflow/Slices/PayPaymentCalendarItemSlice";
import { paymentCalendarItemPrepaidOrderInvoiceReminderAsync } from "State/OrderWorkflow/Slices/PaymentCalendarItemPrepaidOrderInvoiceReminderSlice";
import { sendAndPayPrepaidPaymentCalendarItemAsync } from "State/OrderWorkflow/Slices/SendAndPayPrepaidPaymentCalendarItemSlice";
import { sendPaymentCalendarItemReminderAsync } from "State/OrderWorkflow/Slices/SendPaymentCalendarItemReminderSlice";
import { Resources, useResource } from "Translations/Resources";
import { fromLocalTimeZoneString } from "Utils/DateUtils";
import { useNavigate } from "react-router";

const PageResources = Resources.Orders.Workflow.Workflow.RecurringPaymentStep;

type Props = {
  status: PaymentCalendarItemStatus;
  paymentCalendarItemID: number;
  paymentType: OrderPaymentType;
  orderPublicID: string;
  invoicePublicID: string | null | undefined;
  invoiceForClientByOrderStatus?: ClientInvoiceStatus | null;
  item: PaymentCalendarItemDto;
};

export const InvoiceIssuedActions: React.FunctionComponent<Props> = props => {
  const {
    status,
    paymentCalendarItemID,
    orderPublicID,
    invoicePublicID,
    paymentType,
    invoiceForClientByOrderStatus,
    item,
  } = props;

  const { t } = useResource();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const isPrepaidOrder = paymentType === OrderPaymentType.Prepaid;

  if (
    (status !== PaymentCalendarItemStatus.SentToClient && !isPrepaidOrder) ||
    (status !== PaymentCalendarItemStatus.Created && isPrepaidOrder) ||
    invoiceForClientByOrderStatus === ClientInvoiceStatus.Canceled
  ) {
    return null;
  }

  return (
    <Stack
      direction={"row"}
      justifyItems={"center"}
      alignItems={"center"}
      justifyContent={"flex-end"}
    >
      {!isPrepaidOrder && (
        <>
          <BlConfirmButton
            variant="default"
            modalTitle={t(PageResources.Pay.Title)}
            modalContent={t(PageResources.Pay.Text)}
            tooltip={t(PageResources.Pay.Title)}
            onResult={r => {
              if (r) {
                dispatch(
                  payPaymentCalendarItemAsync.request({
                    paymentCalendarItemID,
                    orderPublicID: orderPublicID,
                    request: {},
                  }),
                );
              }
            }}
          >
            <CheckIcon
              color={theme.palette.success.main}
              height={18}
              width={18}
            />
          </BlConfirmButton>

          <BlConfirmButton
            variant="default"
            modalTitle={t(PageResources.Remind.Title)}
            modalContent={t(PageResources.Remind.Text)}
            tooltip={t(PageResources.Remind.Title)}
            onResult={r => {
              if (r) {
                dispatch(
                  sendPaymentCalendarItemReminderAsync.request({
                    paymentCalendarItemID,
                    orderPublicID: orderPublicID,
                  }),
                );
              }
            }}
          >
            <FlagIcon color="yellow" />
          </BlConfirmButton>
        </>
      )}

      {isPrepaidOrder && (
        <>
          <BlConfirmButton
            variant="default"
            modalTitle={t(PageResources.SendAndPay.Title)}
            modalContent={t(PageResources.SendAndPay.Text)}
            tooltip={t(PageResources.SendAndPay.Title)}
            onResult={r => {
              if (r) {
                dispatch(
                  sendAndPayPrepaidPaymentCalendarItemAsync.request({
                    paymentCalendarItemID,
                    orderPublicID: orderPublicID,
                    request: {},
                  }),
                );
              }
            }}
          >
            <CheckIcon
              color={theme.palette.success.main}
              height={18}
              width={18}
            />
          </BlConfirmButton>
          {fromLocalTimeZoneString(item.invoiceDate) <= new Date() && (
            <BlConfirmButton
              variant="default"
              modalTitle={t(PageResources.Remind.Title)}
              modalContent={t(PageResources.Remind.Text)}
              tooltip={t(PageResources.Remind.Title)}
              onResult={r => {
                if (r) {
                  dispatch(
                    paymentCalendarItemPrepaidOrderInvoiceReminderAsync.request(
                      {
                        paymentCalendarItemID,
                        orderPublicID: orderPublicID,
                      },
                    ),
                  );
                }
              }}
            >
              <FlagIcon color="yellow" />
            </BlConfirmButton>
          )}
        </>
      )}

      {invoicePublicID &&
        invoiceForClientByOrderStatus === ClientInvoiceStatus.Issued && (
          <BlConfirmButton
            variant="default"
            modalTitle={t(PageResources.CancelInvoice.Title)}
            modalContent={t(PageResources.CancelInvoice.Text)}
            tooltip={t(PageResources.CancelInvoice.Title)}
            onResult={r => {
              if (r) {
                dispatch(
                  cancelInvoiceForClientByOrderAsync.request({
                    invoicePublicID: invoicePublicID!,
                    orderPublicID: orderPublicID,
                    navigate: navigate,
                  }),
                );
              }
            }}
          >
            <CrossIcon color="red" />
          </BlConfirmButton>
        )}
    </Stack>
  );
};
