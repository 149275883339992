import {
  ClientInvoiceRecurringPaymentClientPaidCommandResult,
  ClientInvoiceRecurringPaymentClientPaidRequest,
  postOrdersPublicIDWorkflowClientInvoiceRecurringPaymentPaymentCalendarItemIDPay,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getPaymentCalendarItemsAsync } from "State/OrderWorkflow/Slices/GetPaymentCalendarItemsSlice";

export const payPaymentCalendarItemAsync = createAsyncAction(
  "@orderWorkflow/PAY_PAYMENT_CALENDAR_ITEM_REQUEST",
  "@orderWorkflow/PAY_PAYMENT_CALENDAR_ITEM_RESPONSE",
  "@orderWorkflow/PAY_PAYMENT_CALENDAR_ITEM_FAILURE",
)<
  {
    orderPublicID: string;
    paymentCalendarItemID: number;
    request: ClientInvoiceRecurringPaymentClientPaidRequest;
  },
  ClientInvoiceRecurringPaymentClientPaidCommandResult,
  Error
>();

function* payPaymentCalendarItem(
  action: ReturnType<typeof payPaymentCalendarItemAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowClientInvoiceRecurringPaymentPaymentCalendarItemIDPay,
      action.payload.request,
      action.payload.orderPublicID,
      action.payload.paymentCalendarItemID,
    );

    if (status === 200) {
      yield put(
        getPaymentCalendarItemsAsync.request({
          publicID: action.payload.orderPublicID,
        }),
      );
      yield put(payPaymentCalendarItemAsync.success(data));

      return;
    }

    yield put(
      payPaymentCalendarItemAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(payPaymentCalendarItemAsync.failure(err as Error));
  }
}
export function* payPaymentCalendarItemSaga() {
  yield takeLatest(
    getType(payPaymentCalendarItemAsync.request),
    payPaymentCalendarItem,
  );
}

export const payPaymentCalendarItemSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<ClientInvoiceRecurringPaymentClientPaidCommandResult>(
      false,
    ),
  name: "@orderWorkflow/payPaymentCalendarItemSlice",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<ClientInvoiceRecurringPaymentClientPaidCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(payPaymentCalendarItemAsync, builder);
    handleSuccessCase(payPaymentCalendarItemAsync, builder);
    handleFailureCase(payPaymentCalendarItemAsync, builder);
  },
});

export const { resetSlice } = payPaymentCalendarItemSlice.actions;
