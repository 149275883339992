import { Typography } from "@mui/material";
import {
  OrderDto,
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  StepType,
} from "Api/Api";
import { StyledWorkflowButton } from "Components/Orders/Detail/Steps/WorkflowStep";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { completeInvoiceIssuanceAsync } from "State/OrderWorkflow/Slices/CompleteInvoiceIssuanceSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { useTheme } from "styled-components";

type Props = {
  publicID: string;
  step: OrderWorkflowStepDto;
  steps: OrderWorkflowStepDto[];
  isReadOnly: boolean;
  order: OrderDto | null | undefined;
};

export const ClientInvoiceIssuanceStep: React.FunctionComponent<
  Props
> = props => {
  const { publicID, step, steps, isReadOnly } = props;
  const { t } = useResource();

  const processingServicesStep = steps.find(
    x => x.type === StepType.ProcessingServices,
  );
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(
    e => e.orderWorkflow.completeInvoiceIssuance,
  );
  if (
    step.status === OrderWorkflowStepStatus.Completed ||
    processingServicesStep?.status !== OrderWorkflowStepStatus.Completed
  ) {
    return (
      <>
        {!!props.order?.dateOfInvoiceAfterDueDate && (
          <Typography
            fontWeight={500}
            align="left"
            color={theme.palette.error.main}
          >
            {t(Resources.Common.InvoceAfterDueDate)}{" "}
            {formatDate(props.order.dateOfInvoiceAfterDueDate)}
          </Typography>
        )}

        {step.status === OrderWorkflowStepStatus.Completed &&
          props.order?.isZeroPrice && (
            <Typography
              fontWeight={500}
              align="left"
              color={theme.palette.error.main}
            >
              {t(
                Resources.Orders.Workflow.Workflow.ClientInvoiceIssuanceStep
                  .ZeroPrice,
              )}
            </Typography>
          )}
      </>
    );
  }

  const complete = () => {
    dispatch(
      completeInvoiceIssuanceAsync.request({
        publicID,
      }),
    );
  };

  return (
    <>
      {step.status !== OrderWorkflowStepStatus.Rejected && (
        <>
          <Typography marginBottom={2}>
            {t(
              Resources.Orders.Workflow.Workflow.ClientInvoiceIssuanceStep
                .Description,
            )}
          </Typography>

          <StyledWorkflowButton
            onClick={complete}
            isLoading={isLoading}
            color="primary"
            disabled={isReadOnly}
          >
            {t(
              Resources.Orders.Workflow.Workflow.ClientInvoiceIssuanceStep
                .Complete,
            )}
          </StyledWorkflowButton>
        </>
      )}
    </>
  );
};
