import {
  EntityListOfUpcomingPeriodicOrderDto,
  getOrdersPeriodicUpcoming,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getUpcomingPeriodicOrdersAsync = createAsyncAction(
  "@dashboard/GET_UPCOMING_PERIODIC_REQUEST",
  "@dashboard/GET_UPCOMING_PERIODIC_RESPONSE",
  "@dashboard/GET_UPCOMING_PERIODIC_FAILURE",
)<
  {
    offset: number;
    limit: number;
    dateFrom: string;
  },
  EntityListOfUpcomingPeriodicOrderDto,
  Error
>();

function* getUpcomingPeriodicOrders(
  action: ReturnType<typeof getUpcomingPeriodicOrdersAsync.request>,
): Generator {
  try {
    const { offset, limit, dateFrom } = action.payload;

    const { data, error, status } = yield* call(
      getOrdersPeriodicUpcoming,
      offset,
      limit,
      dateFrom,
    );

    if (status === 200) {
      yield put(getUpcomingPeriodicOrdersAsync.success(data));
      return;
    }

    yield put(
      getUpcomingPeriodicOrdersAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(getUpcomingPeriodicOrdersAsync.failure(err as Error));
  }
}
export function* getUpcomingPeriodicOrdersSaga() {
  yield takeLatest(
    getType(getUpcomingPeriodicOrdersAsync.request),
    getUpcomingPeriodicOrders,
  );
}

export const getUpcomingPeriodicOrdersSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfUpcomingPeriodicOrderDto>(false),
  name: "upcomingPeriodicOrders",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfUpcomingPeriodicOrderDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getUpcomingPeriodicOrdersAsync, builder);
    handleSuccessCase(getUpcomingPeriodicOrdersAsync, builder);
    handleFailureCase(getUpcomingPeriodicOrdersAsync, builder);
  },
});

export const { resetSlice } = getUpcomingPeriodicOrdersSlice.actions;
