import { styled, css } from "styled-components";

export const datePickerFieldStyles = css<{ disabled?: boolean }>`
  .MuiInputBase-root {
    font-size: 16px;
    font-weight: 600;
    padding: ${props => props.theme.spacing(0.5, 1.4)};
    border-radius: ${props => props.theme.borderRadius};

    &:before,
    &:after {
      content: none;
    }

    &:hover {
      border-color: ${props => props.theme.palette.text.primary};
    }

    &.Mui-focused {
      border-color: ${props => props.theme.palette.primary.main};
    }

    .MuiIconButton-root {
      color: ${props => props.theme.palette.primary.main};
      padding: 8px;
    }

    &.Mui-error {
      border-color: ${props => props.theme.palette.error.main};
    }
  }

  .MuiTouchRipple-root {
    color: ${props => props.theme.palette.primary.main};
  }

  .MuiInputBase-input {
    padding: ${props => props.theme.spacing(0.7, 0)};
    &::placeholder {
      color: ${props => props.theme.colors.gray};
      opacity: 1;
    }
  }

  ${props =>
    props.disabled &&
    `
    .MuiInputAdornment-root {
      opacity: 0;
      width: 0;
    }
  `}
`;

export const StyledLabel = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${props => props.theme.palette.secondary.main};
`;
