import { OrderFrequency, OrderPaymentFrequency } from "Api/Api";
import { enumToArray } from "Utils/ObjectUtils";
import { differenceInDays } from "date-fns";

export function orderFrequencyToDays(frequency: OrderFrequency) {
  switch (frequency) {
    case OrderFrequency.Weekly:
      return 7;
    case OrderFrequency.Monthly:
      return 30;
    case OrderFrequency.Quarterly:
      return 90;
    case OrderFrequency.Yearly:
      return 365;
    default:
      return 0;
  }
}

export function getAllowedOrderFrequencies(
  paymentFrequency: OrderPaymentFrequency,
  paymentFrequencyDateFrom: Date | undefined,
  paymentFrequencyDateTo: Date | undefined,
) {
  const baseFrequencies = enumToArray(OrderFrequency);

  if (
    paymentFrequency === OrderPaymentFrequency.Single ||
    !paymentFrequencyDateFrom ||
    !paymentFrequencyDateTo
  ) {
    return baseFrequencies;
  }

  const daysDiff =
    differenceInDays(paymentFrequencyDateTo, paymentFrequencyDateFrom) - 1;

  return baseFrequencies.filter(
    x => orderFrequencyToDays(x as OrderFrequency) < daysDiff,
  );
}
