import { parse } from "date-fns";

export const formatDate = (
  date: Date | string | undefined | null,
  withTime = false,
  exact = false,
) => {
  if (!date) {
    return "-";
  }

  const options: Intl.DateTimeFormatOptions = !withTime
    ? {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }
    : !exact
      ? {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }
      : {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
  const format = new Intl.DateTimeFormat("cs", options);

  return format.format(new Date(date));
};

export const toLocalTimeZoneString = (date: Date | string) => {
  const d = new Date(date);
  const pad = (n: number) => (n < 10 ? "0" + n : n);

  return (
    d.getFullYear() +
    "-" +
    pad(d.getMonth() + 1) +
    "-" +
    pad(d.getDate()) +
    "T" +
    pad(d.getHours()) +
    ":" +
    pad(d.getMinutes()) +
    ":" +
    pad(d.getSeconds()) +
    "." +
    d.getMilliseconds().toString().padStart(3, "0")
  );
};

//"2024-02-23T23:59:59"
export const fromLocalTimeZoneString = (date: string) => {
  return parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date());
};
