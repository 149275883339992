import { AddressDto } from "Api/Api";

export const formatAddressStreet = (
  address: AddressDto | null | undefined,
  withCommaAtEnd: boolean = true,
) => {
  if (!address) {
    return "";
  }

  const orientationNumber = address.orientationNumber
    ? `/${address.orientationNumber}`
    : "";
  return `${address.street ?? ""} ${address.streetNumber}${orientationNumber}${
    withCommaAtEnd ? "," : ""
  }`;
};

export const formatAddressMunicipality = (
  address: AddressDto | null | undefined,
) => {
  return !!address ? `${address.postalCode}, ${address.municipality}` : "";
};
