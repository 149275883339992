import {
  CutoffUserSupplierCompanyListItemDto,
  getCutoffsUserSupplierCompanies,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getCutoffUserSupplierCompaniesAsync = createAsyncAction(
  "@CUTOFFS/GET_CUTOFF_USER_SUPPLIER_LIST_REQUEST",
  "@CUTOFFS/GET_CUTOFF_USER_SUPPLIER_LIST_RESPONSE",
  "@CUTOFFS/GET_CUTOFF_USER_SUPPLIER_LIST_FAILURE",
)<void, CutoffUserSupplierCompanyListItemDto[], Error>();

function* getCutoffUserSupplierCompanies(
  action: ReturnType<typeof getCutoffUserSupplierCompaniesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCutoffsUserSupplierCompanies,
    );

    if (status === 200) {
      yield put(getCutoffUserSupplierCompaniesAsync.success(data));
      return;
    }

    yield put(
      getCutoffUserSupplierCompaniesAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getCutoffUserSupplierCompaniesAsync.failure(err as Error));
  }
}
export function* getCutoffUserSupplierCompaniesSaga() {
  yield takeLatest(
    getType(getCutoffUserSupplierCompaniesAsync.request),
    getCutoffUserSupplierCompanies,
  );
}

export const getCutoffUserSupplierCompaniesSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<CutoffUserSupplierCompanyListItemDto[]>(false),
  name: "getCutoffUserSupplierCompanies",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<CutoffUserSupplierCompanyListItemDto[]>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getCutoffUserSupplierCompaniesAsync, builder);
    handleSuccessCase(getCutoffUserSupplierCompaniesAsync, builder);
    handleFailureCase(getCutoffUserSupplierCompaniesAsync, builder);
  },
});

export const { resetSlice } = getCutoffUserSupplierCompaniesSlice.actions;
