import { Box, Stack, Typography } from "@mui/material";
import { CompanyFormModel } from "Components/Companies/CompanyDetail";
import { StyledHtmlLink } from "Components/Routes/UnstyledLink";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import styled from "styled-components";

const Wrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.fieldBackground};
  border-radius: 12px;
  padding: 15px;
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

type Props = {
  form: UseFormReturn<CompanyFormModel>;
};

const FIO_BANK_CODE = "2010";

export const FioSettings: React.FunctionComponent<Props> = props => {
  const { form } = props;
  const { t } = useResource();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;
  const bankCode = watch("party.bankCode");

  useEffect(() => {
    if (bankCode !== FIO_BANK_CODE) {
      setValue("fioSettings.isEnabled", false);
    }
  }, [bankCode, setValue]);

  const isEnabled = watch("fioSettings.isEnabled");

  const isFio = bankCode === FIO_BANK_CODE;

  return (
    <>
      <BlCheckboxInput
        control={control}
        name={"fioSettings.isEnabled"}
        errors={errors}
        label={t(Resources.FioSettings.IsEnabled.Label)}
        disabled={!isFio}
      />

      {isEnabled && (
        <Wrapper>
          <StyledFlex $alignItems="center" $gap={0.5} $marginBottom={2}>
            <Typography variant="h2">
              {t(Resources.FioSettings.Automation.Title)}
            </Typography>
            <BlDefaultTooltip
              title={t(Resources.FioSettings.Automation.Tooltip)}
            />
          </StyledFlex>

          <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={2}>
            {t(Resources.FioSettings.Automation.Description)}
          </Typography>

          <BlCheckboxInput
            control={control}
            name={"fioSettings.isMatchingPaymentsEnabled"}
            errors={errors}
            label={t(Resources.FioSettings.IsMatchingPaymentsEnabled.Label)}
            disabled={!isFio}
          />

          <Box marginBottom={2} />

          <BlCheckboxInput
            control={control}
            name={"fioSettings.isSendingPaymentsEnabled"}
            errors={errors}
            label={t(Resources.FioSettings.IsSendingPaymentsEnabled.Label)}
            disabled={!isFio}
          />

          <Box marginBottom={2} />

          <Box marginTop={1}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"fioSettings.fioApiKey"}
              label={t(Resources.FioSettings.FioApiKey.Label)}
              disabled={!isFio}
              placeholder={
                "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
              }
            />
          </Box>

          <Stack direction={"row"} gap={"4px"}>
            <Typography variant="subtitle2" fontStyle={"normal"}>
              {t(Resources.FioSettings.Automation.Tutorial.Part1)}
            </Typography>

            <StyledHtmlLink
              href="https://www.fio.cz/bankovni-sluzby/api-bankovnictvi"
              target="_blank"
            >
              <Typography
                variant="subtitle2"
                fontStyle={"normal"}
                fontWeight={"bold"}
                color="primary"
              >
                {t(Resources.FioSettings.Automation.Tutorial.Part2)}
              </Typography>
            </StyledHtmlLink>

            <Typography variant="subtitle2" fontStyle={"normal"}>
              {t(Resources.FioSettings.Automation.Tutorial.Part3)}
            </Typography>
          </Stack>
        </Wrapper>
      )}
    </>
  );
};
