import { createSlice } from "@reduxjs/toolkit";
import { all } from "typed-redux-saga";

const initialState = {
  progress: null,
};

const uploadSlice = createSlice({
  name: "@upload",
  initialState,
  reducers: {
    setFetchProcess(state, action) {
      state.progress = action.payload.progress;
    },
  },
});

export const { setFetchProcess } = uploadSlice.actions;

export const uploadReducer = uploadSlice.reducer;

// Combine all upload-related sagas
export function* uploadSaga() {
  yield all([]);
}
