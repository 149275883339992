import { usePartyName } from "Hooks/Party/usePartyName";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import {
  getPartyDetailAsync,
  resetPartyDetail,
} from "State/Parties/Slices/PartyDetailsSlice";
import { useAppSelector } from "State/Store";
import { Gender, PersonalNumber } from "Utils/PersonalNumberUtils";
import { useEffect } from "react";

const none = {
  party: undefined,
  isLoading: false,
  error: null,
};

export function useParty(publicID: string | null | undefined) {
  const dispatch = useAppDispatch();

  const partyDetail = useAppSelector(
    state => state.party.details[publicID ?? ""] || none,
  );

  useEffect(() => {
    if (!publicID || !!partyDetail?.party || partyDetail?.isLoading) {
      return;
    }

    dispatch(getPartyDetailAsync.request({ publicID }));
  }, [dispatch, publicID, partyDetail?.party, partyDetail?.isLoading]);

  useEffect(() => {
    return () => {
      dispatch(resetPartyDetail({ publicID }));
    };
  }, [dispatch, publicID]);

  const gender =
    PersonalNumber.getInfo(partyDetail?.party?.personalNumber ?? null).Gender ??
    Gender.Male;
  const { formatPartyName } = usePartyName();

  return {
    party: partyDetail.party,
    isLoading: partyDetail.isLoading,
    error: partyDetail.error,
    gender,
    formattedName: formatPartyName(partyDetail.party),
  };
}
