import { Typography } from "@mui/material";
import { OrderDto, PartyType } from "Api/Api";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { useParty } from "Hooks/Party/useParty";
import { Resources, useResource } from "Translations/Resources";
import {
  formatAddressMunicipality,
  formatAddressStreet,
} from "Utils/AddressUtils";
import { formatPersonalNumber } from "Utils/PersonalNumberUtils";
import { formatPhoneNumber } from "Utils/PhoneUtils";
import styled from "styled-components";

const StyledWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.spacing(3, 2.5)};
`;

const StyledText = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
  font-weight: 500;
`;

type Props = { order: OrderDto | null | undefined };

export const Client: React.FunctionComponent<Props> = props => {
  const { order } = props;
  const { t } = useResource();

  const { party: clientParty, formattedName } = useParty(
    order?.clientPartyPublicID,
  );

  const address = clientParty?.addresses?.[0];

  if (!clientParty) {
    return <BlSkeleton />;
  }

  const number =
    clientParty.type === PartyType.NaturalPerson
      ? formatPersonalNumber(clientParty.personalNumber)
      : `IČ: ${clientParty.companyNumber}`;

  return (
    <StyledWrapper>
      <Typography variant="h3" marginBottom={2}>
        {t(Resources.Orders.Client.Title)}
      </Typography>

      <StyledText>{formattedName}</StyledText>

      {!!clientParty.companyRepresentativeFirstName &&
        !!clientParty.companyRepresentativeLastName && (
          <StyledText>
            {`${clientParty.companyRepresentativeFirstName} ${clientParty.companyRepresentativeLastName}`}
          </StyledText>
        )}

      {!!address && (
        <>
          <StyledText>{formatAddressStreet(address)}</StyledText>

          <StyledText>{formatAddressMunicipality(address)}</StyledText>
        </>
      )}

      <StyledText marginTop={1.5}>{number}</StyledText>
      <StyledText>{formatPhoneNumber(clientParty.phone)}</StyledText>
      <StyledText>{clientParty.email}</StyledText>
    </StyledWrapper>
  );
};
