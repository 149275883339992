import {
  EnterpriseDesignSettingsUpdateRequest,
  SaveEnterpriseDesignSettingsCommandResult,
  putEnterprisesDesignSettings,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, select, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { getEnterpriseBasicSettingsAsync } from "State/Enterprises/BasicSettings/EnterpriseBasicSettingsSlice";
import { RootStateType } from "State/Store";
import { enterpriseLogoAsync } from "State/Enterprises/BasicSettings/EnterpriseLogoSlice";
import { toast } from "sonner";
import { getTranslation, Resources } from "Translations/Resources";

export const putEnterpriseDesignSettingsAsync = createAsyncAction(
  "@ENTERPRISE/PUT_DESIGN_SETTINGS_REQUEST",
  "@ENTERPRISE/PUT_DESIGN_SETTINGS_RESPONSE",
  "@ENTERPRISE/PUT_DESIGN_SETTINGS_FAILURE",
)<
  EnterpriseDesignSettingsUpdateRequest,
  SaveEnterpriseDesignSettingsCommandResult,
  Error
>();

function* putEnterpriseDesignSettings(
  action: ReturnType<typeof putEnterpriseDesignSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      putEnterprisesDesignSettings,
      action.payload,
    );
    const settingsState = (yield select(
      (state: RootStateType) => state.enterprise.basicSettings.settings,
    )) as unknown as RootStateType["enterprise"]["basicSettings"]["settings"];

    if (status === 200) {
      toast.success(getTranslation(Resources.Settings.Enterprise.Save.Success));
      yield put(putEnterpriseDesignSettingsAsync.success(data));
      yield put(
        getEnterpriseBasicSettingsAsync.success({
          ...settingsState.data!,
          color: action.payload.color,
        }),
      );

      if (action.payload.logoBase64) {
        yield put(
          enterpriseLogoAsync.success({
            logoBase64: action.payload.logoBase64,
          }),
        );
      }
      return;
    }

    yield put(
      putEnterpriseDesignSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(putEnterpriseDesignSettingsAsync.failure(err as Error));
  }
}
export function* putEnterpriseDesignSettingsSaga() {
  yield takeLatest(
    getType(putEnterpriseDesignSettingsAsync.request),
    putEnterpriseDesignSettings,
  );
}

type EnterpriseDesignSettingsSliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: EnterpriseDesignSettingsSliceState = {
  isLoading: false,
  error: null,
};

export const putEnterpriseDesignSettingsSlice = createSlice({
  initialState,
  name: "putEnterpriseDesignSettings",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(putEnterpriseDesignSettingsAsync.request),
      (
        state,
        action: ReturnType<typeof putEnterpriseDesignSettingsAsync.request>,
      ) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(putEnterpriseDesignSettingsAsync.success),
      (
        state,
        action: ReturnType<typeof putEnterpriseDesignSettingsAsync.success>,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(putEnterpriseDesignSettingsAsync.failure),
      (
        state,
        action: ReturnType<typeof putEnterpriseDesignSettingsAsync.failure>,
      ) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
