import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import {
  StyledContainer,
  StyledDivider,
  StyledWrapper,
} from "Components/Auth/Styles";
import { UnauthenticatedHeader } from "Components/Layout/UnauthenticatedHeader";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPasswordAsync,
  ResetPasswordStep,
  setResetPasswordStep,
} from "State/Auth/Passwords/ResetPasswordSlice";
import { RootStateType } from "State/Store";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { object, ObjectSchema, string } from "yup";

type FormModel = {
  login: string;
};

const StyledForm = styled.form`
  margin-top: ${props => props.theme.spacing(4)};
`;

const StyledSubmitButton = styled(BlButton)`
  margin-top: ${props => props.theme.spacing(3)};
`;

const SuccessMessageWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(4)};
  margin-bottom: ${props => props.theme.spacing(4)};
`;

export const ForgottenPasswordPage: React.FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useDispatch();

  const validationSchema: ObjectSchema<FormModel> = object({
    login: string().required(t(Resources.Validation.Required)),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: { login: "" },
  });

  const onSubmit = (data: FormModel) => {
    dispatch(resetPasswordAsync.request({ login: data.login }));
  };

  const { step, isLoading } = useSelector(
    (e: RootStateType) => e.auth.resetPassword,
  );

  React.useEffect(() => {
    return () => {
      dispatch(setResetPasswordStep(ResetPasswordStep.Email));
    };
  }, [dispatch]);

  return (
    <>
      <UnauthenticatedLayout title={"Zapomenuté heslo"}>
        <StyledWrapper>
          <StyledContainer>
            <div>
              <UnauthenticatedHeader></UnauthenticatedHeader>

              <Typography
                component="h2"
                variant="h2"
                paddingTop={theme => theme.spacing(3)}
              >
                {"Zapomenuté heslo"}
              </Typography>
              {step === ResetPasswordStep.Email && (
                <Typography>
                  {"Zadejte Váš e-mail a zašleme vám odkaz na reset hesla."}
                </Typography>
              )}
            </div>
          </StyledContainer>

          {step === ResetPasswordStep.Email && (
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <StyledContainer>
                <div>
                  <BlFormInput
                    autoFocus
                    control={control}
                    name="login"
                    errors={errors}
                    label="E-mail"
                  />
                </div>
              </StyledContainer>

              <StyledContainer $isGray>
                <div>
                  <StyledSubmitButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    isLoading={isLoading}
                  >
                    {"Zaslat e-mail"}
                  </StyledSubmitButton>
                </div>
              </StyledContainer>
            </StyledForm>
          )}

          {step === ResetPasswordStep.Success && (
            <StyledContainer>
              <div>
                <SuccessMessageWrapper>
                  <Typography>
                    {
                      "Pokud uživatel s daným e-mailem existuje, odeslali jsme na něj pokyny k obnovení hesla."
                    }
                  </Typography>
                </SuccessMessageWrapper>
              </div>
            </StyledContainer>
          )}

          <StyledContainer $isGray={true}>
            <div>
              <StyledDivider></StyledDivider>
              <StyledFlex
                $alignItems={"center"}
                $gap={1}
                $justifyContent={"center"}
              >
                <Typography>{"Vzpomněli jste si na heslo?"}</Typography>

                <UnstyledLink to={getPath(AppRouting.SignIn)}>
                  {"Přihlaste se."}
                </UnstyledLink>
              </StyledFlex>
            </div>
          </StyledContainer>
        </StyledWrapper>
      </UnauthenticatedLayout>
    </>
  );
};

export default ForgottenPasswordPage;
