import { put, takeLatest } from "redux-saga/effects";
import {
  resetSession,
  setUnauthenticatedUrl,
} from "State/Auth/Session/SessionSlice";

function* resetUserFn(action: ReturnType<typeof resetSession>): Generator {
  window.onbeforeunload = null;
  localStorage.clear();
  yield put(setUnauthenticatedUrl(action.payload.authRedirectUrl ?? null));
  //Do not refresh page if user is already sign outed.
  if (!action.payload.authRedirectUrl) {
    window.setTimeout(() => window.location.reload(), 500);
  }
}
export function* resetUserSaga() {
  yield takeLatest(resetSession, resetUserFn);
}
