import { GetOrdersCountQueryResult, getOrdersCount } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getOrderCountsAsync = createAsyncAction(
  "@dashboard/GET_ORDER_COUNTS_REQUEST",
  "@dashboard/GET_ORDER_COUNTS_RESPONSE",
  "@dashboard/GET_ORDER_COUNTS_FAILURE",
)<{ onlyActive: boolean }, GetOrdersCountQueryResult, Error>();

function* getOrderCounts(
  action: ReturnType<typeof getOrderCountsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrdersCount,
      action.payload.onlyActive,
    );

    if (status === 200) {
      yield put(getOrderCountsAsync.success(data));
      return;
    }

    yield put(getOrderCountsAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getOrderCountsAsync.failure(err as Error));
  }
}
export function* getOrderCountSaga() {
  yield takeLatest(getType(getOrderCountsAsync.request), getOrderCounts);
}

export const getOrderCountsSlice = createSlice({
  initialState: getFetchStateDefaultValue<GetOrdersCountQueryResult>(false),
  name: "collection",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<GetOrdersCountQueryResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getOrderCountsAsync, builder);
    handleSuccessCase(getOrderCountsAsync, builder);
    handleFailureCase(getOrderCountsAsync, builder);
  },
});

export const { resetSlice } = getOrderCountsSlice.actions;
