import { createSlice } from "@reduxjs/toolkit";
import { getCutoffsPublicIDISDOCPdfs } from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { Resources, getTranslation } from "Translations/Resources";
import { downloadBase64File } from "Utils/FileUtils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getCutoffPdfsAsync = createAsyncAction(
  "@cutoff/GET_PDFS_REQUEST",
  "@cutoff/GET_PDFS_RESPONSE",
  "@cutoff/GET_PDFS_FAILURE",
)<{ cutoffPublicID: string; period: string }, void, Error>();

function* getCutoffPdfs(
  action: ReturnType<typeof getCutoffPdfsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCutoffsPublicIDISDOCPdfs,
      action.payload.cutoffPublicID,
    );

    if (status === 200) {
      downloadBase64File(
        data.isdocPdfsZipBase64,
        "application/zip",
        `${getTranslation(Resources.Settings.Cutoff.Title)} ${
          action.payload.period
        }.zip`,
      );
      yield put(getCutoffPdfsAsync.success());
      return;
    }

    yield put(getCutoffPdfsAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getCutoffPdfsAsync.failure(err as Error));
  }
}
export function* getCutoffPdfsSaga() {
  yield takeLatest(getType(getCutoffPdfsAsync.request), getCutoffPdfs);
}

type GetCutoffPdfsSliceState = {
  cutoffPublicID: string | null;
  isLoading: boolean;
  error: Error | null;
};

const initialState: GetCutoffPdfsSliceState = {
  cutoffPublicID: null,
  isLoading: false,
  error: null,
};

export const getCutoffPdfsSlice = createSlice({
  initialState,
  name: "getCutoffPdfs",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(getCutoffPdfsAsync.request),
      (state, action: ReturnType<typeof getCutoffPdfsAsync.request>) => {
        state.cutoffPublicID = action.payload.cutoffPublicID;
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(getCutoffPdfsAsync.success),
      (state, action: ReturnType<typeof getCutoffPdfsAsync.success>) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(getCutoffPdfsAsync.failure),
      (state, action: ReturnType<typeof getCutoffPdfsAsync.failure>) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
