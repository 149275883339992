import { EntityListOfOrderListItemDto } from "Api/Api";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { BlDataTable } from "Components/Shared/DataTable/BlDataTable";
import { ExpandArrowIcon } from "Components/Shared/Icons";
import { OrderStatus } from "Components/Shared/OrderStatus";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { formatDate } from "Utils/DateUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useNavigate } from "react-router";
import styled, { useTheme } from "styled-components";

type Props = {
  data: EntityListOfOrderListItemDto;
  onPageChange: (page: number, rows: number) => void;
};

const StyledBlDefaultButton = styled(BlDefaultButton)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;

  svg {
    color: ${props => props.theme.palette.text.secondary};
    width: 12px;
    height: auto;
    transform: rotate(-90deg);
  }
`;

const DateOfInvoiceAfterDueDateBody = styled.span`
  color: ${props => props.theme.palette.error.main};
  padding-right: ${props => props.theme.spacing(1)};
`;

export const Table: React.FunctionComponent<Props> = props => {
  const { data, onPageChange } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useResource();

  return (
    <BlDataTable
      totalCount={data.totalCount}
      rows={data.items.map(e => ({ data: e }))}
      rowEntityID="externalID"
      onRowClick={row => {
        navigate(getPath(AppRouting.OrderDetail, row.externalID));
      }}
      getHeaderCell={column => {
        return <div>{column.label ?? column.id}</div>;
      }}
      rowsPerPage={10}
      onPageChange={onPageChange}
      columns={[
        { id: "clientFormattedName", label: "Klient" },
        { id: "advisorFirstName", label: "Poradce" },
        { id: "dueDate", label: "Datum splatnosti" },
        { id: "number", label: "Číslo objednávky" },
        { id: "priceWithTax", label: "Částka" },
        { id: "priceWithoutTax", label: "Bez DPH" },
        { id: "lastCompleteStepType", label: "Stav" },
        { id: "action", label: "", maxWidth: 0, justifySelf: "flex-end" },
      ]}
      getCell={(column, row) => {
        switch (column) {
          case "clientFormattedName":
            return <span>{row.clientFormattedName}</span>;

          case "advisorFirstName":
            return (
              <span>{`${row.advisorFirstName} ${row.advisorLastName}`}</span>
            );

          case "dueDate":
            return (
              <span>
                {!!row.dateOfInvoiceAfterDueDate ? (
                  <>
                    <StyledFlex $justifyContent="center">
                      <DateOfInvoiceAfterDueDateBody>
                        {formatDate(row.dateOfInvoiceAfterDueDate)}
                      </DateOfInvoiceAfterDueDateBody>
                      <BlDefaultTooltip
                        title={t(Resources.Common.InvoceAfterDueDate)}
                        iconColor={theme.palette.error.main}
                      />
                    </StyledFlex>
                  </>
                ) : !!row.dueDate ? (
                  formatDate(row.dueDate)
                ) : (
                  "-"
                )}
              </span>
            );

          case "number":
            return <span>{row.number}</span>;

          case "priceWithTax":
            return (
              <span>{formatCurrency(row.priceWithTax, row.currencyCode)}</span>
            );

          case "priceWithoutTax":
            return (
              <span>
                {formatCurrency(row.priceWithoutTax, row.currencyCode)}
              </span>
            );

          case "lastCompleteStepType":
            return (
              <OrderStatus
                currentStepType={row.currentStepType}
                currentStepStatus={row.currentStepStatus}
                lastCompleteStepStatus={row.lastCompleteStepStatus}
                lastCompleteStepType={row.lastCompleteStepType}
                areAllStepsCompleted={row.areAllStepsCompleted}
                workflowType={row.workflowType}
              />
            );

          case "action":
            return (
              <UnstyledLink
                to={getPath(AppRouting.OrderDetail, row.externalID)}
              >
                <StyledBlDefaultButton>
                  <ExpandArrowIcon></ExpandArrowIcon>
                </StyledBlDefaultButton>
              </UnstyledLink>
            );

          default:
            const value = (row as any)[column as any] ?? "";

            return <div>{value}</div>;
        }
      }}
    />
  );
};
