import {
  EntityListOfPaymentCalendarClientZoneItemDto,
  getOrdersPublicIDClientZonePaymentCalendarItems,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getPaymentCalendarItemsClientZoneAsync = createAsyncAction(
  "@clientZone/GET_PAYMENT_CALENDAR_REQUEST",
  "@clientZone/GET_PAYMENT_CALENDAR_RESPONSE",
  "@clientZone/GET_PAYMENT_CALENDAR_FAILURE",
)<
  {
    publicID: string;
  },
  EntityListOfPaymentCalendarClientZoneItemDto,
  Error
>();

function* getPaymentCalendarItemsClientZone(
  action: ReturnType<typeof getPaymentCalendarItemsClientZoneAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrdersPublicIDClientZonePaymentCalendarItems,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getPaymentCalendarItemsClientZoneAsync.success(data));
      return;
    }

    yield put(
      getPaymentCalendarItemsClientZoneAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(getPaymentCalendarItemsClientZoneAsync.failure(err as Error));
  }
}
export function* getPaymentCalendarItemsClientZoneSaga() {
  yield takeLatest(
    getType(getPaymentCalendarItemsClientZoneAsync.request),
    getPaymentCalendarItemsClientZone,
  );
}

export const getPaymentCalendarItemsClientZoneSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfPaymentCalendarClientZoneItemDto>(
      false,
    ),
  name: "@clientZone/getPaymentCalendarItems",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfPaymentCalendarClientZoneItemDto>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(getPaymentCalendarItemsClientZoneAsync, builder);
    handleSuccessCase(getPaymentCalendarItemsClientZoneAsync, builder);
    handleFailureCase(getPaymentCalendarItemsClientZoneAsync, builder);
  },
});

export const { resetSlice: getPaymentCalendarItemsReset } =
  getPaymentCalendarItemsClientZoneSlice.actions;
