import { SignInResult } from "Api/Api";
import { isAfter, parseJSON } from "date-fns";
import { RootStateType } from "State/Store";
import { setToken } from "Utils/LocalStorageUtils";
import { AppRouting, getPath, resolveStartPage } from "Utils/UrlUtils";
import { put, select } from "typed-redux-saga";
import { NavigateFunction } from "react-router";
import { trackUserIdentification } from "Utils/TrackingUtils";
import { captureException } from "@sentry/react";
import { setUnauthenticatedUrl } from "State/Auth/Session/SessionSlice";

export const JWT_KEY = "jwtToken";

export function checkIsAuthenticated(tokenExpiration: string | null) {
  if (tokenExpiration === null) {
    return false;
  }
  const utcNow = new Date();
  return isAfter(parseJSON(tokenExpiration), utcNow);
}

export function* afterSignInSuccess(
  response: SignInResult,
  navigate?: NavigateFunction,
) {
  setToken(response);
  trackUserIdentification();
  const { user, unauthenticatedUrl } = yield* select(
    (state: RootStateType) => state.auth.session,
  );

  yield* put(setUnauthenticatedUrl(null));

  if (!navigate) {
    return;
  }

  try {
    const startUrl = resolveStartPage(user, unauthenticatedUrl);

    const url = new URL(
      `${window.location.protocol}//${window.location.host}${startUrl}`,
    );

    navigate(
      {
        pathname: url.pathname,
        search: url.search,
      },
      {
        replace: true,
        state: null,
        relative: "path",
        preventScrollReset: true,
      },
    );
  } catch (e) {
    captureException(e);
    navigate(
      {
        pathname: getPath(AppRouting.SignIn),
      },
      {
        replace: true,
        state: null,
        relative: "path",
        preventScrollReset: true,
      },
    );
  }
}
