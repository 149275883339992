import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { BlDropdown } from "Components/Shared/Inputs/Form/BlDropdown";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import useDebounce from "Hooks/useDebounce";
import { Resources, useResource } from "Translations/Resources";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { BlDateRangePicker } from "Components/Shared/Inputs/DatePickers/BlDateRangePicker";
import { useDispatch, useSelector } from "react-redux";
import { getStepListAsync } from "State/Workflows/Steps/GetStepsSlice";
import { RootStateType } from "State/Store";
import { CodeListItem } from "Models/ICodeListDto";
import { StepType } from "Api/Api";
import { SearchIcon } from "Components/Shared/Icons";
import { Box, Grid } from "@mui/material";
import { BlTooltip } from "Components/Shared/BlTooltip";
import { toLocalTimeZoneString } from "Utils/DateUtils";

export type FilterProps = {
  query: string;
  statuses: string[];
  startDate: string | null;
  endDate: string | null;
  isSearchInStructure: boolean;
  onlyAfterInvoiceDueDate: boolean;
};

type Props = {
  onFilterChange: (filter: FilterProps) => void;
};

const StyledQuery = styled(StyledFlex)`
  .filter-query {
    flex-grow: 1;
    max-width: 650px;
  }
`;
const StyledBlDropdownWrapper = styled.div`
  max-width: 200px;
`;

export const Filter: React.FunctionComponent<Props> = props => {
  const { onFilterChange } = props;
  const { t } = useResource();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStepListAsync.request());
  }, [dispatch]);

  const {
    watch,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      query: "",
      onlyActive: false,
      isOnlyUserOrders: false,
      onlyAfterInvoiceDueDate: false,
      statuses: [] as string[],
      startDate: null as string | null,
      endDate: null as string | null,
    },
  });

  const {
    onlyActive,
    query,
    statuses,
    startDate,
    endDate,
    isOnlyUserOrders,
    onlyAfterInvoiceDueDate,
  } = watch();
  const queryDebounce = useDebounce(query, 500);
  const statusesDebounce = useDebounce(statuses, 50);

  useEffect(() => {
    onFilterChange({
      query: queryDebounce,
      statuses: statusesDebounce,
      startDate: startDate,
      endDate: endDate,
      isSearchInStructure: !isOnlyUserOrders,
      onlyAfterInvoiceDueDate: onlyAfterInvoiceDueDate,
    });
  }, [
    onlyActive,
    queryDebounce,
    statusesDebounce,
    startDate,
    endDate,
    isOnlyUserOrders,
    onFilterChange,
    onlyAfterInvoiceDueDate,
  ]);

  const {
    list: { data: steps },
  } = useSelector((e: RootStateType) => e.workflow);

  const onOnlyActiveToggle = (value: boolean) => {
    if (value) {
      setValue("statuses", [
        StepType.ClientAssignment,
        StepType.ClientReview,
        StepType.ClientApproval,
        StepType.ClientPrepaidPaymentApproval,
        StepType.ProcessingServices,
        StepType.ClientInvoiceIssuance,
        StepType.ClientInvoiceRecurringPayment,
      ]);
    } else {
      setValue("statuses", []);
    }
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={10} sm={6} md={5}>
          <BlFormInput
            control={control}
            errors={errors}
            name={"query"}
            placeholder={t(Resources.Orders.List.Query)}
            hasFilterStyle
            formControlClass="filter-query filter"
            inputStartAdornment={<SearchIcon />}
            maxLength={50}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        marginTop={1}
      >
        <Grid item xs={7} sm={7} md={6}>
          <StyledQuery $alignItems="center" $gap={2} $flexGrow={1}>
            <BlCheckboxInput
              control={control}
              errors={errors}
              name={"onlyActive"}
              label={"Pouze aktivní"}
              hasFilterStyle
              onChange={onOnlyActiveToggle}
            />

            <BlCheckboxInput
              control={control}
              errors={errors}
              name={"isOnlyUserOrders"}
              label={"Pouze mé objednávky"}
              hasFilterStyle
            />

            <BlCheckboxInput
              control={control}
              errors={errors}
              name={"onlyAfterInvoiceDueDate"}
              label={"Pouze po splatnosti"}
              hasFilterStyle
            />
          </StyledQuery>
        </Grid>

        <Grid item>
          <StyledFlex $alignItems="center" $gap={2}>
            <BlTooltip
              title={t(Resources.Orders.List.DateRangeTooltip)}
              placement="top"
            >
              <Box>
                <BlDateRangePicker
                  onChange={range => {
                    setValue(
                      "startDate",
                      !!range.start ? toLocalTimeZoneString(range.start) : null,
                    );
                    setValue(
                      "endDate",
                      !!range.end ? toLocalTimeZoneString(range.end) : null,
                    );
                  }}
                  hasFilterStyle
                />
              </Box>
            </BlTooltip>

            <StyledBlDropdownWrapper>
              <BlDropdown
                multiple
                control={control}
                errors={errors}
                name={"statuses"}
                hasFilterStyle
                codeList={
                  steps?.items?.map(
                    e =>
                      ({
                        code: e.type,
                        name: t(Resources.StepType[e.type]),
                      }) as CodeListItem,
                  ) ?? []
                }
                placeholder="Vyberte stav"
                onChange={() => setValue("onlyActive", false)}
              ></BlDropdown>
            </StyledBlDropdownWrapper>
          </StyledFlex>
        </Grid>
      </Grid>
    </>
  );
};
