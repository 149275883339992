import * as React from "react";
export const ListLayoutIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={17.999}
      viewBox="0 0 18 17.999"
      {...props}
    >
      <path
        d="M-678.578-498.751h-12.343a2.832,2.832,0,0,1-2.828-2.828v-2.057a2.832,2.832,0,0,1,2.828-2.828h12.343a2.832,2.832,0,0,1,2.828,2.828v2.057A2.832,2.832,0,0,1-678.578-498.751Zm-12.343-6.171a1.287,1.287,0,0,0-1.285,1.286v2.057a1.287,1.287,0,0,0,1.285,1.285h12.343a1.287,1.287,0,0,0,1.285-1.285v-2.057a1.287,1.287,0,0,0-1.285-1.286Zm12.343-4.115h-12.343a2.832,2.832,0,0,1-2.828-2.828v-2.057a2.832,2.832,0,0,1,2.828-2.828h12.343a2.832,2.832,0,0,1,2.828,2.828v2.057A2.832,2.832,0,0,1-678.578-509.036Zm-12.343-6.171a1.287,1.287,0,0,0-1.285,1.285v2.057a1.287,1.287,0,0,0,1.285,1.286h12.343a1.287,1.287,0,0,0,1.285-1.286v-2.057a1.287,1.287,0,0,0-1.285-1.285Z"
        transform="translate(693.75 516.75)"
        fill="currentColor"
      />
    </svg>
  );
};
