import { Typography } from "@mui/material";
import {
  AddressType,
  CompanyDto,
  UserSupplierCompanyListItemDto,
} from "Api/Api";
import { Company } from "Components/Companies/List/Company";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getUserCompanyDetailAsync } from "State/Companies/Detail/GetUserCompanyDetailSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";

export const InvoiceCompany: React.FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserCompanyDetailAsync.request());
  }, [dispatch]);

  const { isLoading, data: company } = useAppSelector(
    state => state.company.userCompany,
  );

  const getCompany = (c: CompanyDto): UserSupplierCompanyListItemDto => {
    const address = c.party.addresses.find(
      a => a.type === AddressType.Permanent,
    );

    return {
      companyID: c.companyID,
      publicID: c.publicID,
      companyName: c.party.companyName,
      companyNumber: c.party.companyNumber,
      taxNumber: c.party.taxNumber,
      isDefault: false,
      canBeSelectedToOrderDisabled: false,
      permanentAddress: {
        addressID: address?.addressID ?? 0,
        municipality: address?.municipality ?? "",
        street: address?.street ?? "",
        streetNumber: address?.streetNumber ?? "",
        postalCode: address?.postalCode ?? "",
        type: address?.type ?? AddressType.Permanent,
        orientationNumber: address?.orientationNumber ?? "",
      },
    };
  };

  return (
    <>
      <StyledFlex $gap={0.5} $alignItems="center">
        <Typography variant="h2">
          {t(Resources.Settings.Invoices.Company.Title)}
        </Typography>

        <BlDefaultTooltip
          title={t(Resources.Settings.Invoices.Company.Tooltip)}
        />
      </StyledFlex>

      <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={2}>
        {t(Resources.Settings.Invoices.Company.Description)}
      </Typography>

      {isLoading ? (
        <BlSkeleton height={100} />
      ) : !!company ? (
        <Company company={getCompany(company)} type="user" />
      ) : (
        <UnstyledLink to={getPath(AppRouting.CompanyCreate, "user")}>
          <BlButton>{t(Resources.Settings.Invoices.Company.Add)}</BlButton>
        </UnstyledLink>
      )}
    </>
  );
};
