import { Typography, Box, Divider } from "@mui/material";
import { ServicesFormModel } from "Components/Settings/Services/ServicesForms";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useResource, Resources } from "Translations/Resources";
import { UseFormReturn } from "react-hook-form";

type Props = {
  form: UseFormReturn<ServicesFormModel>;
};

export const ServiceSettings: React.FunctionComponent<Props> = props => {
  const { t } = useResource();

  const {
    form: {
      control,
      watch,
      formState: { errors },
    },
  } = props;

  return (
    <>
      <StyledFlex $alignItems="center" $gap={0.5}>
        <Typography variant="h2">
          {t(Resources.Settings.Services.ServiceSettings.Title)}
        </Typography>
        <BlDefaultTooltip
          title={t(Resources.Settings.Services.ServiceSettings.Tooltip)}
        />
      </StyledFlex>
      <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={2}>
        {t(Resources.Settings.Services.ServiceSettings.Description)}
      </Typography>

      <BlCheckboxInput
        control={control}
        errors={errors}
        name={"serviceSettings.canUserSetPrice"}
        label={t(Resources.Settings.Services.ServiceSettings.CanUserSetPrice)}
      />

      <Box maxWidth={340} marginTop={2}>
        <BlFormInput
          control={control}
          errors={errors}
          name={"serviceSettings.maximalPriceRangeRate"}
          unit="%"
          min={0}
          max={99999}
          isNumber
          mask={Number}
          label={t(
            Resources.Settings.Services.ServiceSettings.MaximalPriceRangeRate,
          )}
          disabled={!watch("serviceSettings.canUserSetPrice")}
        />
      </Box>

      <Box marginTop={2} />
      <Divider />
      <Box marginBottom={3.5} />
    </>
  );
};
