import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Divider } from "@mui/material";
import {
  AddressType,
  CompanyDesignSettingsDto,
  CompanyDto,
  CompanyFioSettingsDto,
  IdentificationType,
  OrderClientStatus,
  PartyType,
  SaveFioSettingsRequest,
  VATPayerOrderTaxChangeType,
} from "Api/Api";
import { BasicInformation } from "Components/Companies/Detail/BasicInformation";
import { FioSettings } from "Components/Companies/Detail/FioSettings";
import { OtherInformation } from "Components/Companies/Detail/OtherInformation";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { PartyFormType } from "Components/Shared/Party/PartyForm";
import {
  PartyFormModel,
  getPartyFormValidationsSchema,
} from "Components/Shared/Party/PartyFormValidationSchema";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import useScrollToError from "Hooks/Form/useScrollToError";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import {
  CompanyEntityType,
  saveCompanyDetailAsync,
} from "State/Companies/Detail/SaveCompanyDetailSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { convertIbanToBankAccount, convertToIban } from "Utils/IbanUtils";
import { nameof } from "Utils/ObjectUtils";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { ObjectSchema, boolean, number, object, string } from "yup";

type Props = {
  type: CompanyEntityType;
  company: CompanyDto | null;
  fioSettings: CompanyFioSettingsDto | null;
  designSettings: CompanyDesignSettingsDto | null;
};

export type CompanyFormModel = PartyFormModel & {
  isDefault?: boolean;
  canBeSelectedToOrderDisabled?: boolean;
  invoiceNumberSeries?: string;
  vatPayerOrderTaxChangeType?: VATPayerOrderTaxChangeType;
  fioSettings?: SaveFioSettingsRequest;
  designSettings?: CompanyDesignSettingsDto;
};

const getDefaultValues = (
  company: CompanyDto | null,
  fioSettings: CompanyFioSettingsDto | null,
  designSettings: CompanyDesignSettingsDto | null,
): CompanyFormModel => {
  const iban = convertIbanToBankAccount(company?.party.iban);

  const permanentAddress = company?.party.addresses.find(
    x => x.type === AddressType.Permanent,
  );

  return {
    invoiceNumberSeries: company?.invoiceNumberSeries?.toString() ?? "1000",
    isDefault: company?.isDefault ?? false,
    canBeSelectedToOrderDisabled:
      company?.canBeSelectedToOrderDisabled ?? false,
    party: {
      status: OrderClientStatus.New,
      type: PartyType.LegalEntity,
      firstName: company?.party.firstName ?? "",
      lastName: company?.party.lastName ?? "",
      taxNumber: company?.party.taxNumber ?? "CZ",
      phone: company?.party.phone ?? "+420",
      email: company?.party.email ?? "",
      companyName: company?.party.companyName ?? "",
      companyNumber: company?.party.companyNumber ?? "",
      isVATPayer: company?.party.isVATPayer ?? false,
      municipality: permanentAddress?.municipality ?? "",
      street: permanentAddress?.street ?? "",
      postalCode: permanentAddress?.postalCode ?? "",
      orientationNumber: permanentAddress?.orientationNumber ?? "",
      streetNumber: permanentAddress?.streetNumber ?? "",
      bankAccountNumber: iban?.accountNumber ?? "",
      bankCode: iban?.bankCode ?? "",
      companyRepresentativeFirstName:
        company?.party?.companyRepresentativeFirstName ?? "",
      companyRepresentativeLastName:
        company?.party?.companyRepresentativeLastName ?? "",
    },
    fioSettings: {
      isEnabled: fioSettings?.isEnabled ?? false,
      isMatchingPaymentsEnabled:
        fioSettings?.isMatchingPaymentsEnabled ?? false,
      isSendingPaymentsEnabled: fioSettings?.isSendingPaymentsEnabled ?? false,
      fioApiKey: fioSettings?.fioApiKey ?? "",
    },
    designSettings: {
      logoBase64: designSettings?.logoBase64 ?? "",
    },
  };
};

export const CompanyDetail: React.FunctionComponent<Props> = props => {
  const { company, fioSettings, type, designSettings } = props;
  const isCreate = !company;
  const dispatch = useAppDispatch();
  const { t } = useResource();
  const navigate = useNavigate();

  const isSaving = useAppSelector(state => state.company.save.isLoading);

  const validationSchema: ObjectSchema<CompanyFormModel> = object({
    party: getPartyFormValidationsSchema(t, true, true, PartyFormType.Company),
    isDefault: boolean().required(),
    canBeSelectedToOrderDisabled: boolean().required(),
    invoiceNumberSeries: string().required(),
    clientID: number().optional(),
    clientIdentification: string<IdentificationType>().optional().nullable(),
    clientIdentificationNumber: string().optional().nullable(),
    vatPayerOrderTaxChangeType: string()
      .oneOf(Object.values(VATPayerOrderTaxChangeType))
      .optional(),
    fioSettings: object({
      isEnabled: boolean().required(),
      isMatchingPaymentsEnabled: boolean().required(),
      isSendingPaymentsEnabled: boolean().required(),
      fioApiKey: string().when([nameof<CompanyFioSettingsDto>("isEnabled")], {
        is: (isEnabled: boolean) => isEnabled,
        then: schema => schema.required(),
        otherwise: schema => schema.optional(),
      }),
    }).required(),
    designSettings: object({
      logoBase64: string().optional(),
    }).required(),
  }).defined();

  const form = useForm<CompanyFormModel>({
    resolver: yupResolver<CompanyFormModel>(validationSchema),
  });
  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = form;

  useScrollToError(errors);

  useEffect(() => {
    reset(getDefaultValues(company, fioSettings, designSettings));
  }, [company, reset, fioSettings, designSettings]);

  const [originalIsVATPayer, setOriginalIsVATPayer] = useState<boolean | null>(
    null,
  );

  useEffect(() => {
    if (!!company) {
      setOriginalIsVATPayer(company.party.isVATPayer ?? false);
    }
  }, [company, isCreate]);

  const isVATPayerOrderTaxChangeTypeShown =
    type === "enterprise" &&
    originalIsVATPayer === false &&
    !!company &&
    form.watch("party.isVATPayer") !== originalIsVATPayer;

  const onSubmit = (data: CompanyFormModel) => {
    dispatch(
      saveCompanyDetailAsync.request({
        isNew: company === null,
        type: type,
        navigate: navigate,
        company: {
          vatPayerOrderTaxChangeType: isVATPayerOrderTaxChangeTypeShown
            ? data.vatPayerOrderTaxChangeType!
            : VATPayerOrderTaxChangeType.None,
          publicID: company?.publicID ?? "",
          companyName: data.party.companyName ?? "",
          companyNumber: data.party.companyNumber ?? "",
          taxNumber: data.party.taxNumber,
          isDefault: type === "enterprise" ? data.isDefault ?? false : false,
          canBeSelectedToOrderDisabled:
            data?.canBeSelectedToOrderDisabled ?? false,
          invoiceNumberSeries: Number(data.invoiceNumberSeries ?? 1000),
          email: data.party.email ?? "",
          iban:
            convertToIban(data.party.bankAccountNumber, data.party.bankCode) ??
            "",
          isVATPayer: data.party.isVATPayer ?? false,
          phone: data.party.phone ?? "",
          address: {
            addressID:
              company?.party.addresses.find(
                x => x.type === AddressType.Permanent,
              )?.addressID ?? 0,
            municipality: data.party.municipality ?? "",
            orientationNumber: data.party.orientationNumber,
            postalCode: data.party.postalCode ?? "",
            street: data.party.street,
            streetNumber: data.party.streetNumber,
            type: AddressType.Permanent,
          },
        },
        fioSettings: data.fioSettings?.isEnabled
          ? data.fioSettings
          : {
              isEnabled: false,
              isMatchingPaymentsEnabled: false,
              isSendingPaymentsEnabled: false,
              fioApiKey: "",
            },
        designSettings: data.designSettings!,
      }),
    );

    setOriginalIsVATPayer(data.party.isVATPayer ?? false);
  };

  return (
    <StyledContentWrapper $fullPadding>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BasicInformation
          isCreate={isCreate}
          type={type}
          form={form}
          isVATPayerOrderTaxChangeTypeShown={isVATPayerOrderTaxChangeTypeShown}
        />
        {type === "enterprise" && <FioSettings form={form} />}

        <OtherInformation
          form={form}
          isDefaultFieldVisible={type === "enterprise"}
          isLogoVisible={type === "enterprise"}
          canBeSelectedToOrderFieldVisible={type === "enterprise"}
        />
        <Box marginTop={2} />
        <Divider />
        <Box marginTop={2} />
        <BlButton type="submit" color={"primary"} isLoading={isSaving}>
          {t(Resources.Common.Save_FirstUppercase)}
        </BlButton>
      </form>
    </StyledContentWrapper>
  );
};
