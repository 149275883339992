import { createSlice } from "@reduxjs/toolkit";
import {
  EntityListOfEnterpriseCommissionSettingsListItemDto,
  getEnterprisesCommissionSettings,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getEnterpriseCommissionSettingsAsync = createAsyncAction(
  "@ENTERPRISE/GET_COMMISSION_SETTINGS_REQUEST",
  "@ENTERPRISE/GET_COMMISSION_SETTINGS_RESPONSE",
  "@ENTERPRISE/GET_COMMISSION_SETTINGS_FAILURE",
)<void, EntityListOfEnterpriseCommissionSettingsListItemDto, Error>();

function* getCommissionSettings(
  action: ReturnType<typeof getEnterpriseCommissionSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getEnterprisesCommissionSettings,
      0,
      100,
    );

    if (status === 200) {
      yield put(getEnterpriseCommissionSettingsAsync.success(data));
      return;
    }

    yield put(
      getEnterpriseCommissionSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(getEnterpriseCommissionSettingsAsync.failure(err as Error));
  }
}
export function* getEnterpriseCommissionSettingsSaga() {
  yield takeLatest(
    getType(getEnterpriseCommissionSettingsAsync.request),
    getCommissionSettings,
  );
}

export const getEnterpriseCommissionSettingsSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfEnterpriseCommissionSettingsListItemDto>(
      false,
    ),
  name: "getEnterpriseCommissionSettings",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfEnterpriseCommissionSettingsListItemDto>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(getEnterpriseCommissionSettingsAsync, builder);
    handleSuccessCase(getEnterpriseCommissionSettingsAsync, builder);
    handleFailureCase(getEnterpriseCommissionSettingsAsync, builder);
  },
});

export const { resetSlice } = getEnterpriseCommissionSettingsSlice.actions;
