import {
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  StepType,
} from "Api/Api";
import {
  StyledStepText,
  StyledWorkflowButton,
} from "Components/Orders/Detail/Steps/WorkflowStep";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { sendClientAssignmentEmailAsync } from "State/OrderWorkflow/Slices/SendClientAssignmentEmailSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { useMemo, useState } from "react";

type Props = {
  publicID: string;
  steps: OrderWorkflowStepDto[];
};

export const ClientAssignmentStep: React.FunctionComponent<Props> = props => {
  const { publicID, steps } = props;
  const { t } = useResource();
  const dispatch = useAppDispatch();

  const canSendReminder = useMemo(() => {
    const clientApprovalStep = steps.find(
      x => x.type === StepType.ClientApproval,
    );
    const clientPrepaidPaymentApproval = steps.find(
      x => x.type === StepType.ClientPrepaidPaymentApproval,
    );
    return (
      clientApprovalStep?.status !== OrderWorkflowStepStatus.Completed &&
      clientApprovalStep?.status !== OrderWorkflowStepStatus.Rejected &&
      clientPrepaidPaymentApproval?.status !==
        OrderWorkflowStepStatus.Completed &&
      clientPrepaidPaymentApproval?.status !== OrderWorkflowStepStatus.Rejected
    );
  }, [steps]);

  const { isLoading: isLoadingsendClientAssignmentEmail } = useAppSelector(
    e => e.orderWorkflow.sendClientAssignmentEmail,
  );
  const [wasSentReminder, setWasSentReminder] = useState(false);

  const sendReminder = () => {
    dispatch(sendClientAssignmentEmailAsync.request({ publicID: publicID }));
    setWasSentReminder(true);
  };

  return (
    <>
      {canSendReminder && (
        <>
          <StyledStepText marginTop={2} marginBottom={2}>
            {t(Resources.Orders.Workflow.Workflow.ClientAssignmentStep.Text)}
          </StyledStepText>

          <StyledWorkflowButton
            color="primary"
            isLoading={isLoadingsendClientAssignmentEmail}
            disabled={wasSentReminder}
            onClick={sendReminder}
          >
            {wasSentReminder
              ? t(
                  Resources.Orders.Workflow.Workflow.ClientAssignmentStep
                    .WasSent,
                )
              : t(Resources.Orders.Workflow.Workflow.ClientAssignmentStep.Send)}
          </StyledWorkflowButton>
        </>
      )}
    </>
  );
};
