import * as React from "react";
import { Stack, Typography } from "@mui/material";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { useResource, Resources } from "Translations/Resources";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { resetSession } from "State/Auth/Session/SessionSlice";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router";
import { AppRouting, getPath } from "Utils/UrlUtils";

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
`;

const StyledIcon = styled(NotInterestedIcon)`
  margin: 20px;
  font-size: 120px;
`;

export const Error403Page: React.FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const signOut = () => {
    dispatch(resetSession({}));
    navigate(getPath(AppRouting.SignIn));
  };

  return (
    <UnauthenticatedLayout title={"403"}>
      <StyledPage>
        <Typography variant="h1">Error 403</Typography>
        <Typography variant="h2">
          {t(Resources.Errors.Error403.Subtitle)}
        </Typography>
        <StyledIcon />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <UnstyledLink to="/">
            <BlButton
              startIcon={<HomeIcon />}
              size="large"
              variant="contained"
              color="primary"
            >
              {t(Resources.Errors.Error404.Home)}
            </BlButton>
          </UnstyledLink>

          <BlButton onClick={signOut}>Odhlásit</BlButton>
        </Stack>
      </StyledPage>
    </UnauthenticatedLayout>
  );
};

export default Error403Page;
