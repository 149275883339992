import {
  EnterpriseServicesUpdateRequest,
  SaveEnterpriseServicesCommandResult,
  putEnterprisesServices as request,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { toast } from "sonner";
import { getTranslation, Resources } from "Translations/Resources";
import { getEnterpriseServicesAsync } from "State/Enterprises/Services/ServicesSlice";
import { getServicePackagesSliceReset } from "State/ServicePackages/Slices/GetServicePackagesSlice";

export const putEnterpriseServicesAsync = createAsyncAction(
  "@ENTERPRISE/PUT_SERVICES_REQUEST",
  "@ENTERPRISE/PUT_SERVICES_RESPONSE",
  "@ENTERPRISE/PUT_SERVICES_FAILURE",
)<
  {
    toastID: string;
    request: EnterpriseServicesUpdateRequest;
  },
  SaveEnterpriseServicesCommandResult,
  Error
>();

function* putEnterpriseServices(
  action: ReturnType<typeof putEnterpriseServicesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      request,
      action.payload.request,
    );

    if (status === 200) {
      toast.success(
        getTranslation(Resources.Settings.Enterprise.Save.Success),
        {
          id: action.payload.toastID,
        },
      );
      yield put(putEnterpriseServicesAsync.success(data));
      yield put(getEnterpriseServicesAsync.request());
      yield put(getServicePackagesSliceReset());
      return;
    }

    yield put(
      putEnterpriseServicesAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(putEnterpriseServicesAsync.failure(err as Error));
  }
}
export function* putEnterpriseServicesSaga() {
  yield takeLatest(
    getType(putEnterpriseServicesAsync.request),
    putEnterpriseServices,
  );
}

type SliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: SliceState = {
  isLoading: false,
  error: null,
};

export const putEnterpriseServicesSlice = createSlice({
  initialState,
  name: "putEnterpriseServices",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(putEnterpriseServicesAsync.request),
      (
        state,
        action: ReturnType<typeof putEnterpriseServicesAsync.request>,
      ) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(putEnterpriseServicesAsync.success),
      (
        state,
        action: ReturnType<typeof putEnterpriseServicesAsync.success>,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(putEnterpriseServicesAsync.failure),
      (
        state,
        action: ReturnType<typeof putEnterpriseServicesAsync.failure>,
      ) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
