import { OrderDto, SaveDraftRequest } from "Api/Api";
import { DraftForm } from "Components/Orders/Draft/DraftForm";
import { FormMode } from "Components/Orders/Draft/StepFormValidationSchema";
import { DraftSummary } from "Components/Orders/Draft/DraftSummary";
import { getUserSupplierCompaniesAsync } from "State/Companies/List/GetUserSupplierCompaniesSlice";
import { getEnterpriseServiceSettingsAsync } from "State/Enterprises/Services/ServiceSettingsSlice";
import { getEnterpriseServicesAsync } from "State/Enterprises/Services/ServicesSlice";
import { getOrderServicesAsync } from "State/Orders/Detail/OrderServicesSlice";
import { Step, setStep } from "State/Orders/Draft/DraftSlice";
import { saveDraftAsync } from "State/Orders/Draft/SaveDraftSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getUserCompanyDetailAsync } from "State/Companies/Detail/GetUserCompanyDetailSlice";
import { useParty } from "Hooks/Party/useParty";
import { getServicePackagesAsync } from "State/ServicePackages/Slices/GetServicePackagesSlice";

type Props = {
  publicID: string | null;
  order: OrderDto | null;
  isLoading: boolean;
  clonedOrderPublicID: string | null;
};

export const OrderDraft: React.FunctionComponent<Props> = props => {
  const { isLoading, order, publicID, clonedOrderPublicID } = props;
  const { step } = useAppSelector(e => e.order.draft);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserSupplierCompaniesAsync.request());
    dispatch(getEnterpriseServicesAsync.request());
    dispatch(getEnterpriseServiceSettingsAsync.request());
    dispatch(getUserCompanyDetailAsync.request());
    dispatch(getServicePackagesAsync.request({ offset: 0, limit: 100 }));
  }, [dispatch]);

  useEffect(() => {
    if (!!publicID) {
      dispatch(getOrderServicesAsync.request({ publicID }));
    }
  }, [dispatch, publicID]);

  const { data: companies } = useAppSelector(e => e.company.list);
  const { data: enterpriseServices } = useAppSelector(
    e => e.enterprise.services,
  );

  const { data: serviceSettings } = useAppSelector(
    e => e.enterprise.serviceSettings,
  );
  const { data: orderServices } = useAppSelector(e => e.order.orderServices);

  const { isLoading: isSavingDraft } = useAppSelector(e => e.order.saveDraft);

  const { data: company, isLoading: isCompanyLoading } = useAppSelector(
    state => state.company.userCompany,
  );
  const { data: servicePackages } = useAppSelector(e => e.servicePackage.list);

  const navigate = useNavigate();

  const submit = (mode: FormMode, saveRequest: SaveDraftRequest) => {
    if (isSavingDraft) {
      return;
    }
    dispatch(
      saveDraftAsync.request({
        mode,
        request: saveRequest,
        navigate,
        clonedOrderPublicID,
      }),
    );
  };

  const { party: clientParty } = useParty(order?.clientPartyPublicID);

  const areOrderServicesLoaded = !order || !!orderServices;
  const isClientPartyLoaded = !order || !!clientParty;

  const { t } = useResource();

  useEffect(() => {
    if (step === Step.Form) {
      document.title = `${t(Resources.Orders.Detail.CreateTitle)} | ${t(
        Resources.Common.AppName,
      )}`;
    }

    if (step === Step.Summary) {
      document.title = `${t(Resources.Orders.Detail.Summary.Title)} | ${t(
        Resources.Common.AppName,
      )}`;
    }
  }, [step, t]);

  useEffect(() => {
    return () => {
      dispatch(setStep(Step.Form));
    };
  }, [dispatch]);

  switch (step) {
    case Step.Summary:
      if (!order) {
        return null;
      }
      return <DraftSummary order={order} />;

    default:
      if (
        !companies ||
        !enterpriseServices ||
        !serviceSettings ||
        !areOrderServicesLoaded ||
        !isClientPartyLoaded ||
        !servicePackages ||
        company === undefined ||
        isCompanyLoading
      ) {
        return null;
      }

      return (
        <DraftForm
          order={order}
          isLoading={isLoading}
          companies={companies}
          enterpriseServices={enterpriseServices}
          serviceSettings={serviceSettings}
          orderServices={orderServices?.items ?? []}
          clientParty={clientParty ?? null}
          onSubmit={submit}
          isSaving={isSavingDraft}
          company={company}
          servicePackages={servicePackages.items}
        />
      );
  }
};
