import { combineReducers } from "@reduxjs/toolkit";
import {
  getCompanyDesignSettingsSaga,
  getCompanyDesignSettingsSlice,
} from "State/Companies/Detail/GetCompanyDesignSettings";
import {
  getCompanyDetailSlice,
  getCompanyDetailSaga,
} from "State/Companies/Detail/GetCompanyDetailSlice";
import {
  getCompanyFioSettingsSaga,
  getCompanyFioSettingsSlice,
} from "State/Companies/Detail/GetCompanyFioSettingsSlice";
import {
  getUserCompanyDetailSlice,
  getUserCompanyDetailSaga,
} from "State/Companies/Detail/GetUserCompanyDetailSlice";
import {
  saveCompanyDetailSlice,
  saveCompanyDetailSaga,
} from "State/Companies/Detail/SaveCompanyDetailSlice";
import {
  getUserSupplierCompaniesSaga,
  getUserSupplierCompaniesSlice,
} from "State/Companies/List/GetUserSupplierCompaniesSlice";
import { all } from "typed-redux-saga";

export const companyReducer = combineReducers({
  list: getUserSupplierCompaniesSlice.reducer,
  detail: getCompanyDetailSlice.reducer,
  save: saveCompanyDetailSlice.reducer,
  userCompany: getUserCompanyDetailSlice.reducer,
  fioSettings: getCompanyFioSettingsSlice.reducer,
  designSettings: getCompanyDesignSettingsSlice.reducer,
});

export function* watchCompanySagas() {
  yield all([
    getUserSupplierCompaniesSaga(),
    getCompanyDetailSaga(),
    saveCompanyDetailSaga(),
    getUserCompanyDetailSaga(),
    getCompanyFioSettingsSaga(),
    getCompanyDesignSettingsSaga(),
  ]);
}
