import { EntityListOfCutoffListItemDto, getCutoffs } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getCutoffsListAsync = createAsyncAction(
  "@cutoff/GET_LIST_REQUEST",
  "@cutoff/GET_LIST_RESPONSE",
  "@cutoff/GET_LIST_FAILURE",
)<{ offset: number; limit: number }, EntityListOfCutoffListItemDto, Error>();

function* getCutoffsList(
  action: ReturnType<typeof getCutoffsListAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCutoffs,
      action.payload.offset,
      action.payload.limit,
    );

    if (status === 200) {
      yield put(getCutoffsListAsync.success(data));
      return;
    }

    yield put(getCutoffsListAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getCutoffsListAsync.failure(err as Error));
  }
}
export function* getCutoffsListSaga() {
  yield takeLatest(getType(getCutoffsListAsync.request), getCutoffsList);
}

export const getCutoffsListSlice = createSlice({
  initialState: getFetchStateDefaultValue<EntityListOfCutoffListItemDto>(false),
  name: "@cutoff/getCutoffsList",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfCutoffListItemDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getCutoffsListAsync, builder);
    handleSuccessCase(getCutoffsListAsync, builder);
    handleFailureCase(getCutoffsListAsync, builder);
  },
});

export const { resetSlice } = getCutoffsListSlice.actions;
