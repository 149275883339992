import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { verifyEmailAsync } from "State/Auth/Verifications/EmailVerificationSlice";

export const EmailVerificationPage: React.FunctionComponent = _ => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!!token) {
      dispatch(verifyEmailAsync.request({ command: { token }, navigate }));
    }
  }, [dispatch, navigate, token]);

  return (
    <UnauthenticatedLayout title="Verifikace e-mailu"></UnauthenticatedLayout>
  );
};

export default EmailVerificationPage;
