import {
  EnterprisePackageServiceSettingsDto,
  ServiceVariantFrequency,
} from "Api/Api";
import { APP_CURRENCY_CODE } from "App";
import { PriceTaxType } from "Constants/PriceTaxType";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import { nameof } from "Utils/ObjectUtils";
import {
  ObjectSchema,
  object,
  string,
  array,
  number,
  boolean,
  TestContext,
  AnyObject,
} from "yup";

const PageResources = Resources.Settings.ServicePackages.Detail.Form;

export type FormModel = {
  name: string;
  description: string;
  services: {
    serviceID: number;
    isSelected: boolean;
    name: string;
    description?: string | null;
  }[];
  variants: VariantFormModel[];
  priceTaxType: PriceTaxType;
};

export type VariantFormModel = {
  name: string;
  priceWithoutTax?: number;
  priceWithTax?: number;
  taxRate: number;
  isSelected: boolean;
  frequency: ServiceVariantFrequency;
  serviceVariantID?: number;
};

export function useValidationSchema(
  serviceSettings: EnterprisePackageServiceSettingsDto,
) {
  const { t } = useResource();

  const validatePrice = (
    value: number,
    priceTaxType: PriceTaxType,
    ctx: TestContext<AnyObject>,
  ) => {
    const frequency = ctx.parent.frequency as ServiceVariantFrequency;

    const taxRate =
      1 + (priceTaxType === PriceTaxType.WithoutTax ? 0 : ctx.parent.taxRate);
    const {
      minimalPriceWithoutTaxSingle,
      maximalPriceWithoutTaxSingle,
      minimalPriceWithoutTaxMonthly,
      maximalPriceWithoutTaxMonthly,
    } = serviceSettings;

    if (frequency === ServiceVariantFrequency.Single) {
      const min = (minimalPriceWithoutTaxSingle ?? 0) * taxRate;

      if (value < min) {
        return ctx.createError({
          message: t(PageResources.Variants.Validation.Range.Min, {
            min: formatCurrency(min, APP_CURRENCY_CODE),
          }),
        });
      }

      const max =
        !!maximalPriceWithoutTaxSingle && maximalPriceWithoutTaxSingle > 0
          ? maximalPriceWithoutTaxSingle * taxRate
          : Number.MAX_SAFE_INTEGER;

      if (value > max) {
        return ctx.createError({
          message: t(PageResources.Variants.Validation.Range.Max, {
            max: formatCurrency(max, APP_CURRENCY_CODE),
          }),
        });
      }

      return true;
    }

    {
      const min = (minimalPriceWithoutTaxMonthly ?? 0) * taxRate;

      if (value < min) {
        return ctx.createError({
          message: t(PageResources.Variants.Validation.Range.Min, {
            min: formatCurrency(min, APP_CURRENCY_CODE),
          }),
        });
      }

      const max =
        !!maximalPriceWithoutTaxMonthly && maximalPriceWithoutTaxMonthly > 0
          ? maximalPriceWithoutTaxMonthly * taxRate
          : Number.MAX_SAFE_INTEGER;

      if (value > max) {
        return ctx.createError({
          message: t(PageResources.Variants.Validation.Range.Max, {
            max: formatCurrency(max, APP_CURRENCY_CODE),
          }),
        });
      }

      return true;
    }
  };

  const validationSchema: ObjectSchema<FormModel> = object({
    name: string().required(t(Resources.Validation.Required)),
    priceTaxType: string<PriceTaxType>().required(),
    description: string().required(t(Resources.Validation.Required)),
    services: array()
      .of(
        object({
          serviceID: number().required(t(Resources.Validation.Required)),
          isSelected: boolean().required(t(Resources.Validation.Required)),
          name: string().required(t(Resources.Validation.Required)),
        }),
      )
      .required(t(Resources.Validation.Required))
      .test("atLeastOneSelected", t(PageResources.Services.Validation), value =>
        value.some((e: { isSelected: boolean }) => e.isSelected),
      ),

    variants: array()
      .of(
        object({
          name: string().required(t(Resources.Validation.Required)),
          priceWithoutTax: number().when(
            nameof<VariantFormModel>("isSelected"),
            {
              is: true,
              then: schema =>
                schema.required().test("isInRange", (value: number, ctx) => {
                  return validatePrice(value, PriceTaxType.WithoutTax, ctx);
                }),
              otherwise: schema => schema.optional(),
            },
          ),
          priceWithTax: number().when(nameof<VariantFormModel>("isSelected"), {
            is: true,
            then: schema =>
              schema.required().test("isInRange", (value: number, ctx) => {
                return validatePrice(value, PriceTaxType.WithTax, ctx);
              }),
            otherwise: schema => schema.optional(),
          }),
          taxRate: number().required(t(Resources.Validation.Required)),
          isSelected: boolean().required(t(Resources.Validation.Required)),
          frequency: string()
            .oneOf(Object.values(ServiceVariantFrequency))
            .required(),
          serviceVariantID: number().optional(),
        }),
      )
      .required(t(Resources.Validation.Required))
      .test(
        "atLeastOneSelected",
        t(PageResources.Variants.Validation.AtLeastOneSelected),
        value => value.some((e: VariantFormModel) => e.isSelected),
      ),
  }).defined();

  return validationSchema;
}
