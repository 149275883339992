import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  EnterprisePackageServiceSettingsDto,
  SaveServicePackageServiceVariant,
  ServiceListItemDto,
  ServicePackageDto,
  ServiceVariantFrequency,
} from "Api/Api";
import { APP_CURRENCY_CODE } from "App";
import {
  FormModel,
  VariantFormModel,
  useValidationSchema,
} from "Components/Settings/Services/ServicePackages/Details/useValidationSchema";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlConfirmButton } from "Components/Shared/Buttons/BlConfirmButton";
import { StyledValidationText } from "Components/Shared/FormStyles";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { BlRadioButtons } from "Components/Shared/Inputs/Form/BlRadioButtons";
import {
  StyledContentWrapper,
  StyledFlex,
} from "Components/Shared/StyledComponents";
import { PriceTaxType } from "Constants/PriceTaxType";
import { calculatePriceByPriceTaxTax, useTaxLabels } from "Hooks/useWithTax";
import { CurrencyCode } from "Models/CurrencyCodes";
import { deleteServicePackageAsync } from "State/ServicePackages/Slices/DeleteServicePackageSlice";
import { saveServicePackageAsync } from "State/ServicePackages/Slices/SaveServicePackage";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency, getCurrencySymbol } from "Utils/CurrencyUtils";
import { enumToCodeList } from "Utils/EnumUtils";
import {
  PRICE_TAX_TYPE_STORAGE_KEY,
  getPriceTaxTypeFromLocalStorage,
} from "Utils/LocalStorageUtils";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";

const FormWrapper = styled(Box)``;

const StyledPriceInputs = styled(Stack)<{ $hasErrors: boolean }>`
  .bl-text-input__unit {
    ${props => (props.$hasErrors ? "top: -18px !important" : "")}
  }
  .input-wrapper {
    width: 140px;
    input {
      text-align: right;
      padding-right: ${props => props.theme.spacing(4)};
    }
  }
`;

const StyledPriceTaxTypRadioWrapper = styled.div``;
const PageResources = Resources.Settings.ServicePackages.Detail.Form;

type Props = {
  servicePackage: ServicePackageDto | null;
  availableServices: ServiceListItemDto[];
  serviceSettings: EnterprisePackageServiceSettingsDto;
};

export const ServicePackageForm: React.FunctionComponent<Props> = props => {
  const { servicePackage, availableServices, serviceSettings } = props;

  const { t } = useResource();

  const validationSchema = useValidationSchema(serviceSettings);

  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: servicePackage?.name ?? "",
      priceTaxType: getPriceTaxTypeFromLocalStorage(),
      description: servicePackage?.description ?? "",
      services: availableServices.map(x => ({
        serviceID: x.serviceID,
        isSelected:
          servicePackage?.relatedServices.some(
            y => y.serviceID === x.serviceID,
          ) ?? false,
        name: x.name,
        description: x.description,
      })),
      variants: getVariants().map(x => {
        const variant = servicePackage?.variants.find(y => y.name === x.name);
        return {
          ...x,
          priceWithoutTax: variant?.priceWithoutTax ?? 0,
          priceWithTax: variant?.priceWithTax ?? 0,
          isSelected: !!variant,
          serviceVariantID: variant?.serviceVariantID ?? undefined,
        };
      }),
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    trigger,
    setValue,
  } = form;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { isLoading: isSaving } = useAppSelector(x => x.servicePackage.save);

  const onSubmit = async () => {
    await trigger();

    handleSubmit((formModel: FormModel) => {
      dispatch(
        saveServicePackageAsync.request({
          request: {
            name: formModel.name,
            description: formModel.description,
            relatedServices: formModel.services
              .filter(x => x.isSelected)
              .map(x => ({
                serviceID: x.serviceID,
              })),
            variants: formModel.variants
              .filter(x => x.isSelected)
              .map(x => {
                const variant: SaveServicePackageServiceVariant = {
                  currencyCode: CurrencyCode.CZK,
                  frequency: x.frequency,
                  isEnabled: x.isSelected,
                  name: x.name,
                  priceWithoutTax: x.priceWithoutTax!,
                  taxRate: x.taxRate,
                  priceWithTax: x.priceWithTax!,
                  serviceVariantID: x.serviceVariantID!,
                };

                return variant;
              }),
          },
          servicePublicID: servicePackage?.servicePublicID ?? null,
          navigate,
        }),
      );
    })();
  };

  const { withTax, withoutTax } = useTaxLabels();

  const onKeyUpPriceWithoutTax = (i: number) => {
    const priceWithTax = calculatePriceByPriceTaxTax(
      watch(`variants.${i}.priceWithoutTax`),
      watch(`variants.${i}.taxRate`),
      PriceTaxType.WithoutTax,
    );
    setValue(`variants.${i}.priceWithTax`, priceWithTax);
  };

  const onKeyUpPriceWithTax = (i: number) => {
    const priceWithoutTax = calculatePriceByPriceTaxTax(
      watch(`variants.${i}.priceWithTax`),
      watch(`variants.${i}.taxRate`),
      PriceTaxType.WithTax,
    );
    setValue(`variants.${i}.priceWithoutTax`, priceWithoutTax);
  };

  return (
    <StyledContentWrapper>
      <FormWrapper>
        <Typography variant="h2">{t(PageResources.Title)}</Typography>

        <Typography variant="subtitle2" fontStyle={"normal"} mb={2}>
          {t(PageResources.FormDescription)}
        </Typography>

        <form>
          <BlFormInput
            name={"name"}
            label={t(PageResources.Name.Label)}
            placeholder={t(PageResources.Name.Placeholder)}
            control={control}
            errors={errors}
            fullWidth={false}
            maxLength={32}
          />

          <Box width={"80%"}>
            <BlFormInput
              name={"description"}
              label={t(PageResources.Description.Label)}
              placeholder={t(PageResources.Description.Placeholder)}
              control={control}
              errors={errors}
              maxLength={128}
            />
          </Box>

          <Typography variant="h2">
            {t(PageResources.Services.Title)}
          </Typography>

          <Typography variant="subtitle2" fontStyle={"normal"} mb={2}>
            {t(PageResources.Services.Description)}
          </Typography>

          <Grid container spacing={2} mb={2}>
            {watch("services").map((e, i) => (
              <Grid item key={i} xs={6}>
                <StyledFlex $gap={0.5} $alignItems="center">
                  <BlCheckboxInput
                    name={`services.${i}.isSelected`}
                    label={watch("services")[i].name}
                    control={control}
                    errors={errors}
                  />
                  {watch("services")[i].description && (
                    <BlDefaultTooltip
                      title={watch("services")[i].description}
                    />
                  )}
                </StyledFlex>
              </Grid>
            ))}
          </Grid>

          <StyledValidationText data-name={"services.root"}>
            {errors.services?.message ?? errors.services?.root?.message}
          </StyledValidationText>

          <Typography variant="h2">
            {t(PageResources.Variants.Title)}
          </Typography>

          <StyledFlex $gap={1}>
            <Typography variant="subtitle2" fontStyle={"normal"}>
              {t(PageResources.Variants.Description)}
            </Typography>
          </StyledFlex>

          <StyledFlex $marginTop={2} $marginBottom={1}>
            <StyledPriceTaxTypRadioWrapper>
              <BlRadioButtons
                control={form.control}
                errors={errors}
                name={"priceTaxType"}
                onChange={value => {
                  localStorage.setItem(PRICE_TAX_TYPE_STORAGE_KEY, value);
                }}
                labelComponent={
                  <>
                    <Typography marginRight={1}>
                      {t(PageResources.PriceTaxType.Label)}
                    </Typography>
                    <BlDefaultTooltip
                      title={t(PageResources.PriceTaxType.Tooltip)}
                    />
                  </>
                }
                codeList={enumToCodeList(
                  PriceTaxType,
                  Resources.Transactions.PriceTaxTypeName,
                )}
              />
            </StyledPriceTaxTypRadioWrapper>
          </StyledFlex>

          <Grid container spacing={2}>
            {watch("variants").map((e, i) => (
              <Grid
                item
                key={i}
                xs={6}
                sx={{
                  paddingTop: "0px !important",
                }}
              >
                <Grid container alignItems={"center"} minHeight={"67px"}>
                  <Grid item xs={4} mb={2}>
                    <BlCheckboxInput
                      name={`variants.${i}.isSelected`}
                      label={watch("variants")[i].name}
                      control={control}
                      errors={errors}
                    />
                  </Grid>

                  {watch("variants")[i].isSelected && (
                    <Grid item gap={1.5}>
                      {watch("priceTaxType") === PriceTaxType.WithoutTax && (
                        <StyledPriceInputs
                          $hasErrors={!!errors.variants?.[i]?.priceWithoutTax}
                          ml={4}
                          direction={"row"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyItems={"center"}
                          gap={1.5}
                        >
                          <Box width={120}>
                            <BlFormInput
                              hasFilterStyle={false}
                              control={control}
                              errors={errors}
                              name={`variants.${i}.priceWithoutTax`}
                              isNumber
                              mask={Number}
                              scale={2}
                              min={0}
                              max={999999}
                              onKeyUp={value => onKeyUpPriceWithoutTax(i)}
                              unit={getCurrencySymbol(APP_CURRENCY_CODE)}
                              formControlClass="no-padding"
                            />
                          </Box>

                          <Typography
                            variant="subtitle2"
                            fontStyle={"normal"}
                            ml={2}
                            mb={!!errors.variants?.[i]?.priceWithoutTax ? 4 : 2}
                          >
                            {withTax(
                              formatCurrency(
                                calculatePriceByPriceTaxTax(
                                  watch(`variants.${i}.priceWithoutTax`),
                                  watch(`variants.${i}.taxRate`),
                                  PriceTaxType.WithoutTax,
                                ),
                                APP_CURRENCY_CODE,
                              ),
                            )}
                          </Typography>
                        </StyledPriceInputs>
                      )}
                      {watch("priceTaxType") === PriceTaxType.WithTax && (
                        <StyledPriceInputs
                          $hasErrors={!!errors.variants?.[i]?.priceWithoutTax}
                          ml={4}
                          direction={"row"}
                          display={"flex"}
                          alignItems={"center"}
                          justifyItems={"center"}
                          gap={1.5}
                        >
                          <Box width={120}>
                            <BlFormInput
                              hasFilterStyle={false}
                              control={control}
                              errors={errors}
                              name={`variants.${i}.priceWithTax`}
                              scale={2}
                              isNumber
                              mask={Number}
                              min={0}
                              max={Math.ceil(999999 * 1.21)}
                              onKeyUp={value => onKeyUpPriceWithTax(i)}
                              unit={getCurrencySymbol(APP_CURRENCY_CODE)}
                              formControlClass="no-padding"
                            />
                          </Box>

                          <Typography
                            variant="subtitle2"
                            fontStyle={"normal"}
                            ml={2}
                            mb={!!errors.variants?.[i]?.priceWithTax ? 4 : 2}
                          >
                            {withoutTax(
                              formatCurrency(
                                calculatePriceByPriceTaxTax(
                                  watch(`variants.${i}.priceWithTax`),
                                  watch(`variants.${i}.taxRate`),
                                  PriceTaxType.WithTax,
                                ),
                                APP_CURRENCY_CODE,
                              ),
                            )}
                          </Typography>
                        </StyledPriceInputs>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>

          <StyledValidationText data-name={"variants.root"}>
            {errors.variants?.root?.message}
          </StyledValidationText>
        </form>

        <Grid container gap={2} mt={4}>
          <Grid item>
            <BlButton color="primary" onClick={onSubmit} isLoading={isSaving}>
              {t(PageResources.Save)}
            </BlButton>
          </Grid>
          <Grid
            item
            visibility={servicePackage === null ? "hidden" : "inherit"}
          >
            <BlConfirmButton
              variant="secondary"
              tooltip={t(Resources.Common.Delete)}
              modalTitle={t(
                Resources.Settings.ServicePackages.List.Delete.ModalTitle,
              )}
              modalContent={t(
                Resources.Settings.ServicePackages.List.Delete.ModalContent,
              )}
              onResult={result => {
                if (result) {
                  if (!!servicePackage?.servicePublicID) {
                    dispatch(
                      deleteServicePackageAsync.request({
                        servicePublicID: servicePackage.servicePublicID,
                      }),
                    );
                  }
                  navigate(
                    getPath(
                      AppRouting.Settings,
                      t(Resources.Settings.ServicePackages.Url),
                    ),
                  );
                }
              }}
            >
              {t(PageResources.Delete.Button)}
            </BlConfirmButton>
          </Grid>
        </Grid>
      </FormWrapper>
    </StyledContentWrapper>
  );
};

function getVariants(): VariantFormModel[] {
  return [
    {
      name: "Normal",
      taxRate: 0.21,
      isSelected: true,
      priceWithoutTax: 0,
      frequency: ServiceVariantFrequency.Single,
    },
    {
      name: "Normal / měsíční",
      taxRate: 0.21,
      isSelected: false,
      priceWithoutTax: 0,
      frequency: ServiceVariantFrequency.Monthly,
    },
    {
      name: "Premium",
      taxRate: 0.21,
      isSelected: false,
      priceWithoutTax: 0,
      frequency: ServiceVariantFrequency.Single,
    },
    {
      name: "Premium / měsíční",
      taxRate: 0.21,
      isSelected: false,
      priceWithoutTax: 0,
      frequency: ServiceVariantFrequency.Monthly,
    },
    {
      name: "VIP",
      taxRate: 0.21,
      isSelected: false,
      priceWithoutTax: 0,
      frequency: ServiceVariantFrequency.Single,
    },
    {
      name: "VIP / měsíční",
      taxRate: 0.21,
      isSelected: false,
      priceWithoutTax: 0,
      frequency: ServiceVariantFrequency.Monthly,
    },
  ];
}
