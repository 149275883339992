import {
  InvoiceIssuanceStepCompleteCommandResult,
  postOrdersPublicIDWorkflowInvoiceIssuanceComplete,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderWorkflowStepsAsync } from "State/OrderWorkflow/Slices/OrderWorkflowStepsSlice";
import { getOrderDetailAsync } from "State/Orders/Detail/OrderDetailSlice";

export const completeInvoiceIssuanceAsync = createAsyncAction(
  "@orderWorkflow/COMPLETE_INVOICE_ISSUANCE_REQUEST",
  "@orderWorkflow/COMPLETE_INVOICE_ISSUANCE_RESPONSE",
  "@orderWorkflow/COMPLETE_INVOICE_ISSUANCE_FAILURE",
)<
  {
    publicID: string;
  },
  InvoiceIssuanceStepCompleteCommandResult,
  Error
>();

function* completeInvoiceIssuance(
  action: ReturnType<typeof completeInvoiceIssuanceAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowInvoiceIssuanceComplete,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(completeInvoiceIssuanceAsync.success(data));
      yield put(
        getOrderWorkflowStepsAsync.request({
          publicID: action.payload.publicID,
        }),
      );
      yield put(
        getOrderDetailAsync.request({
          publicID: action.payload.publicID,
        }),
      );
      return;
    }

    yield put(
      completeInvoiceIssuanceAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(completeInvoiceIssuanceAsync.failure(err as Error));
  }
}
export function* completeInvoiceIssuanceSaga() {
  yield takeLatest(
    getType(completeInvoiceIssuanceAsync.request),
    completeInvoiceIssuance,
  );
}

export const completeInvoiceIssuanceSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<InvoiceIssuanceStepCompleteCommandResult>(false),
  name: "@orderWorkflow/completeInvoiceIssuance",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<InvoiceIssuanceStepCompleteCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(completeInvoiceIssuanceAsync, builder);
    handleSuccessCase(completeInvoiceIssuanceAsync, builder);
    handleFailureCase(completeInvoiceIssuanceAsync, builder);
  },
});

export const { resetSlice } = completeInvoiceIssuanceSlice.actions;
