import { Typography } from "@mui/material";
import { ClientZoneLayout } from "Components/ClientZone/ClientZoneLayout";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { TermsAndConditions } from "Components/TermsAndConditions/TermsAndConditions";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getOrderDetailAsync } from "State/ClientZone/Orders/GetOrderDetailSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useParams } from "react-router";
import { styled } from "styled-components";

const StyledText = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
`;

export const TermsAndConditionsPage: React.FunctionComponent = _ => {
  const { t } = useResource();
  const params = useParams();
  const { clientToken: token, isAdvisorDetail } = params;
  const isAdvisorDetailBool =
    isAdvisorDetail?.toLowerCase().indexOf("true") !== -1;

  const { data: orderDetail } = useAppSelector(e => e.clientZone.orderDetail);
  console.log("isAdvisorDetailBool", isAdvisorDetailBool);
  console.log("isAdvisorDetail", isAdvisorDetail);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!!token && !orderDetail) {
      dispatch(
        getOrderDetailAsync.request({
          token: token,
        }),
      );
    }
  }, [dispatch, token, orderDetail]);

  const goBackUrl = isAdvisorDetailBool
    ? getPath(AppRouting.OrderDetail, orderDetail?.order.publicID!)
    : getPath(AppRouting.ClientZoneOrder, token!);

  return (
    <ClientZoneLayout
      title={t(Resources.ClientZone.Order.TermsAndConditions.Title)}
      companyPublicID={orderDetail?.supplierCompanyPublicID}
      enterprisePublicID={orderDetail?.enterprisePublicID}
    >
      <StyledPageWrapper>
        <PageTitle
          title={t(Resources.ClientZone.Order.TermsAndConditions.Title)}
          goBackUrl={goBackUrl}
        />

        <StyledText marginBottom={2}>
          {t(Resources.ClientZone.Order.TermsAndConditions.Subtitle)}
        </StyledText>

        <TermsAndConditions
          supplierParty={orderDetail?.supplierParty}
          token={token!}
          isAdvisorDetail={isAdvisorDetailBool}
        />
      </StyledPageWrapper>
    </ClientZoneLayout>
  );
};
