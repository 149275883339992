import {
  CancelInvoiceForClientByOrderCommandResult,
  postInvoicesInvoiceForClientByOrderPublicIDCancel,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { NavigateFunction } from "react-router";
import { getPaymentCalendarItemsAsync } from "State/OrderWorkflow/Slices/GetPaymentCalendarItemsSlice";
import { getOrderDetailAsync } from "State/Orders/Detail/OrderDetailSlice";

export const cancelInvoiceForClientByOrderAsync = createAsyncAction(
  "@/CANCEL_INVOICE_FOR_CLIENT_BY_ORDER_REQUEST",
  "@/CANCEL_INVOICE_FOR_CLIENT_BY_ORDER_RESPONSE",
  "@/CANCEL_INVOICE_FOR_CLIENT_BY_ORDER_FAILURE",
)<
  {
    invoicePublicID: string;
    navigate: NavigateFunction;
    orderPublicID: string;
  },
  CancelInvoiceForClientByOrderCommandResult,
  Error
>();

function* cancelInvoiceForClientByOrder(
  action: ReturnType<typeof cancelInvoiceForClientByOrderAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postInvoicesInvoiceForClientByOrderPublicIDCancel,
      action.payload.invoicePublicID,
    );

    if (status === 200) {
      yield put(cancelInvoiceForClientByOrderAsync.success(data));
      yield put(
        getPaymentCalendarItemsAsync.request({
          publicID: action.payload.orderPublicID,
        }),
      );
      yield put(
        getOrderDetailAsync.request({
          publicID: action.payload.orderPublicID,
        }),
      );
      return;
    }

    yield put(
      cancelInvoiceForClientByOrderAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(cancelInvoiceForClientByOrderAsync.failure(err as Error));
  }
}
export function* cancelInvoiceForClientByOrderSaga() {
  yield takeLatest(
    getType(cancelInvoiceForClientByOrderAsync.request),
    cancelInvoiceForClientByOrder,
  );
}

export const cancelInvoiceForClientByOrderSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<CancelInvoiceForClientByOrderCommandResult>(
      false,
    ),
  name: "@/",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<CancelInvoiceForClientByOrderCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(cancelInvoiceForClientByOrderAsync, builder);
    handleSuccessCase(cancelInvoiceForClientByOrderAsync, builder);
    handleFailureCase(cancelInvoiceForClientByOrderAsync, builder);
  },
});

export const { resetSlice } = cancelInvoiceForClientByOrderSlice.actions;
