import { PriceTaxType } from "Constants/PriceTaxType";
import { Resources, useResource } from "Translations/Resources";
import BigNumber from "bignumber.js";

export const useTaxLabels = () => {
  const { t } = useResource();

  return {
    withoutTax: (price: string, isLabelShown: boolean = true) =>
      isLabelShown ? `${price} ${t(Resources.Common.WithoutTax)}` : price,
    withTax: (price: string) => `${price} ${t(Resources.Common.WithTax)}`,
  };
};

export const calculatePriceByPriceTaxTax = (
  price: number | undefined,
  taxRate: number,
  priceTaxType: PriceTaxType,
  decimalPlaces: number = 2,
) => {
  if (priceTaxType === PriceTaxType.WithoutTax) {
    return new BigNumber(price ?? 0)
      .times(1 + taxRate)
      .decimalPlaces(decimalPlaces)
      .toNumber();
  } else {
    return new BigNumber(price ?? 0)
      .dividedBy(1 + taxRate)
      .decimalPlaces(decimalPlaces)
      .toNumber();
  }
};
