import { Typography } from "@mui/material";
import {
  ClientInvoiceStatus,
  EntityListOfOrderWorkflowStepDto,
  OrderDto,
  OrderServiceDto,
} from "Api/Api";
import { CancelInvoiceForClientByOrderWaning } from "Components/Orders/Detail/CancelInvoiceForClientByOrderWaning";
import { WorkflowStep } from "Components/Orders/Detail/Steps/WorkflowStep";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { sortBy } from "lodash-es";

type Props = {
  publicID: string;
  services: OrderServiceDto[] | null | undefined;
  workflowSteps: EntityListOfOrderWorkflowStepDto | null | undefined;
  order: OrderDto | null | undefined;
};

export const Workflow: React.FunctionComponent<Props> = props => {
  const { publicID, services, workflowSteps, order } = props;
  const { t } = useResource();

  if (!workflowSteps) {
    return <BlSkeleton />;
  }

  const workflowStepItems = sortBy(workflowSteps.items, x => x.rank);

  const singlePaymentOrderDateCanceledInvoice =
    order?.isRecurringPayment === false &&
    order.invoices?.[0]?.status === ClientInvoiceStatus.Canceled
      ? order.invoices?.[0].dateCanceled
      : null;

  return (
    <>
      <StyledContentWrapper>
        {singlePaymentOrderDateCanceledInvoice && (
          <CancelInvoiceForClientByOrderWaning
            dateCanceledInvoice={singlePaymentOrderDateCanceledInvoice!}
          />
        )}
        <Typography variant="h3" marginBottom={3}>
          {t(Resources.Orders.Workflow.Workflow.Title)}
        </Typography>
        {workflowStepItems.map((x, index) => (
          <WorkflowStep
            key={x.type}
            publicID={publicID}
            step={x}
            steps={workflowStepItems}
            nextStep={workflowStepItems[index + 1]}
            services={services}
            order={order}
          />
        ))}
      </StyledContentWrapper>
    </>
  );
};
