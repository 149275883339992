import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SignInResult } from "Api/Api";
import { AppUser } from "State/Auth/Models/AuthStateModels";
import { signInAsync } from "State/Auth/SignIn/SignInSlice";
import { signInSsoAsync } from "State/Auth/SignIn/SignInSsoSlice";
import { verifyEmailAsync } from "State/Auth/Verifications/EmailVerificationSlice";
import { fromUnixTime } from "date-fns";
import { getType } from "typesafe-actions";

type SessionSliceState = {
  isAuthenticated: boolean;
  unauthenticatedUrl: string | null;
  tokenExpiration: string | null;
  user: AppUser | null;
};

const initialState: SessionSliceState = {
  isAuthenticated: false,
  unauthenticatedUrl: null,
  user: null,
  tokenExpiration: null,
};

export const sessionSlice = createSlice({
  initialState,
  name: "session",
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setUnauthenticatedUrl: (state, action: PayloadAction<string | null>) => {
      state.unauthenticatedUrl = action.payload;
    },
    resetSession: (
      state,
      action: PayloadAction<{ authRedirectUrl?: string }>,
    ) => {
      state.user = null;
      state.isAuthenticated = false;
      state.tokenExpiration = null;
      state.unauthenticatedUrl = action.payload.authRedirectUrl ?? null;
    },
    extendSignOutDate: state => {
      // state.tokenExpiration = new Date(
      //   new Date().getTime() + 10 * 60 * 1000,
      // ).toISOString();
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getType(signInAsync.success),
      (state, action: ReturnType<typeof signInAsync.success>) => {
        state.user = mapSignInResultToAppUser(action.payload);
        state.isAuthenticated = true;
        state.tokenExpiration = parseJwt(action.payload.token!);
      },
    );
    builder.addCase(
      getType(signInSsoAsync.success),
      (state, action: ReturnType<typeof signInSsoAsync.success>) => {
        state.user = mapSignInResultToAppUser(action.payload);
        state.isAuthenticated = true;
        state.tokenExpiration = parseJwt(action.payload.token!);
      },
    );
    builder.addCase(
      getType(verifyEmailAsync.success),
      (state, action: ReturnType<typeof verifyEmailAsync.success>) => {
        state.user = mapSignInResultToAppUser(action.payload.signInResult);
        state.isAuthenticated = true;
        state.tokenExpiration = parseJwt(action.payload.signInResult.token!);
      },
    );
  },
});

const mapSignInResultToAppUser = (result: SignInResult) => {
  const user: AppUser = {
    login: result.login as string,
    firstName: result.firstName as string,
    lastName: result.lastName as string,
    accessRightCodes: result.accessRightCodes || [],
    isFirstSignIn: result.isFirstSignIn,
    userIdentityProviders: result.userIdentityProviders,
    profilePicture: result.profilePicture ?? null,
    logoUrl: result.logoUrl ?? null,
    color: result.color ?? null,
  };
  return user;
};

function parseJwt(token: string) {
  return fromUnixTime(
    JSON.parse(window.atob(token.split(".")[1])).exp,
  ).toISOString();
}

export const {
  setIsAuthenticated,
  setUnauthenticatedUrl,
  resetSession,
  extendSignOutDate,
} = sessionSlice.actions;
