import { getOrdersPublicID, OrderDto } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getOrderDetailAsync = createAsyncAction(
  "@order/GET_DETAIL_REQUEST",
  "@order/GET_DETAIL_RESPONSE",
  "@order/GET_DETAIL_FAILURE",
)<
  {
    publicID: string;
  },
  OrderDto,
  Error
>();

function* getOrderDetail(
  action: ReturnType<typeof getOrderDetailAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrdersPublicID,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getOrderDetailAsync.success(data));
      return;
    }

    yield put(getOrderDetailAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getOrderDetailAsync.failure(err as Error));
  }
}
export function* getOrderDetailSaga() {
  yield takeLatest(getType(getOrderDetailAsync.request), getOrderDetail);
}

export const getOrderDetailSlice = createSlice({
  initialState: getFetchStateDefaultValue<OrderDto>(false),
  name: "detail",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<OrderDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getOrderDetailAsync, builder);
    handleSuccessCase(getOrderDetailAsync, builder);
    handleFailureCase(getOrderDetailAsync, builder);
  },
});

export const { resetSlice: getOrderDetailReset } = getOrderDetailSlice.actions;
