import {
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  StepType,
} from "Api/Api";
import {
  StyledStepText,
  StyledWorkflowButton,
} from "Components/Orders/Detail/Steps/WorkflowStep";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { sendReminderAsync } from "State/OrderWorkflow/Slices/SendClientReviewReminderSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { addWeeks, isBefore } from "date-fns";
import { useMemo } from "react";

type Props = {
  publicID: string;
  step: OrderWorkflowStepDto;
  steps: OrderWorkflowStepDto[];
  isReadOnly: boolean;
};

export const ClientReviewStep: React.FunctionComponent<Props> = props => {
  const { steps, publicID, isReadOnly } = props;
  const { t } = useResource();

  const canSendReminder = useMemo(() => {
    const assignedToClientStep = steps.find(
      x => x.type === StepType.ClientAssignment,
    );

    if (!assignedToClientStep?.dateProcessed) {
      return false;
    }

    const approvedByClientStep = steps.find(
      x => x.type === StepType.ClientApproval,
    );

    const clientPrepaidPaymentApprovalStep = steps.find(
      x => x.type === StepType.ClientPrepaidPaymentApproval,
    );

    if (
      approvedByClientStep?.status === OrderWorkflowStepStatus.Completed ||
      clientPrepaidPaymentApprovalStep?.status ===
        OrderWorkflowStepStatus.Completed
    ) {
      return false;
    }

    const clientReviewStep = steps.find(x => x.type === StepType.ClientReview);
    const lastEmailSent =
      clientReviewStep?.clientReviewStep?.lastReminderSent ??
      assignedToClientStep.dateProcessed;

    return isBefore(new Date(lastEmailSent), addWeeks(Date.now(), -2));
  }, [steps]);

  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(e => e.orderWorkflow.sendReminder);

  return (
    <>
      {canSendReminder && (
        <>
          <StyledStepText marginTop={2} marginBottom={2}>
            {t(Resources.Orders.Workflow.Workflow.SeenByClientStep.Text)}
          </StyledStepText>

          <StyledWorkflowButton
            color="primary"
            isLoading={isLoading}
            disabled={isReadOnly}
            onClick={() => {
              dispatch(sendReminderAsync.request({ publicID: publicID }));
            }}
          >
            {t(Resources.Orders.Workflow.Workflow.SeenByClientStep.Send)}
          </StyledWorkflowButton>
        </>
      )}
    </>
  );
};
