import { Divider, Typography } from "@mui/material";
import { baseLinkStyles } from "Components/Routes/UnstyledLink";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import styled from "styled-components";

const StyledWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.spacing(3, 2.5)};
  margin-top: ${props => props.theme.spacing(1.5)};
`;

const StyledBox = styled.div`
  display: grid;
  gap: ${props => props.theme.spacing(1)};
`;

export const StyledRowWrapper = styled(StyledFlex)`
  padding: ${props => props.theme.spacing(0, 0.5)};
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing(0.5)};
`;

const StyledInnerBox = styled.div`
  background-color: ${props => props.theme.palette.common.white};
  padding: ${props => props.theme.spacing(1)};
  border-radius: 7px;
`;

const StyledDocumentLink = styled.a`
  ${baseLinkStyles}
  font-size: 12px;
`;

type Props = { token: string };

export const TersmAndConditions: React.FunctionComponent<Props> = props => {
  const { token } = props;

  const { t } = useResource();

  return (
    <>
      <StyledWrapper>
        <Typography variant="h3" marginBottom={2}>
          Dokumenty
        </Typography>

        <StyledBox>
          <StyledInnerBox>
            <StyledRowWrapper>
              <StyledDocumentLink
                href={getPath(
                  AppRouting.ClientZoneTermsAndConditions,
                  token,
                  true,
                )}
                target="_blank"
              >
                {t(Resources.Orders.Detail.Summary.ApprovalTerms)}
              </StyledDocumentLink>
            </StyledRowWrapper>
            <Divider />

            <StyledRowWrapper $marginTop={0.5}>
              <StyledDocumentLink
                href={getPath(AppRouting.ClientZoneGDPR, token, true)}
                target="_blank"
              >
                {t(Resources.Orders.Detail.Summary.GDPR)}
              </StyledDocumentLink>{" "}
            </StyledRowWrapper>
          </StyledInnerBox>
        </StyledBox>
      </StyledWrapper>
    </>
  );
};
