import { BlButton } from "Components/Shared/Buttons/BlButton";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getAllInvoicePdfsAsync } from "State/Invoice/GetAllInvoicePdfsSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  isDisabled: boolean;
};

export const DownloadAllButton: React.FunctionComponent<Props> = props => {
  const { t } = useResource();

  const { isLoading } = useAppSelector(e => e.invoice.allPdf);

  const dispatch = useAppDispatch();

  const downloadAll = () => {
    dispatch(getAllInvoicePdfsAsync.request());
  };

  return (
    <>
      <BlButton
        isLoading={isLoading}
        disabled={props.isDisabled}
        onClick={downloadAll}
      >
        {t(Resources.Settings.Invoices.InvoicesList.DownloadAll.Button)}
      </BlButton>
    </>
  );
};
