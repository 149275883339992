import { Box } from "@mui/material";
import { Header } from "Components/Orders/Detail/Header";
import { PeriodicityBox } from "Components/Orders/Detail/Periodicity/PeriodicityBox";
import { Services } from "Components/Orders/Detail/Services";
import { SetPeriodicityBox } from "Components/Orders/Detail/Periodicity/SetPeriodicityBox";
import { Client } from "Components/Orders/Detail/Client";
import { Workflow } from "Components/Orders/Detail/Workflow";
import { getOrderServicesAsync } from "State/Orders/Detail/OrderServicesSlice";
import { getOrderWorkflowStepsAsync } from "State/OrderWorkflow/Slices/OrderWorkflowStepsSlice";
import { useAppSelector } from "State/Store";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { TersmAndConditions } from "Components/Orders/Detail/TersmAndConditions";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: 0fr;
  gap: ${props => props.theme.spacing(1.5)};
  align-items: flex-start;
`;

type Props = { publicID: string };

export const OrderDetail: React.FunctionComponent<Props> = props => {
  const { publicID } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderWorkflowStepsAsync.request({ publicID }));
    dispatch(getOrderServicesAsync.request({ publicID }));
  }, [dispatch, publicID]);

  const { data: order } = useAppSelector(e => e.order.detail);

  const { data: services } = useAppSelector(e => e.order.orderServices);
  const { data: workflowSteps } = useAppSelector(
    e => e.orderWorkflow.workflowSteps,
  );

  return (
    <>
      <Header order={order} />

      <StyledGrid>
        <div>
          <Client order={order} />

          <Box marginTop={1.5} />

          <Services
            publicID={publicID}
            order={order}
            services={services?.items}
          />
          {!!order?.token && (
            <TersmAndConditions token={order.token!}></TersmAndConditions>
          )}
        </div>
        <div>
          <PeriodicityBox order={order} workflowSteps={workflowSteps} />
          <Workflow
            order={order}
            publicID={publicID}
            services={services?.items}
            workflowSteps={workflowSteps}
          />
          <SetPeriodicityBox order={order} workflowSteps={workflowSteps} />
        </div>
      </StyledGrid>
    </>
  );
};
