import { CodeListItem } from "Models/ICodeListDto";
import { ResourceDictionary, getResourcePath } from "Translations/Resources";
import i18next from "i18next";
import { ObjPathProxy } from "ts-object-path";

export function enumToCodeList<T>(
  enumToConvert: T,
  resourcePath?: ObjPathProxy<ResourceDictionary, T>,
): CodeListItem[] {
  return Object.keys(enumToConvert as any).map(key => ({
    code: key,
    name: resourcePath
      ? i18next.t(`${getResourcePath(resourcePath as any)}.${key}`)
      : key,
  }));
}
