import {
  postOrdersPublicIDOrderServiceOrderServiceIDProductCreate,
  CreateProductInInstitutionCommandResult,
  CreateProductInInstitutionRequest,
  OrderServiceProductInstitutionStatus,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getEucsOrderInfoAsync } from "State/Orders/Detail/EucsOrderInfoSlice";
import { getOrderServicesAsync } from "State/Orders/Detail/OrderServicesSlice";
import { toast } from "sonner";

export const postCreateOrderServiceProductAsync = createAsyncAction(
  "@order/POST_CREATE_ORDER_PRODUCT_SERVICE_REQUEST",
  "@order/POST_CREATE_ORDER_PRODUCT_SERVICE_RESPONSE",
  "@order/POST_CREATE_ORDER_PRODUCT_SERVICE_FAILURE",
)<
  {
    orderPublicID: string;
    orderServiceID: number;
    request: CreateProductInInstitutionRequest;
  },
  CreateProductInInstitutionCommandResult,
  Error
>();

function* postCreateOrderServiceProduct(
  action: ReturnType<typeof postCreateOrderServiceProductAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDOrderServiceOrderServiceIDProductCreate,
      action.payload.request,
      action.payload.orderPublicID,
      action.payload.orderServiceID,
    );

    if (status === 200) {
      yield put(postCreateOrderServiceProductAsync.success(data));

      yield put(
        getOrderServicesAsync.request({
          publicID: action.payload.orderPublicID,
        }),
      );

      if (
        data.status === OrderServiceProductInstitutionStatus.Created ||
        data.status === OrderServiceProductInstitutionStatus.Canceled
      ) {
        yield put(
          getEucsOrderInfoAsync.request({
            orderPublicID: action.payload.orderPublicID,
            orderServiceID: action.payload.orderServiceID,
          }),
        );
      }
      if (
        data.status ===
        OrderServiceProductInstitutionStatus.NotCreatedBecauseTest
      ) {
        toast.error(
          "Smlouva nevytvořena, protože v konfiguraci je nastaveno testovací prostředí.",
        );
      }
      return;
    }

    toast.error("Problém s vytvořením smlouvy u EUCS, zkuste zachvíli.");

    yield put(
      postCreateOrderServiceProductAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(postCreateOrderServiceProductAsync.failure(err as Error));
  }
}
export function* postCreateOrderServiceProductSaga() {
  yield takeLatest(
    getType(postCreateOrderServiceProductAsync.request),
    postCreateOrderServiceProduct,
  );
}

export const postCreateOrderServiceProductSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<CreateProductInInstitutionCommandResult>(false),
  name: "detail",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<CreateProductInInstitutionCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(postCreateOrderServiceProductAsync, builder);
    handleSuccessCase(postCreateOrderServiceProductAsync, builder);
    handleFailureCase(postCreateOrderServiceProductAsync, builder);
  },
});

export const { resetSlice: postCreateOrderServiceProductReset } =
  postCreateOrderServiceProductSlice.actions;
