import {
  EnterprisePackageServiceSettingsUpdateRequest,
  SaveEnterprisePackageServiceSettingsCommandResult,
  putEnterprisesPackageServiceSettings,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { toast } from "sonner";
import { Resources, getTranslation } from "Translations/Resources";

export const savePackageServiceSettingsAsync = createAsyncAction(
  "@servicePackage/SAVE_SETTINGS_REQUEST",
  "@servicePackage/SAVE_SETTINGS_RESPONSE",
  "@servicePackage/SAVE_SETTINGS_FAILURE",
)<
  EnterprisePackageServiceSettingsUpdateRequest,
  SaveEnterprisePackageServiceSettingsCommandResult,
  Error
>();

function* savePackageServiceSettings(
  action: ReturnType<typeof savePackageServiceSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      putEnterprisesPackageServiceSettings,
      action.payload,
    );

    if (status === 200) {
      yield put(savePackageServiceSettingsAsync.success(data));
      toast.success(getTranslation(Resources.Common.SaveSuccess));
      return;
    }

    yield put(
      savePackageServiceSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(savePackageServiceSettingsAsync.failure(err as Error));
  }
}
export function* savePackageServiceSettingsSaga() {
  yield takeLatest(
    getType(savePackageServiceSettingsAsync.request),
    savePackageServiceSettings,
  );
}

export const savePackageServiceSettingsSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<SaveEnterprisePackageServiceSettingsCommandResult>(
      false,
    ),
  name: "@servicePackage/savePackageServiceSettings",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<SaveEnterprisePackageServiceSettingsCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(savePackageServiceSettingsAsync, builder);
    handleSuccessCase(savePackageServiceSettingsAsync, builder);
    handleFailureCase(savePackageServiceSettingsAsync, builder);
  },
});

export const { resetSlice } = savePackageServiceSettingsSlice.actions;
