import { call } from "typed-redux-saga";
import { JWT_KEY } from "Utils/AuthUtils";
import { saveAs } from "file-saver";

export function* downloadFile(route: string, method: "POST" | "GET") {
  return yield* call(() =>
    fetch(route, {
      method: method,
      mode: "cors",
      headers: {
        Authorization: localStorage.getItem(JWT_KEY) as string,
      },
    }).then(e => {
      const fileName = getFileName(e);
      const fileLength = Number(e.headers.get("Content-Length") ?? 0);

      return { fileName, fileLength, stream: e, clone: e.clone() };
    }),
  );
}

export function getFileName(response: Response) {
  const header = response.headers.get("Content-Disposition") || "";

  const utfHeader = "filename*=UTF-8''";
  const utfName = header
    .split("; ")
    .filter(e => e.startsWith("filename"))
    .find(e => e.startsWith(utfHeader));

  if (!!utfName) {
    return decodeURIComponent(utfName.replace(utfHeader, ""));
  }

  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(header || "");
  let fileName = "";
  if (matches != null && matches[1]) {
    fileName = matches[1].replace(/['"]/g, "");
  }

  return fileName;
}

export const getBase64Images = async (fileList: FileList | null) => {
  if (!fileList) return [];

  const files = Array.from(fileList);

  const promises = files.map(e => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(e);
      reader.onload = () => resolve(reader.result?.toString() ?? "");
      reader.onerror = error => reject(error);
    });
  });

  return new Promise<string[]>((resolve, reject) => {
    Promise.all(promises)
      .then(e => resolve(e))
      .catch(e => reject(e));
  });
};

export const downloadBase64File = (
  base64: string,
  contentType: "application/zip" | "application/pdf" | "application/xml",
  fileName: string,
) => {
  const base64toBlob = (contentType: string) => {
    const binaryData = atob(base64);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: contentType });
    return blob;
  };

  const blob = base64toBlob(contentType);

  if (contentType === "application/pdf") {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, "_blank");

    return;
  }

  saveAs(blob, fileName);
};
