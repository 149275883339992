import { deleteServicesServicePublicIDPackage } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { toast } from "sonner";
import { Resources, getTranslation } from "Translations/Resources";
import { getServicePackagesAsync } from "State/ServicePackages/Slices/GetServicePackagesSlice";

export const deleteServicePackageAsync = createAsyncAction(
  "@servicePackage/DELETE_REQUEST",
  "@servicePackage/DELETE_RESPONSE",
  "@servicePackage/DELETE_FAILURE",
)<
  {
    servicePublicID: string;
  },
  void,
  Error
>();

function* deleteServicePackage(
  action: ReturnType<typeof deleteServicePackageAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      deleteServicesServicePublicIDPackage,
      action.payload.servicePublicID,
    );

    if (status === 200) {
      yield put(getServicePackagesAsync.request({ offset: 0, limit: 100 }));
      yield put(deleteServicePackageAsync.success());
      toast.success(
        getTranslation(
          Resources.Settings.ServicePackages.List.Delete.SuccessToast,
        ),
      );
      return;
    }

    yield put(
      deleteServicePackageAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(deleteServicePackageAsync.failure(err as Error));
  }
}
export function* deleteServicePackageSaga() {
  yield takeLatest(
    getType(deleteServicePackageAsync.request),
    deleteServicePackage,
  );
}

export const deleteServicePackageSlice = createSlice({
  initialState: getFetchStateDefaultValue<void>(false),
  name: "@servicePackage/deleteServicePackage",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<void>(false),
  },
  extraReducers: builder => {
    handleRequestCase(deleteServicePackageAsync, builder);
    handleSuccessCase(deleteServicePackageAsync, builder);
    handleFailureCase(deleteServicePackageAsync, builder);
  },
});

export const { resetSlice } = deleteServicePackageSlice.actions;
