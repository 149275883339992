import * as React from "react";
export const ContractRemoveIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={19}
      height={21}
      viewBox="0 0 19 21"
      {...props}
    >
      <path
        d="M2.25 0C1.0095 0 0 1.0095 0 2.25V17.75C0 18.9905 1.0095 20 2.25 20H8.82031C8.39281 19.5555 8.02776 19.0515 7.73926 18.5H2.25C1.8365 18.5 1.5 18.1635 1.5 17.75V2.25C1.5 1.8365 1.8365 1.5 2.25 1.5H8V5.75C8 6.9905 9.0095 8 10.25 8H14.5V9.08496C15.0225 9.16596 15.5265 9.30345 16 9.50195V7.25C16 7.04275 15.916 6.85541 15.7803 6.71973L9.28027 0.219727C9.14459 0.0840391 8.95725 0 8.75 0H2.25ZM9.5 2.56055L13.4395 6.5H10.25C9.8365 6.5 9.5 6.1635 9.5 5.75V2.56055ZM13.5 10C10.4625 10 8 12.4625 8 15.5C8 18.5375 10.4625 21 13.5 21C16.5375 21 19 18.5375 19 15.5C19 12.4625 16.5375 10 13.5 10ZM11 12.5C11.1279 12.5 11.2558 12.5487 11.3535 12.6465L13.5 14.793L15.6465 12.6465C15.842 12.451 16.158 12.451 16.3535 12.6465C16.549 12.842 16.549 13.158 16.3535 13.3535L14.207 15.5L16.3535 17.6465C16.549 17.842 16.549 18.158 16.3535 18.3535C16.256 18.451 16.128 18.5 16 18.5C15.872 18.5 15.744 18.451 15.6465 18.3535L13.5 16.207L11.3535 18.3535C11.256 18.451 11.128 18.5 11 18.5C10.872 18.5 10.744 18.451 10.6465 18.3535C10.451 18.158 10.451 17.842 10.6465 17.6465L12.793 15.5L10.6465 13.3535C10.451 13.158 10.451 12.842 10.6465 12.6465C10.7442 12.5487 10.8721 12.5 11 12.5Z"
        fill="#FF6690"
      />
    </svg>
  );
};
