import { createSlice } from "@reduxjs/toolkit";
import {
  EnterpriseProductAdditionalInfoDto,
  ServiceVariantProduct,
  getEnterprisesEnterpriseProductAdditionalInfoProduct,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getEnterpriseProductAdditionalInfoAsync = createAsyncAction(
  "@cutoff/GET_ENTERPRISE_PRODUCT_ADDITIONAL_INFO_REQUEST",
  "@cutoff/GET_ENTERPRISE_PRODUCT_ADDITIONAL_INFO_RESPONSE",
  "@cutoff/GET_ENTERPRISE_PRODUCT_ADDITIONAL_INFO_FAILURE",
)<
  { product: ServiceVariantProduct },
  EnterpriseProductAdditionalInfoDto,
  Error
>();

function* getEnterpriseProductAdditionalInfo(
  action: ReturnType<typeof getEnterpriseProductAdditionalInfoAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getEnterprisesEnterpriseProductAdditionalInfoProduct,
      action.payload.product,
    );

    if (status === 200) {
      yield put(getEnterpriseProductAdditionalInfoAsync.success(data));

      return;
    }

    yield put(
      getEnterpriseProductAdditionalInfoAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(getEnterpriseProductAdditionalInfoAsync.failure(err as Error));
  }
}
export function* getEnterpriseProductAdditionalInfoSaga() {
  yield takeLatest(
    getType(getEnterpriseProductAdditionalInfoAsync.request),
    getEnterpriseProductAdditionalInfo,
  );
}

export const getEnterpriseProductAdditionalInfoSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EnterpriseProductAdditionalInfoDto>(false),
  name: "@cutoff/getEnterpriseProductAdditionalInfoDto",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EnterpriseProductAdditionalInfoDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getEnterpriseProductAdditionalInfoAsync, builder);
    handleSuccessCase(getEnterpriseProductAdditionalInfoAsync, builder);
    handleFailureCase(getEnterpriseProductAdditionalInfoAsync, builder);
  },
});
