import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  EnterpriseBasicSettingsDto,
  getEnterprisesBasicSettings,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { getFetchStateDefaultValue } from "State/Utils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getEnterpriseBasicSettingsAsync = createAsyncAction(
  "@ENTERPRISE/GET_BASIC_SETTINGS_REQUEST",
  "@ENTERPRISE/GET_BASIC_SETTINGS_RESPONSE",
  "@ENTERPRISE/GET_BASIC_SETTINGS_FAILURE",
)<void, EnterpriseBasicSettingsDto, Error>();

function* enterpriseBasicSettings(
  action: ReturnType<typeof getEnterpriseBasicSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(getEnterprisesBasicSettings);

    if (status === 200) {
      yield put(getEnterpriseBasicSettingsAsync.success(data));
      return;
    }

    yield put(
      getEnterpriseBasicSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(getEnterpriseBasicSettingsAsync.failure(err as Error));
  }
}
export function* enterpriseBasicSettingsSaga() {
  yield takeLatest(
    getType(getEnterpriseBasicSettingsAsync.request),
    enterpriseBasicSettings,
  );
}

export const enterpriseBasicSettingsSlice = createSlice({
  initialState: {
    editedColor: null as string | null,
    settings: getFetchStateDefaultValue<EnterpriseBasicSettingsDto>(false),
  },
  name: "getEnterpriseBasicSettings",
  reducers: {
    resetSlice: () => ({
      editedColor: null,
      settings: getFetchStateDefaultValue<EnterpriseBasicSettingsDto>(false),
    }),
    modifyColor: (state, action: PayloadAction<{ color: string | null }>) => {
      state.editedColor = action.payload.color;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getType(getEnterpriseBasicSettingsAsync.request),
      (
        state,
        action: ReturnType<typeof getEnterpriseBasicSettingsAsync.request>,
      ) => {
        state.settings.error = null;
        state.settings.isLoading = true;
      },
    );
    builder.addCase(
      getType(getEnterpriseBasicSettingsAsync.success),
      (
        state,
        action: ReturnType<typeof getEnterpriseBasicSettingsAsync.success>,
      ) => {
        state.settings.isLoading = false;
        state.settings.data = action.payload;
        state.editedColor = null;
      },
    );
    builder.addCase(
      getType(getEnterpriseBasicSettingsAsync.failure),
      (
        state,
        action: ReturnType<typeof getEnterpriseBasicSettingsAsync.failure>,
      ) => {
        state.settings.isLoading = false;
        state.settings.error = action.payload;
      },
    );
  },
});

const { resetSlice, modifyColor } = enterpriseBasicSettingsSlice.actions;

export const enterpriseBasicSettingsActions = {
  resetSlice,
  modifyColor,
};
