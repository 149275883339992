import { Typography } from "@mui/material";
import { EnterpriseMode } from "Api/Api";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlConfirmDialog } from "Components/Shared/Dialogs/BlConfirmDialog";
import { BlRadioButtons } from "Components/Shared/Inputs/Form/BlRadioButtons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { postEnterpriseChangeModeAsync } from "State/Enterprises/BasicSettings/PostEnterpriseChangeModeSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { enumToCodeList } from "Utils/EnumUtils";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

type FormModel = {
  mode: EnterpriseMode;
};

export const ModeForm: React.FunctionComponent = _ => {
  const dispatch = useAppDispatch();
  const { t } = useResource();
  const basicSettings = useAppSelector(
    state => state.enterprise.basicSettings.settings,
  );
  const isLoading = basicSettings.isLoading && !basicSettings.data;
  const isSaving = useAppSelector(
    x => x.enterprise.postEnterpriseChangeMode.isLoading,
  );

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormModel>({});

  useEffect(() => {
    reset({
      mode: basicSettings.data?.mode ?? EnterpriseMode.Strict,
    });
  }, [reset, basicSettings.data]);

  const resetForm = () => {
    reset({
      mode: basicSettings.data?.mode ?? EnterpriseMode.Strict,
    });
  };

  const onSubmit = (data: FormModel) => {
    if (!isSubmitDisabled) {
      setIsDialogOpen(true);
    }
  };

  const handleDialogResult = (result: boolean) => {
    if (result) {
      dispatch(postEnterpriseChangeModeAsync.request({ mode: formModeValue }));
    }
    setIsDialogOpen(false);
  };

  const formModeValue = watch("mode");
  const isSubmitDisabled = basicSettings.data?.mode === formModeValue;
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledFlex $alignItems="center" $gap={0.5}>
          <Typography variant="h2">
            {t(Resources.Settings.Enterprise.Mode.Title)}
          </Typography>
          <BlDefaultTooltip
            title={
              <>
                <Typography>
                  {t(Resources.Settings.Enterprise.Mode.Tooltip.Text)}
                </Typography>

                <br />
                <Typography fontWeight={700}>
                  {t(Resources.Transactions.EnterpriseMode.Strict)}
                </Typography>
                <Typography>
                  {t(Resources.Settings.Enterprise.Mode.Tooltip.Strict)}
                </Typography>

                <br />
                <Typography fontWeight={700}>
                  {t(Resources.Transactions.EnterpriseMode.Free)}
                </Typography>
                <Typography>
                  {t(Resources.Settings.Enterprise.Mode.Tooltip.Free)}
                </Typography>
              </>
            }
          />
        </StyledFlex>
        <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={1}>
          {t(Resources.Settings.Enterprise.Mode.Description)}
        </Typography>

        {isLoading ? (
          <BlSkeleton height={60} />
        ) : (
          <BlRadioButtons
            control={control}
            errors={errors}
            name={"mode"}
            codeList={enumToCodeList(
              EnterpriseMode,
              Resources.Transactions.EnterpriseMode,
            )}
          />
        )}

        <StyledFlex $alignItems="center" $gap={2}>
          <BlButton
            color="primary"
            type="submit"
            disabled={isSubmitDisabled}
            isLoading={isSaving}
          >
            {t(Resources.Common.Save_FirstUppercase)}
          </BlButton>
          <BlButton type="button" onClick={() => resetForm()}>
            {t(Resources.Common.Reset)}
          </BlButton>
        </StyledFlex>
      </form>

      <BlConfirmDialog
        isOpen={isDialogOpen}
        handleResult={handleDialogResult}
        modalTitle={t(Resources.Settings.Enterprise.Mode.Confirm.Title)}
        modalContent={t(Resources.Settings.Enterprise.Mode.Confirm.Text)}
      />
    </>
  );
};
