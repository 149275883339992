import * as React from "react";
export const RepeatIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={11.575}
      viewBox="0 0 13 11.575"
      {...props}
    >
      <g transform="translate(-1.916 -1.917)">
        <path
          d="M-722.334-535.917a3.894,3.894,0,0,1,1.366-2.9A4.885,4.885,0,0,1-717.733-540h5.664l-.057-.053a.715.715,0,0,1,0-1.06.839.839,0,0,1,1.133,0l1.425,1.333.014.013.006.006.008.008.007.008.006.006.008.009,0,0,.008.01,0,0,.009.01,0,0,.009.011,0,0,.008.011,0,0,.007.01,0,.006.006.009,0,.007,0,.008.006.009,0,.005.007.012v0a.709.709,0,0,1,.091.348.713.713,0,0,1-.111.381h0l-.009.014,0,0-.008.012,0,.005-.006.009-.005.007-.006.007-.006.008,0,.006-.007.009,0,.005-.008.01,0,0-.01.011h0l-.039.039-1.425,1.333a.825.825,0,0,1-.566.22.826.826,0,0,1-.566-.22.715.715,0,0,1,0-1.06l.057-.053h-5.664a3.271,3.271,0,0,0-2.138.773,2.4,2.4,0,0,0-.86,1.81.777.777,0,0,1-.8.75A.778.778,0,0,1-722.334-535.917Z"
          transform="translate(724.25 543.25)"
          fill="currentColor"
        />
        <path
          d="M-720.674-535.387l-1.409-1.319a.726.726,0,0,1-.251-.544.727.727,0,0,1,.249-.544l1.411-1.32a.84.84,0,0,1,1.134,0,.717.717,0,0,1,0,1.06l-.057.053h5.663a3.272,3.272,0,0,0,2.138-.773,2.4,2.4,0,0,0,.86-1.81.778.778,0,0,1,.8-.751.777.777,0,0,1,.8.751,3.9,3.9,0,0,1-1.366,2.9,4.883,4.883,0,0,1-3.234,1.179H-719.6l.057.053a.717.717,0,0,1,0,1.06.827.827,0,0,1-.567.22A.827.827,0,0,1-720.674-535.387Z"
          transform="translate(724.25 548.658)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
