import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Typography } from "@mui/material";
import { OrderFrequency, OrderPeriodicity } from "Api/Api";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { StyledDialog } from "Components/Shared/Dialogs/DialogStyles";
import { CrossIcon } from "Components/Shared/Icons";
import { BlDropdown } from "Components/Shared/Inputs/Form/BlDropdown";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { CodeListItem } from "Models/ICodeListDto";
import { setPeriodicityAsync } from "State/Orders/Detail/SetPeriodicitySlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { enumToArray } from "Utils/ObjectUtils";
import { addMonths } from "date-fns";
import { useForm } from "react-hook-form";
import { styled } from "styled-components";
import { object, string } from "yup";

const StyledInputsWrapper = styled(StyledFlex)`
  & > div {
    flex: 1;
  }

  label {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .Mui-disabled.MuiInputBase-formControl {
    background-color: transparent;

    .MuiInputBase-input {
      color: ${({ theme }) => theme.palette.text.primary};
      -webkit-text-fill-color: ${({ theme }) => theme.palette.text.primary};
      opacity: 0.5;
    }
  }
`;

type FormModel = {
  frequency: OrderFrequency;
};

type Props = {
  publicID: string;
  onClosed: () => void;
};

export const SetPeriodicityDialog: React.FunctionComponent<Props> = props => {
  const { onClosed, publicID } = props;
  const { t } = useResource();
  const dispatch = useAppDispatch();

  const { isLoading: isRunningCutoff, isDraft: isRunningCutoffDraft } =
    useAppSelector(state => state.cutoff.post);

  const {
    control,
    watch,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<FormModel>({
    resolver: yupResolver(
      object({
        frequency: string().oneOf(Object.values(OrderFrequency)).required(),
      }).defined(),
    ),
    defaultValues: {
      frequency: OrderFrequency.Monthly,
    },
  });

  const frequency = watch("frequency");

  const frequencyInMonths =
    frequency === OrderFrequency.Monthly
      ? 1
      : frequency === OrderFrequency.Quarterly
        ? 3
        : 12;

  const save = async () => {
    await trigger();
    handleSubmit((formModel: FormModel) => {
      dispatch(
        setPeriodicityAsync.request({
          publicID,
          request: {
            periodicity: OrderPeriodicity.Periodic,
            frequency: formModel.frequency,
          },
        }),
      );
      onClosed();
    })();
  };

  return (
    <>
      <StyledDialog open={true} onClose={onClosed}>
        <StyledFlex $alignItems="center" $justifyContent="space-between">
          <Typography variant="h2">
            {t(Resources.Orders.Completed.SetRepetitionDialog.Title)}
          </Typography>

          <BlDefaultButton
            title={t(Resources.Common.Close)}
            onClick={() => onClosed()}
          >
            <CrossIcon />
          </BlDefaultButton>
        </StyledFlex>

        <Divider sx={{ marginTop: 1, marginBottom: 2 }} />

        <div>
          <StyledFlex $gap={0.5} $alignItems="center">
            <Typography variant="subtitle2" fontStyle={"normal"}>
              {t(Resources.Orders.Completed.SetRepetitionDialog.Title)}
            </Typography>
          </StyledFlex>

          <StyledInputsWrapper
            $gap={1}
            $marginTop={2.5}
            $marginBottom={1}
            $flexDirection="column"
          >
            <BlDropdown
              control={control}
              errors={errors}
              name={"frequency"}
              codeList={enumToArray(OrderFrequency).map(
                x =>
                  ({
                    code: x,
                    name: t(Resources.OrderFrequency[x]),
                  }) as CodeListItem,
              )}
              formControlClass="no-padding flex-1"
            />

            <Typography variant="subtitle2">
              {t(Resources.Orders.Detail.Periodicity.Disclaimer, {
                date: formatDate(addMonths(new Date(), frequencyInMonths)),
              })}
            </Typography>
          </StyledInputsWrapper>

          <StyledFlex $alignItems="center" $marginBottom={1} $marginTop={2}>
            <BlButton
              color="primary"
              fullWidth
              isLoading={isRunningCutoff && !isRunningCutoffDraft}
              onClick={() => save()}
            >
              {t(Resources.Common.Save_FirstUppercase)}
            </BlButton>
          </StyledFlex>
        </div>
      </StyledDialog>
    </>
  );
};
