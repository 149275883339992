import { createSlice } from "@reduxjs/toolkit";
import { getCutoffsPublicIDDetailPdf } from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { Resources, getTranslation } from "Translations/Resources";
import { downloadBase64File } from "Utils/FileUtils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const getCutoffDetailPdfAsync = createAsyncAction(
  "@cutoff/GET_DETAIL_PDF_REQUEST",
  "@cutoff/GET_DETAIL_PDF_RESPONSE",
  "@cutoff/GET_DETAIL_PDF_FAILURE",
)<{ cutoffPublicID: string; period: string }, void, Error>();

function* getCutoffDetailPdf(
  action: ReturnType<typeof getCutoffDetailPdfAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCutoffsPublicIDDetailPdf,
      action.payload.cutoffPublicID,
    );

    if (status === 200) {
      downloadBase64File(
        data.cutoffDetailPdfBase64,
        "application/pdf",
        `${getTranslation(Resources.Settings.Cutoff.Title)}-${
          action.payload.period
        }.pdf`,
      );
      yield put(getCutoffDetailPdfAsync.success());
      return;
    }

    yield put(
      getCutoffDetailPdfAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getCutoffDetailPdfAsync.failure(err as Error));
  }
}
export function* getCutoffDetailPdfSaga() {
  yield takeLatest(
    getType(getCutoffDetailPdfAsync.request),
    getCutoffDetailPdf,
  );
}

type GetCutoffDetailPdfSliceState = {
  cutoffPublicID: string | null;
  isLoading: boolean;
  error: Error | null;
};

const initialState: GetCutoffDetailPdfSliceState = {
  cutoffPublicID: null,
  isLoading: false,
  error: null,
};

export const getCutoffDetailPdfSlice = createSlice({
  initialState,
  name: "@cutoff/getCutoffDetailPdf",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(getCutoffDetailPdfAsync.request),
      (state, action: ReturnType<typeof getCutoffDetailPdfAsync.request>) => {
        state.cutoffPublicID = action.payload.cutoffPublicID;
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(getCutoffDetailPdfAsync.success),
      (state, action: ReturnType<typeof getCutoffDetailPdfAsync.success>) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(getCutoffDetailPdfAsync.failure),
      (state, action: ReturnType<typeof getCutoffDetailPdfAsync.failure>) => {
        state.cutoffPublicID = null;
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
