import { Typography } from "@mui/material";
import {
  OrderDto,
  OrderPaymentFrequency,
  OrderServiceDto,
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  RelationType,
  StepType,
} from "Api/Api";
import { ClientInvoiceIssuanceStep } from "Components/Orders/Detail/Steps/ClientInvoiceIssuanceStep";
import { InvoicePaymentStep } from "Components/Orders/Detail/Steps/ClientInvoicePaymentStep";
import { EnterpriseInvoiceIssuanceAndPaymentStep } from "Components/Orders/Detail/Steps/EnterpriseInvoiceIssuanceAndPaymentStep";
import { ProcessingServicesStep } from "Components/Orders/Detail/Steps/ProcessingServicesStep/ProcessingServicesStep";
import { ClientReviewStep } from "Components/Orders/Detail/Steps/ClientReviewStep";
import { StepIcon } from "Components/Orders/Detail/Steps/StepIcon";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useParty } from "Hooks/Party/useParty";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { Gender } from "Utils/PersonalNumberUtils";
import styled from "styled-components";
import { ClientInvoiceRecurringPaymentStep } from "Components/Orders/Detail/Steps/RecurringPayment/ClientInvoiceRecurringPaymentStep";
import { ClientAssignmentStep } from "Components/Orders/Detail/Steps/ClientAssignmentStep";
import { ClientPrepaidPaymentApprovalStep } from "Components/Orders/Detail/Steps/ClientPrepaidPaymentApprovalStep";

const StyledContentWrapper = styled.div`
  padding-bottom: ${props => props.theme.spacing(3)};
`;

export const StyledWorkflowButton = styled(BlButton)`
  font-size: 12px;
  padding: ${props => props.theme.spacing(0.6, 2.5)};
  min-width: 175px;
`;

export const StyledStepText = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
`;

type Props = {
  publicID: string;
  step: OrderWorkflowStepDto;
  steps: OrderWorkflowStepDto[];
  nextStep: OrderWorkflowStepDto;
  services: OrderServiceDto[] | null | undefined;
  order: OrderDto | null | undefined;
};

export const WorkflowStep: React.FunctionComponent<Props> = props => {
  const { step, steps, publicID, nextStep, services, order } = props;
  const { type, dateProcessed, status } = step;

  const { t } = useResource();
  const isLastStep = steps[steps.length - 1].type === type;
  const isReadOnly = order?.userRelationType !== RelationType.OrderActions;

  const wasProcessedByRepresentative =
    !!step.dateProcessed &&
    !!order &&
    !!step.processedByUserPartyPublicID &&
    order.userPartyPublicID !== step.processedByUserPartyPublicID;

  const { formattedName, gender } = useParty(
    wasProcessedByRepresentative ? step.processedByUserPartyPublicID : null,
  );

  const processedByText = wasProcessedByRepresentative
    ? ` - ${t(Resources.Common.ProcessedByRepresentative, {
        name: formattedName,
        gender: gender === Gender.Female ? "a" : "",
      })}`
    : "";

  return (
    <>
      <StyledFlex $gap={1.5}>
        <div style={{ position: "relative" }}>
          <StepIcon
            status={step.status}
            type={type}
            nextStepStatus={nextStep?.status ?? null}
            isLastStep={isLastStep}
          />
        </div>

        <StyledContentWrapper>
          <StyledStepText fontWeight={700}>
            {getStepTranslation(type, status, order?.paymentFrequency, t)}
          </StyledStepText>
          {dateProcessed && (
            <StyledStepText>
              {`${formatDate(new Date(dateProcessed), true)}${processedByText}`}
            </StyledStepText>
          )}
          <div>
            {type === StepType.ClientReview && (
              <>
                <ClientReviewStep
                  publicID={publicID}
                  step={step}
                  steps={steps}
                  isReadOnly={isReadOnly}
                />
              </>
            )}

            {type === StepType.ClientAssignment && (
              <>
                <ClientAssignmentStep publicID={publicID} steps={steps} />
              </>
            )}

            {type === StepType.ClientInvoiceRecurringPayment && !!order && (
              <ClientInvoiceRecurringPaymentStep
                step={step}
                steps={steps}
                publicID={publicID}
                order={order}
              />
            )}

            {type === StepType.ProcessingServices && (
              <ProcessingServicesStep
                publicID={publicID}
                step={step}
                steps={steps}
                services={services}
                order={order}
                isReadOnly={isReadOnly}
              />
            )}

            {type === StepType.ClientInvoiceIssuance && (
              <ClientInvoiceIssuanceStep
                step={step}
                steps={steps}
                publicID={publicID}
                order={order}
                isReadOnly={isReadOnly}
              />
            )}

            {type === StepType.ClientInvoicePayment && (
              <InvoicePaymentStep
                step={step}
                steps={steps}
                publicID={publicID}
                order={order}
                isReadOnly={isReadOnly}
              />
            )}

            {type === StepType.ClientPrepaidPaymentApproval && (
              <ClientPrepaidPaymentApprovalStep
                step={step}
                steps={steps}
                publicID={publicID}
                order={order}
                isReadOnly={isReadOnly}
              />
            )}

            {type === StepType.EnterpriseInvoiceIssuanceAndPayment && (
              <EnterpriseInvoiceIssuanceAndPaymentStep
                step={step}
                steps={steps}
                order={order}
                publicID={publicID}
              />
            )}
          </div>
        </StyledContentWrapper>
      </StyledFlex>
    </>
  );
};

const getStepTranslation = (
  type: StepType,
  status: OrderWorkflowStepStatus,
  orderPaymentFrequency: OrderPaymentFrequency | undefined,
  t: ReturnType<typeof useResource>["t"],
) => {
  if (
    type === StepType.ClientApproval &&
    status === OrderWorkflowStepStatus.Rejected
  ) {
    return t(Resources.StepType.ClientApprovalRejected);
  }

  if (
    type === StepType.ClientPrepaidPaymentApproval &&
    status === OrderWorkflowStepStatus.Rejected
  ) {
    return t(Resources.StepType.ClientPrepaidPaymentRejected);
  }

  if (
    orderPaymentFrequency !== OrderPaymentFrequency.Single &&
    type === StepType.ClientPrepaidPaymentApproval &&
    status !== OrderWorkflowStepStatus.Rejected
  ) {
    return t(Resources.StepType.ClientPrepaidPaymentApprovalRecurring);
  }

  if (
    orderPaymentFrequency === OrderPaymentFrequency.Single &&
    type === StepType.ClientPrepaidPaymentApproval &&
    status !== OrderWorkflowStepStatus.Rejected
  ) {
    return t(Resources.StepType.ClientPrepaidPaymentApprovalSingle);
  }

  if (
    type === StepType.ClientInvoiceIssuance &&
    status === OrderWorkflowStepStatus.Rejected
  ) {
    return t(Resources.StepType.ClientInvoiceIssuanceRejected);
  }
  return t(Resources.StepType[type]);
};
