import { Typography } from "@mui/material";
import { Company } from "Components/Companies/List/Company";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getUserSupplierCompaniesAsync } from "State/Companies/List/GetUserSupplierCompaniesSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";
import styled from "styled-components";

const StyledCompanies = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

export const CompaniesList: React.FunctionComponent = _ => {
  const { t } = useResource();
  const dispatch = useAppDispatch();

  const companies = useAppSelector(state => state.company.list);

  useEffect(() => {
    dispatch(getUserSupplierCompaniesAsync.request());
  }, [dispatch]);

  return (
    <>
      <StyledFlex $alignItems="center" $gap={0.5}>
        <Typography variant="h2">
          {t(Resources.Companies.List.Title)}
        </Typography>
        <BlDefaultTooltip title={t(Resources.Companies.List.Tooltip)} />
      </StyledFlex>
      <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={2}>
        {t(Resources.Companies.List.Description)}
      </Typography>

      {companies.isLoading ? (
        <>
          <BlSkeleton height={100} />
          <BlSkeleton height={100} />
          <BlSkeleton height={100} />
        </>
      ) : (
        <StyledCompanies>
          {companies.data?.map(company => (
            <Company
              key={company.publicID}
              type="enterprise"
              company={company}
            />
          ))}
        </StyledCompanies>
      )}

      <UnstyledLink to={getPath(AppRouting.CompanyCreate, "enterprise")}>
        <BlButton>{t(Resources.Companies.List.Add)}</BlButton>
      </UnstyledLink>
    </>
  );
};
