import * as React from "react";
export const CrossIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M-745.979-517.65a.918.918,0,0,1,.651.27l5.428,5.428,5.428-5.428a.921.921,0,0,1,1.3,0,.918.918,0,0,1,.27.651.918.918,0,0,1-.27.651l-5.428,5.428,5.428,5.427a.919.919,0,0,1,.27.651.918.918,0,0,1-.27.651.921.921,0,0,1-1.3,0l-5.428-5.428-5.428,5.428a.921.921,0,0,1-1.3,0,.918.918,0,0,1-.27-.651.918.918,0,0,1,.27-.651l5.428-5.427-5.428-5.428a.918.918,0,0,1-.27-.651.918.918,0,0,1,.27-.651A.918.918,0,0,1-745.979-517.65Z"
        transform="translate(746.9 517.65)"
        fill="currentColor"
      />
    </svg>
  );
};
