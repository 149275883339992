import { Box, Stack, Typography } from "@mui/material";
import {
  OrderDto,
  OrderPaymentFrequency,
  OrderPaymentType,
  OrderServiceDto,
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  PartyDto,
} from "Api/Api";
import { StyledOrderSummaryText } from "Components/Orders/Summary/OrderSummary";
import { Service } from "Components/Orders/Summary/Service";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useMonthTranslations } from "Hooks/Resources/useMonthTranslations";
import { useTaxLabels } from "Hooks/useWithTax";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency, roundHalfUp } from "Utils/CurrencyUtils";
import { formatDate } from "Utils/DateUtils";
import { orderPaymentFrequencyToMonths } from "Utils/Order/PaymentFrequencyUtils";
import { differenceInMonths, parseISO } from "date-fns";
import { sumBy } from "lodash-es";

const PageResources = Resources.Orders.Detail.Summary.Services;

type Props = {
  order: OrderDto;
  orderServices: OrderServiceDto[];
  supplierParty: PartyDto | null | undefined;
  clientApprovalStep?: OrderWorkflowStepDto;
  clientPrepaidPaymentApprovalStep?: OrderWorkflowStepDto;
};

export const Services: React.FunctionComponent<Props> = props => {
  const {
    orderServices,
    supplierParty,
    order,
    clientApprovalStep,
    clientPrepaidPaymentApprovalStep,
  } = props;
  const { t } = useResource();
  const { withTax, withoutTax } = useTaxLabels();

  const isVatUsed = supplierParty?.isVATPayer ?? false;

  const isSinglePayment =
    order.paymentFrequency === OrderPaymentFrequency.Single;

  const multiplier = getMultiplier(order);

  const priceWithoutTax = roundHalfUp(
    sumBy(orderServices, x => x.priceWithoutTax),
  );
  const priceWithTax = roundHalfUp(sumBy(orderServices, x => x.priceWithTax));
  const currencyCode = orderServices?.[0]?.currencyCode;

  const isPricePerFrequencyShown =
    !isSinglePayment &&
    order.paymentFrequency !== OrderPaymentFrequency.Monthly;

  const priceFontSize = isPricePerFrequencyShown ? 12 : 16;

  const isPeriodicPayment =
    order.paymentFrequency !== OrderPaymentFrequency.Single;

  const monthDiff =
    differenceInMonths(
      !!order.paymentFrequencyDateTo
        ? parseISO(order.paymentFrequencyDateTo)
        : new Date(),
      !!order.paymentFrequencyDateFrom
        ? parseISO(order.paymentFrequencyDateFrom)
        : new Date(),
    ) + 1;

  const tMonth = useMonthTranslations();

  const approvalStep =
    order.paymentType === OrderPaymentType.Prepaid
      ? order.paymentFrequency === OrderPaymentFrequency.Single //if is prepaid recurring than we dont show approval step
        ? clientPrepaidPaymentApprovalStep
        : null
      : clientApprovalStep;
  return (
    <>
      <Typography variant="h2" marginBottom={2}>
        {t(PageResources.Title)}
      </Typography>

      {orderServices.map(x => (
        <Service key={x.orderServiceID} service={x} isVatUsed={isVatUsed} />
      ))}

      {isPeriodicPayment && (
        <Box>
          <Typography variant="h2" mt={2}>
            {t(PageResources.Periodicity.Title)}
          </Typography>

          <Typography mt={1}>
            {t(PageResources.Periodicity.Dates, {
              from: formatDate(order.paymentFrequencyDateFrom),
              to: formatDate(order.paymentFrequencyDateTo),
              months: tMonth(monthDiff),
            })}
          </Typography>
        </Box>
      )}

      {orderServices.length > 0 && (
        <Stack
          direction="row"
          alignItems="stretch"
          justifyContent="space-between"
        >
          <Box>
            {!!approvalStep?.status &&
              [
                OrderWorkflowStepStatus.Completed,
                OrderWorkflowStepStatus.Rejected,
              ].includes(approvalStep?.status) && (
                <Stack direction="row" gap={1} mt={2}>
                  <Box>
                    {approvalStep.status ===
                      OrderWorkflowStepStatus.Completed && (
                      <Typography>
                        {order.paymentType === OrderPaymentType.Prepaid
                          ? t(PageResources.ApprovalPaymentDate)
                          : t(PageResources.ApprovalDate)}
                      </Typography>
                    )}

                    {approvalStep.status ===
                      OrderWorkflowStepStatus.Rejected && (
                      <Typography>{t(PageResources.RejectionDate)}</Typography>
                    )}
                  </Box>

                  <Box>
                    {
                      <Typography>
                        {formatDate(approvalStep.dateProcessed, true)}
                      </Typography>
                    }
                  </Box>
                </Stack>
              )}
          </Box>

          <StyledFlex
            $flexDirection="column"
            $alignItems="flex-end"
            $marginTop={2}
          >
            <Typography marginBottom={0.5}>
              {isSinglePayment
                ? t(PageResources.Price.Single)
                : t(PageResources.Price.Periodic)}
            </Typography>

            <Typography
              variant="h3"
              marginBottom={0.5}
              fontSize={priceFontSize}
            >
              {`${withoutTax(
                formatCurrency(priceWithoutTax, currencyCode),
                isVatUsed,
              )}${!isSinglePayment ? ` /  ${t(Resources.Common.Month)}` : ""}`}
            </Typography>

            {isVatUsed && (
              <StyledOrderSummaryText fontSize={priceFontSize}>
                {`${withTax(formatCurrency(priceWithTax, currencyCode))}
              ${!isSinglePayment ? ` /  ${t(Resources.Common.Month)}` : ""}`}
              </StyledOrderSummaryText>
            )}

            {isPricePerFrequencyShown && (
              <>
                <Typography variant="h3" marginBottom={0.5}>
                  {`${withoutTax(
                    formatCurrency(
                      priceWithoutTax * (multiplier ?? 1),
                      currencyCode,
                    ),
                    isVatUsed,
                  )} / ${t(
                    Resources.Orders.Detail.Payment.RadioButtons.PeriodPostfix[
                      order.paymentFrequency
                    ],
                  )}`}
                </Typography>

                {isVatUsed && (
                  <StyledOrderSummaryText fontSize={priceFontSize} variant="h3">
                    {`${withTax(
                      formatCurrency(
                        priceWithTax * (multiplier ?? 1),
                        currencyCode,
                      ),
                    )}${
                      !isSinglePayment
                        ? ` /  ${t(
                            Resources.Orders.Detail.Payment.RadioButtons
                              .PeriodPostfix[order.paymentFrequency],
                          )}`
                        : ""
                    }`}
                  </StyledOrderSummaryText>
                )}
              </>
            )}
          </StyledFlex>
        </Stack>
      )}
    </>
  );
};

function getMultiplier(order: OrderDto) {
  if (order.paymentFrequency === OrderPaymentFrequency.Single) {
    return null;
  }

  return orderPaymentFrequencyToMonths(order.paymentFrequency);
}
