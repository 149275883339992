import {
  ClientPrepaidPaymentApprovalStepRejectRequest,
  ClientPrepaidPaymentApprovalStepRejectCommandResult,
  getOrdersWorkflowClientReviewTokenSummary,
  postOrdersWorkflowClientPrepaidPaymentApprovalTokenReject,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { getOrderDetailAsync } from "State/ClientZone/Orders/GetOrderDetailSlice";

export const clientPrepaidPaymentApprovalRejectAsync = createAsyncAction(
  "@clientZone/CLIENT_PREPAID_PAYMENT_APPROVAL_REJECT_REQUEST",
  "@clientZone/CLIENT_PREPAID_PAYMENT_APPROVAL_REJECT_RESPONSE",
  "@clientZone/CLIENT_PREPAID_PAYMENT_APPROVAL_REJECT_FAILURE",
)<
  {
    token: string;
  },
  ClientPrepaidPaymentApprovalStepRejectCommandResult,
  Error
>();

function* clientPrepaidPaymentApprovalReject(
  action: ReturnType<typeof clientPrepaidPaymentApprovalRejectAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersWorkflowClientPrepaidPaymentApprovalTokenReject,
      {
        userAgent: navigator.userAgent,
      } as ClientPrepaidPaymentApprovalStepRejectRequest,
      action.payload.token,
    );

    if (status === 200) {
      const { data: orderDetail, status: orderDetailStatus } = yield* call(
        getOrdersWorkflowClientReviewTokenSummary,
        action.payload.token,
      );

      if (!!orderDetail && orderDetailStatus === 200) {
        yield put(getOrderDetailAsync.success(orderDetail));
      }

      yield put(clientPrepaidPaymentApprovalRejectAsync.success(data));
      return;
    }

    yield put(
      clientPrepaidPaymentApprovalRejectAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(clientPrepaidPaymentApprovalRejectAsync.failure(err as Error));
  }
}
export function* clientPrepaidPaymentApprovalRejectSaga() {
  yield takeLatest(
    getType(clientPrepaidPaymentApprovalRejectAsync.request),
    clientPrepaidPaymentApprovalReject,
  );
}

export const clientPrepaidPaymentApprovalRejectSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<ClientPrepaidPaymentApprovalStepRejectCommandResult>(
      false,
    ),
  name: "@clientZone/clientPrepaidPaymentApprovalReject",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<ClientPrepaidPaymentApprovalStepRejectCommandResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(clientPrepaidPaymentApprovalRejectAsync, builder);
    handleSuccessCase(clientPrepaidPaymentApprovalRejectAsync, builder);
    handleFailureCase(clientPrepaidPaymentApprovalRejectAsync, builder);
  },
});

export const { resetSlice } = clientPrepaidPaymentApprovalRejectSlice.actions;
