import { Typography } from "@mui/material";
import { PartyDto, PartyType } from "Api/Api";
import { StyledOrderSummaryText } from "Components/Orders/Summary/OrderSummary";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { Resources, useResource } from "Translations/Resources";
import {
  formatAddressMunicipality,
  formatAddressStreet,
} from "Utils/AddressUtils";
import { formatPersonalNumber } from "Utils/PersonalNumberUtils";
import { formatPhoneNumber } from "Utils/PhoneUtils";
import styled from "styled-components";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacing(0, 1)};
  margin-bottom: ${props => props.theme.spacing(3)};

  & > * {
    min-height: ${props => props.theme.spacing(2)};
  }
`;

type Props = {
  party: PartyDto;
};

export const Client: React.FunctionComponent<Props> = props => {
  const { party } = props;
  const { t } = useResource();

  const address = party?.addresses?.[0];

  if (!party) {
    return <BlSkeleton />;
  }

  return (
    <div>
      <Typography variant="h2" marginBottom={1}>
        {t(Resources.Orders.Client.Title)}
      </Typography>

      <StyledGrid>
        <div>
          <StyledOrderSummaryText>
            {!!party.companyName
              ? party.companyName
              : `${party.firstName} ${party.lastName}`}
          </StyledOrderSummaryText>

          {!!party.companyRepresentativeFirstName &&
            !!party.companyRepresentativeLastName && (
              <StyledOrderSummaryText>
                {`${t(Resources.Orders.Client.Representative)}: ${
                  party.companyRepresentativeFirstName
                } ${party.companyRepresentativeLastName}`}
              </StyledOrderSummaryText>
            )}

          {!!address ? (
            <div>
              <StyledOrderSummaryText>
                {formatAddressStreet(address)}
              </StyledOrderSummaryText>

              <StyledOrderSummaryText>
                {formatAddressMunicipality(address)}
              </StyledOrderSummaryText>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div>
          {party.type === PartyType.NaturalPerson && (
            <StyledOrderSummaryText>
              {t(Resources.Common.PersonalNumber)}:{" "}
              {formatPersonalNumber(party.personalNumber)}
            </StyledOrderSummaryText>
          )}

          {party.type !== PartyType.NaturalPerson && (
            <>
              <StyledOrderSummaryText>
                {t(Resources.Common.CompanyNumber)}: {party.companyNumber}
              </StyledOrderSummaryText>

              {party.isVATPayer && (
                <StyledOrderSummaryText>
                  {t(Resources.Common.TaxNumber)}: {party.taxNumber}
                </StyledOrderSummaryText>
              )}
            </>
          )}
          <StyledOrderSummaryText>
            {t(Resources.Orders.Detail.Summary.Phone, {
              phone: formatPhoneNumber(party.phone),
            })}
          </StyledOrderSummaryText>

          <StyledOrderSummaryText>
            {t(Resources.Orders.Detail.Summary.Email, {
              email: party.email,
            })}
          </StyledOrderSummaryText>
        </div>
      </StyledGrid>
    </div>
  );
};
