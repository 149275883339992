import { Box, Divider } from "@mui/material";
import { DesignForm } from "Components/Settings/Enterprise/DesignForm";
import { CommunicationModeForm } from "Components/Settings/Enterprise/CommunicationModeForm";
import { ModeForm } from "Components/Settings/Enterprise/ModeForm";
import { EucsForInvoiceForm } from "Components/Settings/Enterprise/EucsForInvoiceForm";

export const EnterpriseSettings: React.FunctionComponent = _ => {
  return (
    <>
      <ModeForm />

      <Box marginBottom={3} />
      <Divider />
      <Box marginBottom={3} />

      <DesignForm />

      <Box marginBottom={3} />
      <Divider />
      <Box marginBottom={3} />

      <CommunicationModeForm />

      <EucsForInvoiceForm />
    </>
  );
};
