import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, RouteProps, useLocation } from "react-router";
import { resetSession } from "State/Auth/Session/SessionSlice";
import { getCodeListCollectionAsync } from "State/CodeLists/Collection/GetCodeListCollection";
import { getEnterpriseBasicSettingsAsync } from "State/Enterprises/BasicSettings/EnterpriseBasicSettingsSlice";
import { enterpriseLogoAsync } from "State/Enterprises/BasicSettings/EnterpriseLogoSlice";
import { AppRouting, getPath } from "Utils/UrlUtils";

type AuthorizedRouteProps = RouteProps & {
  isUserAuthenticated: boolean;
};

export const AuthenticatedRoute: React.FunctionComponent<
  React.PropsWithChildren<AuthorizedRouteProps>
> = props => {
  const { children, isUserAuthenticated } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  if (isUserAuthenticated === false && location.pathname !== "/") {
    dispatch(resetSession({ authRedirectUrl: location.pathname }));
  }

  useEffect(() => {
    if (isUserAuthenticated) {
      dispatch(getCodeListCollectionAsync.request());
      dispatch(getEnterpriseBasicSettingsAsync.request());
      dispatch(enterpriseLogoAsync.request());
    }
  }, [dispatch, isUserAuthenticated]);

  if (isUserAuthenticated === false) {
    return (
      <Navigate
        to={getPath(AppRouting.SignOutPage)}
        state={{ from: location }}
        replace={true}
      />
    ) as any;
  }

  return children ?? (null as any);
};
