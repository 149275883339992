import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import * as React from "react";

type Props = TooltipProps & {
  tooltipColor?: {
    background: string;
    text: string;
  };
  maxWidth?: number | null;
  maxHeight?: number | null;
};

const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: theme.spacing(1, 1.5),
    fontSize: 12,
    maxWidth: (props: Props) => (props.maxWidth ? props.maxWidth : 300),
    maxHeight: (props: Props) => (props.maxHeight ? props.maxHeight : 400),
    overflowY: "auto",
    fontWeight: 400,
    "& *": {
      fontSize: 12,
    },
  },
}))(Tooltip);

const useStyles = (placement: string, color?: Props["tooltipColor"]) =>
  makeStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: color?.background ?? theme.colors.darkGrey,
      color: color?.text ?? theme.palette.common.white,
      marginBottom: placement.startsWith("top") ? `4px !important` : "",
      marginLeft: placement === "top-start" ? `5px !important` : "",
    },
  }));

export const BlTooltip: React.FunctionComponent<Props> = props => {
  const { tooltipColor, maxWidth, ...rest } = props;
  const placement = rest.placement ?? "top-start";
  const classes = useStyles(placement, tooltipColor)();
  return (
    <StyledTooltip
      {...rest}
      placement={placement}
      classes={classes}
      maxWidth={maxWidth}
    ></StyledTooltip>
  );
};
