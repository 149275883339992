import {
  EnterpriseInvoiceIssuanceAndPaymentStepQueryResult,
  getOrdersPublicIDWorkflowEnterpriseInvoiceIssuanceAndPayment,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getEnterpriseInvoiceIssuanceAndPaymentAsync = createAsyncAction(
  "@orderWorkflow/GET_ENTERPRISE_INVOICE_ISSUANCE_AND_PAYMENT_REQUEST",
  "@orderWorkflow/GET_ENTERPRISE_INVOICE_ISSUANCE_AND_PAYMENT_RESPONSE",
  "@orderWorkflow/GET_ENTERPRISE_INVOICE_ISSUANCE_AND_PAYMENT_FAILURE",
)<
  {
    publicID: string;
  },
  EnterpriseInvoiceIssuanceAndPaymentStepQueryResult,
  Error
>();

function* getEnterpriseInvoiceIssuanceAndPayment(
  action: ReturnType<
    typeof getEnterpriseInvoiceIssuanceAndPaymentAsync.request
  >,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrdersPublicIDWorkflowEnterpriseInvoiceIssuanceAndPayment,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getEnterpriseInvoiceIssuanceAndPaymentAsync.success(data));
      return;
    }

    yield put(
      getEnterpriseInvoiceIssuanceAndPaymentAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(
      getEnterpriseInvoiceIssuanceAndPaymentAsync.failure(err as Error),
    );
  }
}
export function* getEnterpriseInvoiceIssuanceAndPaymentSaga() {
  yield takeLatest(
    getType(getEnterpriseInvoiceIssuanceAndPaymentAsync.request),
    getEnterpriseInvoiceIssuanceAndPayment,
  );
}

export const getEnterpriseInvoiceIssuanceAndPaymentSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EnterpriseInvoiceIssuanceAndPaymentStepQueryResult>(
      false,
    ),
  name: "@orderWorkflow/getEnterpriseInvoiceIssuanceAndPayment",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EnterpriseInvoiceIssuanceAndPaymentStepQueryResult>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(getEnterpriseInvoiceIssuanceAndPaymentAsync, builder);
    handleSuccessCase(getEnterpriseInvoiceIssuanceAndPaymentAsync, builder);
    handleFailureCase(getEnterpriseInvoiceIssuanceAndPaymentAsync, builder);
  },
});

export const { resetSlice } =
  getEnterpriseInvoiceIssuanceAndPaymentSlice.actions;
