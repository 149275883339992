import { createSlice } from "@reduxjs/toolkit";
import { CutoffCreateRequest, postCutoffs } from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { getCutoffsListAsync } from "State/Cutoff/GetCutoffsListSlice";
import { Resources, getTranslation } from "Translations/Resources";
import { downloadBase64File } from "Utils/FileUtils";
import { toast } from "sonner";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const postCutoffAsync = createAsyncAction(
  "@cutoff/POST_CUTOFF_REQUEST",
  "@cutoff/POST_CUTOFF_RESPONSE",
  "@cutoff/POST_CUTOFF_FAILURE",
)<CutoffCreateRequest, { isDraft: boolean }, Error>();

function* postCutoff(
  action: ReturnType<typeof postCutoffAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(postCutoffs, action.payload);

    if (status === 200) {
      downloadBase64File(data.pdfBase64, "application/pdf", "");
      yield put(postCutoffAsync.success({ isDraft: action.payload.isDraft }));

      if (!action.payload.isDraft) {
        yield put(getCutoffsListAsync.request({ limit: 25, offset: 0 }));
        toast.success(getTranslation(Resources.Settings.Cutoff.New.Success));
      }
      return;
    }

    if (status === 404) {
      toast.error(getTranslation(Resources.Settings.Cutoff.New.NotFound));
    }

    yield put(postCutoffAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(postCutoffAsync.failure(err as Error));
  }
}
export function* postCutoffSaga() {
  yield takeLatest(getType(postCutoffAsync.request), postCutoff);
}

type PostCutoffSliceState = {
  isLoading: boolean;
  error: Error | null;
  isDraft: boolean;
};

const initialState: PostCutoffSliceState = {
  isLoading: false,
  error: null,
  isDraft: false,
};

export const postCutoffSlice = createSlice({
  initialState,
  name: "@cutoff/postCutoff",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(postCutoffAsync.request),
      (state, action: ReturnType<typeof postCutoffAsync.request>) => {
        state.error = null;
        state.isLoading = true;
        state.isDraft = action.payload.isDraft;
      },
    );
    builder.addCase(
      getType(postCutoffAsync.success),
      (state, action: ReturnType<typeof postCutoffAsync.success>) => {
        state.isLoading = false;
        state.isDraft = action.payload.isDraft;
      },
    );
    builder.addCase(
      getType(postCutoffAsync.failure),
      (state, action: ReturnType<typeof postCutoffAsync.failure>) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
