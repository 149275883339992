import { Copyright } from "Components/Layout/CopyRight";
import { Logo } from "Components/Layout/Logo";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getClientZoneEnterpriseDesignSettingsAsync } from "State/ClientZone/Settings/EnterpriseDesignSettingsSlice";
import { getClientZoneLogoAsync } from "State/ClientZone/Settings/EnterpriseLogoSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { useEffect } from "react";
import styled from "styled-components";

const StyledLayoutWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: ${props => props.theme.vh(100)};
`;

const StyledContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.border};
  min-height: 85px;
  display: flex;
  & > div {
    padding-left: ${props => props.theme.spacing(2.5)};
    padding-right: ${props => props.theme.spacing(3.6)};
    border-right: 1px solid ${props => props.theme.colors.border};
  }
`;

const StyledCopyright = styled.div`
  padding-top: ${props => props.theme.spacing(2)};
`;

type Props = {
  title: string;
  companyPublicID: string | undefined;
  enterprisePublicID: string | undefined;
};

export const ClientZoneLayout: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { t } = useResource();
  const { title, companyPublicID, enterprisePublicID } = props;
  const dispatch = useAppDispatch();

  const {
    logo: { data: logo },
    designSettings: { data: designSettings },
  } = useAppSelector(e => e.clientZone);

  useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  useEffect(() => {
    if (companyPublicID && !logo) {
      dispatch(
        getClientZoneLogoAsync.request({ companyPublicID: companyPublicID }),
      );
    }
  }, [dispatch, companyPublicID, logo, designSettings]);

  useEffect(() => {
    if (enterprisePublicID && !designSettings) {
      dispatch(
        getClientZoneEnterpriseDesignSettingsAsync.request({
          publicID: enterprisePublicID,
        }),
      );
    }
  }, [dispatch, enterprisePublicID, designSettings]);

  return (
    <StyledLayoutWrapper>
      <StyledHeader>
        <Logo
          enterpriseLogo={logo?.logoBase64 ?? undefined}
          isTenantLogoVisible={false}
          isUserLogoVisible={false}
        />
      </StyledHeader>

      <StyledContent>{props.children}</StyledContent>

      <StyledCopyright>
        <Copyright />
      </StyledCopyright>
    </StyledLayoutWrapper>
  );
};
