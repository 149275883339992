import { Divider } from "@mui/material";
import { CutoffNew } from "Components/Settings/Cutoff/CutoffNew";
import { CutoffsList } from "Components/Settings/Cutoff/CutoffsList";

export const Cutoffs: React.FunctionComponent = _ => {
  return (
    <>
      <CutoffNew />

      <Divider sx={{ marginTop: 3, marginBottom: 2 }} />

      <CutoffsList />
    </>
  );
};
