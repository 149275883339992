import * as React from "react";
export const GenerateIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={16}
      viewBox="0 0 18 16"
      {...props}
    >
      <path
        d="M-790.522-578.993h-16.457a.767.767,0,0,1-.771-.762.767.767,0,0,1,.771-.762h16.457a.767.767,0,0,1,.771.762A.767.767,0,0,1-790.522-578.993Zm-3.865-5.852a.767.767,0,0,1-.771-.762v-6.783l-7.411,7.322a.778.778,0,0,1-1.091,0,.755.755,0,0,1-.226-.539.755.755,0,0,1,.226-.539l7.411-7.322h-6.865a.767.767,0,0,1-.771-.762.767.767,0,0,1,.771-.762h8.727a.778.778,0,0,1,.3.058h0a.772.772,0,0,1,.247.164.759.759,0,0,1,.167.247h0a.753.753,0,0,1,.058.291v8.624A.767.767,0,0,1-794.387-584.845Z"
        transform="translate(807.75 594.993)"
        fill="currentColor"
      />
    </svg>
  );
};
