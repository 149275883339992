import {
  SendReminderCommandResult,
  postOrdersPublicIDWorkflowClientReviewReminder,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { toast } from "sonner";
import { getOrderWorkflowStepsAsync } from "State/OrderWorkflow/Slices/OrderWorkflowStepsSlice";

export const sendReminderAsync = createAsyncAction(
  "@orderWorkflow/SEND_REMINDER_REQUEST",
  "@orderWorkflow/SEND_REMINDER_RESPONSE",
  "@orderWorkflow/SEND_REMINDER_FAILURE",
)<
  {
    publicID: string;
  },
  SendReminderCommandResult,
  Error
>();

function* sendReminder(
  action: ReturnType<typeof sendReminderAsync.request>,
): Generator {
  try {
    const { publicID } = action.payload;
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowClientReviewReminder,
      publicID,
    );

    if (status === 200) {
      yield put(
        getOrderWorkflowStepsAsync.request({
          publicID: publicID,
        }),
      );
      yield put(sendReminderAsync.success(data));
      toast.success("Připomenutí bylo odesláno");
      return;
    }

    yield put(sendReminderAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(sendReminderAsync.failure(err as Error));
  }
}
export function* sendReminderSaga() {
  yield takeLatest(getType(sendReminderAsync.request), sendReminder);
}

export const sendReminderSlice = createSlice({
  initialState: getFetchStateDefaultValue<SendReminderCommandResult>(false),
  name: "@orderWorkflow/sendReminder",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<SendReminderCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(sendReminderAsync, builder);
    handleSuccessCase(sendReminderAsync, builder);
    handleFailureCase(sendReminderAsync, builder);
  },
});

export const { resetSlice: sendReminderReset } = sendReminderSlice.actions;
