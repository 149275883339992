import { BlSkeleton } from "Components/Shared/BlSkeleton";

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
};

export const LoadingWrapper: React.FunctionComponent<Props> = props => {
  return <>{props.isLoading ? <BlSkeleton height={80} /> : props.children}</>;
};
