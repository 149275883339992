import * as React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import styled from "styled-components";
import { BlTooltip } from "Components/Shared/BlTooltip";
import { CircularProgress, TooltipProps } from "@mui/material";

export type DefaultButtonProps = Omit<ButtonProps, "title"> & {
  title?: string | JSX.Element;
  placement?: TooltipProps["placement"];
  tooltipColor?: {
    background: string;
    text: string;
  };
  isSquare?: boolean;
  isLoading?: boolean;
};

const StyledButton = styled(Button)<{ $isSquare: boolean }>`
  min-width: 0;
  ${props => props.$isSquare && "aspect-ratio: 1;"}

  &.Mui-disabled {
    opacity: 0.5;
  }
`;

export const BlDefaultButton: React.FunctionComponent<
  DefaultButtonProps
> = props => {
  const { title, tooltipColor, isSquare, isLoading, ...rest } = props;
  const t = title || "";

  return (
    <>
      {t ? (
        <BlTooltip
          title={t}
          placement={props.placement ?? "top"}
          tooltipColor={tooltipColor}
        >
          <div>
            <StyledButton
              {...rest}
              children={
                isLoading ? <CircularProgress size={18} /> : props.children
              }
              color={props.color ?? "primary"}
              $isSquare={isSquare ?? false}
            ></StyledButton>
          </div>
        </BlTooltip>
      ) : (
        <div>
          <StyledButton
            {...rest}
            children={
              isLoading ? <CircularProgress size={18} /> : props.children
            }
            color={props.color ?? "primary"}
            $isSquare={isSquare ?? false}
          ></StyledButton>
        </div>
      )}
    </>
  );
};
