import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { LoadingWrapper } from "Components/Orders/Draft/LoadingWrapper";
import { Resources, useResource } from "Translations/Resources";
import { UseFormReturn } from "react-hook-form";
import { OrderFormModel } from "Components/Orders/Draft/StepFormValidationSchema";
import {
  StyledPartyFormItem,
  StyledPartyFormRow,
} from "Components/Shared/Party/PartyForm";
import { useEffect, useState } from "react";
import { IdentificationType, OrderClientStatus } from "Api/Api";
import { Typography } from "@mui/material";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { enumToArray } from "Utils/ObjectUtils";
import { BlDropdown } from "Components/Shared/Inputs/Form/BlDropdown";
import { CodeListItem } from "Models/ICodeListDto";

type Props = {
  form: UseFormReturn<OrderFormModel>;
  isLoading: boolean;
  isReadOnly: boolean;
};

export const ClientConsents: React.FunctionComponent<Props> = props => {
  const { form, isLoading, isReadOnly } = props;
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = form;
  const { t } = useResource();
  const isNewClient = watch("party.status") === OrderClientStatus.New;

  const [isIdentificationVisible, setIsIdentificationVisible] =
    useState<boolean>(watch("isClientElectronicCommunicationConsent") === true);

  const onIsClientElectronicCommunicationConsentChange = (
    isClientElectronicCommunicationConsent: boolean,
  ) => {
    setIsIdentificationVisible(isClientElectronicCommunicationConsent);
    if (isClientElectronicCommunicationConsent) {
      if (watch("clientIdentification") == null) {
        setValue("clientIdentification", IdentificationType.IDCard);
      }
      setValue("isClientPersonalDataProcessingConsent", true);
    } else {
      setValue("clientIdentification", null);
      setValue("clientIdentificationNumber", null);
    }
  };

  useEffect(() => {
    onIsClientElectronicCommunicationConsentChange(
      watch("isClientElectronicCommunicationConsent") === true,
    );
  });

  if (isNewClient !== true) {
    return null;
  }

  return (
    <>
      <StyledPartyFormRow $marginBottom={1}>
        <StyledPartyFormItem $span={6}>
          <LoadingWrapper isLoading={isLoading}>
            <BlCheckboxInput
              control={control}
              errors={errors}
              disabled={
                isReadOnly ||
                watch("isClientElectronicCommunicationConsent") === true
              }
              name={"isClientPersonalDataProcessingConsent"}
              label={t(
                Resources.Orders.Detail.ClientConsents
                  .IsClientPersonalDataProcessingConsent,
              )}
            ></BlCheckboxInput>
          </LoadingWrapper>
        </StyledPartyFormItem>
      </StyledPartyFormRow>

      <StyledPartyFormRow $marginBottom={1}>
        <StyledPartyFormItem $span={6}>
          <LoadingWrapper isLoading={isLoading}>
            <BlCheckboxInput
              control={control}
              errors={errors}
              disabled={isReadOnly}
              onChange={v => {
                setValue(
                  "isClientElectronicCommunicationConsent",
                  v as boolean,
                );
                onIsClientElectronicCommunicationConsentChange(v as boolean);
              }}
              name={"isClientElectronicCommunicationConsent"}
              label={t(
                Resources.Orders.Detail.ClientConsents
                  .IsClientElectronicCommunicationConsent,
              )}
            ></BlCheckboxInput>
          </LoadingWrapper>
        </StyledPartyFormItem>
      </StyledPartyFormRow>

      {isIdentificationVisible && (
        <>
          <StyledPartyFormRow>
            <StyledPartyFormItem $span={3}>
              <LoadingWrapper isLoading={isLoading}>
                <BlDropdown
                  control={control}
                  errors={errors}
                  name={"clientIdentification"}
                  label={t(Resources.Party.Identification.Label)}
                  codeList={enumToArray(IdentificationType).map(
                    x =>
                      ({
                        code: x,
                        name: t(Resources.IdentificationType[x]),
                      }) as CodeListItem,
                  )}
                  formControlClass="no-padding flex-1"
                />
              </LoadingWrapper>
            </StyledPartyFormItem>
            <StyledPartyFormItem $span={3}>
              <LoadingWrapper isLoading={isLoading}>
                <BlFormInput
                  control={control}
                  errors={errors}
                  name={"clientIdentificationNumber"}
                  label={t(Resources.Party.IdentificationNumber)}
                  maxLength={32}
                  disabled={isReadOnly}
                ></BlFormInput>
              </LoadingWrapper>
            </StyledPartyFormItem>
          </StyledPartyFormRow>
          <Typography fontStyle={"italic"}>
            {t(Resources.Party.Identification.Description)}
          </Typography>
        </>
      )}
    </>
  );
};
