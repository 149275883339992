import { Box, Divider, Stack, Typography } from "@mui/material";
import { OrderServiceDto, ServiceVariantProduct } from "Api/Api";
import { EucsService } from "Components/Orders/Detail/Eucs/EucsService";
import { ServiceDescription } from "Components/Orders/Summary/ServiceDescription";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlConfirmButton } from "Components/Shared/Buttons/BlConfirmButton";
import { TrashIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useTaxLabels } from "Hooks/useWithTax";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import styled, { useTheme } from "styled-components";

const StyledWrapper = styled.div`
  background-color: ${props => props.theme.palette.common.white};
  padding: ${props => props.theme.spacing(1)};
  border-radius: 7px;
`;

export const StyledRowWrapper = styled(StyledFlex)`
  padding: ${props => props.theme.spacing(0, 0.5)};
`;

export const StyledTrashIcon = styled(TrashIcon)`
  color: ${props => props.theme.palette.error.main};
`;

const SecondaryText = styled(Typography)<{}>`
  font-size: 12px;
  color: ${props => props.theme.palette.common.white};
`;

type Props = {
  service: OrderServiceDto;
  canDelete: boolean;
  cannotDeleteReason: "TooLate" | "LastService" | "IsReadOnly" | null;
  isVatUsed: boolean;
  publicID: string;
  isReadOnly: boolean;
  onDelete: (orderServiceID: number) => void;
};

export const Service: React.FunctionComponent<Props> = props => {
  const {
    onDelete,
    canDelete,
    isReadOnly,
    service: {
      serviceName,
      currencyCode,
      priceWithTax,
      priceWithoutTax,
      orderServiceID,
      relatedServices,
    },
    cannotDeleteReason,
    isVatUsed,
    publicID,
  } = props;

  const { t } = useResource();
  const { withTax, withoutTax } = useTaxLabels();
  const theme = useTheme();
  const eucs = props.service.products.findLast(
    x => x.product === ServiceVariantProduct.EucsGarance,
  );
  const isEucs = !!eucs;

  return (
    <>
      <StyledWrapper>
        {isEucs ? (
          <EucsService
            publicID={publicID}
            serviceName={serviceName}
            orderServiceID={orderServiceID}
            orderServiceProduct={eucs}
            isReadOnly={isReadOnly}
          ></EucsService>
        ) : (
          <StyledRowWrapper
            $alignItems="center"
            $justifyContent="space-between"
            $marginBottom={0.5}
          >
            <Box display={"inline-flex"}>
              <Typography fontWeight={500} paddingRight={1}>
                {serviceName}
              </Typography>
              {relatedServices.length > 0 && (
                <BlDefaultTooltip
                  placement="right"
                  maxWidth={600}
                  maxHeight={500}
                  title={
                    <>
                      <SecondaryText mt={1} mb={1}>
                        {t(
                          Resources.Orders.Detail.Summary.Services
                            .PackageIncludeText,
                        )}
                      </SecondaryText>
                      {relatedServices.map(x => (
                        <div key={x.orderServiceRelatedServiceID}>
                          <Typography
                            variant="h3"
                            marginTop={1}
                            color={theme.palette.common.white}
                          >
                            {x.serviceName}
                          </Typography>

                          <ServiceDescription
                            fontColor="white"
                            description={x.serviceDescription}
                            withPackageServicesName={false}
                          />
                        </div>
                      ))}
                    </>
                  }
                />
              )}
            </Box>
            <BlConfirmButton
              modalContent={t(Resources.Orders.Workflow.Services.Delete.Text, {
                name: serviceName,
              })}
              modalTitle={t(Resources.Orders.Workflow.Services.Delete.Title)}
              tooltip={
                canDelete
                  ? t(Resources.Common.Delete)
                  : !cannotDeleteReason
                    ? t(
                        Resources.Orders.Workflow.Services.Delete.Disabled
                          .TooLate,
                      )
                    : t(
                        Resources.Orders.Workflow.Services.Delete.Disabled[
                          cannotDeleteReason
                        ],
                      )
              }
              disabled={!canDelete}
              onResult={r => {
                if (r) {
                  onDelete(orderServiceID);
                }
              }}
            >
              <StyledTrashIcon />
            </BlConfirmButton>
          </StyledRowWrapper>
        )}

        <Divider />

        <Stack mt={1} alignItems={"end"}>
          <Typography fontWeight={500}>
            {withoutTax(
              formatCurrency(priceWithoutTax, currencyCode),
              isVatUsed,
            )}
          </Typography>
          <Box>
            {isVatUsed && (
              <Typography
                variant="subtitle2"
                fontSize={12}
                fontStyle={"normal"}
              >
                {withTax(formatCurrency(priceWithTax, currencyCode))}
              </Typography>
            )}
          </Box>
        </Stack>
      </StyledWrapper>
    </>
  );
};
