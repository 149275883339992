import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Stack, Typography } from "@mui/material";
import { EnterprisePackageServiceSettingsDto } from "Api/Api";
import { APP_CURRENCY_CODE } from "App";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { savePackageServiceSettingsAsync } from "State/ServicePackages/Slices/SavePackagesServiceSettingsSlice";
import { useAppSelector } from "State/Store";
import { useResource, Resources } from "Translations/Resources";
import { getCurrencySymbol } from "Utils/CurrencyUtils";
import { isNoU } from "Utils/ObjectUtils";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { ObjectSchema, object, number, boolean } from "yup";

const StyledInputWrapper = styled(Box)`
  width: 275px;
`;

const CheckboxWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export type FormModel = {
  minimalPriceWithoutTaxSingle?: number;
  maximalPriceWithoutTaxSingle?: number;
  minimalPriceWithoutTaxMonthly?: number;
  maximalPriceWithoutTaxMonthly?: number;
  isCreatePackageByAdvisorEnabled: boolean;
};

const PageResources = Resources.Settings.ServicePackages.PackageServiceSettings;

type Props = {
  packageServiceSettings: EnterprisePackageServiceSettingsDto;
};

export const ServicePackageSettingsForm: React.FunctionComponent<
  Props
> = props => {
  const { packageServiceSettings } = props;
  const { t } = useResource();

  const validationSchema: ObjectSchema<FormModel> = object({
    isCreatePackageByAdvisorEnabled: boolean().required(),
    minimalPriceWithoutTaxSingle: number()
      .optional()
      .test(
        "isLessThanMaxSingle",
        t(PageResources.Validation.MinMustBeLessThanMax),
        (value: number | undefined, ctx) => {
          const { maximalPriceWithoutTaxSingle } = ctx.parent as FormModel;

          if (
            isNoU(value) ||
            value === 0 ||
            isNoU(maximalPriceWithoutTaxSingle) ||
            maximalPriceWithoutTaxSingle === 0
          ) {
            return true;
          }

          return value < maximalPriceWithoutTaxSingle;
        },
      ),
    maximalPriceWithoutTaxSingle: number()
      .optional()
      .test(
        "isGreaterThanMinSingle",
        t(PageResources.Validation.MaxMustBeGreaterThanMin),
        (value: number | undefined, ctx) => {
          const { minimalPriceWithoutTaxSingle } = ctx.parent as FormModel;

          if (
            isNoU(value) ||
            value === 0 ||
            isNoU(minimalPriceWithoutTaxSingle)
          ) {
            return true;
          }

          return value > minimalPriceWithoutTaxSingle;
        },
      ),
    minimalPriceWithoutTaxMonthly: number()
      .optional()
      .test(
        "isLessThanMaxMonthly",
        t(PageResources.Validation.MinMustBeLessThanMax),
        (value: number | undefined, ctx) => {
          const { maximalPriceWithoutTaxMonthly } = ctx.parent as FormModel;

          if (
            isNoU(value) ||
            value === 0 ||
            isNoU(maximalPriceWithoutTaxMonthly) ||
            maximalPriceWithoutTaxMonthly === 0
          ) {
            return true;
          }

          return value < maximalPriceWithoutTaxMonthly;
        },
      ),
    maximalPriceWithoutTaxMonthly: number()
      .optional()
      .test(
        "isGreaterThanMinMonthly",
        t(PageResources.Validation.MaxMustBeGreaterThanMin),
        (value: number | undefined, ctx) => {
          const { minimalPriceWithoutTaxMonthly } = ctx.parent as FormModel;

          if (
            isNoU(value) ||
            value === 0 ||
            isNoU(minimalPriceWithoutTaxMonthly)
          ) {
            return true;
          }

          return value > minimalPriceWithoutTaxMonthly;
        },
      ),
  }).defined();
  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      minimalPriceWithoutTaxSingle:
        packageServiceSettings.minimalPriceWithoutTaxSingle ?? undefined,
      maximalPriceWithoutTaxSingle:
        packageServiceSettings.maximalPriceWithoutTaxSingle ?? undefined,
      minimalPriceWithoutTaxMonthly:
        packageServiceSettings.minimalPriceWithoutTaxMonthly ?? undefined,
      maximalPriceWithoutTaxMonthly:
        packageServiceSettings.maximalPriceWithoutTaxMonthly ?? undefined,
      isCreatePackageByAdvisorEnabled:
        packageServiceSettings.isCreatePackageByAdvisorEnabled ?? false,
    },
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = form;

  const dispatch = useAppDispatch();

  const submit = (data: FormModel) => {
    dispatch(savePackageServiceSettingsAsync.request(data));
  };

  const { isLoading } = useAppSelector(
    x => x.servicePackage.saveServiceSettings,
  );

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <CheckboxWrapper>
          <BlCheckboxInput
            control={control}
            name={"isCreatePackageByAdvisorEnabled"}
            errors={errors}
            label={t(PageResources.IsCreatePackageByAdvisorLabel)}
          />
        </CheckboxWrapper>
        <Stack direction={"row"} gap={4} width={"100%"}>
          <StyledInputWrapper>
            <BlFormInput
              name={"minimalPriceWithoutTaxSingle"}
              control={control}
              errors={errors}
              label={t(PageResources.Single.MinimalPriceLabel)}
              isNumber
              mask={Number}
              min={0}
              max={99999}
              unit={getCurrencySymbol(APP_CURRENCY_CODE)}
              fullWidth
            />
          </StyledInputWrapper>

          <StyledInputWrapper>
            <BlFormInput
              name={"maximalPriceWithoutTaxSingle"}
              control={control}
              errors={errors}
              label={t(PageResources.Single.MaximalPriceLabel)}
              isNumber
              mask={Number}
              min={0}
              max={999999}
              unit={getCurrencySymbol(APP_CURRENCY_CODE)}
              fullWidth
            />
          </StyledInputWrapper>
        </Stack>

        <Stack direction={"row"} gap={4}>
          <StyledInputWrapper>
            <BlFormInput
              name={"minimalPriceWithoutTaxMonthly"}
              control={control}
              errors={errors}
              label={t(PageResources.Monthly.MinimalPriceLabel)}
              isNumber
              mask={Number}
              min={0}
              max={99999}
              unit={getCurrencySymbol(APP_CURRENCY_CODE)}
              fullWidth
            />
          </StyledInputWrapper>
          <StyledInputWrapper>
            <BlFormInput
              name={"maximalPriceWithoutTaxMonthly"}
              control={control}
              errors={errors}
              label={t(PageResources.Monthly.MaximalPriceLabel)}
              isNumber
              mask={Number}
              min={0}
              max={999999}
              unit={getCurrencySymbol(APP_CURRENCY_CODE)}
              fullWidth
            />
          </StyledInputWrapper>
        </Stack>

        <Typography variant="subtitle2" fontStyle={"normal"}>
          {t(PageResources.NoLimitsLabel)}
        </Typography>

        <Box>
          <BlButton type="submit" color="primary" isLoading={isLoading}>
            {t(Resources.Common.Save)}
          </BlButton>
        </Box>
      </form>
    </>
  );
};
