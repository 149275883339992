import { createSlice } from "@reduxjs/toolkit";
import {
  CompanyUpdateRequest,
  CreateCompanyCommandResult,
  UpdateCompanyCommandResult,
  postCompanies,
  postCompaniesUserSupplierCompany,
  putUserCompany,
  putCompanies,
  SaveFioSettingsRequest,
  putCompaniesPublicIDFioSettings,
  putCompaniesPublicIDDesignSettings,
  SaveCompanyDesignSettingsRequest,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { getCompanyDetailReturnUrl } from "Pages/Companies/CompanyDetailPage";
import { Resources, getTranslation } from "Translations/Resources";
import { NavigateFunction } from "react-router";
import { toast } from "sonner";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export type CompanyEntityType = "enterprise" | "user";

function* setCompanyEntityType(
  type: CompanyEntityType,
  companyPublicID: string,
) {
  switch (type) {
    case "enterprise":
      return yield* call(postCompaniesUserSupplierCompany, {
        publicID: companyPublicID,
      });
    case "user":
      return yield* call(putUserCompany, {
        publicID: companyPublicID,
      });
  }
}

export const saveCompanyDetailAsync = createAsyncAction(
  "@COMPANIES/SAVE_DETAIL_REQUEST",
  "@COMPANIES/SAVE_DETAIL_RESPONSE",
  "@COMPANIES/SAVE_DETAIL_FAILURE",
)<
  {
    isNew: boolean;
    type: CompanyEntityType;
    company: CompanyUpdateRequest;
    fioSettings: SaveFioSettingsRequest;
    designSettings: SaveCompanyDesignSettingsRequest;
    navigate: NavigateFunction;
  },
  UpdateCompanyCommandResult | CreateCompanyCommandResult,
  Error
>();

function* saveCompanyDetail(
  action: ReturnType<typeof saveCompanyDetailAsync.request>,
): Generator {
  try {
    if (action.payload.isNew) {
      const { data, error, status } = yield* call(
        postCompanies,
        action.payload.company,
      );

      if (status === 200) {
        const entityTypeResult = yield* call(
          setCompanyEntityType,
          action.payload.type,
          data.publicID,
        );

        if (entityTypeResult.status === 200) {
          const { status: saveFioStatus } = yield* call(
            putCompaniesPublicIDFioSettings,
            action.payload.fioSettings,
            data.publicID,
          );

          if (saveFioStatus === 200) {
            const { status: saveCompanyDesignSettings } = yield* call(
              putCompaniesPublicIDDesignSettings,
              action.payload.designSettings,
              data.publicID,
            );

            if (saveCompanyDesignSettings === 200) {
              yield put(saveCompanyDetailAsync.success(data));
              toast.success(getTranslation(Resources.Common.SaveSuccess));
              action.payload.navigate(
                {
                  pathname: getCompanyDetailReturnUrl(
                    action.payload.type,
                    getTranslation,
                  ),
                },
                { replace: true },
              );
            }
          }
        } else {
          yield put(
            saveCompanyDetailAsync.failure(
              mapAPIErrorResponse(
                entityTypeResult.error ?? entityTypeResult.data,
              ),
            ),
          );
        }

        return;
      } else {
        yield put(
          saveCompanyDetailAsync.failure(mapAPIErrorResponse(error ?? data)),
        );
      }
    } else {
      const { data, error, status } = yield* call(
        putCompanies,
        action.payload.company,
      );

      if (status === 200) {
        const { status: saveFioStatus } = yield* call(
          putCompaniesPublicIDFioSettings,
          action.payload.fioSettings,
          action.payload.company.publicID,
        );
        if (saveFioStatus === 200) {
          const { status: saveCompanyDesignSettings } = yield* call(
            putCompaniesPublicIDDesignSettings,
            action.payload.designSettings,
            action.payload.company.publicID,
          );

          if (saveCompanyDesignSettings === 200) {
            yield put(saveCompanyDetailAsync.success(data));
            toast.success(getTranslation(Resources.Common.SaveSuccess));
            return;
          }
        }
      } else {
        yield put(
          saveCompanyDetailAsync.failure(mapAPIErrorResponse(error ?? data)),
        );
      }
    }
  } catch (err) {
    yield put(saveCompanyDetailAsync.failure(err as Error));
  }
}
export function* saveCompanyDetailSaga() {
  yield takeLatest(getType(saveCompanyDetailAsync.request), saveCompanyDetail);
}

type SliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: SliceState = {
  isLoading: false,
  error: null,
};

export const saveCompanyDetailSlice = createSlice({
  initialState,
  name: "saveCompanyDetail",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(saveCompanyDetailAsync.request),
      (state, action: ReturnType<typeof saveCompanyDetailAsync.request>) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(saveCompanyDetailAsync.success),
      (state, action: ReturnType<typeof saveCompanyDetailAsync.success>) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(saveCompanyDetailAsync.failure),
      (state, action: ReturnType<typeof saveCompanyDetailAsync.failure>) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
