import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import {
  GetClientReviewSummaryQueryResult,
  getOrdersWorkflowClientReviewTokenSummary,
} from "Api/Api";

export const getOrderDetailAsync = createAsyncAction(
  "@clientZone/GET_ORDER_DETAIL_REQUEST",
  "@clientZone/GET_ORDER_DETAIL_RESPONSE",
  "@clientZone/GET_ORDER_DETAIL_FAILURE",
)<
  {
    token: string;
  },
  GetClientReviewSummaryQueryResult,
  Error
>();

function* getOrderDetail(
  action: ReturnType<typeof getOrderDetailAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrdersWorkflowClientReviewTokenSummary,
      action.payload.token,
    );

    if (status === 200) {
      yield put(getOrderDetailAsync.success(data));
      return;
    }

    yield put(getOrderDetailAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getOrderDetailAsync.failure(err as Error));
  }
}
export function* getOrderDetailSaga() {
  yield takeLatest(getType(getOrderDetailAsync.request), getOrderDetail);
}

export const getOrderDetailSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<GetClientReviewSummaryQueryResult>(false),
  name: "@clientZone/getOrderDetail",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<GetClientReviewSummaryQueryResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getOrderDetailAsync, builder);
    handleSuccessCase(getOrderDetailAsync, builder);
    handleFailureCase(getOrderDetailAsync, builder);
  },
});

export const { resetSlice } = getOrderDetailSlice.actions;
