import { CompanyDetail } from "Components/Companies/CompanyDetail";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { getCompanyDetailReturnUrl } from "Pages/Companies/CompanyDetailPage";
import { CompanyEntityType } from "State/Companies/Detail/SaveCompanyDetailSlice";
import { Resources, useResource } from "Translations/Resources";
import { useParams } from "react-router";

export const CompanyCreatePage: React.FunctionComponent = _ => {
  const { t } = useResource();
  const { type } = useParams<{ type: CompanyEntityType }>();

  return (
    <>
      <AuthenticatedLayout title={t(Resources.Companies.Detail.Title.New)}>
        <StyledPageWrapper $isNarrow>
          <PageTitle
            title={t(Resources.Companies.Detail.Title.New)}
            goBackUrl={getCompanyDetailReturnUrl(type, t)}
          ></PageTitle>

          <CompanyDetail
            type={type!}
            fioSettings={null}
            company={null}
            designSettings={null}
          />
        </StyledPageWrapper>
      </AuthenticatedLayout>
    </>
  );
};
