import {
  SignInResult,
  SsoSignInRequest,
  PostAuthSsoFetchResponse,
  FetchResponse,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { NavigateFunction } from "react-router";
import { afterSignInSuccess } from "Utils/AuthUtils";

export const signInSsoAsync = createAsyncAction(
  "@auth/SIGN_IN_SSO_REQUEST",
  "@auth/SIGN_IN_SSO_RESPONSE",
  "@auth/SIGN_IN_SSO_FAILURE",
)<
  {
    model: SsoSignInRequest;
    sendCookies?: true;
    navigate: NavigateFunction;
  },
  SignInResult,
  Error
>();

function* signInSso(
  action: ReturnType<typeof signInSsoAsync.request>,
): Generator {
  // TODO: This is temporary solution for SSO. We have to allow post credentials `credentials: "include"`.
  const postAuthSso = (
    requestContract: SsoSignInRequest,
  ): Promise<PostAuthSsoFetchResponse> =>
    apiPost(
      `${import.meta.env.VITE_API_URL}/api/v1/auth/sso`,
      requestContract,
    ) as Promise<PostAuthSsoFetchResponse>;

  function apiPost<TResponse extends FetchResponse<unknown, number>, TRequest>(
    url: string,
    request: TRequest,
  ) {
    var requestOptions = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        Cookie: document.cookie,
      }),
      body: JSON.stringify(request),
      redirect: "follow",
      // credentials: "same-origin",
      credentials: "include",
    };

    return fetchJson<TResponse>(url, requestOptions as any);
  }

  async function fetchJson<T extends FetchResponse<unknown, number>>(
    ...args: any
  ): Promise<T> {
    let CONFIG = {
      jwtKey: undefined,
      onResponse: (...args: any[]) => {},
    };
    const errorResponse = (error: any, args: any) => {
      const errorResponse = {
        status: 0,
        args,
        data: null,
        error,
      } satisfies FetchResponse<T>;
      CONFIG.onResponse && CONFIG.onResponse(errorResponse);
      return errorResponse as unknown as T;
    };

    const errorStatus = (args: any) => {
      const errorResponse = {
        status: 0,
        args,
        data: null,
        error: new Error("Network error", { cause: undefined }),
      } as FetchResponse<T, any>;
      CONFIG.onResponse && CONFIG.onResponse(errorResponse);
      return errorResponse as unknown as T;
    };

    try {
      const res: Response = await (fetch as any)(...args);
      try {
        const json = await res.json();
        const response = { data: json, status: res.status, args, error: null };
        CONFIG.onResponse && CONFIG.onResponse(response);
        return response as unknown as T;
      } catch (error) {
        return errorResponse(error, args);
      }
    } catch {
      return errorStatus(args);
    }
  }

  try {
    const { data, error, status } = yield* call(
      postAuthSso,
      action.payload.model,
    );

    if (status === 200) {
      yield put(signInSsoAsync.success(data));
      yield* call(afterSignInSuccess, data, action.payload.navigate);

      return;
    }

    yield put(signInSsoAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(signInSsoAsync.failure(err as Error));
  }
}
export function* signInSsoSaga() {
  yield takeLatest(getType(signInSsoAsync.request), signInSso);
}

type SignInSsoSliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: SignInSsoSliceState = {
  isLoading: false,
  error: null,
};

export const signInSsoSlice = createSlice({
  initialState,
  name: "signInSso",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(signInSsoAsync.request),
      (state, action: ReturnType<typeof signInSsoAsync.request>) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(signInSsoAsync.success),
      (state, action: ReturnType<typeof signInSsoAsync.success>) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(signInSsoAsync.failure),
      (state, action: ReturnType<typeof signInSsoAsync.failure>) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
