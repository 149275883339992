import { EnterpriseMode } from "Api/Api";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { AccessRightCodes } from "Models/AccessRightCodes";
import { getEnterpriseBasicSettingsAsync } from "State/Enterprises/BasicSettings/EnterpriseBasicSettingsSlice";
import { useAppSelector } from "State/Store";
import { useEffect } from "react";

export function useHasCompaniesAccess() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEnterpriseBasicSettingsAsync.request());
  }, [dispatch]);

  const enterpriseMode = useAppSelector(
    x => x.enterprise.basicSettings.settings.data?.mode,
  );

  const accessRightCodes =
    useAppSelector(e => e.auth.session.user?.accessRightCodes) || [];

  const hasSettingsEnterprise = accessRightCodes.some(
    x => x === AccessRightCodes.SettingsEnterprise,
  );
  const hasSettingsAdvisor = accessRightCodes.some(
    x => x === AccessRightCodes.SettingsAdvisor,
  );

  return (
    hasSettingsEnterprise ||
    (hasSettingsAdvisor && enterpriseMode === EnterpriseMode.Free)
  );
}
