import { Typography } from "@mui/material";
import { EnterpriseMode } from "Api/Api";
import { StyledDivider } from "Components/Auth/Styles";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { ServicePackageItem } from "Components/Settings/Services/ServicePackages/List/ServicePackageItem";
import { ServicePackageSettingsForm } from "Components/Settings/Services/ServicePackages/List/ServicePackageSettingsForm";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { useUser } from "Hooks/useUser";
import { AccessRightCodes } from "Models/AccessRightCodes";
import { getPackageServiceSettingsAsync } from "State/ServicePackages/Slices/GetPackagesServiceSettingsSlice";
import { getServicePackagesAsync } from "State/ServicePackages/Slices/GetServicePackagesSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";

const PageResources = Resources.Settings.ServicePackages;

export const ServicePackages: React.FunctionComponent = _ => {
  const { t } = useResource();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getServicePackagesAsync.request({ offset: 0, limit: 100 }));
    dispatch(getPackageServiceSettingsAsync.request());
  }, [dispatch]);

  const { data, isLoading: isDataLoading } = useAppSelector(
    x => x.servicePackage.list,
  );

  const { isLoading: isSettingsLoading, data: packageServiceSettings } =
    useAppSelector(x => x.servicePackage.serviceSettings);

  const isLoading =
    (isSettingsLoading && !packageServiceSettings) ||
    (isDataLoading && !data?.items);

  const servicePackages = data?.items ?? [];

  const enterpriseMode = useAppSelector(
    x => x.enterprise.basicSettings.settings.data?.mode,
  );

  const canUserCreatePackage = useAppSelector(
    x => x.servicePackage.serviceSettings.data?.canUserCreatePackage,
  );

  const user = useUser();

  const canUpdateServiceSettings =
    (user?.accessRightCodes ?? []).some(
      x => x === AccessRightCodes.SettingsEnterprise,
    ) && enterpriseMode === EnterpriseMode.Strict;

  return (
    <>
      <StyledFlex $gap={0.5} $alignItems="center">
        <Typography variant="h2">{t(PageResources.Title)}</Typography>

        <BlDefaultTooltip title={t(PageResources.Tooltip)} />
      </StyledFlex>

      <Typography variant="subtitle2" fontStyle={"normal"} mb={2}>
        {t(PageResources.Description)}
      </Typography>

      <>
        {!isLoading && (
          <>
            {servicePackages.map(servicePackage => (
              <ServicePackageItem
                key={servicePackage.servicePublicID}
                servicePackage={servicePackage}
              />
            ))}

            {canUserCreatePackage && (
              <UnstyledLink to={getPath(AppRouting.ServicePackagesCreate)}>
                <BlButton>{t(PageResources.CreateButton)}</BlButton>
              </UnstyledLink>
            )}

            {canUpdateServiceSettings && (
              <>
                <StyledDivider sx={{ margin: "30px 0 !important" }} />

                <StyledFlex $gap={0.5} $alignItems="center">
                  <Typography variant="h2">
                    {t(PageResources.PackageServiceSettings.Title)}
                  </Typography>

                  <BlDefaultTooltip
                    title={t(PageResources.PackageServiceSettings.Tooltip)}
                  />
                </StyledFlex>

                <Typography variant="subtitle2" fontStyle={"normal"} mb={2}>
                  {t(PageResources.PackageServiceSettings.Description)}
                </Typography>

                {!!packageServiceSettings && (
                  <ServicePackageSettingsForm
                    packageServiceSettings={packageServiceSettings}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    </>
  );
};
