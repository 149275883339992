import {
  EntityListOfServiceListItemDto,
  getServicesPackagesAvailableServices,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getAvailableServicesAsync = createAsyncAction(
  "@servicePackages/GET_AVAILABLE_SERVICES_REQUEST",
  "@servicePackages/GET_AVAILABLE_SERVICES_RESPONSE",
  "@servicePackages/GET_AVAILABLE_SERVICES_FAILURE",
)<
  {
    offset: number;
    limit: number;
  },
  EntityListOfServiceListItemDto,
  Error
>();

function* getAvailableServices(
  action: ReturnType<typeof getAvailableServicesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getServicesPackagesAvailableServices,
      action.payload.offset,
      action.payload.limit,
    );

    if (status === 200) {
      yield put(getAvailableServicesAsync.success(data));
      return;
    }

    yield put(
      getAvailableServicesAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getAvailableServicesAsync.failure(err as Error));
  }
}
export function* getAvailableServicesSaga() {
  yield takeLatest(
    getType(getAvailableServicesAsync.request),
    getAvailableServices,
  );
}

export const getAvailableServicesSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfServiceListItemDto>(false),
  name: "@servicePackages/getAvailableServices",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfServiceListItemDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getAvailableServicesAsync, builder);
    handleSuccessCase(getAvailableServicesAsync, builder);
    handleFailureCase(getAvailableServicesAsync, builder);
  },
});

export const { resetSlice: getAvailableServicesSliceReset } =
  getAvailableServicesSlice.actions;
