import { Typography } from "@mui/material";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { UserAddIcon } from "Components/Shared/Icons/UserAdd";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getClientCountsAsync } from "State/Dashboard/Statistics/ClientCountsSlice";
import { getExpectedUserCommissionAsync } from "State/Dashboard/Statistics/ExpectedUserCommissionSlice";
import { getPaidUserCommissionAsync } from "State/Dashboard/Statistics/PaidUserCommissionSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import React, { useEffect } from "react";
import styled from "styled-components";
import { formatCurrency } from "Utils/CurrencyUtils";
import { getOrderCountsAsync } from "State/Dashboard/Statistics/OrderCountsSlice";
import { CopyIcon } from "Components/Shared/Icons/Copy";
import { ClockIcon } from "@mui/x-date-pickers";
import { StarIcon } from "Components/Shared/Icons/Star";

const StyledWrapper = styled.div`
  display: grid;
  margin-top: ${props => props.theme.spacing(4)};
`;

const StyledBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: ${props => props.theme.spacing(2)};
  justify-content: space-between;
`;

const StyledBox = styled.div<{ $isPrimaryTransparent?: boolean }>`
  min-height: 140px;
  border-radius: 18px;
  padding: ${props => props.theme.spacing(2.5, 1, 2.5, 2)};
  background-color: ${props => props.theme.colors.gray};
  ${props =>
    props.$isPrimaryTransparent &&
    `
      background-color: ${props.theme.colors.primaryMainTransparent};
  `}

  .label {
    padding-left: ${props => props.theme.spacing(1)};
    color: ${props => props.theme.palette.text.secondary};
  }

  .icon {
    color: ${props => props.theme.palette.primary.main};
    width: 24px;
    height: auto;
  }

  .divider {
    height: 1px;
    background-color: ${props => props.theme.palette.common.white};
    margin: ${props => props.theme.spacing(0.5, 0)};
  }

  .value {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
  }
`;

const LoadingWrapper = (props: {
  isLoading: boolean;
  children: React.ReactNode;
}) => {
  return props.isLoading ? <BlSkeleton height={50} /> : <>{props.children}</>;
};

type Props = {};

export const Statistics: React.FunctionComponent<Props> = props => {
  const { t } = useResource();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPaidUserCommissionAsync.request({}));

    dispatch(getExpectedUserCommissionAsync.request({}));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getClientCountsAsync.request());
    dispatch(getOrderCountsAsync.request({ onlyActive: true }));
  }, [dispatch]);

  const { data: clientCounts, isLoading: isClientCountsLoading } =
    useAppSelector(e => e.dashboard.clientCounts);

  const { data: paidUserCommission, isLoading: isPaidUserCommissionLoading } =
    useAppSelector(e => e.dashboard.paidUserCommission);

  const { data: orderCounts, isLoading: isOrderCountsLoading } = useAppSelector(
    e => e.dashboard.orderCounts,
  );

  const {
    data: expectedUserCommission,
    isLoading: isExpectedUserCommissionLoading,
  } = useAppSelector(e => e.dashboard.expectedUserCommission);

  const boxes = [
    // NOTE: Commented out because we are not able to get this number from the broker's API
    // {
    //   label: t(Resources.Dashboard.Statistics.Leads),
    //   value: clientCounts?.leadsCount,
    //   isLoading: isClientCountsLoading,
    //   icon: <UserAddIcon className="icon" />,
    // },
    {
      label: t(Resources.Dashboard.Statistics.Clients),
      value: clientCounts?.clientsCount,
      isLoading: isClientCountsLoading,
      icon: <UserAddIcon className="icon" />,
    },
    {
      label: t(Resources.Dashboard.Statistics.Orders),
      value: orderCounts?.ordersCount,
      isLoading: isOrderCountsLoading,
      icon: <CopyIcon className="icon" />,
    },
    {
      label: t(Resources.Dashboard.Statistics.ExpectedCommission),
      value: formatCurrency(
        expectedUserCommission?.expectedUserCommission,
        null,
      ),
      icon: <ClockIcon className="icon" />,
      isPrimaryTransparent: true,
      tooltip: t(Resources.Dashboard.Statistics.ExpectedCommissionTooltip),
      isLoading: isExpectedUserCommissionLoading,
    },
    {
      label: t(Resources.Dashboard.Statistics.PaidCommission),
      value: formatCurrency(paidUserCommission?.paidUserCommission, null),
      icon: <StarIcon className="icon" />,
      tooltip: t(Resources.Dashboard.Statistics.PaidCommissionTooltip),
      isLoading: isPaidUserCommissionLoading,
    },
  ];

  return (
    <StyledWrapper>
      <StyledBoxWrapper>
        {boxes.map(box => (
          <StyledBox
            key={box.label}
            $isPrimaryTransparent={box.isPrimaryTransparent}
          >
            <StyledFlex $alignItems="center">
              {box.icon}
              <Typography className="label">{box.label}</Typography>
            </StyledFlex>
            <div className="divider"></div>
            <LoadingWrapper isLoading={box.isLoading}>
              <Typography className="value">{box.value}</Typography>
            </LoadingWrapper>
            {box.tooltip && (
              <StyledFlex $justifyContent="flex-end">
                <BlDefaultTooltip title={box.tooltip} />
              </StyledFlex>
            )}
          </StyledBox>
        ))}
      </StyledBoxWrapper>
    </StyledWrapper>
  );
};
