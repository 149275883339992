import { Typography } from "@mui/material";
import {
  EntityListOfOrderWorkflowStepDto,
  OrderDto,
  OrderWorkflowStepStatus,
} from "Api/Api";
import { SetPeriodicityDialog } from "Components/Orders/Detail/Periodicity/SetPeriodicityDialog";
import { RoundedIcon } from "Components/Orders/Detail/RoundedIcon";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { CheckIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { useState } from "react";
import styled from "styled-components";

type Props = {
  order: OrderDto | null | undefined;

  workflowSteps: EntityListOfOrderWorkflowStepDto | null | undefined;
};

const Wrapper = styled.div`
  background: ${props => props.theme.colors.primaryMainTransparent};
  border-radius: 18px;
  padding: ${props => props.theme.spacing(2)};
  margin-top: ${props => props.theme.spacing(2)};
`;

const StyledButton = styled(BlButton)`
  background-color: ${props => props.theme.palette.common.white};

  &:hover {
    background-color: ${props => props.theme.palette.common.white};
  }

  margin-top: ${props => props.theme.spacing(2)};
  margin-left: ${props => props.theme.spacing(3)};
  width: 200px;
  height: 35px;
  font-size: 12px;
  font-weight: 700;
`;

export const SetPeriodicityBox: React.FunctionComponent<Props> = props => {
  const { order, workflowSteps } = props;
  const { t } = useResource();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  if (
    !!order?.nextOrderDate ||
    !workflowSteps ||
    workflowSteps.items.some(
      x => x.status !== OrderWorkflowStepStatus.Completed,
    )
  ) {
    return null;
  }

  const setRepetition = () => {
    setIsDialogOpen(true);
  };

  return (
    <>
      <Wrapper>
        <StyledFlex>
          <RoundedIcon isFinished={false} isRejected={false}>
            <CheckIcon />
          </RoundedIcon>
          <Typography fontWeight={700} marginTop={0.5} marginLeft={1}>
            {t(Resources.Orders.Completed.Title)}
          </Typography>
        </StyledFlex>

        <StyledButton color="secondary" onClick={setRepetition}>
          {t(Resources.Orders.Completed.SetRepetitionDialog.OpenDialogButton)}
        </StyledButton>
      </Wrapper>

      {isDialogOpen && !!order && (
        <SetPeriodicityDialog
          publicID={order.publicID}
          onClosed={() => setIsDialogOpen(false)}
        />
      )}
    </>
  );
};
