import { ServicePackageDto, getServicesServicePublicIDPackage } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getServicePackageDetailAsync = createAsyncAction(
  "@servicePackage/DETAIL_REQUEST",
  "@servicePackage/DETAIL_RESPONSE",
  "@servicePackage/DETAIL_FAILURE",
)<{ servicePublicID: string }, ServicePackageDto, Error>();

function* getServicePackageDetail(
  action: ReturnType<typeof getServicePackageDetailAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getServicesServicePublicIDPackage,
      action.payload.servicePublicID,
    );

    if (status === 200) {
      yield put(getServicePackageDetailAsync.success(data));
      return;
    }

    yield put(
      getServicePackageDetailAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getServicePackageDetailAsync.failure(err as Error));
  }
}
export function* getServicePackageDetailSaga() {
  yield takeLatest(
    getType(getServicePackageDetailAsync.request),
    getServicePackageDetail,
  );
}

export const getServicePackageDetailSlice = createSlice({
  initialState: getFetchStateDefaultValue<ServicePackageDto>(false),
  name: "@servicePackage/getServicePackageDetail",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<ServicePackageDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getServicePackageDetailAsync, builder);
    handleSuccessCase(getServicePackageDetailAsync, builder);
    handleFailureCase(getServicePackageDetailAsync, builder);
  },
});

export const { resetSlice: resetServicePackageDetail } =
  getServicePackageDetailSlice.actions;
