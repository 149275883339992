import { EntityListOfOrderListItemDto, getOrders } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export type GetOrderListQuery = {
  query: string;
  /**
   * Url encoded list of statuses
   */
  statuses: string[];
  offset: number;
  limit: number;
  startDate: string | null;
  endDate: string | null;
  isSearchInStructure: boolean;
  onlyAfterInvoiceDueDate: boolean;
};

export const getOrderListAsync = createAsyncAction(
  "@order/GET_LIST_REQUEST",
  "@order/GET_LIST_RESPONSE",
  "@order/GET_LIST_FAILURE",
)<GetOrderListQuery, EntityListOfOrderListItemDto, Error>();

function* getOrderList(
  action: ReturnType<typeof getOrderListAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getOrders,
      action.payload.offset,
      action.payload.limit,
      action.payload.statuses,
      action.payload.query,
      action.payload.startDate,
      action.payload.endDate,
      action.payload.isSearchInStructure,
      action.payload.onlyAfterInvoiceDueDate,
    );

    if (status === 200) {
      yield put(getOrderListAsync.success(data));
      return;
    }

    yield put(getOrderListAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getOrderListAsync.failure(err as Error));
  }
}
export function* getOrderListSaga() {
  yield takeLatest(getType(getOrderListAsync.request), getOrderList);
}

export const getOrderListSlice = createSlice({
  initialState: getFetchStateDefaultValue<EntityListOfOrderListItemDto>(false),
  name: "orderList",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfOrderListItemDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getOrderListAsync, builder);
    handleSuccessCase(getOrderListAsync, builder);
    handleFailureCase(getOrderListAsync, builder);
  },
});

export const { resetSlice: resetOrderList } = getOrderListSlice.actions;
