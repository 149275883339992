import {
  EnterpriseServiceSettingsUpdateRequest,
  SaveEnterpriseServiceSettingsCommandResult,
  putEnterprisesServiceSettings,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { toast } from "sonner";
import { getTranslation, Resources } from "Translations/Resources";
import { getEnterpriseServiceSettingsAsync } from "State/Enterprises/Services/ServiceSettingsSlice";

export const putEnterpriseServiceSettingsAsync = createAsyncAction(
  "@ENTERPRISE/PUT_SERVICE_SETTINGS_REQUEST",
  "@ENTERPRISE/PUT_SERVICE_SETTINGS_RESPONSE",
  "@ENTERPRISE/PUT_SERVICE_SETTINGS_FAILURE",
)<
  {
    toastID: string;
    request: EnterpriseServiceSettingsUpdateRequest;
  },
  SaveEnterpriseServiceSettingsCommandResult,
  Error
>();

function* putEnterpriseServiceSettings(
  action: ReturnType<typeof putEnterpriseServiceSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      putEnterprisesServiceSettings,
      action.payload.request,
    );

    if (status === 200) {
      toast.success(
        getTranslation(Resources.Settings.Enterprise.Save.Success),
        {
          id: action.payload.toastID,
        },
      );
      yield put(putEnterpriseServiceSettingsAsync.success(data));
      yield put(getEnterpriseServiceSettingsAsync.request());
      return;
    }

    yield put(
      putEnterpriseServiceSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data, status),
      ),
    );
  } catch (err) {
    yield put(putEnterpriseServiceSettingsAsync.failure(err as Error));
  }
}
export function* putEnterpriseServiceSettingsSaga() {
  yield takeLatest(
    getType(putEnterpriseServiceSettingsAsync.request),
    putEnterpriseServiceSettings,
  );
}

type SliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: SliceState = {
  isLoading: false,
  error: null,
};

export const putEnterpriseServiceSettingsSlice = createSlice({
  initialState,
  name: "putEnterpriseServiceSettings",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(putEnterpriseServiceSettingsAsync.request),
      (
        state,
        action: ReturnType<typeof putEnterpriseServiceSettingsAsync.request>,
      ) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(putEnterpriseServiceSettingsAsync.success),
      (
        state,
        action: ReturnType<typeof putEnterpriseServiceSettingsAsync.success>,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(putEnterpriseServiceSettingsAsync.failure),
      (
        state,
        action: ReturnType<typeof putEnterpriseServiceSettingsAsync.failure>,
      ) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
