import {
  CompanyFioSettingsDto,
  getCompaniesPublicIDFioSettings,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getCompanyFioSettingsAsync = createAsyncAction(
  "@company/GET_FIO_SETTINGS_REQUEST",
  "@company/GET_FIO_SETTINGS_RESPONSE",
  "@company/GET_FIO_SETTINGS_FAILURE",
)<{ publicID: string }, CompanyFioSettingsDto, Error>();

function* getCompanyFioSettings(
  action: ReturnType<typeof getCompanyFioSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCompaniesPublicIDFioSettings,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getCompanyFioSettingsAsync.success(data));
      return;
    }

    yield put(
      getCompanyFioSettingsAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getCompanyFioSettingsAsync.failure(err as Error));
  }
}
export function* getCompanyFioSettingsSaga() {
  yield takeLatest(
    getType(getCompanyFioSettingsAsync.request),
    getCompanyFioSettings,
  );
}

export const getCompanyFioSettingsSlice = createSlice({
  initialState: getFetchStateDefaultValue<CompanyFioSettingsDto>(false),
  name: "@company/getCompanyFioSettings",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<CompanyFioSettingsDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getCompanyFioSettingsAsync, builder);
    handleSuccessCase(getCompanyFioSettingsAsync, builder);
    handleFailureCase(getCompanyFioSettingsAsync, builder, true);
  },
});

export const { resetSlice } = getCompanyFioSettingsSlice.actions;
