import { createSlice } from "@reduxjs/toolkit";
import {
  SaveEnterpriseCutoffProductPriceSettingsCommandResult,
  SaveEnterpriseCutoffProductPriceSettingsRequest,
  putCutoffsEnterpriseCutoffProductPriceSettings,
} from "Api/Api";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const putEnterpriseCutoffProductPriceSettingsAsync = createAsyncAction(
  "@cutoff/PUT_ENTERPRISE_CUTOFF_PRODUCT_PRICE_SETTINGS_REQUEST",
  "@cutoff/PUT_ENTERPRISE_CUTOFF_PRODUCT_PRICE_SETTINGS_RESPONSE",
  "@cutoff/PUT_ENTERPRISE_CUTOFF_PRODUCT_PRICE_SETTINGS_FAILURE",
)<
  SaveEnterpriseCutoffProductPriceSettingsRequest,
  SaveEnterpriseCutoffProductPriceSettingsCommandResult,
  Error
>();

function* putEnterpriseCutoffProductPriceSettings(
  action: ReturnType<
    typeof putEnterpriseCutoffProductPriceSettingsAsync.request
  >,
): Generator {
  try {
    const { data, error, status } = yield* call(
      putCutoffsEnterpriseCutoffProductPriceSettings,
      action.payload,
    );

    if (status === 200) {
      yield put(putEnterpriseCutoffProductPriceSettingsAsync.success(data));

      return;
    }

    yield put(
      putEnterpriseCutoffProductPriceSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(
      putEnterpriseCutoffProductPriceSettingsAsync.failure(err as Error),
    );
  }
}
export function* putEnterpriseCutoffProductPriceSettingsSaga() {
  yield takeLatest(
    getType(putEnterpriseCutoffProductPriceSettingsAsync.request),
    putEnterpriseCutoffProductPriceSettings,
  );
}

type putEnterpriseCutoffProductPriceSettingsSliceState = {
  isLoading: boolean;
  error: Error | null;
};

const initialState: putEnterpriseCutoffProductPriceSettingsSliceState = {
  isLoading: false,
  error: null,
};

export const putEnterpriseCutoffProductPriceSettingsSlice = createSlice({
  initialState,
  name: "@cutoff/putEnterpriseCutoffProductPriceSettings",
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(putEnterpriseCutoffProductPriceSettingsAsync.request),
      (
        state,
        action: ReturnType<
          typeof putEnterpriseCutoffProductPriceSettingsAsync.request
        >,
      ) => {
        state.error = null;
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(putEnterpriseCutoffProductPriceSettingsAsync.success),
      (
        state,
        action: ReturnType<
          typeof putEnterpriseCutoffProductPriceSettingsAsync.success
        >,
      ) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(putEnterpriseCutoffProductPriceSettingsAsync.failure),
      (
        state,
        action: ReturnType<
          typeof putEnterpriseCutoffProductPriceSettingsAsync.failure
        >,
      ) => {
        state.isLoading = false;
      },
    );
  },
});
