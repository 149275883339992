import { Box, Typography } from "@mui/material";
import {
  OrderFrequency,
  OrderPaymentFrequency,
  OrderPeriodicity,
} from "Api/Api";
import { LoadingWrapper } from "Components/Orders/Draft/LoadingWrapper";
import { OrderFormModel } from "Components/Orders/Draft/StepFormValidationSchema";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlCheckboxInput } from "Components/Shared/Inputs/BlCheckboxInput";
import { BlDropdown } from "Components/Shared/Inputs/Form/BlDropdown";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import { CodeListItem } from "Models/ICodeListDto";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { getAllowedOrderFrequencies } from "Utils/Order/OrderFrequencyUtils";
import { addMonths, addWeeks, max } from "date-fns";
import { FieldPath, UseFormReturn } from "react-hook-form";

type Props = {
  form: UseFormReturn<OrderFormModel>;
  isLoading: boolean;
  isReadOnly: boolean;
};

export const PeriodicityFields: React.FunctionComponent<Props> = props => {
  const { isLoading, form, isReadOnly } = props;
  const { t } = useResource();

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = form;

  const periodicity = watch("order.periodicity");

  const frequency = watch("order.frequency");

  const periodicityName: FieldPath<OrderFormModel> = "order.periodicity";
  const isPeriodic = periodicity === OrderPeriodicity.Periodic;

  const paymentFrequency = watch("order.paymentFrequency");

  const paymentFrequencyDateFrom = watch("order.paymentFrequencyDateFrom");
  const paymentFrequencyDateTo = watch("order.paymentFrequencyDateTo");

  const allowedFrequencies = getAllowedOrderFrequencies(
    paymentFrequency,
    paymentFrequencyDateFrom,
    paymentFrequencyDateTo,
  );

  return (
    <StyledContentWrapper $fullPadding>
      <Typography variant="h2" marginBottom={2}>
        {paymentFrequency === OrderPaymentFrequency.Single
          ? t(Resources.Orders.Detail.Periodicity.Title.Single)
          : t(Resources.Orders.Detail.Periodicity.Title.Periodic)}
      </Typography>

      <LoadingWrapper isLoading={isLoading}>
        <Box>
          <BlCheckboxInput
            onChange={e =>
              setValue(
                "order.periodicity",
                isPeriodic
                  ? OrderPeriodicity.Single
                  : OrderPeriodicity.Periodic,
              )
            }
            value={isPeriodic}
            checked={isPeriodic}
            name={periodicityName}
            fieldError={errors.order?.periodicity}
            label={t(Resources.Orders.Detail.Periodicity.Label)}
          />
        </Box>
        {periodicity === OrderPeriodicity.Periodic && (
          <Box mt={3}>
            <BlDropdown
              control={control}
              errors={errors}
              name={"order.frequency"}
              codeList={allowedFrequencies.map(
                x =>
                  ({
                    code: x,
                    name: t(Resources.OrderFrequency[x]),
                  }) as CodeListItem,
              )}
              formControlClass="no-padding flex-1"
              disabled={isReadOnly}
              label={t(Resources.Orders.Detail.Periodicity.Frequency.Label)}
              labelEndAdornment={
                <BlDefaultTooltip
                  title={t(
                    Resources.Orders.Detail.Periodicity.Frequency.Tooltip,
                  )}
                />
              }
            />
          </Box>
        )}
        {periodicity === OrderPeriodicity.Periodic && (
          <Typography variant="subtitle2">
            {t(Resources.Orders.Detail.Periodicity.Disclaimer, {
              date: formatDate(
                nextOrderDate(
                  frequency,
                  paymentFrequency !== OrderPaymentFrequency.Single
                    ? paymentFrequencyDateTo
                    : undefined,
                ),
              ),
            })}
          </Typography>
        )}
      </LoadingWrapper>
    </StyledContentWrapper>
  );
};

function nextOrderDate(
  frequency: OrderFrequency,
  paymentFrequencyDateTo: Date | undefined,
) {
  const date = !!paymentFrequencyDateTo ? paymentFrequencyDateTo : new Date();

  const multiplier = !!paymentFrequencyDateTo ? -1 : 1;

  return max([addTime(), new Date()]);

  function addTime() {
    switch (frequency) {
      case OrderFrequency.Weekly:
        return addWeeks(date, 1 * multiplier);
      case OrderFrequency.Monthly:
        return addMonths(date, 1 * multiplier);
      case OrderFrequency.Quarterly:
        return addMonths(date, 3 * multiplier);
      case OrderFrequency.Yearly:
        return addMonths(date, 12 * multiplier);
      default:
        return date;
    }
  }
}
