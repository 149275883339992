import { Dashboard } from "Components/Dashboard/Dashboard";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { FunctionComponent } from "react";

export const DASHBOARD_TRANSACTION_LIMIT = 2;

export const DashboardPage: FunctionComponent = _ => {
  const { t } = useResource();

  return (
    <AuthenticatedLayout title={t(Resources.Dashboard.PageName)}>
      <StyledPageWrapper>
        <Dashboard />
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default DashboardPage;
