import { postAuthSignUp, SignUpCommand } from "Api/Api";
import { ActionType, createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLeading } from "typed-redux-saga";
import { NavigateFunction } from "react-router";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import { createSlice } from "@reduxjs/toolkit";
import { AppRouting, getPath } from "Utils/UrlUtils";

export type SignUpState = {
  error: Error | null;
  isLoading: boolean;
};

export type SignUpAction = ActionType<typeof signUpAsync>;

export const getInitialState = (): SignUpState => ({
  error: null,
  isLoading: false,
});

export const signUpAsync = createAsyncAction(
  "@auth/SIGN_UP_REQUEST",
  "@auth/SIGN_UP_SUCCESS",
  "@auth/SIGN_UP_FAILURE",
)<
  { model: SignUpCommand; navigate: NavigateFunction },
  { login: string },
  Error
>();

function* signUp(action: ReturnType<typeof signUpAsync.request>): Generator {
  try {
    const { data, error, status } = yield* call(
      postAuthSignUp,
      action.payload.model,
    );

    if (status === 201) {
      yield put(signUpAsync.success({ login: action.payload.model.login }));
      action.payload.navigate(getPath(AppRouting.AwaitingEmailVerification));
      return;
    }
    if (status === 400) {
      yield put(signUpAsync.failure(mapAPIErrorResponse(data)));
      return;
    }

    yield put(signUpAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(signUpAsync.failure(err as Error));
  }
}

export function* signUpSaga() {
  yield takeLeading(getType(signUpAsync.request), signUp);
}

export const signUpSlice = createSlice({
  name: "signUp",
  initialState: getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(
      getType(signUpAsync.request),
      (state, action: ReturnType<typeof signUpAsync.request>) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      getType(signUpAsync.success),
      (state, action: ReturnType<typeof signUpAsync.success>) => {
        state.isLoading = false;
      },
    );
    builder.addCase(
      getType(signUpAsync.failure),
      (state, action: ReturnType<typeof signUpAsync.failure>) => {
        state.isLoading = false;
        state.error = action.payload;
      },
    );
  },
});
