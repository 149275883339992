import { CompanyDto, getCompaniesPublicID } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getCompanyDetailAsync = createAsyncAction(
  "@COMPANIES/GET_DETAIL_REQUEST",
  "@COMPANIES/GET_DETAIL_RESPONSE",
  "@COMPANIES/GET_DETAIL_FAILURE",
)<{ publicID: string }, CompanyDto, Error>();

function* getCompanyDetail(
  action: ReturnType<typeof getCompanyDetailAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCompaniesPublicID,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getCompanyDetailAsync.success(data));
      return;
    }

    yield put(
      getCompanyDetailAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getCompanyDetailAsync.failure(err as Error));
  }
}
export function* getCompanyDetailSaga() {
  yield takeLatest(getType(getCompanyDetailAsync.request), getCompanyDetail);
}

export const getCompanyDetailSlice = createSlice({
  initialState: getFetchStateDefaultValue<CompanyDto>(false),
  name: "companyDetail",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<CompanyDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getCompanyDetailAsync, builder);
    handleSuccessCase(getCompanyDetailAsync, builder);
    handleFailureCase(getCompanyDetailAsync, builder);
  },
});

export const { resetSlice } = getCompanyDetailSlice.actions;
