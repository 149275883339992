import { Box, Typography } from "@mui/material";
import { UnauthenticatedHeader } from "Components/Layout/UnauthenticatedHeader";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import styled from "styled-components";

const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.fieldBackground};
  padding: ${({ theme }) => theme.spacing(5)};
  border-radius: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

export const SignedOutPage: React.FunctionComponent = _ => {
  const { t } = useResource();
  return (
    <>
      <UnauthenticatedLayout title={t(Resources.SignedOut.Title)}>
        <UnauthenticatedHeader />

        <StyledFlex $justifyContent="center">
          <StyledBox>
            <Typography variant="h2">
              {t(Resources.SignedOut.Description)}
            </Typography>
            <Typography>{t(Resources.SignedOut.Description2)}</Typography>
          </StyledBox>
        </StyledFlex>
      </UnauthenticatedLayout>
    </>
  );
};
