import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import styled from "styled-components";

export const OrderDetailIconButton = styled(BlDefaultButton)`
  aspect-ratio: 1 / 1;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${props => props.theme.palette.text.primary};
  }
`;
