import { CssBaseline } from "@mui/material";
import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { getTheme } from "Components/Layout/Themes/Themes";
import { useAppSelector } from "State/Store";
import { AppRouting, getPath } from "Utils/UrlUtils";
import * as React from "react";
import { useLocation } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { Toaster } from "sonner";
import { useUser } from "Hooks/useUser";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const DEFAULT_APP_COLOR = "#00C0A9";

const Layout: React.FunctionComponent<React.PropsWithChildren<{}>> = props => {
  const [theme, setTheme] = React.useState<DefaultTheme | null>(null);

  const isAuthenticated = useAppSelector(s => s.auth.session.isAuthenticated);
  const enterpriseSettings = useAppSelector(
    s => s.enterprise.basicSettings.settings.data,
  );
  const clientZoneDesignSettings = useAppSelector(
    s => s.clientZone.designSettings.data,
  );
  const user = useUser();

  const color = user?.color ?? enterpriseSettings?.color ?? "#ffffff00";
  const clientZoneColor = clientZoneDesignSettings?.color;

  const editedColor = useAppSelector(
    s => s.enterprise.basicSettings.editedColor,
  );

  React.useEffect(() => {
    setTheme(
      getTheme(
        !isAuthenticated
          ? clientZoneColor ?? DEFAULT_APP_COLOR
          : editedColor ?? color ?? DEFAULT_APP_COLOR,
      ),
    );
  }, [color, editedColor, isAuthenticated, clientZoneColor]);

  const onRefresh = () => {
    return new Promise(resolve => {
      window.location.reload();
      setTimeout(resolve, 200);
    });
  };

  function isPwa() {
    return ["fullscreen", "standalone", "minimal-ui"].some(
      displayMode =>
        window.matchMedia("(display-mode: " + displayMode + ")").matches,
    );
  }
  var location = useLocation();

  const isOnWhitelistedPage = () => {
    return location.pathname === getPath(AppRouting.Dashboard);
  };

  return (
    <>
      {theme && (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <StyledThemeProvider theme={theme}>
              <Toaster richColors position="top-left" />

              <PullToRefresh
                isPullable={isPwa() && isOnWhitelistedPage()}
                onRefresh={onRefresh}
                resistance={4}
                pullingContent={""}
              >
                <>{props.children}</>
              </PullToRefresh>
            </StyledThemeProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
};

export { Layout };
