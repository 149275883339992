import { Box, Typography } from "@mui/material";
import {
  EnterpriseServiceListItemDto,
  EnterpriseServiceVariantListItemDto,
  EnterpriseServiceVariantUpdateListItemDto,
} from "Api/Api";
import { APP_CURRENCY_CODE } from "App";
import { ServicesFormModel } from "Components/Settings/Services/ServicesForms";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { PriceTaxType } from "Constants/PriceTaxType";
import { calculatePriceByPriceTaxTax, useTaxLabels } from "Hooks/useWithTax";
import { formatCurrency, getCurrencySymbol } from "Utils/CurrencyUtils";
import { FieldPath, UseFormReturn } from "react-hook-form";
import styled from "styled-components";

const StyledVariantWrapper = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
  padding-right: ${props => props.theme.spacing(0)};

  .checkbox {
    margin: ${props => props.theme.spacing(1, 0, 3.4, 0)};
  }

  .input-wrapper {
    width: 140px;
    input {
      text-align: right;
      padding-right: ${props => props.theme.spacing(4)};
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:first-of-type {
    margin-top: ${props => props.theme.spacing(2)};
  }
`;

type Props = {
  serviceSettingServiceVariantID: number;
  formVariant: EnterpriseServiceVariantUpdateListItemDto;
  service: EnterpriseServiceListItemDto;
  form: UseFormReturn<ServicesFormModel>;
  onIsEnabledChange?: (isEnabled: boolean) => void;
};

export const ServiceVariant: React.FunctionComponent<Props> = props => {
  const {
    formVariant,
    service,
    form,
    serviceSettingServiceVariantID,
    onIsEnabledChange,
  } = props;
  const {
    control,
    setValue,
    trigger,
    watch,
    formState: { errors, isSubmitted },
  } = form;
  const { withTax, withoutTax } = useTaxLabels();

  const variant = service.variants.find(
    x =>
      x.serviceSettingServiceVariantID ===
      formVariant.serviceSettingServiceVariantID,
  );

  const services = watch("services");

  var indexes = services
    ?.map((x, serviceIndex) => {
      if (x.serviceSettingID !== service.serviceSettingID) {
        return null;
      }
      var serviceVariantIndex =
        x.serviceSettingID === service.serviceSettingID &&
        x.variants.findIndex(
          y =>
            y.serviceSettingServiceVariantID === serviceSettingServiceVariantID,
        );
      return serviceVariantIndex === -1
        ? null
        : [serviceIndex, serviceVariantIndex as number];
    })
    .filter(x => !!x)[0];

  const [serviceIndex, serviceVariantIndex] = indexes!;

  const getFieldName = (
    fieldName: "isEnabled" | "priceWithoutTax" | "priceWithTax",
  ): FieldPath<ServicesFormModel> => {
    return `services.${serviceIndex}.variants.${serviceVariantIndex}.${fieldName}`;
  };

  const onKeyUpPriceWithoutTax = (
    item: EnterpriseServiceVariantUpdateListItemDto,
    variant: EnterpriseServiceVariantListItemDto,
  ) => {
    const priceWithTax = calculatePriceByPriceTaxTax(
      item.priceWithoutTax!,
      variant.taxRate,
      PriceTaxType.WithoutTax,
    );
    setValue(getFieldName("priceWithTax"), priceWithTax);
  };

  const onKeyUpPriceWithTax = (
    item: EnterpriseServiceVariantUpdateListItemDto,
    variant: EnterpriseServiceVariantListItemDto,
  ) => {
    const priceWithoutTax = calculatePriceByPriceTaxTax(
      item.priceWithTax!,
      variant.taxRate,
      PriceTaxType.WithTax,
    );
    setValue(getFieldName("priceWithoutTax"), priceWithoutTax);
  };

  return (
    <StyledVariantWrapper key={formVariant.serviceSettingServiceVariantID}>
      <Box>
        <BlCheckboxInput
          control={control}
          errors={errors}
          name={getFieldName("isEnabled")}
          label={variant?.name}
          onChange={v => {
            onIsEnabledChange?.(v);
            isSubmitted && trigger(`services.${serviceIndex}.variants`);
          }}
        />
      </Box>

      {!!variant && formVariant.isEnabled && (
        <>
          {watch("priceTaxType") === PriceTaxType.WithoutTax && (
            <StyledFlex $alignItems="center" $gap={1.5}>
              <BlFormInput
                scale={2}
                control={control}
                errors={errors}
                name={getFieldName("priceWithoutTax")}
                isNumber
                mask={Number}
                min={0}
                max={99999}
                unit={getCurrencySymbol(APP_CURRENCY_CODE)}
                formControlClass="no-padding"
                onKeyUp={value => onKeyUpPriceWithoutTax(formVariant, variant!)}
              />

              <Typography
                variant="subtitle2"
                fontStyle={"normal"}
                paddingBottom={1.5}
              >
                {withTax(
                  formatCurrency(
                    calculatePriceByPriceTaxTax(
                      formVariant.priceWithoutTax!,
                      variant.taxRate!,
                      PriceTaxType.WithoutTax,
                    ),
                    APP_CURRENCY_CODE,
                  ),
                )}
              </Typography>
            </StyledFlex>
          )}
          {watch("priceTaxType") === PriceTaxType.WithTax && (
            <StyledFlex $alignItems="center" $gap={1.5}>
              <BlFormInput
                control={control}
                errors={errors}
                scale={2}
                name={getFieldName("priceWithTax")}
                isNumber
                mask={Number}
                min={0}
                max={Math.ceil(99999 * 1.21)}
                unit={getCurrencySymbol(APP_CURRENCY_CODE)}
                formControlClass="no-padding"
                onKeyUp={value => onKeyUpPriceWithTax(formVariant, variant!)}
              />

              <Typography
                variant="subtitle2"
                fontStyle={"normal"}
                paddingBottom={1.5}
              >
                {withoutTax(
                  formatCurrency(
                    calculatePriceByPriceTaxTax(
                      formVariant.priceWithTax!,
                      variant.taxRate,
                      PriceTaxType.WithTax,
                    ),
                    APP_CURRENCY_CODE,
                  ),
                )}
              </Typography>
            </StyledFlex>
          )}
        </>
      )}
    </StyledVariantWrapper>
  );
};
