import {
  DraftStepCompleteCommandResult,
  postOrdersPublicIDWorkflowDraftComplete,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { NavigateFunction } from "react-router";

export const completeDraftAsync = createAsyncAction(
  "@orderWorkflow/COMPLETE_DRAFT_REQUEST",
  "@orderWorkflow/COMPLETE_DRAFT_RESPONSE",
  "@orderWorkflow/COMPLETE_DRAFT_FAILURE",
)<
  {
    publicID: string;
    navigate: NavigateFunction;
  },
  DraftStepCompleteCommandResult,
  Error
>();

function* completeDraft(
  action: ReturnType<typeof completeDraftAsync.request>,
): Generator {
  const { publicID } = action.payload;
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowDraftComplete,
      publicID,
    );

    if (status === 200) {
      yield put(completeDraftAsync.success(data));
      window.location.reload();
      return;
    }

    yield put(completeDraftAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(completeDraftAsync.failure(err as Error));
  }
}
export function* completeDraftSaga() {
  yield takeLatest(getType(completeDraftAsync.request), completeDraft);
}

export const completeDraftSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<DraftStepCompleteCommandResult>(false),
  name: "@orderWorkflow/completeDraft",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<DraftStepCompleteCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(completeDraftAsync, builder);
    handleSuccessCase(completeDraftAsync, builder);
    handleFailureCase(completeDraftAsync, builder);
  },
});

export const { resetSlice } = completeDraftSlice.actions;
