import {
  SendClientAssignmentEmailCommandResult,
  postOrdersPublicIDWorkflowClientAssignmentSendAssignmentEmail,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const sendClientAssignmentEmailAsync = createAsyncAction(
  "@order/SEND_CLEINT_ASSIGNMENT_EMAIL_REQUEST",
  "@order/SEND_CLEINT_ASSIGNMENT_EMAIL_RESPONSE",
  "@order/SEND_CLEINT_ASSIGNMENT_EMAIL_FAILURE",
)<{ publicID: string }, SendClientAssignmentEmailCommandResult, Error>();

function* sendClientAssignmentEmail(
  action: ReturnType<typeof sendClientAssignmentEmailAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      postOrdersPublicIDWorkflowClientAssignmentSendAssignmentEmail,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(sendClientAssignmentEmailAsync.success(data));
      return;
    }

    yield put(
      sendClientAssignmentEmailAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(sendClientAssignmentEmailAsync.failure(err as Error));
  }
}
export function* sendClientAssignmentEmailSaga() {
  yield takeLatest(
    getType(sendClientAssignmentEmailAsync.request),
    sendClientAssignmentEmail,
  );
}

export const sendClientAssignmentEmailSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<SendClientAssignmentEmailCommandResult>(false),
  name: "@order/sendClientAssignmentEmail",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<SendClientAssignmentEmailCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(sendClientAssignmentEmailAsync, builder);
    handleSuccessCase(sendClientAssignmentEmailAsync, builder);
    handleFailureCase(sendClientAssignmentEmailAsync, builder);
  },
});

export const { resetSlice } = sendClientAssignmentEmailSlice.actions;
