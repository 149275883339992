import {
  EntityListOfServicePackageListItemDto,
  getServicesPackages,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getServicePackagesAsync = createAsyncAction(
  "@servicePackages/GET_SERVICE_PACKAGES_REQUEST",
  "@servicePackages/GET_SERVICE_PACKAGES_RESPONSE",
  "@servicePackages/GET_SERVICE_PACKAGES_FAILURE",
)<
  { offset: number; limit: number },
  EntityListOfServicePackageListItemDto,
  Error
>();

function* getServicePackages(
  action: ReturnType<typeof getServicePackagesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getServicesPackages,
      action.payload.offset,
      action.payload.limit,
    );

    if (status === 200) {
      yield put(getServicePackagesAsync.success(data));
      return;
    }

    yield put(
      getServicePackagesAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getServicePackagesAsync.failure(err as Error));
  }
}
export function* getServicePackagesSaga() {
  yield takeLatest(
    getType(getServicePackagesAsync.request),
    getServicePackages,
  );
}

export const getServicePackagesSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfServicePackageListItemDto>(false),
  name: "@servicePackages/getServicePackages",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfServicePackageListItemDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getServicePackagesAsync, builder);
    handleSuccessCase(getServicePackagesAsync, builder);
    handleFailureCase(getServicePackagesAsync, builder);
  },
});

export const { resetSlice: getServicePackagesSliceReset } =
  getServicePackagesSlice.actions;
