import { yupResolver } from "@hookform/resolvers/yup";
import { Typography, Divider } from "@mui/material";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { StyledDialog } from "Components/Shared/Dialogs/DialogStyles";
import { CrossIcon } from "Components/Shared/Icons";
import { BlDatePicker } from "Components/Shared/Inputs/DatePickers/BlDatePicker";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import useHandleServerError from "Hooks/Form/useHandleServerError";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import {
  createEnterprisesCommissionSettingsAsync,
  resetError,
} from "State/Enterprises/Commission/CreateCommissionSettingsSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { toLocalTimeZoneString } from "Utils/DateUtils";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { object, date, number, ObjectSchema } from "yup";

const StyledInputsWrapper = styled(StyledFlex)`
  & > div {
    flex: 1;
  }

  label {
    color: ${({ theme }) => theme.palette.text.primary};
  }

  .Mui-disabled.MuiInputBase-formControl {
    background-color: transparent;

    .MuiInputBase-input {
      color: ${({ theme }) => theme.palette.text.primary};
      -webkit-text-fill-color: ${({ theme }) => theme.palette.text.primary};
      opacity: 0.5;
    }
  }
`;

type Props = {
  lastCommissionSettings: {
    enterpriseCommissionRate: number;
    advisorCommissionRate: number;
  };
  onClosed: () => void;
};

type FormModel = {
  dateFrom: Date;
  enterpriseCommissionRate: number;
  advisorCommissionRate: number;
};

export const CommissionDialogForm: React.FunctionComponent<Props> = props => {
  const { lastCommissionSettings, onClosed } = props;
  const { t } = useResource();

  const validationSchema: ObjectSchema<FormModel> = object({
    dateFrom: date().required(),
    enterpriseCommissionRate: number()
      .required()
      .test({
        test: (v, c: { parent: FormModel }) => {
          return v + c.parent.advisorCommissionRate === 100;
        },
        exclusive: false,
        message: t(Resources.Settings.Commission.TotalCommission),
      }),
    advisorCommissionRate: number().required(),
  }).defined();

  const form = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      dateFrom: new Date(),
      enterpriseCommissionRate:
        lastCommissionSettings.enterpriseCommissionRate * 100,
      advisorCommissionRate: lastCommissionSettings.advisorCommissionRate * 100,
    },
  });

  const {
    control,
    watch,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    formState: { errors },
  } = form;

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    const isValid = await trigger();
    if (!isValid) {
      return;
    }

    handleSubmit((formModel: FormModel) => {
      dispatch(
        createEnterprisesCommissionSettingsAsync.request({
          advisorCommissionRate: formModel.advisorCommissionRate / 100,
          enterpriseCommissionRate: formModel.enterpriseCommissionRate / 100,
          dateFrom: toLocalTimeZoneString(formModel.dateFrom),
        }),
      );
    })();
  };

  const { error } = useAppSelector(e => e.enterprise.createCommissionSettings);

  useHandleServerError({
    form,
    formKey: "dateFrom",
    error,
    resource: Resources.Commission.Create.Validation,
  });
  return (
    <>
      <StyledDialog open={true} onClose={onClosed}>
        <StyledFlex $alignItems="center" $justifyContent="space-between">
          <Typography variant="h2">
            {t(Resources.Commission.Create.Title)}
          </Typography>

          <BlDefaultButton
            title={t(Resources.Common.Close)}
            onClick={() => onClosed()}
          >
            <CrossIcon />
          </BlDefaultButton>
        </StyledFlex>

        <Divider sx={{ marginTop: 1, marginBottom: 2 }} />

        <div>
          <StyledFlex $gap={0.5} $alignItems="center">
            <Typography variant="subtitle2" fontStyle={"normal"}>
              {t(Resources.Commission.Create.Description)}
            </Typography>

            <BlDefaultTooltip title={t(Resources.Commission.Create.Tooltip)} />
          </StyledFlex>

          <StyledInputsWrapper $gap={1} $marginTop={2.5} $marginBottom={0.5}>
            <BlDatePicker
              label={t(Resources.Commission.Create.DateFrom)}
              name="dateFrom"
              value={watch("dateFrom")}
              onChange={v => {
                setValue("dateFrom", v ?? ("" as unknown as Date));
                dispatch(resetError());
              }}
              hasError={!!errors.dateFrom}
              errorMessage={errors.dateFrom?.message}
              disablePast
              readOnlyField
            />
          </StyledInputsWrapper>
          <StyledInputsWrapper $gap={1} $marginBottom={0.5}>
            <BlFormInput
              control={control}
              errors={errors}
              name={"enterpriseCommissionRate"}
              label={t(Resources.Commission.Create.EnterpriseCommissionRate)}
              mask={Number}
              isNumber
              max={100}
              min={0}
              unit="%"
              onChange={() => {
                setValue(
                  "advisorCommissionRate",
                  100 - getValues("enterpriseCommissionRate"),
                );
              }}
            />

            <BlFormInput
              control={control}
              errors={errors}
              name={"advisorCommissionRate"}
              label={t(Resources.Commission.Create.AdvisorCommissionRate)}
              mask={Number}
              isNumber
              max={100}
              min={0}
              unit="%"
              onChange={() => {
                setValue(
                  "enterpriseCommissionRate",
                  100 - getValues("advisorCommissionRate"),
                );
              }}
            ></BlFormInput>
          </StyledInputsWrapper>

          <StyledFlex $alignItems="center" $gap={1}>
            <BlButton color="primary" fullWidth onClick={() => onSubmit()}>
              {t(Resources.Common.Save_FirstUppercase)}
            </BlButton>

            <BlButton color="secondary" fullWidth onClick={() => onClosed()}>
              {t(Resources.Common.Cancel)}
            </BlButton>
          </StyledFlex>
        </div>
      </StyledDialog>
    </>
  );
};
