import { Typography } from "@mui/material";
import {
  OrderDto,
  OrderPaymentFrequency,
  OrderPaymentType,
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  StepType,
} from "Api/Api";
import { AutomationDisclaimer } from "Components/Orders/Detail/Steps/Shared/AutomationDisclaimer";
import { StyledWorkflowButton } from "Components/Orders/Detail/Steps/WorkflowStep";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { completeClientPrepaidPaymentApprovalAsync } from "State/OrderWorkflow/Slices/CompleteClientPrepaidPaymentApprovalSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import { useTheme } from "styled-components";

type Props = {
  publicID: string;
  order: OrderDto | null | undefined;
  step: OrderWorkflowStepDto;
  steps: OrderWorkflowStepDto[];
  isReadOnly: boolean;
};

const PageResources =
  Resources.Orders.Workflow.Workflow.ClientPrepaidPaymentApprovalStep;

export const ClientPrepaidPaymentApprovalStep: React.FunctionComponent<
  Props
> = props => {
  const { step, steps, publicID, order, isReadOnly } = props;

  const dispatch = useAppDispatch();
  const { t } = useResource();

  const { isLoading: isLoadingCompleteClientPrepaidPaymentApproval } =
    useAppSelector(e => e.orderWorkflow.completeClientPrepaidPaymentApproval);
  const theme = useTheme();

  if (
    step.status === OrderWorkflowStepStatus.Completed &&
    order?.isZeroPrice &&
    order.paymentFrequency === OrderPaymentFrequency.Single &&
    order.paymentType === OrderPaymentType.Prepaid
  ) {
    return (
      <Typography
        fontWeight={500}
        align="left"
        color={theme.palette.error.main}
      >
        {t(
          Resources.Orders.Workflow.Workflow.ClientInvoicePaymentStep.ZeroPrice,
        )}
      </Typography>
    );
  }

  const isNotDisplayed =
    step.status === OrderWorkflowStepStatus.Completed ||
    steps.some(
      x =>
        x.type === StepType.ClientReview &&
        x.status !== OrderWorkflowStepStatus.Completed,
    ) ||
    steps.some(
      x =>
        x.type === StepType.ClientInvoiceIssuance &&
        x.status !== OrderWorkflowStepStatus.Completed,
    );

  if (isNotDisplayed || !order) {
    return null;
  }

  const wasRejected = step.status === OrderWorkflowStepStatus.Rejected;
  const inProgress = step.status === OrderWorkflowStepStatus.InProgress;

  const complete = () => {
    dispatch(
      completeClientPrepaidPaymentApprovalAsync.request({
        publicID: publicID,
        request: {},
      }),
    );
  };

  return (
    <>
      {!wasRejected &&
        order.paymentFrequency === OrderPaymentFrequency.Single && (
          <>
            {inProgress && (
              <>
                <Typography>
                  {t(PageResources.ClientApproveInfo, {
                    date: formatDate(step.dateInProgressStarted, true),
                  })}
                </Typography>

                <AutomationDisclaimer
                  companyPublicID={order.supplierCompanyPublicID}
                  isPrepaid={false}
                />
                <StyledFlex $gap={1.5} $alignItems="center">
                  <StyledWorkflowButton
                    onClick={complete}
                    color="primary"
                    isLoading={isLoadingCompleteClientPrepaidPaymentApproval}
                    disabled={isReadOnly}
                  >
                    {t(PageResources.Pay)}
                  </StyledWorkflowButton>
                </StyledFlex>
              </>
            )}
          </>
        )}
    </>
  );
};
