import { Typography } from "@mui/material";
import { ClientConsents } from "Components/Orders/Draft/ClientConsents";
import { OrderFormModel } from "Components/Orders/Draft/StepFormValidationSchema";
import { PartyForm, PartyFormType } from "Components/Shared/Party/PartyForm";
import { PartyFormModel } from "Components/Shared/Party/PartyFormValidationSchema";
import { StyledContentWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { UseFormReturn } from "react-hook-form";

type Props = {
  form: UseFormReturn<OrderFormModel>;
  isLoading: boolean;
  isReadOnly: boolean;
};

export const ClientFields: React.FunctionComponent<Props> = props => {
  const { form, isLoading, isReadOnly } = props;
  const { t } = useResource();

  return (
    <StyledContentWrapper>
      <Typography variant="h2" marginBottom={2}>
        {t(Resources.Orders.Detail.Client.Title)}
      </Typography>

      <PartyForm
        form={form as unknown as UseFormReturn<PartyFormModel>}
        isLoading={isLoading}
        isReadOnly={isReadOnly}
        isPartyTypeEditable
        isPartyStatusEditable
        partyFormType={PartyFormType.OrderClient}
        clientConsentsChildren={
          <ClientConsents
            form={form}
            isLoading={isLoading}
            isReadOnly={isReadOnly}
          />
        }
      />
    </StyledContentWrapper>
  );
};
