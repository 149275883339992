import {
  ClientApprovalStepInitCommandResult,
  putOrdersWorkflowClientApprovalTokenInProgress,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const sendSignatureCodeAsync = createAsyncAction(
  "@clientZone/SEND_SIGNATURE_CODE_REQUEST",
  "@clientZone/SEND_SIGNATURE_CODE_RESPONSE",
  "@clientZone/SEND_SIGNATURE_CODE_FAILURE",
)<
  {
    token: string;
  },
  ClientApprovalStepInitCommandResult,
  Error
>();

function* sendSignatureCode(
  action: ReturnType<typeof sendSignatureCodeAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      putOrdersWorkflowClientApprovalTokenInProgress,
      action.payload.token,
    );

    if (status === 200) {
      yield put(sendSignatureCodeAsync.success(data));
      return;
    }

    yield put(
      sendSignatureCodeAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(sendSignatureCodeAsync.failure(err as Error));
  }
}
export function* sendSignatureCodeSaga() {
  yield takeLatest(getType(sendSignatureCodeAsync.request), sendSignatureCode);
}

export const sendSignatureCodeSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<ClientApprovalStepInitCommandResult>(false),
  name: "@clientZone/sendSignatureCode",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<ClientApprovalStepInitCommandResult>(false),
  },
  extraReducers: builder => {
    handleRequestCase(sendSignatureCodeAsync, builder);
    handleSuccessCase(sendSignatureCodeAsync, builder);
    handleFailureCase(sendSignatureCodeAsync, builder);
  },
});

export const { resetSlice } = sendSignatureCodeSlice.actions;
