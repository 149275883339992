import { UnknownAction } from "@reduxjs/toolkit";
import { logError } from "ErrorService";
import { ApiCallError } from "Models/Errors/ApiCallError";
import { ApplicationError } from "Models/Errors/ApplicationError";
import { history } from "Routes/AppHistory";
import { put, takeEvery } from "typed-redux-saga";

function* handleFailure(action: UnknownAction): Generator {
  if (action.type.endsWith("_FAILURE")) {
    const payload = action.payload as any;

    const cause = payload.cause;

    if (
      isError(payload) &&
      !isApplicationError(payload) &&
      !isApiCallError(payload)
    ) {
      if (cause !== 201 && cause !== 401 && cause !== 503) {
        const isUserDialogEnabled =
          import.meta.env.VITE_SENTRY_IS_USER_DIALOG_ENABLED === "true";
        logError(payload, null, isUserDialogEnabled);
      }
    }

    if (isApiCallError(payload)) {
      if (cause === 403) {
        console.log(payload);
        history.push("/403");
        window.location.reload();
      }
      // const cause = getCause(payload);
      // if (!!cause) {
      //   toast.error(`Chyba při komunikaci s API.`);
      //   captureEvent({
      //     message: `Problem while calling API.`,
      //     level: "log",
      //     extra: {
      //       apiCallError: payload,
      //     },
      //   });
      // }
    }
    yield put({ type: "EMPTY" });
  }
}

const isError = (o: any) => o instanceof Error;
const isApplicationError = (o: any) => o instanceof ApplicationError;
const isApiCallError = (o: any) => o instanceof ApiCallError;

export function* errorSaga() {
  yield takeEvery("*", handleFailure);
}
