import { Box, Typography } from "@mui/material";
import { CompanyFormModel } from "Components/Companies/CompanyDetail";
import { Logo } from "Components/Layout/Logo";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { StyledValidationText } from "Components/Shared/FormStyles";
import { BlTextInput } from "Components/Shared/Inputs/BlTextInput";
import { BlCheckboxInput } from "Components/Shared/Inputs/Form/BlCheckboxInput";
import { BlFormInput } from "Components/Shared/Inputs/Form/BlFormInput";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { getBase64Images } from "Utils/FileUtils";
import { UseFormReturn } from "react-hook-form";
import { toast } from "sonner";
import styled from "styled-components";

type Props = {
  form: UseFormReturn<CompanyFormModel>;
  isDefaultFieldVisible: boolean;
  isLogoVisible: boolean;
  canBeSelectedToOrderFieldVisible: boolean;
};

const StyledLogoWrapper = styled.div`
  display: grid;
  grid-template-columns: 340px auto;
  justify-content: flex-start;
  align-items: center;
  gap: ${props => props.theme.spacing(4)};
`;

export const OtherInformation: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const {
    form,
    isDefaultFieldVisible,
    isLogoVisible,
    canBeSelectedToOrderFieldVisible,
  } = props;
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = form;

  const logoBase64 = watch("designSettings.logoBase64");

  return (
    <>
      <StyledFlex
        $alignItems="center"
        $gap={0.5}
        $marginTop={3}
        $marginBottom={2}
      >
        <Typography variant="h2">
          {t(Resources.Companies.Detail.OtherInformation.Title)}
        </Typography>
        <BlDefaultTooltip
          title={t(Resources.Companies.Detail.OtherInformation.Tooltip)}
        />
      </StyledFlex>
      {isDefaultFieldVisible && (
        <>
          <BlCheckboxInput
            control={control}
            errors={errors}
            name={"isDefault"}
            onChange={v => {
              if (v) {
                setValue("canBeSelectedToOrderDisabled", false);
              }
            }}
            label={t(Resources.Companies.Detail.OtherInformation.IsDefault)}
          />
          <Box marginBottom={3} />
        </>
      )}
      {canBeSelectedToOrderFieldVisible && (
        <>
          <StyledFlex $alignItems="center" $gap={0.5} $marginBottom={2}>
            <BlCheckboxInput
              control={control}
              errors={errors}
              disabled={watch("isDefault")}
              name={"canBeSelectedToOrderDisabled"}
              label={t(
                Resources.Companies.Detail.OtherInformation
                  .CanBeSelectedToOrder,
              )}
            />
            <BlDefaultTooltip
              title={t(
                Resources.Companies.Detail.OtherInformation
                  .CanBeSelectedToOrderTooltip,
              )}
            />
          </StyledFlex>

          <Box marginBottom={3} />
        </>
      )}

      <Typography variant="subtitle2" fontStyle={"normal"}>
        {t(
          Resources.Companies.Detail.OtherInformation
            .InvoiceNumberSeriesDescription,
        )}
      </Typography>
      <Box sx={{ maxWidth: "265px" }} marginTop={1}>
        <BlFormInput
          control={control}
          errors={errors}
          name={"invoiceNumberSeries"}
          label={t(
            Resources.Companies.Detail.OtherInformation.InvoiceNumberSeries,
          )}
          placeholder={t(
            Resources.Companies.Detail.OtherInformation
              .InvoiceNumberSeriesPlaceholder,
          )}
          mask={"000000000"}
        />
      </Box>
      <StyledValidationText>
        {t(
          Resources.Companies.Detail.OtherInformation
            .InvoiceNumberSeriesWarning,
        )}
      </StyledValidationText>
      {isLogoVisible && (
        <>
          <Box marginTop={2}>
            <StyledFlex $alignItems="center" $gap={0.5} $marginBottom={2}>
              <Typography variant="h2">
                {t(Resources.Companies.Detail.Logo.Title)}
              </Typography>
              <BlDefaultTooltip
                title={t(Resources.Companies.Detail.Logo.Tooltip)}
              />
            </StyledFlex>
            <StyledLogoWrapper>
              <BlTextInput
                label={t(Resources.Companies.Detail.Logo.Label)}
                type="file"
                accept="image/png, image/jpeg, image/svg+xml"
                placeholder={t(Resources.Companies.Detail.Logo.Placeholder)}
                onInputChange={async f => {
                  const maxSizeMB = 10;
                  const maxSize = maxSizeMB * 1024 * 1024;

                  const fileList = (f as React.ChangeEvent<HTMLInputElement>)
                    .target.files;
                  const files = Array.from(fileList ?? []);

                  const isTooBig = files.some(file => file.size > maxSize);
                  if (isTooBig) {
                    toast.error(
                      t(Resources.Validation.FileTooBig, {
                        maxSize: maxSizeMB,
                      }),
                    );

                    f.target.value = "";
                    return;
                  }

                  setValue(
                    "designSettings.logoBase64",
                    (await getBase64Images(fileList))?.[0] ?? "",
                  );
                }}
              />

              <Logo
                enterpriseLogo={logoBase64 ?? undefined}
                isTenantLogoVisible={false}
                isUserLogoVisible={false}
              />
            </StyledLogoWrapper>
          </Box>
        </>
      )}
    </>
  );
};
