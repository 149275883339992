import { Typography } from "@mui/material";
import {
  EnterpriseEmailCommunicationMode,
  OrderDto,
  OrderPaymentType,
  OrderServiceDto,
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  PartyDto,
} from "Api/Api";
import { Provider } from "Components/Orders/Summary/Provider";
import { Services } from "Components/Orders/Summary/Services";
import { Client } from "Components/Orders/Summary/Client";
import {
  StyledContentWrapper,
  StyledFlex,
} from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { styled, useTheme } from "styled-components";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { baseLinkStyles } from "Components/Routes/UnstyledLink";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { FileTextIcon } from "Components/Shared/Icons/FileText";

export const StyledOrderSummaryText = styled(Typography)`
  color: ${props => props.theme.palette.text.secondary};
`;
const TermsWrapper = styled.div`
  margin-top: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(1)};
`;

const StyledDocumentLink = styled.a`
  ${baseLinkStyles}
  font-size: 12px;
`;
type Props = {
  order: OrderDto | null | undefined;
  clientParty: PartyDto | null | undefined;
  supplierParty: PartyDto | null | undefined;
  orderServices: OrderServiceDto[] | null | undefined;
  isLoading: boolean;
  clientApprovalStep?: OrderWorkflowStepDto;
  clientPrepaidPaymentApprovalStep?: OrderWorkflowStepDto;
  enterpriseEmailCommunicationMode:
    | EnterpriseEmailCommunicationMode
    | undefined;
  userFormatName: string;
  token: string | null | undefined;
};

export const OrderSummary: React.FunctionComponent<Props> = props => {
  const {
    order,
    clientParty,
    supplierParty,
    orderServices,
    isLoading,
    clientApprovalStep,
    clientPrepaidPaymentApprovalStep,
    enterpriseEmailCommunicationMode,
    userFormatName,
    token,
  } = props;
  const { t } = useResource();
  const theme = useTheme();

  const approvalStep =
    order?.paymentType === OrderPaymentType.Prepaid
      ? clientPrepaidPaymentApprovalStep
      : clientApprovalStep;

  const canShowTerms =
    !!token &&
    (approvalStep?.status === OrderWorkflowStepStatus.Completed ||
      (approvalStep?.status === OrderWorkflowStepStatus.InProgress &&
        order?.paymentType === OrderPaymentType.Prepaid));
  return (
    <>
      <StyledContentWrapper $fullPadding>
        {isLoading ? (
          <>
            <BlSkeleton height={200} />
            <BlSkeleton height={100} />
            <BlSkeleton height={50} />
            <BlSkeleton height={50} />
            <BlSkeleton height={50} />
          </>
        ) : (
          <>
            {!!order && (
              <StyledOrderSummaryText marginBottom={3}>
                {t(Resources.Orders.Detail.Summary.OrderNumber, {
                  number: order.number,
                })}
              </StyledOrderSummaryText>
            )}

            {!!supplierParty && !!order && (
              <Provider
                party={supplierParty}
                order={order}
                enterpriseEmailCommunicationMode={
                  enterpriseEmailCommunicationMode
                }
                userFormatName={userFormatName}
              />
            )}

            {!!clientParty && <Client party={clientParty} />}

            {!!orderServices && !!order && (
              <Services
                order={order}
                orderServices={orderServices}
                supplierParty={supplierParty}
                clientApprovalStep={clientApprovalStep}
                clientPrepaidPaymentApprovalStep={
                  clientPrepaidPaymentApprovalStep
                }
              />
            )}

            {!!order?.note && (
              <>
                <Typography variant="h2" marginBottom={1} marginTop={3}>
                  {t(Resources.Orders.Detail.Summary.Note)}
                </Typography>
                <StyledOrderSummaryText>{order.note}</StyledOrderSummaryText>
              </>
            )}
            {canShowTerms && (
              <TermsWrapper>
                <StyledFlex $marginBottom={1}>
                  <FileTextIcon
                    color={theme.palette.primary.main}
                  ></FileTextIcon>
                  <StyledDocumentLink
                    href={getPath(
                      AppRouting.ClientZoneTermsAndConditions,
                      token,
                      false,
                    )}
                    target="_blank"
                  >
                    {t(Resources.Orders.Detail.Summary.ApprovalTerms)}
                  </StyledDocumentLink>
                </StyledFlex>
                <StyledFlex>
                  <FileTextIcon
                    color={theme.palette.primary.main}
                  ></FileTextIcon>
                  <StyledDocumentLink
                    href={getPath(AppRouting.ClientZoneGDPR, token, false)}
                    target="_blank"
                  >
                    {t(Resources.Orders.Detail.Summary.GDPR)}
                  </StyledDocumentLink>
                </StyledFlex>
              </TermsWrapper>
            )}
          </>
        )}
      </StyledContentWrapper>
    </>
  );
};
