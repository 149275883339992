import { Stack } from "@mui/material";
import { PaymentCalendarItemStatus } from "Api/Api";
import { OrderDetailIconButton } from "Components/Orders/Detail/Styles";
import { ListLayoutIcon, ContractIcon } from "Components/Shared/Icons";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getInvoicePdfAsync } from "State/Invoice/GetInvoicePdf";
import { getInvoiceXmlAsync } from "State/Invoice/GetInvoiceXml";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  status: PaymentCalendarItemStatus;
  invoicePublicID: string | null | undefined;
  invoiceForClientByOrderNumber: number | null | undefined;
};

export const InvoiceForClientByOrderActions: React.FunctionComponent<
  Props
> = props => {
  const { invoicePublicID, invoiceForClientByOrderNumber, status } = props;
  const { t } = useResource();
  const dispatch = useAppDispatch();

  if (
    !invoicePublicID ||
    !invoiceForClientByOrderNumber ||
    ![
      PaymentCalendarItemStatus.PaidByClient,
      PaymentCalendarItemStatus.CommissionPaid,
    ].includes(status)
  ) {
    return null;
  }

  return (
    <Stack
      direction={"row"}
      justifyItems={"center"}
      alignItems={"center"}
      justifyContent={"flex-end"}
      gap={0.5}
    >
      <OrderDetailIconButton
        title={
          invoicePublicID
            ? t(Resources.Orders.Detail.Icons.ISDOC)
            : t(Resources.Orders.Detail.Icons.ISDOCNotAvailable)
        }
        disabled={!invoicePublicID}
        onClick={() => {
          if (!invoicePublicID) {
            return;
          }
          return dispatch(
            getInvoiceXmlAsync.request({
              invoicePublicID: invoicePublicID,
              invoiceNumber: invoiceForClientByOrderNumber!,
            }),
          );
        }}
      >
        <ListLayoutIcon />
      </OrderDetailIconButton>

      <OrderDetailIconButton
        title={
          invoicePublicID
            ? t(Resources.Orders.Detail.Icons.Pdf)
            : t(Resources.Orders.Detail.Icons.PdfNotAvailable)
        }
        disabled={!invoicePublicID}
        onClick={() => {
          if (!invoicePublicID) {
            return;
          }

          return dispatch(
            getInvoicePdfAsync.request({
              invoicePublicID: invoicePublicID,
              invoiceNumber: invoiceForClientByOrderNumber!,
            }),
          );
        }}
      >
        <ContractIcon />
      </OrderDetailIconButton>
    </Stack>
  );
};
