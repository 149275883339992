import { Typography, Box, Stack } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { useHasCompaniesAccess } from "Hooks/AccessRights/useHasCompaniesAccess";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { getCompanyFioSettingsAsync } from "State/Companies/Detail/GetCompanyFioSettingsSlice";
import { useAppSelector } from "State/Store";
import { useResource, Resources } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useEffect } from "react";

type Props = {
  companyPublicID: string;
  isPrepaid: boolean;
};

export const AutomationDisclaimer: React.FunctionComponent<Props> = props => {
  const { companyPublicID, isPrepaid } = props;
  const { t } = useResource();

  const dispatch = useAppDispatch();
  const PageResources = isPrepaid
    ? Resources.Orders.Workflow.Workflow.ClientInvoicePaymentStep
    : Resources.Orders.Workflow.Workflow.ClientPrepaidPaymentApprovalStep;

  useEffect(() => {
    if (companyPublicID) {
      dispatch(
        getCompanyFioSettingsAsync.request({
          publicID: companyPublicID,
        }),
      );
    }
  }, [companyPublicID, dispatch]);

  const { data } = useAppSelector(e => e.company.fioSettings);
  const hasCompaniesAccess = useHasCompaniesAccess();
  const isAutomationEnabled =
    data?.isEnabled && data?.isMatchingPaymentsEnabled;

  return (
    <>
      {isAutomationEnabled ? (
        <Typography marginTop={2} marginBottom={2}>
          {t(PageResources.Automation.IsEnabled)}
        </Typography>
      ) : (
        <Box mt={2} mb={2}>
          <Typography>
            {t(PageResources.Automation.IsDisabled.Part1)}
          </Typography>

          {hasCompaniesAccess ? (
            <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
              <Typography>
                {t(PageResources.Automation.IsDisabled.Part2)}
              </Typography>

              <Stack direction={"row"} gap={"1px"} alignItems={"center"}>
                <UnstyledLink
                  to={getPath(
                    AppRouting.Settings,
                    t(Resources.Settings.Companies.Url),
                  )}
                >
                  <Typography
                    variant="subtitle2"
                    fontStyle={"normal"}
                    fontWeight={"bold"}
                    color="primary"
                  >
                    {t(PageResources.Automation.IsDisabled.Link)}
                  </Typography>
                </UnstyledLink>

                <Typography>.</Typography>
              </Stack>
            </Stack>
          ) : (
            <Typography>
              {t(PageResources.Automation.IsDisabled.ContactAdmin)}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
