import { OrderWorkflowStepStatus, StepType, WorkflowType } from "Api/Api";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  currentStepType: StepType;
  currentStepStatus: OrderWorkflowStepStatus;
  lastCompleteStepType: StepType;
  lastCompleteStepStatus: OrderWorkflowStepStatus;
  workflowType: WorkflowType;
  areAllStepsCompleted: boolean;
  dateOfInvoiceAfterDueDate?: string | null;
};

export const useStepTranslation = (props: Props) => {
  const {
    currentStepType,
    currentStepStatus,
    lastCompleteStepType,
    workflowType,
    areAllStepsCompleted,
    dateOfInvoiceAfterDueDate,
  } = props;
  const { t } = useResource();

  if (dateOfInvoiceAfterDueDate) {
    return t(Resources.StepType.InvoiceAfterDueDate);
  }

  if (areAllStepsCompleted) {
    return t(Resources.StepType[StepType.Completed]);
  }

  if (
    currentStepType === StepType.ClientApproval &&
    currentStepStatus === OrderWorkflowStepStatus.Rejected
  ) {
    return t(Resources.StepType.ClientApprovalRejected);
  }

  if (
    currentStepType === StepType.ClientPrepaidPaymentApproval &&
    currentStepStatus === OrderWorkflowStepStatus.Rejected
  ) {
    return t(Resources.StepType.ClientPrepaidPaymentRejected);
  }

  if (
    ((currentStepType === StepType.ClientPrepaidPaymentApproval &&
      currentStepStatus !== OrderWorkflowStepStatus.Rejected) ||
      lastCompleteStepType === StepType.ClientPrepaidPaymentApproval) &&
    [
      WorkflowType.PaidAdvisoryPrepaidRecurringPaymentFree,
      WorkflowType.PaidAdvisoryPrepaidRecurringPaymentStrict,
    ].includes(workflowType)
  ) {
    return t(Resources.StepType.ClientPrepaidPaymentApprovalRecurring);
  }

  if (
    ((currentStepType === StepType.ClientPrepaidPaymentApproval &&
      currentStepStatus !== OrderWorkflowStepStatus.Rejected &&
      currentStepStatus === OrderWorkflowStepStatus.Completed) ||
      lastCompleteStepType === StepType.ClientPrepaidPaymentApproval) &&
    [
      WorkflowType.PaidAdvisoryPrepaidPaymentFree,
      WorkflowType.PaidAdvisoryPrepaidPaymentStrict,
    ].includes(workflowType)
  ) {
    return t(Resources.StepType.ClientPrepaidPaymentApprovalSingle);
  }

  if (
    currentStepType === StepType.ClientInvoiceIssuance &&
    currentStepStatus === OrderWorkflowStepStatus.Rejected
  ) {
    return t(Resources.StepType.ClientInvoiceIssuanceRejected);
  }

  if (
    currentStepType === StepType.ClientApproval &&
    currentStepStatus === OrderWorkflowStepStatus.Completed &&
    [
      WorkflowType.PaidAdvisoryRecurringPaymentFree,
      WorkflowType.PaidAdvisoryRecurringPaymentStrict,
    ].includes(workflowType)
  ) {
    return t(Resources.StepType[StepType.ClientInvoiceRecurringPayment]);
  }
  return t(Resources.StepType[lastCompleteStepType]);
};
