import { Typography } from "@mui/material";
import {
  OrderDto,
  OrderServiceDto,
  OrderServiceStatus,
  OrderWorkflowStepDto,
  OrderWorkflowStepStatus,
  StepType,
} from "Api/Api";
import { CompletedChip } from "Components/Orders/Detail/Steps/ProcessingServicesStep/CompletedChip";
import { RevocationDisclaimer } from "Components/Orders/Detail/Steps/Shared/RevocationDisclaimer";
import { BlConfirmButton } from "Components/Shared/Buttons/BlConfirmButton";
import { useAppDispatch } from "Hooks/State/useAppDispatch";
import { completeOrderServiceAsync } from "State/Orders/Detail/CompleteOrderServiceSlice";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { orderBy } from "lodash-es";
import { styled } from "styled-components";

const StyledDescription = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`;

const StyledWrapper = styled.div`
  display: grid;
  justify-items: flex-start;
`;

const StyledServicesWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const StyledService = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: ${({ theme }) => theme.spacing(3)};
  align-items: center;
  border-radius: 7px;
  padding: ${({ theme }) => theme.spacing(1, 1.5)};
  background-color: ${({ theme }) => theme.colors.gray};

  .MuiButtonBase-root {
    width: 250px;
    font-size: 12px;
    font-weight: 700;
    background-color: ${({ theme }) => theme.palette.common.white};
    padding: ${({ theme }) => theme.spacing(0.6, 2.5)};
  }
`;

type Props = {
  publicID: string;
  step: OrderWorkflowStepDto;
  steps: OrderWorkflowStepDto[];
  services: OrderServiceDto[] | null | undefined;
  order: OrderDto | null | undefined;
  isReadOnly: boolean;
};

export const ProcessingServicesStep: React.FunctionComponent<Props> = props => {
  const { publicID, steps, services, step, order, isReadOnly } = props;
  const { t } = useResource();

  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector(e => e.order.completeOrderService);

  if (!services) {
    return null;
  }

  const canBeShown =
    steps.some(
      x =>
        x.type === StepType.ProcessingServices &&
        x.status === OrderWorkflowStepStatus.Completed,
    ) ||
    steps.some(
      x =>
        x.type === StepType.ClientApproval &&
        x.status === OrderWorkflowStepStatus.Completed,
    ) ||
    steps.some(
      x =>
        x.type === StepType.ClientPrepaidPaymentApproval &&
        x.status === OrderWorkflowStepStatus.Completed,
    );

  if (!canBeShown) {
    return null;
  }

  const completeService = (service: OrderServiceDto) => {
    dispatch(
      completeOrderServiceAsync.request({
        publicID,
        orderServiceID: service.orderServiceID,
      }),
    );
  };

  return (
    <StyledWrapper>
      {services.some(x => x.status === OrderServiceStatus.NotStarted) && (
        <>
          {[
            OrderWorkflowStepStatus.NotStarted,
            OrderWorkflowStepStatus.InProgress,
          ].includes(step.status) && (
            <RevocationDisclaimer
              paymentFrequency={order?.paymentType}
              steps={steps}
            />
          )}

          <StyledDescription>
            {t(
              Resources.Orders.Workflow.Workflow.ProcessingServicesStep
                .Description,
            )}
          </StyledDescription>
        </>
      )}

      <StyledServicesWrapper>
        {orderBy(services, x => x.orderServiceID).map(x => (
          <StyledService key={x.orderServiceID}>
            <Typography>{x.serviceName}</Typography>

            {x.status === OrderServiceStatus.NotStarted && (
              <BlConfirmButton
                modalTitle={t(
                  Resources.Orders.Workflow.Workflow.ProcessingServicesStep
                    .Confirm.Title,
                )}
                modalContent={t(
                  Resources.Orders.Workflow.Workflow.ProcessingServicesStep
                    .Confirm.Content,
                )}
                onResult={r => r && completeService(x)}
                isLoading={isLoading}
                variant="secondary"
                disabled={isReadOnly}
              >
                {t(
                  Resources.Orders.Workflow.Workflow.ProcessingServicesStep
                    .Complete,
                )}
              </BlConfirmButton>
            )}

            {x.status === OrderServiceStatus.Completed && (
              <CompletedChip orderService={x} order={order} />
            )}
          </StyledService>
        ))}
      </StyledServicesWrapper>
    </StyledWrapper>
  );
};
