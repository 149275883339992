import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import * as React from "react";
import { CircularProgress, Typography } from "@mui/material";
import styled from "styled-components";
import { StyledContainer } from "Components/Auth/Styles";
import { UnauthenticatedHeader } from "Components/Layout/UnauthenticatedHeader";
import { AuthMode, IdentityProvider } from "../../Api/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signInSsoAsync } from "../../State/Auth/SignIn/SignInSsoSlice";

const Progress = styled(CircularProgress)`
  margin-top: ${props => props.theme.spacing(10)};
`;

export const BrokerAuthPage: React.FC = _ => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { VITE_BROKER_AUTH_MODE } = import.meta.env;

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  // OAUTH
  React.useEffect(() => {
    if (
      VITE_BROKER_AUTH_MODE.toString().toLowerCase() ===
      AuthMode.OAuth.toLowerCase()
    ) {
      const apiUrl = import.meta.env.VITE_API_URL;

      // Page immediately redirects to the auth page
      window.location.href = `${apiUrl}/api/v1/auth/sso-token?identityProvider=${IdentityProvider.Broker}`;
    }
  }, [VITE_BROKER_AUTH_MODE]);

  // UserToken flow
  React.useEffect(() => {
    if (
      VITE_BROKER_AUTH_MODE.toString().toLowerCase() ===
      AuthMode.UserToken.toLowerCase()
    ) {
      const params = new URLSearchParams(location.search);
      const originUrl = params.get("originUrl") ?? "";
      const userToken = params.get("userToken");

      if (!!userToken) {
        setErrorMessage(null);
        dispatch(
          signInSsoAsync.request({
            model: {
              token: userToken,
              state: "",
              identityProvider: IdentityProvider.Broker,
              originUrl: originUrl,
              mode: AuthMode.UserToken,
            },
            sendCookies: true,
            navigate,
          }),
        );
      } else {
        setErrorMessage("Chyba při přihlášení. Url neobsahuje userToken.");
      }
    }
  }, [VITE_BROKER_AUTH_MODE, location.search, dispatch, navigate]);

  return (
    <UnauthenticatedLayout title="Přesměrování">
      <UnauthenticatedHeader></UnauthenticatedHeader>
      <StyledContainer>
        {!errorMessage && <Progress size={"150px"} />}
        {errorMessage && (
          <Typography color={"error"} fontSize={"1.5rem"}>
            {errorMessage}
          </Typography>
        )}
      </StyledContainer>
    </UnauthenticatedLayout>
  );
};
