import { combineReducers } from "@reduxjs/toolkit";
import {
  getOrdersInProgressSaga,
  getOrdersInProgressSlice,
} from "State/Dashboard/OrdersInProgress/OrdersInProgressSlice";
import {
  getClientCountSaga,
  getClientCountsSlice,
} from "State/Dashboard/Statistics/ClientCountsSlice";
import {
  getExpectedUserCommissionSaga,
  getExpectedUserCommissionSlice,
} from "State/Dashboard/Statistics/ExpectedUserCommissionSlice";
import {
  getOrderCountsSlice,
  getOrderCountSaga,
} from "State/Dashboard/Statistics/OrderCountsSlice";
import {
  getPaidUserCommissionSaga,
  getPaidUserCommissionSlice,
} from "State/Dashboard/Statistics/PaidUserCommissionSlice";
import {
  getUpcomingPeriodicOrdersSaga,
  getUpcomingPeriodicOrdersSlice,
} from "State/Dashboard/UpcomingPeriodicOrders/UpcomingPeriodicOrdersSlice";

import { all } from "typed-redux-saga";

export const dashboardReducer = combineReducers({
  ordersInProgress: getOrdersInProgressSlice.reducer,
  upcomingPeriodicOrders: getUpcomingPeriodicOrdersSlice.reducer,
  clientCounts: getClientCountsSlice.reducer,
  orderCounts: getOrderCountsSlice.reducer,
  paidUserCommission: getPaidUserCommissionSlice.reducer,
  expectedUserCommission: getExpectedUserCommissionSlice.reducer,
});

export function* watchDashboardSagas() {
  yield all([
    getOrdersInProgressSaga(),
    getUpcomingPeriodicOrdersSaga(),
    getClientCountSaga(),
    getOrderCountSaga(),
    getPaidUserCommissionSaga(),
    getExpectedUserCommissionSaga(),
  ]);
}
