import { combineReducers } from "@reduxjs/toolkit";
import {
  getCodeListCollectionSaga,
  getCodeListCollectionSlice,
} from "State/CodeLists/Collection/GetCodeListCollection";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { all } from "typed-redux-saga";

export const codeListReducer = combineReducers({
  collection: getCodeListCollectionSlice.reducer,
});

export function* watchCodeListSaga() {
  yield all([getCodeListCollectionSaga()]);
}

export const persistedCodeListReducer = () => {
  return persistReducer({ key: "codeList", storage }, codeListReducer);
};
