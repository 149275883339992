import { combineReducers } from "@reduxjs/toolkit";
import {
  postCancelOrderServiceProductSaga,
  postCancelOrderServiceProductSlice,
} from "State/Orders/Detail/CancelOrderServiceProductSlice";
import {
  completeOrderServiceSaga,
  completeOrderServiceSlice,
} from "State/Orders/Detail/CompleteOrderServiceSlice";
import {
  postCreateOrderServiceProductSaga,
  postCreateOrderServiceProductSlice,
} from "State/Orders/Detail/CreateOrderServiceProductSlice";
import {
  deleteOrderServiceSaga,
  deleteOrderServiceSlice,
} from "State/Orders/Detail/DeleteOrderServiceSlice";
import {
  deleteOrderSaga,
  deleteOrderSlice,
} from "State/Orders/Detail/DeleteOrderSlice";
import {
  getEucsOrderInfoSaga,
  getEucsOrderInfoSlice,
} from "State/Orders/Detail/EucsOrderInfoSlice";
import {
  getOrderDetailSaga,
  getOrderDetailSlice,
} from "State/Orders/Detail/OrderDetailSlice";
import {
  getOrderServicesSaga,
  getOrderServicesSlice,
} from "State/Orders/Detail/OrderServicesSlice";
import {
  setPeriodicitySaga,
  setPeriodicitySlice,
} from "State/Orders/Detail/SetPeriodicitySlice";
import { draftSlice } from "State/Orders/Draft/DraftSlice";
import {
  saveDraftSaga,
  saveDraftSlice,
} from "State/Orders/Draft/SaveDraftSlice";
import {
  getOrderListSaga,
  getOrderListSlice,
} from "State/Orders/List/OrderListSlice";
import { all } from "typed-redux-saga";

export const orderReducer = combineReducers({
  list: getOrderListSlice.reducer,
  detail: getOrderDetailSlice.reducer,
  orderServices: getOrderServicesSlice.reducer,
  deleteOrderService: deleteOrderServiceSlice.reducer,
  draft: draftSlice.reducer,
  saveDraft: saveDraftSlice.reducer,
  completeOrderService: completeOrderServiceSlice.reducer,
  setPeriodicity: setPeriodicitySlice.reducer,
  deleteOrder: deleteOrderSlice.reducer,
  eucsOrderInfo: getEucsOrderInfoSlice.reducer,
  postCancelOrderServiceProduct: postCancelOrderServiceProductSlice.reducer,
  postCreateOrderServiceProduct: postCreateOrderServiceProductSlice.reducer,
});

export function* watchOrderSagas() {
  yield all([
    getOrderListSaga(),
    getOrderDetailSaga(),
    getOrderServicesSaga(),
    deleteOrderServiceSaga(),
    saveDraftSaga(),
    completeOrderServiceSaga(),
    setPeriodicitySaga(),
    deleteOrderSaga(),
    getEucsOrderInfoSaga(),
    postCancelOrderServiceProductSaga(),
    postCreateOrderServiceProductSaga(),
  ]);
}
