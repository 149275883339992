import { Typography } from "@mui/material";
import { RoundedIcon } from "Components/Orders/Detail/RoundedIcon";
import { CrossIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { formatDate } from "Utils/DateUtils";
import styled, { useTheme } from "styled-components";

const Wrapper = styled.div`
  border-radius: 12px;
  border: 1px solid;
  border-color: ${props => props.theme.palette.error.main};
  padding: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(3)};
`;
const WrapperIcon = styled.div`
  margin-top: ${props => props.theme.spacing(0.3)};
`;
type Props = {
  dateCanceledInvoice: string;
};
export const CancelInvoiceForClientByOrderWaning: React.FunctionComponent<
  Props
> = props => {
  const { t } = useResource();
  const { dateCanceledInvoice } = props;
  const theme = useTheme();

  return (
    <Wrapper>
      <StyledFlex $gap={1}>
        <WrapperIcon>
          <RoundedIcon isFinished={false} isRejected={true}>
            <CrossIcon color="white" />
          </RoundedIcon>
        </WrapperIcon>
        <div>
          <Typography fontWeight={800} fontSize={16}>
            {t(Resources.Orders.CancelInvoiceForClientByOrderWaning.Title)}
          </Typography>
          <Typography fontStyle={"italic"} color={theme.palette.text.secondary}>
            {t(Resources.Orders.CancelInvoiceForClientByOrderWaning.Text, {
              date: formatDate(dateCanceledInvoice, true),
            })}
          </Typography>
        </div>
      </StyledFlex>
    </Wrapper>
  );
};
