import { combineReducers } from "@reduxjs/toolkit";
import {
  searchByPersonalNumberSaga,
  searchByPersonalNumberSlice,
} from "State/Clients/Slices/SearchByPersonalNumberSlice";
import {
  searchPartiesSaga,
  searchPartiesSlice,
} from "State/Clients/Slices/SearchSlice";
import { all } from "typed-redux-saga";

export const clientReducer = combineReducers({
  personalNumberSearch: searchByPersonalNumberSlice.reducer,
  search: searchPartiesSlice.reducer,
});

export function* watchClientSagas() {
  yield all([searchByPersonalNumberSaga(), searchPartiesSaga()]);
}
