import { Typography } from "@mui/material";
import { ClientInvoiceStatus, OrderDto } from "Api/Api";
import { CancelInvoiceIcon } from "Components/Orders/Detail/CancelInvoiceIcon";
import { DeleteOrderIcon } from "Components/Orders/Detail/DeleteOrderIcon";
import { OrderDetailIconButton } from "Components/Orders/Detail/Styles";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { ContractIcon, ListLayoutIcon } from "Components/Shared/Icons";
import { OrderStatus } from "Components/Shared/OrderStatus";
import { PageTitle } from "Components/Shared/PageTitle";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { useParty } from "Hooks/Party/useParty";
import { getInvoicePdfAsync } from "State/Invoice/GetInvoicePdf";
import { getInvoiceXmlAsync } from "State/Invoice/GetInvoiceXml";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(4)};
  align-items: flex-start;

  .page-title-wrapper {
    margin-bottom: 0;
    align-self: center;
  }
`;

const StyledIconsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > * {
    margin-right: ${props => props.theme.spacing(1)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  order: OrderDto | null | undefined;
};

export const Header: React.FunctionComponent<Props> = props => {
  const { order } = props;
  const { t } = useResource();

  const { formattedName } = useParty(order?.userPartyPublicID);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoading = !order;

  const goBack = () => {
    if (window.history.state?.idx > 0) {
      navigate(-1);
    } else {
      navigate(getPath(AppRouting.Dashboard));
    }
  };

  if (isLoading) {
    return <BlSkeleton />;
  }

  const isZeroPrice = order.isZeroPrice;
  const invoice = order.invoices?.[0];
  const isAvaibleInvoiceActions =
    order.invoices?.length > 0 &&
    invoice?.status !== ClientInvoiceStatus.Canceled &&
    !isZeroPrice;

  return (
    <>
      <StyledWrapper>
        <PageTitle title="" onChevronClick={() => goBack()}></PageTitle>
        <div>
          <StyledFlex>
            <Typography fontSize={24} fontWeight={700} marginRight={3}>
              {formattedName}
            </Typography>

            <OrderStatus
              currentStepType={order.currentStepType}
              currentStepStatus={order.currentStepStatus}
              lastCompleteStepStatus={order.lastCompleteStepStatus}
              lastCompleteStepType={order.lastCompleteStepType}
              areAllStepsCompleted={order.areAllStepsCompleted}
              workflowType={order.workflowType}
            />
          </StyledFlex>

          <Typography fontSize={16}>
            {t(Resources.Orders.Detail.DetailTitle)}
            <Typography
              component={"span"}
              marginLeft={0.5}
              fontWeight={700}
              fontSize={16}
            >
              #{order.number}
            </Typography>
          </Typography>
        </div>
        <StyledIconsWrapper>
          {/* Bude sluzit na anonimizaciu dat - zatial nie je doriesene, skryvam */}
          {/* <StyledBlDefaultButton>
            <UsersIcon />
          </StyledBlDefaultButton> */}

          {!order.isRecurringPayment && (
            <>
              <CancelInvoiceIcon order={order} />

              <OrderDetailIconButton
                title={
                  isAvaibleInvoiceActions
                    ? t(Resources.Orders.Detail.Icons.ISDOC)
                    : isZeroPrice
                      ? t(
                          Resources.Orders.Detail.Icons
                            .ISDOCNotAvailableZeroPrice,
                        )
                      : t(Resources.Orders.Detail.Icons.ISDOCNotAvailable)
                }
                disabled={!isAvaibleInvoiceActions}
                onClick={() =>
                  dispatch(
                    getInvoiceXmlAsync.request({
                      invoicePublicID: invoice.invoicePublicID,
                      invoiceNumber: invoice.number,
                    }),
                  )
                }
              >
                <ListLayoutIcon />
              </OrderDetailIconButton>

              <OrderDetailIconButton
                title={
                  isAvaibleInvoiceActions
                    ? t(Resources.Orders.Detail.Icons.Pdf)
                    : isZeroPrice
                      ? t(
                          Resources.Orders.Detail.Icons
                            .PdfNotAvailableZeroPrice,
                        )
                      : t(Resources.Orders.Detail.Icons.PdfNotAvailable)
                }
                disabled={!isAvaibleInvoiceActions}
                onClick={() =>
                  dispatch(
                    getInvoicePdfAsync.request({
                      invoicePublicID: invoice.invoicePublicID,
                      invoiceNumber: invoice.number,
                    }),
                  )
                }
              >
                <ContractIcon />
              </OrderDetailIconButton>
            </>
          )}
          <DeleteOrderIcon order={order} />
        </StyledIconsWrapper>
      </StyledWrapper>
    </>
  );
};
