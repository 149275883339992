import { LogoIcon } from "Components/Shared/Icons";
import { useUser } from "Hooks/useUser";
import { useAppSelector } from "State/Store";
import styled from "styled-components";

type Props = {
  enterpriseLogo?: string;
  size?: number;
  isTenantLogoVisible?: boolean;
  isUserLogoVisible: boolean;
};

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    margin-right: ${props => props.theme.spacing(1.5)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const StyledLogo = styled(LogoIcon)<{ $height?: number }>`
  color: ${props => props.theme.palette.primary.main};
  width: auto;
  height: ${props => props.$height}px;
`;

const StyledEnterpriseLogo = styled.img<{ $height?: number }>`
  width: auto;
  height: ${props => props.$height}px;
`;

const StyledLogoDivider = styled.div<{ $height?: number }>`
  width: 1px;
  height: ${props => props.$height}px;
  background-color: ${props => props.theme.palette.text.secondary};
`;

export const Logo: React.FunctionComponent<Props> = (props: Props) => {
  const { enterpriseLogo, isUserLogoVisible } = props;
  const logoBase64 = useAppSelector(
    state => state.enterprise.logo.data?.logoBase64,
  );

  const user = useUser();

  const logo =
    isUserLogoVisible && !!user?.logoUrl
      ? user?.logoUrl
      : enterpriseLogo ?? logoBase64;

  const size = props.size ?? 40;

  const isTenantLogoVisible = props.isTenantLogoVisible ?? true;

  return (
    <StyledLogoWrapper>
      {!!logo && (
        <>
          <StyledEnterpriseLogo src={logo} alt="Logo" $height={size} />
        </>
      )}

      {!!logo && isTenantLogoVisible && (
        <StyledLogoDivider $height={size + 10}></StyledLogoDivider>
      )}

      {isTenantLogoVisible && <StyledLogo $height={size}></StyledLogo>}
    </StyledLogoWrapper>
  );
};
