import { signUpSaga, signUpSlice } from "State/Auth/SignUp/SignUpSlice";
import { sessionSlice } from "State/Auth/Session/SessionSlice";
import { all } from "typed-redux-saga";
import { checkTokenExpirationSaga } from "State/Auth/Session/Sagas/CheckTokenExpiration";
import { resetUserSaga } from "State/Auth/Session/Sagas/ResetUserState";
import {
  emailVerificationSlice,
  verifyEmailSaga,
} from "State/Auth/Verifications/EmailVerificationSlice";
import { signInSaga, signInSlice } from "State/Auth/SignIn/SignInSlice";
import {
  resendVerificationEmailSaga,
  resendVerificationEmailSlice,
} from "State/Auth/Verifications/ResendVerificationEmailSlice";
import {
  signInSsoSaga,
  signInSsoSlice,
} from "State/Auth/SignIn/SignInSsoSlice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/es/storage";
import { nameof } from "Utils/ObjectUtils";
import {
  resetPasswordSaga,
  resetPasswordSlice,
} from "State/Auth/Passwords/ResetPasswordSlice";
import { combineReducers } from "@reduxjs/toolkit";

export const authReducer = combineReducers({
  session: sessionSlice.reducer,
  signUp: signUpSlice.reducer,
  signIn: signInSlice.reducer,
  signInSso: signInSsoSlice.reducer,
  emailVerification: emailVerificationSlice.reducer,
  resendEmailVerification: resendVerificationEmailSlice.reducer,
  resetPassword: resetPasswordSlice.reducer,
});

export function* watchAuthSagas() {
  yield all([
    signUpSaga(),
    signInSaga(),
    signInSsoSaga(),
    checkTokenExpirationSaga(),
    resetUserSaga(),
    verifyEmailSaga(),
    resendVerificationEmailSaga(),
    resetPasswordSaga(),
  ]);
}

export type AuthState = ReturnType<typeof authReducer>;

export function persistedAuthReducer() {
  return persistReducer(
    {
      key: "auth",
      storage,
      whitelist: [nameof<AuthState>("session") as string],
    },
    authReducer,
  );
}
