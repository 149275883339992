import * as React from "react";
export const LogoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={107.412}
      height={42.04}
      viewBox="0 0 107.412 42.04"
      {...props}
    >
      <g transform="translate(-22.142 -23.31)">
        <g transform="translate(52.797 23.42)">
          <path
            d="M72.8,41.227c2.115,0,3.245-1.612,3.272-4.811.028-3.227-1.1-4.988-3.188-5.017-2.2-.059-3.332,1.614-3.332,4.723,0,3.4,1.158,5.135,3.245,5.1m3.362,2.642A.247.247,0,0,1,76,43.8a.253.253,0,0,1-.072-.162l.057-1.524a5.431,5.431,0,0,1-4.41,2.053c-4.231,0-6.461-3.4-6.461-7.8,0-4.37,2.492-7.891,6.544-7.891a5.336,5.336,0,0,1,4.316,1.906l-.085-1.379a.252.252,0,0,1,.073-.161.245.245,0,0,1,.159-.072h3.911a.246.246,0,0,1,.159.072.251.251,0,0,1,.072.161l-.058,7.342.058,7.3a.253.253,0,0,1-.072.162.248.248,0,0,1-.16.074Z"
            transform="translate(-52.104 -23.337)"
            fill="#2f2c27"
          />
          <path
            d="M88.57,41.257c2.2.058,3.333-1.673,3.333-4.724,0-3.311-1.159-5.017-3.245-4.986-2.115,0-3.246,1.583-3.272,4.694-.03,3.226,1.1,4.987,3.187,5.017m1.218,2.991a5.337,5.337,0,0,1-4.318-1.907l.087,1.379a.253.253,0,0,1-.072.162.248.248,0,0,1-.16.074H81.416a.25.25,0,0,1-.16-.074.255.255,0,0,1-.072-.162l.058-10.033-.058-10.033a.254.254,0,0,1,.072-.161.248.248,0,0,1,.159-.073H85.3a.248.248,0,0,1,.16.073.253.253,0,0,1,.072.161v6.9a5.4,5.4,0,0,1,4.346-2c4.231,0,6.463,3.4,6.463,7.8,0,4.372-2.492,7.891-6.544,7.891"
            transform="translate(-51.845 -23.419)"
            fill="#2f2c27"
          />
          <path
            d="M72.741,48.613c-1.738,0-2.781,1.057-3.13,3.138h6.117C75.555,50.139,74.657,48.613,72.741,48.613ZM79.754,56.1a.2.2,0,0,1,.153.071.209.209,0,0,1,.049.163C79.609,59.12,77,61.585,72.8,61.585c-4.979,0-7.682-3.311-7.682-7.891,0-4.812,2.845-7.981,7.592-7.981,4.926,0,7.621,3.311,7.707,8.6a.253.253,0,0,1-.072.161.248.248,0,0,1-.16.073H69.5c.174,2.844,1.28,4.107,3.362,4.107a2.7,2.7,0,0,0,2.9-2.318.308.308,0,0,1,.1-.166.3.3,0,0,1,.181-.069Z"
            transform="translate(-52.104 -23.059)"
            fill="#2f2c27"
          />
          <path
            d="M81.385,61.195a.248.248,0,0,1-.157-.078.254.254,0,0,1-.068-.163l.087-7.341L81.16,46.32a.253.253,0,0,1,.073-.16.248.248,0,0,1,.159-.073h3.854a.246.246,0,0,1,.159.072.252.252,0,0,1,.072.161l-.115,2.318a6.13,6.13,0,0,1,5.329-2.669,5.25,5.25,0,0,1,.7.058.346.346,0,0,1,.156.111.352.352,0,0,1,.074.177V49.1a.227.227,0,0,1-.075.148.222.222,0,0,1-.155.056,3.93,3.93,0,0,0-.58-.029c-3.071,0-5.332,1.727-5.332,5.192v6.478a.252.252,0,0,1-.072.162.246.246,0,0,1-.16.073Z"
            transform="translate(-51.845 -23.055)"
            fill="#2f2c27"
          />
          <path
            d="M102.626,60.953a.344.344,0,0,1-.122.169.336.336,0,0,1-.2.066H97.7a.336.336,0,0,1-.2-.066.342.342,0,0,1-.122-.169L92.31,46.313a.161.161,0,0,1,.006-.157.159.159,0,0,1,.058-.056.156.156,0,0,1,.078-.02h4.234a.282.282,0,0,1,.184.064.288.288,0,0,1,.1.169l3.188,11.06,3.556-11.06a.293.293,0,0,1,.1-.169A.283.283,0,0,1,104,46.08h3.641a.157.157,0,0,1,.078.02.161.161,0,0,1,.081.134.165.165,0,0,1-.017.079Z"
            transform="translate(-51.666 -23.053)"
            fill="#2f2c27"
          />
          <path
            d="M108.691,61.279a.244.244,0,0,1-.161-.075.25.25,0,0,1-.07-.165l.057-7.306-.057-7.341a.254.254,0,0,1,.072-.162.246.246,0,0,1,.16-.073h3.941a.249.249,0,0,1,.16.073.253.253,0,0,1,.072.161l-.058,7.341.064,7.306a.253.253,0,0,1-.072.161.248.248,0,0,1-.16.073Zm0-16.575a.249.249,0,0,1-.232-.236V40.978a.249.249,0,0,1,.232-.235h3.941a.248.248,0,0,1,.16.073.253.253,0,0,1,.072.161v3.491a.255.255,0,0,1-.072.162.25.25,0,0,1-.16.074Z"
            transform="translate(-51.405 -23.139)"
            fill="#2f2c27"
          />
          <path
            d="M121.006,61.55c-4.434,0-7.07-1.788-7.187-5.192a.254.254,0,0,1,.073-.161.248.248,0,0,1,.159-.074h3.768a.227.227,0,0,1,.161.067.235.235,0,0,1,.068.162c.086,1.7,1.188,2.466,3.244,2.466,1.707,0,2.638-.677,2.638-1.85,0-2.879-9.765-.088-9.765-6.425,0-3.138,2.492-4.811,6.52-4.811,3.941,0,6.576,1.583,6.809,4.782a.211.211,0,0,1-.007.087.216.216,0,0,1-.042.076.212.212,0,0,1-.07.052.2.2,0,0,1-.084.019H123.7a.271.271,0,0,1-.172-.07.28.28,0,0,1-.089-.165c-.232-1.3-1.043-2.113-2.811-2.113-1.423,0-2.319.528-2.319,1.819,0,2.879,9.766-.059,9.766,6.367,0,3.167-2.956,4.956-7.07,4.956"
            transform="translate(-51.318 -23.059)"
            fill="#2f2c27"
          />
          <path
            d="M55.127,30.625c.155.276.3.56.438.846a2.939,2.939,0,0,1,1.625-.389c1.768,0,2.579.822,2.811,2.113a.279.279,0,0,0,.089.165.274.274,0,0,0,.172.07h3.592a.2.2,0,0,0,.084-.019.207.207,0,0,0,.069-.052.21.21,0,0,0,.042-.076.212.212,0,0,0,.008-.087c-.232-3.2-2.869-4.779-6.81-4.779a10.325,10.325,0,0,0-3.175.448c.377.569.718,1.164,1.054,1.763"
            transform="translate(-52.282 -23.338)"
            fill="#2f2c27"
          />
          <path
            d="M56.614,34.171c.272.864.5,1.75.711,2.654.085.374.162.756.236,1.137,1.623.258,2.892.558,2.892,1.593,0,1.123-.854,1.782-2.418,1.838.033.432.06.864.071,1.3.011.469.009.944,0,1.424,3.814-.16,6.5-1.923,6.5-4.942,0-4.676-5.165-4.4-7.982-5.005"
            transform="translate(-52.241 -23.245)"
            fill="#2f2c27"
          />
          <path
            d="M57.6,48.434a2.356,2.356,0,0,1,2.344,2.084.28.28,0,0,0,.089.165.275.275,0,0,0,.172.07H63.8a.205.205,0,0,0,.154-.071.209.209,0,0,0,.042-.076A.213.213,0,0,0,64,50.519c-.216-2.978-2.519-4.556-6.019-4.759A25.149,25.149,0,0,1,57.6,48.434Z"
            transform="translate(-52.225 -23.058)"
            fill="#2f2c27"
          />
          <path
            d="M56.835,51.528a28.728,28.728,0,0,1-1.386,3.4c2.5.489,5.069.477,5.069,1.951,0,1.175-.928,1.85-2.637,1.85-1.945,0-3.026-.692-3.212-2.205a.025.025,0,0,0-.006-.014.024.024,0,0,0-.012-.008.023.023,0,0,0-.015,0,.024.024,0,0,0-.011.009c-.438.785-.919,1.583-1.461,2.378-.272.4-.559.8-.854,1.19a8.85,8.85,0,0,0,5.282,1.375c4.116,0,7.07-1.788,7.07-4.958,0-4.591-4.979-4.4-7.824-4.971"
            transform="translate(-52.31 -22.965)"
            fill="#2f2c27"
          />
        </g>
        <path
          d="M48.724,61.968a20.418,20.418,0,0,0,6.117-8,23.616,23.616,0,0,0,1.437-4.251,30.177,30.177,0,0,0,.711-3.55,26.641,26.641,0,0,0,.124-4.332,29.124,29.124,0,0,0-1.1-6.673,18.893,18.893,0,0,0-2.347-4.992A13.958,13.958,0,0,0,50.815,26.9a16.971,16.971,0,0,0-6.1-3.2,11.186,11.186,0,0,0-3.861-.354,7.076,7.076,0,0,0-2.865.751,9.983,9.983,0,0,0-3.187,2.129,3.589,3.589,0,0,1-.586.432c-.236.144-.468.272-.711.432a24.993,24.993,0,0,0-6.948,7.078A25.246,25.246,0,0,0,24.3,38.285a26.592,26.592,0,0,0-1.874,6.381,20.952,20.952,0,0,0,.073,7.275A15.688,15.688,0,0,0,25.083,58,17.9,17.9,0,0,0,28.2,61.389a15.884,15.884,0,0,0,4.518,2.892,12.993,12.993,0,0,0,3.164.926,18.712,18.712,0,0,0,5.649-.157,19.3,19.3,0,0,0,3.857-1.126A14.379,14.379,0,0,0,48.724,61.968ZM45.2,60.456a11.665,11.665,0,0,1-6.239.4A15.83,15.83,0,0,1,34,58.757a22.638,22.638,0,0,1-3.931-3.023,18.1,18.1,0,0,1-2.252-2.545,12.044,12.044,0,0,1-1.911-3.951,14.022,14.022,0,0,1-.427-4.981c.046-.522.092-1.048.179-1.595.061-.387.186-.815.285-1.225a16.478,16.478,0,0,1,1.236-3.651,19.07,19.07,0,0,1,2.359-3.818A20.505,20.505,0,0,1,32.7,30.691c.132-.106.324-.344.386-.232s-.142.341-.242.511a17.94,17.94,0,0,0-1.849,4.147,2.968,2.968,0,0,0-.142.967c.037.659.7.507,1.232-.288a8.434,8.434,0,0,0,.73-1.427c.2-.466.408-.929.647-1.394a9.993,9.993,0,0,1,3.272-3.841,9.652,9.652,0,0,1,2.906-1.319,8.431,8.431,0,0,1,2.7-.334,9.119,9.119,0,0,1,2.7.632,12.5,12.5,0,0,1,2.987,1.658,15.181,15.181,0,0,1,4.879,6.011,18.122,18.122,0,0,1,1.774,5.777,19.518,19.518,0,0,1-.036,5.08,21.731,21.731,0,0,1-.932,3.881,21.163,21.163,0,0,1-1.539,3.551,14.639,14.639,0,0,1-4.41,5.164,10.279,10.279,0,0,1-2.561,1.218"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
