import {
  EntityListOfInvoiceForSupplierCompanyByUserListItemDto,
  getInvoicesUserInvoices,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getUserInvoicesAsync = createAsyncAction(
  "@invoice/GET_LIST_REQUEST",
  "@invoice/GET_LIST_RESPONSE",
  "@invoice/GET_LIST_FAILURE",
)<
  { offset: number; limit: number },
  EntityListOfInvoiceForSupplierCompanyByUserListItemDto,
  Error
>();

function* getUserInvoices(
  action: ReturnType<typeof getUserInvoicesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getInvoicesUserInvoices,
      action.payload.offset,
      action.payload.limit,
    );

    if (status === 200) {
      yield put(getUserInvoicesAsync.success(data));
      return;
    }

    yield put(getUserInvoicesAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(getUserInvoicesAsync.failure(err as Error));
  }
}
export function* getUserInvoicesSaga() {
  yield takeLatest(getType(getUserInvoicesAsync.request), getUserInvoices);
}

export const getUserInvoicesSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EntityListOfInvoiceForSupplierCompanyByUserListItemDto>(
      false,
    ),
  name: "@invoice/getUserInvoices",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EntityListOfInvoiceForSupplierCompanyByUserListItemDto>(
        false,
      ),
  },
  extraReducers: builder => {
    handleRequestCase(getUserInvoicesAsync, builder);
    handleSuccessCase(getUserInvoicesAsync, builder);
    handleFailureCase(getUserInvoicesAsync, builder);
  },
});

export const { resetSlice } = getUserInvoicesSlice.actions;
