import { PartyDto, PartyType, getPartiesSearchAres } from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";
import { Resources, getTranslation } from "Translations/Resources";
import { toast } from "sonner";

export const aresSearchAsync = createAsyncAction(
  "@PARTIES/ARES_SEARCH_REQUEST",
  "@PARTIES/ARES_SEARCH_RESPONSE",
  "@PARTIES/ARES_SEARCH_FAILURE",
)<{ query: string; partyType: PartyType }, PartyDto, Error>();

function* aresSearch(
  action: ReturnType<typeof aresSearchAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getPartiesSearchAres,
      action.payload.query,
      action.payload.partyType,
    );

    if (status === 200) {
      yield put(aresSearchAsync.success(data));
      return;
    }

    if (status === 404) {
      toast.error(getTranslation(Resources.Party.AresSearch.NotFound));
    }

    yield put(aresSearchAsync.failure(mapAPIErrorResponse(error ?? data)));
  } catch (err) {
    yield put(aresSearchAsync.failure(err as Error));
  }
}
export function* aresSearchSaga() {
  yield takeLatest(getType(aresSearchAsync.request), aresSearch);
}

export const aresSearchSlice = createSlice({
  initialState: getFetchStateDefaultValue<PartyDto>(false),
  name: "aresSearch",
  reducers: {
    resetSlice: () => getFetchStateDefaultValue<PartyDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(aresSearchAsync, builder);
    handleSuccessCase(aresSearchAsync, builder);
    handleFailureCase(aresSearchAsync, builder);
  },
});

export const { resetSlice } = aresSearchSlice.actions;
