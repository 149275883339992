import {
  CompanyDesignSettingsDto,
  getCompaniesPublicIDDesignSettings,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getCompanyDesignSettingsAsync = createAsyncAction(
  "@company/GET_DESIGN_SETTINGS_REQUEST",
  "@company/GET_DESIGN_SETTINGS_RESPONSE",
  "@company/GET_DESIGN_SETTINGS_FAILURE",
)<
  {
    publicID: string;
  },
  CompanyDesignSettingsDto,
  Error
>();

function* getCompanyDesignSettings(
  action: ReturnType<typeof getCompanyDesignSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCompaniesPublicIDDesignSettings,
      action.payload.publicID,
    );

    if (status === 200) {
      yield put(getCompanyDesignSettingsAsync.success(data));
      return;
    }

    yield put(
      getCompanyDesignSettingsAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getCompanyDesignSettingsAsync.failure(err as Error));
  }
}
export function* getCompanyDesignSettingsSaga() {
  yield takeLatest(
    getType(getCompanyDesignSettingsAsync.request),
    getCompanyDesignSettings,
  );
}

export const getCompanyDesignSettingsSlice = createSlice({
  initialState: getFetchStateDefaultValue<CompanyDesignSettingsDto>(false),
  name: "@company/getCompanyDesignSettings",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<CompanyDesignSettingsDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getCompanyDesignSettingsAsync, builder);
    handleSuccessCase(getCompanyDesignSettingsAsync, builder);
    handleFailureCase(getCompanyDesignSettingsAsync, builder);
  },
});

export const { resetSlice } = getCompanyDesignSettingsSlice.actions;
