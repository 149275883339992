import { Divider } from "@mui/material";
import { InvoiceCompany } from "Components/Settings/Invoices/InvoiceCompany";
import { InvoicesList } from "Components/Settings/Invoices/InvoicesList";

export const Invoices: React.FunctionComponent = _ => {
  return (
    <>
      <InvoiceCompany />

      <Divider sx={{ marginTop: 3, marginBottom: 2 }} />

      <InvoicesList />
    </>
  );
};
