import { Typography } from "@mui/material";
import { ServiceVariantProduct } from "Api/Api";
import { StyledService } from "Components/Settings/Services/ProvidedServices";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { BlCheckboxInput as Checkbox } from "Components/Shared/Inputs/BlCheckboxInput";
import styled, { useTheme } from "styled-components";
import { FlagIcon } from "Components/Shared/Icons";
import { useDispatch, useSelector } from "react-redux";
import { RootStateType } from "State/Store";
import { useEffect } from "react";
import { getHasAccessToProductAsync } from "State/Enterprises/Products/HasAccessToProductSlice";

type Props = {};

const Wrapper = styled.div`
  border-radius: 12px;
  border: 1px solid;
  border-color: ${props => props.theme.palette.warning.light};
  padding: ${props => props.theme.spacing(2)};
  margin-bottom: ${props => props.theme.spacing(1)};
  margin-top: ${props => props.theme.spacing(3)};
`;

export const EucsDisableService: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getHasAccessToProductAsync.request({
        product: ServiceVariantProduct.EucsGarance,
      }),
    );
  }, [dispatch]);

  const { data: hasAccessToEucsProduct } = useSelector(
    (e: RootStateType) => e.enterprise.getHasAccessToProduct,
  );

  return (
    <>
      {!!hasAccessToEucsProduct && !hasAccessToEucsProduct.hasAcces && (
        <>
          <StyledService>
            <div className="checkbox-wrapper">
              <Checkbox disabled={true} />
            </div>

            <div>
              <StyledFlex>
                <Typography
                  fontSize={16}
                  fontWeight={700}
                  marginRight={1}
                  color={theme.palette.text.secondary}
                >
                  {hasAccessToEucsProduct?.serviceName}
                </Typography>
              </StyledFlex>
              <Typography variant="subtitle2" fontStyle={"normal"}>
                {hasAccessToEucsProduct?.serviceDescription}
              </Typography>
              <Wrapper>
                <StyledFlex $gap={1}>
                  <FlagIcon />

                  <Typography
                    color={theme => theme.palette.warning.light}
                    fontWeight={700}
                    fontSize={18}
                    marginBottom={1}
                  >
                    {t(Resources.EucsDisableService.Title)}
                  </Typography>
                </StyledFlex>
                <Typography>
                  {t(Resources.EucsDisableService.Description)}
                </Typography>
              </Wrapper>
            </div>
          </StyledService>
        </>
      )}
    </>
  );
};
