import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { parseSentryUserFromJWT } from "Utils/TrackingUtils";
import { VITE_APPLICATION_VERSION, VITE_BUILD_NUMBER } from "Utils/BuildUtils";
import { colors } from "Components/Layout/Themes/Colors";

export const SENTRY_CONFIG = {
  IS_ENABLED: import.meta.env.VITE_SENTRY_IS_ENABLED === "true",
  IS_FEEDBACK_ENABLED:
    import.meta.env.VITE_SENTRY_IS_FEEDBACK_ENABLED === "true",
  DSN: import.meta.env.VITE_SENTRY_DSN as string,
  ORG: import.meta.env.VITE_SENTRY_ORG as string,
  PROJECT: import.meta.env.VITE_SENTRY_PROJECT as string,
};

export function initSentry() {
  if (SENTRY_CONFIG.IS_ENABLED) {
    const appColors = colors("#FF6690");
    Sentry.init({
      dsn: SENTRY_CONFIG.DSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
          networkDetailAllowUrls: [/PaidAdvisory/, "localhost"],
          networkDetailDenyUrls: [/api\/health\/status/],
        }),
        Sentry.httpContextIntegration(),
        Sentry.dedupeIntegration(),
        Sentry.feedbackIntegration({
          buttonLabel: "Nahlásit chybu",
          nameLabel: "Jméno",
          emailLabel: "E-mail",
          messageLabel: "Popis chyby",
          cancelButtonLabel: "Zrušit",
          submitButtonLabel: "Odeslat",
          namePlaceholder: "Vaše jméno",
          emailPlaceholder: "Váš e-mail",
          messagePlaceholder: "Popište chybu",
          successMessageText: "Děkujeme za nahlášení chyby.",
          formTitle: "Nahlásit chybu",
          showBranding: false,
          colorScheme: "light",
          onFormOpen: function () {
            setTabIndexForMuiDialog(null);
          },
          onFormClose: function () {
            setTabIndexForMuiDialog("-1");
          },
          useSentryUser: {
            email: "",
            name: "",
          },
          themeLight: {
            submitBackground: appColors.Gray,
            submitBackgroundHover: appColors.Gray,
            submitBorder: appColors.Gray,
            submitForeground: appColors.Text,
            submitForegroundHover: appColors.Text,
            cancelBackground: appColors.Gray,
            cancelBackgroundHover: appColors.Gray,
            cancelBorder: appColors.Gray,
            cancelForeground: appColors.Text,
            cancelForegroundHover: appColors.Text,
          },
        }),
      ],
      environment: import.meta.env.VITE_VERSION as string,
      tracePropagationTargets: [/PaidAdvisory/, "localhost"],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1,
      tracesSampleRate: 1,
      normalizeDepth: 8,
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        //if (event.exception && event.extra?.doNotShowDialog !== true) {
        //  Sentry.showReportDialog({ eventId: event.event_id, lang: "cs" }); Not show report dialog because user feedback is enable
        //}
        return event;
      },
      initialScope: scope => {
        const user = parseSentryUserFromJWT();
        if (user) {
          scope.setUser(user);
          scope.setTag("user.login", user.username);
        }

        scope.setTag("app.version", VITE_APPLICATION_VERSION);
        scope.setTag("app.build", VITE_BUILD_NUMBER);

        return scope;
      },
      autoSessionTracking: false,
    });
  }
  /*When was sentry feedback and muidiaog open together, then was problem with inputs focus in sentry feedback */
  function setTabIndexForMuiDialog(tabIndex: string | null) {
    const elements = document.getElementsByClassName("MuiDialog-container");
    if (elements.length > 0) {
      const firstElement = elements[0] as HTMLElement;
      if (tabIndex) {
        firstElement.setAttribute("tabindex", "-1");
      } else {
        firstElement.removeAttribute("tabindex");
      }
    }
  }
}
