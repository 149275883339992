import { combineReducers } from "@reduxjs/toolkit";
import {
  getAllInvoicePdfsSaga,
  getAllInvoicePdfsSlice,
} from "State/Invoice/GetAllInvoicePdfsSlice";
import {
  getInvoicePdfSlice,
  getInvoicePdfSaga,
} from "State/Invoice/GetInvoicePdf";
import {
  getInvoiceXmlSaga,
  getInvoiceXmlSlice,
} from "State/Invoice/GetInvoiceXml";
import {
  getUserInvoicesSaga,
  getUserInvoicesSlice,
} from "State/Invoice/GetUserInvoicesSlice";
import { all } from "typed-redux-saga";
import {
  cancelInvoiceForClientByOrderSaga,
  cancelInvoiceForClientByOrderSlice,
} from "State/Invoice/CancelInvoiceForClientByOrderSlice";

export const invoiceReducer = combineReducers({
  list: getUserInvoicesSlice.reducer,
  pdf: getInvoicePdfSlice.reducer,
  xml: getInvoiceXmlSlice.reducer,
  allPdf: getAllInvoicePdfsSlice.reducer,
  cancelInvoiceForClientByOrder: cancelInvoiceForClientByOrderSlice.reducer,
});

export function* watchInvoiceSagas() {
  yield all([
    getUserInvoicesSaga(),
    getInvoicePdfSaga(),
    getInvoiceXmlSaga(),
    getAllInvoicePdfsSaga(),
    cancelInvoiceForClientByOrderSaga(),
  ]);
}
