import { Typography } from "@mui/material";
import { Filter } from "Components/Orders/List/Filter";
import { Table } from "Components/Orders/List/Table";
import {
  getOrderListAsync,
  resetOrderList,
} from "State/Orders/List/OrderListSlice";
import { RootStateType } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "@uidotdev/usehooks";

type Props = {};

type FilterProps = {
  query: string;
  statuses: string[];
  offset: number;
  limit: number;
  startDate: string | null;
  endDate: string | null;
  isSearchInStructure: boolean;
  onlyAfterInvoiceDueDate: boolean;
};

export const OrdersList: React.FunctionComponent<Props> = props => {
  const { t } = useResource();

  const dispatch = useDispatch();

  const { data } = useSelector((e: RootStateType) => e.order.list);
  const [filter, setFilter] = useState<FilterProps>({
    query: "",
    statuses: [],
    offset: 0,
    limit: 10,
    startDate: null,
    endDate: null,
    isSearchInStructure: false,
    onlyAfterInvoiceDueDate: false,
  });

  const {
    query,
    statuses,
    offset,
    limit,
    startDate,
    endDate,
    isSearchInStructure,
    onlyAfterInvoiceDueDate,
  } = filter;
  const memoizedStatuses = JSON.stringify(statuses);

  useEffect(() => {
    dispatch(
      getOrderListAsync.request({
        query,
        statuses: JSON.parse(memoizedStatuses) as string[],
        offset,
        limit,
        startDate,
        endDate,
        isSearchInStructure,
        onlyAfterInvoiceDueDate,
      }),
    );
  }, [
    dispatch,
    query,
    offset,
    limit,
    startDate,
    endDate,
    memoizedStatuses,
    isSearchInStructure,
    onlyAfterInvoiceDueDate,
  ]);

  const onPageChange = (page: number, rows: number) => {
    setFilter({ ...filter, offset: page * rows, limit: rows });
  };

  const [on, toggle] = useToggle(true);

  const onFilterChange = useCallback(
    (f: Partial<FilterProps>) => {
      setFilter(prevFilter => ({ ...prevFilter, ...f, offset: 0 }));
      toggle();
    },
    [setFilter, toggle],
  );

  useEffect(() => {
    return () => {
      dispatch(resetOrderList());
    };
  }, [dispatch]);

  return (
    <>
      <Typography variant="h1" marginBottom={3}>
        {t(Resources.Orders.List.Title)}
      </Typography>

      <Filter onFilterChange={onFilterChange} />
      {!!data && (
        <>
          <Table
            //key is used to reset table state - pagination, after the filter is changed
            key={on.toString()}
            data={data}
            onPageChange={onPageChange}
          />
        </>
      )}
    </>
  );
};
