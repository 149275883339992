import { Box, Typography } from "@mui/material";
import {
  OrderDto,
  OrderPaymentFrequency,
  OrderServiceDto,
  OrderWorkflowStepStatus,
  RelationType,
  StepType,
} from "Api/Api";
import { Service } from "Components/Orders/Detail/Service";
import { BlSkeleton } from "Components/Shared/BlSkeleton";
import { useParty } from "Hooks/Party/useParty";
import { useTaxLabels } from "Hooks/useWithTax";
import { deleteOrderServiceAsync } from "State/Orders/Detail/DeleteOrderServiceSlice";
import { RootStateType } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency, roundHalfUp } from "Utils/CurrencyUtils";
import { orderBy, sumBy } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledWrapper = styled.div`
  background-color: ${props => props.theme.colors.gray};
  border-radius: ${props => props.theme.borderRadius};
  padding: ${props => props.theme.spacing(3, 2.5)};
`;

const StyledServices = styled.div`
  display: grid;
  gap: ${props => props.theme.spacing(1)};
`;

const StyledTotalPrice = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding: ${props => props.theme.spacing(0, 0.4)};
  margin-top: ${props => props.theme.spacing(2.5)};

  & > *:not(:first-child) {
    text-align: right;
  }
`;

type Props = {
  publicID: string;
  order: OrderDto | null | undefined;
  services: OrderServiceDto[] | null | undefined;
};

export const Services: React.FunctionComponent<Props> = props => {
  const { publicID, order, services } = props;
  const { t } = useResource();
  const dispatch = useDispatch();
  const { withTax, withoutTax } = useTaxLabels();

  const { isLoading: isDeleting } = useSelector(
    (e: RootStateType) => e.order.deleteOrderService,
  );

  const isLoading = isDeleting;

  const deleteOrderService = (orderServiceID: number) => {
    if (isLoading) {
      return;
    }
    dispatch(
      deleteOrderServiceAsync.request({
        publicID,
        orderServiceID,
      }),
    );
  };

  const { party: supplierParty } = useParty(order?.supplierPartyPublicID);

  if (!services) {
    return <BlSkeleton />;
  }

  const isVatUsed = supplierParty?.isVATPayer ?? false;
  const isReadOnly = order?.userRelationType !== RelationType.OrderActions;

  const canDelete =
    !isLoading &&
    !!order &&
    !isReadOnly &&
    services.length > 1 &&
    (order.currentStepType === StepType.ClientAssignment ||
      (order.currentStepType === StepType.ClientReview &&
        order.currentStepStatus !== OrderWorkflowStepStatus.Completed));

  const cannotDeleteReason = isReadOnly
    ? "IsReadOnly"
    : !canDelete
      ? services.length === 1
        ? "LastService"
        : "TooLate"
      : null;

  const isSinglePayment =
    order?.paymentFrequency === OrderPaymentFrequency.Single;

  return (
    <>
      <StyledWrapper>
        <Typography variant="h3" marginBottom={2}>
          {t(Resources.Orders.Workflow.Services.Title)}
        </Typography>

        <StyledServices>
          {orderBy(services, x => x.orderServiceID).map(x => (
            <Service
              key={x.orderServiceID}
              service={x}
              onDelete={deleteOrderService}
              canDelete={canDelete}
              cannotDeleteReason={cannotDeleteReason}
              isVatUsed={isVatUsed}
              publicID={publicID}
              isReadOnly={isReadOnly}
            />
          ))}
        </StyledServices>

        <StyledTotalPrice>
          <Typography fontWeight={700}>
            {isSinglePayment
              ? t(Resources.Orders.Workflow.Services.TotalPrice.Single)
              : t(Resources.Orders.Workflow.Services.TotalPrice.Periodic)}
          </Typography>

          <Box>
            <Typography fontWeight={700}>
              {`${withoutTax(
                formatCurrency(
                  roundHalfUp(sumBy(services, x => x.priceWithoutTax)),
                  services[0]?.currencyCode ?? "CZK",
                ),
                isVatUsed,
              )}${!isSinglePayment ? ` /  ${t(Resources.Common.Month)}` : ""}`}
            </Typography>

            {isVatUsed && (
              <Typography
                variant="subtitle2"
                fontSize={12}
                fontStyle={"normal"}
              >
                {`${withTax(
                  formatCurrency(
                    roundHalfUp(sumBy(services, x => x.priceWithTax)),
                    services[0]?.currencyCode ?? "CZK",
                  ),
                )}${
                  !isSinglePayment ? ` /  ${t(Resources.Common.Month)}` : ""
                }`}
              </Typography>
            )}
          </Box>
        </StyledTotalPrice>
      </StyledWrapper>
    </>
  );
};
