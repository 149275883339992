import { Typography } from "@mui/material";
import { Logo } from "Components/Layout/Logo";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { BlDefaultButton } from "Components/Shared/Buttons/BlDefaultButton";
import { FilterIcon } from "Components/Shared/Icons";
import { StyledFlex } from "Components/Shared/StyledComponents";
import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useAppSelector } from "State/Store";
import styled from "styled-components";
import { Resources, useResource } from "Translations/Resources";
import { AppRouting, getPath } from "Utils/UrlUtils";

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.border};
  min-height: 85px;
`;

const StyledMenu = styled.div`
  display: flex;
  height: 100%;

  & > * {
    padding: ${props => props.theme.spacing(0, 1.5)};

    &.active {
      &::after {
        content: "";
        background-color: ${props => props.theme.palette.primary.main};
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 2px;
      }
    }
  }

  & > a {
    padding: 0;
  }
`;

const StyledUnstyledLink = styled(UnstyledLink)`
  display: flex;
  height: 100%;
  border-right: 1px solid ${props => props.theme.colors.border};
  padding-right: ${props => props.theme.spacing(3.6)};
  padding-left: ${props => props.theme.spacing(2.5)};
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  position: relative;

  & > div {
    height: 100%;
  }
`;

const StyledItem = styled(BlDefaultButton)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${props => props.theme.spacing(0, 1.5)};
  border-radius: 0;

  .MuiTypography-root {
    font-weight: 700;
    color: ${props => props.theme.palette.primary.main};
  }
`;

const StyledAppNameWrapper = styled.div`
  padding-left: ${props => props.theme.spacing(3.6)};
`;

const StyledSettingsIcon = styled(FilterIcon)`
  width: 24px;
  height: auto;
`;

type ApplicationLink = {
  content: JSX.Element;
  accessRightCode: string | null;
};

type Props = {};

const Menu: React.FunctionComponent<Props> = props => {
  const accessRightCodes =
    useAppSelector(e => e.auth.session.user?.accessRightCodes) || [];
  const { t } = useResource();
  const location = useLocation();

  const links: ApplicationLink[] = [
    {
      content: (
        <StyledFlex key={1} $alignItems="center">
          <StyledNavLink
            to={{ pathname: getPath(AppRouting.OrderCreate) }}
            tabIndex={-1}
          >
            <BlButton color="primary">
              {t(Resources.Orders.Detail.CreateTitle)}
            </BlButton>
          </StyledNavLink>
        </StyledFlex>
      ),
      accessRightCode: null,
    },
    {
      content: (
        <StyledNavLink
          key={2}
          to={{ pathname: getPath(AppRouting.Dashboard) }}
          tabIndex={-1}
        >
          <StyledItem>
            <Typography>{t(Resources.Dashboard.PageName)}</Typography>
          </StyledItem>
        </StyledNavLink>
      ),
      accessRightCode: null,
    },
    {
      content: (
        <StyledNavLink
          key={3}
          to={{ pathname: getPath(AppRouting.Orders) }}
          className={
            location.pathname.includes(`${AppRouting.OrderCreate.path()}/`)
              ? "active"
              : ""
          }
          tabIndex={-1}
        >
          <StyledItem>
            <Typography>{t(Resources.Orders.PageName)}</Typography>
          </StyledItem>
        </StyledNavLink>
      ),
      accessRightCode: null,
    },
    {
      content: (
        <StyledNavLink
          key={4}
          to={{ pathname: getPath(AppRouting.Settings) }}
          tabIndex={-1}
        >
          <StyledItem>
            <StyledSettingsIcon></StyledSettingsIcon>
          </StyledItem>
        </StyledNavLink>
      ),
      accessRightCode: null,
    },
  ];

  return (
    <>
      <StyledWrapper>
        <StyledUnstyledLink to={getPath(AppRouting.Dashboard)}>
          <Logo isUserLogoVisible={true} />
        </StyledUnstyledLink>
        <StyledAppNameWrapper>
          <Typography fontWeight={600}>
            {t(Resources.Common.AppName)}
          </Typography>
        </StyledAppNameWrapper>

        <StyledMenu>
          {links
            .filter(
              ({ accessRightCode }) =>
                !accessRightCode ||
                accessRightCodes.some(e => e === accessRightCode),
            )
            .map((l, i) => {
              return l.content;
            })}

          <StyledFlex $alignItems="center"></StyledFlex>
        </StyledMenu>
      </StyledWrapper>
    </>
  );
};

export { Menu };
