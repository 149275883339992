import {
  EnterpriseServiceListItemDto,
  OrderServiceRate,
  ServiceType,
  UserSupplierCompanyListItemDto,
} from "Api/Api";
import { OrderFormModel } from "Components/Orders/Draft/StepFormValidationSchema";
import { useTaxLabels } from "Hooks/useWithTax";
import { formatCurrency, roundHalfUp } from "Utils/CurrencyUtils";
import { round, sumBy } from "lodash-es";
import { UseFormReturn } from "react-hook-form";

export function usePriceData(
  form: UseFormReturn<OrderFormModel>,
  enterpriseServices: EnterpriseServiceListItemDto[],
  companies: UserSupplierCompanyListItemDto[],
) {
  const serviceRate = form.watch("serviceRate");
  const isFixedServiceRate = serviceRate === OrderServiceRate.FixedPrice;
  const isHourRateServiceRate = serviceRate === OrderServiceRate.HourRate;

  const selectedServices =
    form.watch("services").filter(x => x.isSelected) ?? [];

  const priceWithoutTax = roundHalfUp(
    sumBy(selectedServices, x => x.priceWithoutTax),
  );
  const priceWithTax = roundHalfUp(
    sumBy(selectedServices, x => x.priceWithTax),
  );
  const currencyCode = selectedServices[0]?.currencyCode ?? "CZK";

  const totalHours = isFixedServiceRate
    ? 0
    : round(
        sumBy(selectedServices, x => x.hoursSpent),
        2,
      );

  const hourRateVariants = enterpriseServices
    .find(x => x.type === ServiceType.HourRate && x.isEnabled === true)
    ?.variants.filter(x => x.isEnabled === true);

  const { withTax, withoutTax } = useTaxLabels();
  const hourRateServiceVariantID = form.watch("hourRateServiceVariantID");

  const hourVariant = hourRateVariants?.find(
    x => x.serviceVariantID === Number(hourRateServiceVariantID),
  );

  const supplierCompanyID = form.watch("supplierCompanyID");

  const supplierCompany = companies.find(
    x => x.companyID === supplierCompanyID,
  )!;

  const isVatUsed = supplierCompany?.isVATPayer ?? false;

  const finalPriceWithoutTax = isFixedServiceRate
    ? priceWithoutTax
    : totalHours * (hourVariant?.priceWithoutTax ?? 0);

  const finalPriceWithTax = isFixedServiceRate
    ? priceWithTax
    : totalHours * (isFixedServiceRate ? 1 : hourVariant?.priceWithTax ?? 0);

  return {
    isVatUsed,
    isFixedServiceRate,
    isHourRateServiceRate,
    priceWithoutTax: finalPriceWithoutTax,
    priceWithTax: finalPriceWithTax,
    labelWithTax: withTax(formatCurrency(finalPriceWithTax, currencyCode)),
    labelWithoutTax: withoutTax(
      formatCurrency(finalPriceWithoutTax, currencyCode),
      isVatUsed,
    ),
    currencyCode,
    totalHours,
    hourRateVariants,
    hourVariant,
  };
}
