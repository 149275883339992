import {
  EnterprisePackageServiceSettingsDto,
  getEnterprisesPackageServiceSettings,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getPackageServiceSettingsAsync = createAsyncAction(
  "@servicePackages/SERVICE_SETTINGS_REQUEST",
  "@servicePackages/SERVICE_SETTINGS_RESPONSE",
  "@servicePackages/SERVICE_SETTINGS_FAILURE",
)<void, EnterprisePackageServiceSettingsDto, Error>();

function* getPackageServiceSettings(
  action: ReturnType<typeof getPackageServiceSettingsAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getEnterprisesPackageServiceSettings,
    );

    if (status === 200) {
      yield put(getPackageServiceSettingsAsync.success(data));
      return;
    }

    yield put(
      getPackageServiceSettingsAsync.failure(
        mapAPIErrorResponse(error ?? data),
      ),
    );
  } catch (err) {
    yield put(getPackageServiceSettingsAsync.failure(err as Error));
  }
}
export function* getPackageServiceSettingsSaga() {
  yield takeLatest(
    getType(getPackageServiceSettingsAsync.request),
    getPackageServiceSettings,
  );
}

export const getPackageServiceSettingsSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<EnterprisePackageServiceSettingsDto>(false),
  name: "@servicePackages/getPackageServiceSettings",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<EnterprisePackageServiceSettingsDto>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getPackageServiceSettingsAsync, builder);
    handleSuccessCase(getPackageServiceSettingsAsync, builder);
    handleFailureCase(getPackageServiceSettingsAsync, builder);
  },
});

export const { resetSlice } = getPackageServiceSettingsSlice.actions;
