import { Typography } from "@mui/material";
import {
  AddressType,
  EnterpriseEmailCommunicationMode,
  OrderDto,
  PartyDto,
} from "Api/Api";
import { StyledOrderSummaryText } from "Components/Orders/Summary/OrderSummary";
import { Resources, useResource } from "Translations/Resources";
import {
  formatAddressMunicipality,
  formatAddressStreet,
} from "Utils/AddressUtils";
import { convertIbanToBankAccount } from "Utils/IbanUtils";
import { formatPhoneNumber } from "Utils/PhoneUtils";
import styled from "styled-components";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.spacing(0, 1)};
  margin-bottom: ${props => props.theme.spacing(3)};

  & > * {
    min-height: ${props => props.theme.spacing(2)};
  }
`;

type Props = {
  party: PartyDto;
  order: OrderDto;
  userFormatName?: string | null;
  enterpriseEmailCommunicationMode?:
    | EnterpriseEmailCommunicationMode
    | null
    | undefined;
};

export const Provider: React.FunctionComponent<Props> = props => {
  const { party, order, enterpriseEmailCommunicationMode, userFormatName } =
    props;
  const { t } = useResource();

  const address = party?.addresses?.find(e => e.type === AddressType.Permanent);

  const showUserFormatName =
    !!enterpriseEmailCommunicationMode &&
    !!userFormatName &&
    enterpriseEmailCommunicationMode ===
      EnterpriseEmailCommunicationMode.SupplierCompanyAndUser;
  return (
    <>
      <Typography variant="h2" marginBottom={1}>
        {t(Resources.Orders.Detail.Provider.Title)}
      </Typography>

      {!!party && (
        <>
          {showUserFormatName ? (
            <StyledOrderSummaryText fontWeight={800}>
              {userFormatName}
            </StyledOrderSummaryText>
          ) : (
            <></>
          )}

          <StyledGrid>
            <StyledOrderSummaryText fontWeight={showUserFormatName ? 400 : 800}>
              {!!party.companyName
                ? party.companyName
                : `${party.firstName} ${party.lastName}`}
            </StyledOrderSummaryText>

            <StyledOrderSummaryText>
              {t(Resources.Orders.Detail.Summary.CompanyNumber, {
                companyNumber: party.companyNumber,
              })}
            </StyledOrderSummaryText>
            {!!address ? (
              <div>
                <StyledOrderSummaryText>
                  {formatAddressStreet(address, true)}
                </StyledOrderSummaryText>
                <StyledOrderSummaryText>
                  {formatAddressMunicipality(address)}
                </StyledOrderSummaryText>
              </div>
            ) : (
              <div></div>
            )}

            <StyledOrderSummaryText>
              {t(Resources.Orders.Detail.Summary.TaxNumber, {
                taxNumber: party.taxNumber,
              })}
            </StyledOrderSummaryText>

            <div></div>
            <div></div>

            <StyledOrderSummaryText>
              {t(Resources.Orders.Detail.Summary.Phone, {
                phone: formatPhoneNumber(party.phone),
              })}
            </StyledOrderSummaryText>

            <StyledOrderSummaryText>
              {t(Resources.Orders.Detail.Summary.Iban, {
                iban: `${
                  convertIbanToBankAccount(party.iban)?.accountNumber ?? ""
                }/${convertIbanToBankAccount(party.iban)?.bankCode ?? ""}`,
              })}
            </StyledOrderSummaryText>

            <StyledOrderSummaryText>
              {t(Resources.Orders.Detail.Summary.Email, {
                email: party.email,
              })}
            </StyledOrderSummaryText>

            <StyledOrderSummaryText>
              {t(Resources.Orders.Detail.Summary.VariableSymbol, {
                symbol: order?.number,
              })}
            </StyledOrderSummaryText>
          </StyledGrid>
        </>
      )}
    </>
  );
};
