import { Typography } from "@mui/material";
import { CutoffDialog } from "Components/Settings/Cutoff/CutoffDialog";
import {
  EucsCutoffSettings,
  EucsCutoffSettingsRef,
} from "Components/Settings/Cutoff/Eucs/EucsCutoffSettings";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { BlButton } from "Components/Shared/Buttons/BlButton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { usePrevious } from "Hooks/usePrevious";
import { useAppSelector } from "State/Store";
import { Resources, useResource } from "Translations/Resources";
import { useEffect, useRef, useState } from "react";

export const CutoffNew: React.FunctionComponent = _ => {
  const { t } = useResource();
  const eucsFormRef = useRef<EucsCutoffSettingsRef>(null);

  const eucsFormButtonClick = () => {
    if (eucsFormRef.current) {
      eucsFormRef.current.submitForm();
    }
  };

  const { isDraft, isLoading, error } = useAppSelector(
    state => state.cutoff.post,
  );
  const wasLoading = usePrevious(isLoading);

  useEffect(() => {
    if (wasLoading && !isLoading && !error && !isDraft) {
      setIsDialogOpen(false);
    }
  }, [wasLoading, isLoading, error, isDraft]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <StyledFlex $gap={0.5} $alignItems="center">
        <Typography variant="h2">
          {t(Resources.Settings.Cutoff.New.Title)}
        </Typography>

        <BlDefaultTooltip title={t(Resources.Settings.Cutoff.New.Tooltip)} />
      </StyledFlex>

      <EucsCutoffSettings ref={eucsFormRef}></EucsCutoffSettings>

      <StyledFlex $alignItems="center" $gap={2} $marginTop={2}>
        <BlButton
          color="primary"
          onClick={() => {
            setIsDialogOpen(true);
            eucsFormButtonClick();
          }}
        >
          {t(Resources.Settings.Cutoff.New.Run)}
        </BlButton>
      </StyledFlex>

      {isDialogOpen && <CutoffDialog onClosed={() => setIsDialogOpen(false)} />}
    </>
  );
};
