import { combineReducers } from "@reduxjs/toolkit";
import {
  aresSearchSlice,
  aresSearchSaga,
} from "State/Parties/Slices/AresSearchSlice";
import {
  getPartyDetailSaga,
  getPartyDetailSlice,
} from "State/Parties/Slices/PartyDetailsSlice";
import { all } from "typed-redux-saga";

export const partyReducer = combineReducers({
  aresSearch: aresSearchSlice.reducer,
  details: getPartyDetailSlice.reducer,
});

export function* watchPartySagas() {
  yield all([aresSearchSaga(), getPartyDetailSaga()]);
}
