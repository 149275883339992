import { IdentityProvider } from "Api/Api";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { signInSsoAsync } from "State/Auth/SignIn/SignInSsoSlice";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import { StyledContainer } from "Components/Auth/Styles";
import { UnauthenticatedHeader } from "Components/Layout/UnauthenticatedHeader";

const Progress = styled(CircularProgress)`
  margin-top: ${props => props.theme.spacing(10)};
`;

export const GoogleCallbackPage: React.FunctionComponent = _ => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const code = new URLSearchParams(location.search).get("code") || "";

  React.useEffect(() => {
    dispatch(
      signInSsoAsync.request({
        model: {
          token: code,
          identityProvider: IdentityProvider.Google,
        },
        navigate,
      }),
    );
  }, [code, dispatch, navigate]);

  return (
    <UnauthenticatedLayout title="Přesměrování">
      <UnauthenticatedHeader></UnauthenticatedHeader>
      <StyledContainer>
        <Progress size={"150px"} />
      </StyledContainer>
    </UnauthenticatedLayout>
  );
};
