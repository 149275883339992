import styled from "styled-components";

type Props = {
  isFinished: boolean;
  isRejected: boolean;
};

const StyledStepIcon = styled.div<{
  $isFinished: boolean;
  $isRejected: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-top: 5px;
  background-color: ${props =>
    props.$isFinished
      ? props.theme.palette.primary.main
      : props.$isRejected
      ? props.theme.colors.validation
      : props.theme.colors.workflowIcon};
  color: ${props =>
    props.$isFinished
      ? props.theme.palette.primary.contrastText
      : props.theme.palette.common.white};

  svg {
    width: calc(100% - 12px);
    height: auto;
  }
`;

export const RoundedIcon: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = props => {
  const { isFinished, isRejected, children } = props;

  return (
    <>
      <StyledStepIcon $isFinished={isFinished} $isRejected={isRejected}>
        {children}
      </StyledStepIcon>
    </>
  );
};
