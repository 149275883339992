import { Typography } from "@mui/material";
import { CompanyFormModel } from "Components/Companies/CompanyDetail";
import { VatPayerOrderTaxChange } from "Components/Companies/Detail/VatPayerOrderTaxChange";
import { BlDefaultTooltip } from "Components/Shared/BlTooltipDefault";
import { PartyForm, PartyFormType } from "Components/Shared/Party/PartyForm";
import { StyledFlex } from "Components/Shared/StyledComponents";
import { CompanyEntityType } from "State/Companies/Detail/SaveCompanyDetailSlice";
import { Resources, useResource } from "Translations/Resources";
import { UseFormReturn } from "react-hook-form";

type Props = {
  form: UseFormReturn<CompanyFormModel>;
  isCreate: boolean;
  isVATPayerOrderTaxChangeTypeShown: boolean;
  type: CompanyEntityType;
};

export const BasicInformation: React.FunctionComponent<Props> = props => {
  const { t } = useResource();
  const { form, isCreate, isVATPayerOrderTaxChangeTypeShown, type } = props;

  return (
    <>
      <StyledFlex $alignItems="center" $gap={0.5}>
        <Typography variant="h2">
          {t(Resources.Companies.Detail.BasicInformation.Title)}
        </Typography>
        <BlDefaultTooltip
          title={t(Resources.Companies.Detail.BasicInformation.Tooltip)}
        />
      </StyledFlex>
      <Typography variant="subtitle2" fontStyle={"normal"} marginBottom={1}>
        {t(Resources.Settings.Enterprise.Mode.Description)}
      </Typography>

      <PartyForm
        form={form}
        isLoading={false}
        isPartyTypeEditable={false}
        areContactInformationSeparate={true}
        isBankAccountVisible={true}
        isPartyStatusEditable={false}
        isCompanyNumberEditable={isCreate || type === "user"}
        partyFormType={PartyFormType.Company}
        taxRowChildren={
          <VatPayerOrderTaxChange
            form={form}
            isVATPayerOrderTaxChangeTypeShown={
              isVATPayerOrderTaxChangeTypeShown
            }
          />
        }
      />
    </>
  );
};
