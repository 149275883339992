import {
  UserSupplierCompanyListItemDto,
  getCompaniesUserSupplierCompanies,
} from "Api/Api";
import { createAsyncAction, getType } from "typesafe-actions";
import { call, put, takeLatest } from "typed-redux-saga";
import { createSlice } from "@reduxjs/toolkit";
import { mapAPIErrorResponse } from "Models/Errors/ApiCallError";
import {
  getFetchStateDefaultValue,
  handleFailureCase,
  handleRequestCase,
  handleSuccessCase,
} from "State/Utils";

export const getUserSupplierCompaniesAsync = createAsyncAction(
  "@COMPANIES/GET_USER_SUPPLIER_LIST_REQUEST",
  "@COMPANIES/GET_USER_SUPPLIER_LIST_RESPONSE",
  "@COMPANIES/GET_USER_SUPPLIER_LIST_FAILURE",
)<void, UserSupplierCompanyListItemDto[], Error>();

function* getUserSupplierCompanies(
  action: ReturnType<typeof getUserSupplierCompaniesAsync.request>,
): Generator {
  try {
    const { data, error, status } = yield* call(
      getCompaniesUserSupplierCompanies,
    );

    if (status === 200) {
      yield put(getUserSupplierCompaniesAsync.success(data));
      return;
    }

    yield put(
      getUserSupplierCompaniesAsync.failure(mapAPIErrorResponse(error ?? data)),
    );
  } catch (err) {
    yield put(getUserSupplierCompaniesAsync.failure(err as Error));
  }
}
export function* getUserSupplierCompaniesSaga() {
  yield takeLatest(
    getType(getUserSupplierCompaniesAsync.request),
    getUserSupplierCompanies,
  );
}

export const getUserSupplierCompaniesSlice = createSlice({
  initialState:
    getFetchStateDefaultValue<UserSupplierCompanyListItemDto[]>(false),
  name: "getUserSupplierCompanies",
  reducers: {
    resetSlice: () =>
      getFetchStateDefaultValue<UserSupplierCompanyListItemDto[]>(false),
  },
  extraReducers: builder => {
    handleRequestCase(getUserSupplierCompaniesAsync, builder);
    handleSuccessCase(getUserSupplierCompaniesAsync, builder);
    handleFailureCase(getUserSupplierCompaniesAsync, builder);
  },
});

export const { resetSlice } = getUserSupplierCompaniesSlice.actions;
