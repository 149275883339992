import { Grid, Typography } from "@mui/material";
import { OrderFrequency } from "Api/Api";
import { OrderFormModel } from "Components/Orders/Draft/StepFormValidationSchema";
import { BlDropdown } from "Components/Shared/Inputs/BlDropdown";
import { LookupItem } from "Models/LookupItem";
import { Resources, useResource } from "Translations/Resources";
import { getAllowedOrderFrequencies } from "Utils/Order/OrderFrequencyUtils";
import {
  formatDate,
  getDateToOptions,
  getInitDate,
  parseDate,
} from "Utils/Order/PaymentFrequencyUtils";
import { addDays } from "date-fns";
import { last } from "lodash-es";
import { FieldPath, UseFormReturn } from "react-hook-form";
import styled from "styled-components";

const PageResources = Resources.Orders.Detail.Payment;

const Label = styled(Typography)`
  font-weight: 400 !important;
`;

type Props = {
  form: UseFormReturn<OrderFormModel>;
};

export const PaymentFrequencyDateFields: React.FunctionComponent<
  Props
> = props => {
  const { form } = props;
  const { watch, setValue } = form;

  const paymentPeriodFrom: FieldPath<OrderFormModel> =
    "order.paymentFrequencyDateFrom";
  const paymentPeriodTo: FieldPath<OrderFormModel> =
    "order.paymentFrequencyDateTo";

  const dateFrom = watch("order.paymentFrequencyDateFrom") ?? getInitDate();
  const from = formatDate(dateFrom);

  const dateTo = watch("order.paymentFrequencyDateTo") ?? getInitDate();
  const to = formatDate(dateTo);

  const dateFromOptions: LookupItem[] = Array.from(
    { length: 35 },
    (_, i) => i,
  ).map(x => {
    return {
      Name: formatDate(addDays(getInitDate(), x)),
      Value: formatDate(addDays(getInitDate(), x)),
    };
  });

  const { t } = useResource();
  const paymentFrequency = watch("order.paymentFrequency");
  const orderFrequency = watch("order.frequency");

  const checkOrderFrequency = (from: Date, to: Date) => {
    const allowedOrderFrequencies = getAllowedOrderFrequencies(
      paymentFrequency,
      from,
      to,
    );

    if (!allowedOrderFrequencies.includes(orderFrequency)) {
      const newOrderFrequency =
        (last(allowedOrderFrequencies) as OrderFrequency | undefined) ??
        OrderFrequency.Weekly;

      setValue("order.frequency", newOrderFrequency);
    }
  };

  return (
    <Grid container gap={0.2} mt={1}>
      <Grid item xs={5.9}>
        <Label>{t(PageResources.PaymentFrequencyDate.From.Label)}</Label>
        <BlDropdown
          name={paymentPeriodFrom}
          options={dateFromOptions}
          value={from}
          onChange={value => {
            const parsedValue = parseDate(value as string);
            const from = parsedValue;
            setValue(paymentPeriodFrom, from);
            const options = getDateToOptions(from, paymentFrequency);
            const to = parseDate(options[0].Value);
            checkOrderFrequency(from, to);
            setValue(paymentPeriodTo, to);
          }}
        />
      </Grid>
      <Grid item xs={5.9}>
        <Label>{t(PageResources.PaymentFrequencyDate.To.Label)}</Label>
        <BlDropdown
          name={paymentPeriodTo}
          options={getDateToOptions(dateFrom, paymentFrequency)}
          value={to}
          onChange={value => {
            const to = parseDate(value as string);
            checkOrderFrequency(dateFrom, to);
            setValue(paymentPeriodTo, to);
          }}
        />
      </Grid>
    </Grid>
  );
};
