import { Typography } from "@mui/material";
import styled from "styled-components";

type FontColor = "primary" | "secondary" | "white";

type Props = {
  description: string | null | undefined;
  fontColor: FontColor;
  withPackageServicesName: boolean;
};

const MainText = styled(Typography)<{ $fontColor: FontColor }>`
  color: ${props =>
    props.$fontColor === "white"
      ? "white"
      : props.theme.palette.text[props.$fontColor]};
`;
const SecondaryText = styled(Typography)<{ $fontColor: FontColor }>`
  color: ${props =>
    props.$fontColor === "white"
      ? "white"
      : props.theme.palette.text[props.$fontColor]};
  font-size: 12px;
`;
export const ServiceDescription: React.FunctionComponent<Props> = props => {
  const { description, fontColor, withPackageServicesName } = props;

  if (!description) {
    return null;
  }

  const parts = description.split(";");
  const firstPart = parts[0];
  const rest = parts.slice(1);
  return (
    <>
      <MainText $fontColor={fontColor}>{firstPart}</MainText>
      {withPackageServicesName &&
        rest.map(line => (
          <SecondaryText $fontColor={fontColor} key={line} mt={0.5}>
            {line}
          </SecondaryText>
        ))}
    </>
  );
};
