import { put } from "redux-saga/effects";
import { delay, fork, select } from "typed-redux-saga";
import { RootStateType } from "State/Store";
import { checkIsAuthenticated } from "Utils/AuthUtils";
import { setIsAuthenticated } from "State/Auth/Session/SessionSlice";

function* checkTokenExpiration(): Generator {
  const {
    session: { isAuthenticated, tokenExpiration },
  } = yield* select((state: RootStateType) => state.auth);

  if (!isAuthenticated) {
    return;
  }

  const isUserAuthenticated = checkIsAuthenticated(tokenExpiration);

  if (!isUserAuthenticated) {
    yield put(setIsAuthenticated(false));
  }
}
export function* checkTokenExpirationSaga() {
  while (true) {
    yield fork(checkTokenExpiration);
    yield delay(3000);
  }
}
